import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FileUpload} from '../models/file-upload';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(private httpClient: HttpClient) {
    }

    async uploadImage(uuid: string, file: Blob): Promise<FileUpload> {
        const formData = new FormData();

        formData.append('id', uuid);
        formData.append('file', file);

        return firstValueFrom(this.httpClient.post<FileUpload>(`/app-api/v1/upload/image`, formData));
    }

    downloadImage(uuid: string): Observable<Blob> {
        return this.httpClient.get(`/blob/${uuid}`, {responseType: 'blob'});
    }
}
