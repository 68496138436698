<app-project-jobs-object-search class="p-3" (searchCriteria)="onSearchChange($event)"></app-project-jobs-object-search>

<div class="popup__content p-0 max-h-fit overflow-auto" (scroll)="scrollEnd($event)">
    <ng-container *ngIf="(resultState$|async) as resultState">
        <ng-container *ngIf="resultState.hits.length > 0; else empty">
            <ul class="vwui-list">
                <ng-container *ngFor="let projectObject of resultState.hits">
                    <li class="vwui-list-item vwui-list-item--card" (click)="select(projectObject)">
                        <app-object-detail [paulaObject]="projectObject"></app-object-detail>
                        <app-icon class="vwui-list-item__arrow-right" iconName="chevron-right"></app-icon>
                    </li>
                </ng-container>
            </ul>
        </ng-container>

        <div class="flex p-6 justify-center items-center" *ngIf="resultState.hits.length < totalElements">
            <app-spinner></app-spinner>
        </div>

        <ng-template #empty>
            <app-empty-state-message>
                {{ (resultState.notFoundForObjectType ? 'De gevonden objecten zijn niet geldig voor het huidige formulier' : 'Er zijn geen objecten') | translate }}
            </app-empty-state-message>
        </ng-template>
    </ng-container>
</div>

<ng-content select=".popup__footer"></ng-content>

<button *ngIf="allowObjectCreate && objectTypes?.length > 0" class="floating-button bottom-4 right-4"
        (click)="objectCreateClicked.emit()">
    <app-icon iconName="plus" class="floating-button__icon text-white"></app-icon>
</button>
