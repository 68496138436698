import {
    AfterViewInit,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    Inject,
    input,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, firstValueFrom, merge, Subscription} from 'rxjs';
import {map, tap, withLatestFrom} from 'rxjs/operators';
import {FormImageService} from '../../services/form-image.service';
import {PictureUtil} from '../../utils/picture';
import {ImageViewerService} from '../../services/image-viewer.service';
import {ImageToolConfiguration} from '../../models/image-tool-configuration';
import {ProjectJobAnswerMetaService} from '../../services/project-job-answer-meta.service';
import {ProjectJobAnswerMeta} from '../../models/project-job-answer-meta';
import {ActivatedRoute} from '@angular/router';
import {DrawComponentsSaveState} from '../../classes/draw/draw-components';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Capacitor} from '@capacitor/core';

import {Animation as StatusBarAnimation, StatusBar} from '@capacitor/status-bar';

import 'hammerjs';
import {paramMapGetNumberOrFail} from '../../utils/param-map-util';
import {CdkPortal, DomPortalOutlet} from '@angular/cdk/portal';
import {AnnotatableImage, ImageAnnotationResult} from '../image-annotation-v3/annotatable-image';
import {getLoadedImageElement} from '../../utils/image';
import {AnnotateToolConfigService} from '../../services/annotate-tool-config.service';
import {PopupService} from '../../services/popup.service';
import {EditImageCommentPopupComponent} from './edit-image-comment-popup/edit-image-comment-popup.component';

interface DeltaXY {
    x: number;
    y: number;
}

function createDeltaXY(x: number, y: number): DeltaXY {
    return {x, y};
}

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html'
})
export class ImageViewerComponent implements OnInit, OnDestroy, AfterViewInit {
    image = input.required<AnnotatableImage | null>();

    imageSubscription: Subscription | null = null;

    @Input() canEdit = false;
    @Input() canRemove = false;
    @Input() jobId: number | null = null;

    @Output() closed = new EventEmitter<void>();
    @Output() save = new EventEmitter<ImageAnnotationResult>();
    @Output() remove = new EventEmitter<AnnotatableImage>();

    @ViewChild('image') imageElement: ElementRef | null = null;

    @ViewChild(CdkPortal) portal: CdkPortal | null = null;
    private host: DomPortalOutlet | null = null;

    imageUrl: string | null = null;

    memsafeImageUrl: SafeResourceUrl | null = null;
    originalImage: HTMLImageElement | null = null;

    panEnd = new BehaviorSubject<boolean>(false);
    pinchEnd = new BehaviorSubject<boolean>(false);

    lastDeltaScale = new BehaviorSubject<number>(1);
    lastScale = 1;

    lastDeltaXY = new BehaviorSubject<DeltaXY>(createDeltaXY(0, 0));
    lastXY = createDeltaXY(0, 0);

    scale$ = this.lastDeltaScale.pipe(
        withLatestFrom(this.pinchEnd.pipe(map(() => this.lastScale))),
        map(([deltaScale, startScale]) => {
            deltaScale = startScale * deltaScale;

            if (deltaScale < 1) {
                return 1;
            }

            return deltaScale;
        }),
        tap(scale => this.lastScale = scale)
    );

    xy$ = this.lastDeltaXY.pipe(
        withLatestFrom(
            this.scale$,
            merge(this.panEnd, this.pinchEnd).pipe(
                map(() => this.lastXY)
            )
        ),
        map(([delta, scale, startXY]) => ({x: startXY.x + (delta.x / scale), y: startXY.y + (delta.y / scale)})),
        tap(xy => this.lastXY = xy)
    );

    editing = false;

    imageAnnotationVersion$ = this.annotateToolConfigService.version();
    annotateToolShapes$ = this.annotateToolConfigService.shapeConfig();
    toolConfigV2: ImageToolConfiguration = {
        palette: [],
        tools: []
    };

    metadata: ProjectJobAnswerMeta | null = null;

    constructor(
        private imageService: FormImageService,
        private imageViewerService: ImageViewerService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private annotateToolConfigService: AnnotateToolConfigService,
        private popupService: PopupService,
        @Inject('ProjectJobAnswerMetaService') private projectJobAnswerMetaService: ProjectJobAnswerMetaService,
    ) {
        effect(async () => {
            const imageVisible = this.image() !== null;
            const image = this.image();
            this.imageUrl = image && await this.imageService.getImageUrl(image.modifiedPhotoId ?? image.originalPhotoId);
            this.memsafeImageUrl = this.imageUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(this.imageUrl) : null;

            this.imageViewerService.setVisible(imageVisible);
            const annotationVersion = await firstValueFrom(this.imageAnnotationVersion$);

            if (image && annotationVersion == 2) {
                await this.updateMetadata(image);
            }

            if (imageVisible) {
                try {
                    if (Capacitor.isNativePlatform()) {
                        await StatusBar.hide({animation: StatusBarAnimation.Fade});
                    }
                } catch (e) {
                    console.error(e);
                }

                // Instantly move towards the edit image screen
                if (imageVisible && annotationVersion === 2 && !this.canEdit) {
                    this.editImage();
                }
            } else {
                try {
                    if (Capacitor.isNativePlatform()) {
                        await StatusBar.show({animation: StatusBarAnimation.Fade});
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        })
    }

    async ngOnInit() {
        const annotationVersion = await firstValueFrom(this.imageAnnotationVersion$);
        if (annotationVersion === 2) {
            const projectId = paramMapGetNumberOrFail(this.route.snapshot.parent!.paramMap, 'project');
            const jobId = paramMapGetNumberOrFail(this.route.snapshot.parent!.paramMap, 'job');
            this.projectJobAnswerMetaService.findImageTools(projectId, jobId).then((toolConfig) => {
                this.toolConfigV2 = toolConfig;
            });
        }

        this.imageSubscription = combineLatest([this.scale$, this.xy$]).subscribe(([scale, xy]) => {
            const transforms = [];
            transforms.push(`scale(${scale})`);
            transforms.push(`translate(${xy.x}px, ${xy.y}px)`);

            if (this.imageElement) {
                this.imageElement.nativeElement.style.transform = transforms.join(' ');
            }
        });
    }

    ngAfterViewInit() {
        const hostElement = document.getElementById('app-portal-outlet');
        if (!hostElement) {
            throw new Error('Could not find host element with id app-portal-outlet');
        }
        this.host = new DomPortalOutlet(hostElement);
        this.host.attach(this.portal);
    }

    async ngOnDestroy() {
        this.host?.detach();

        if (this.imageSubscription) {
            this.imageSubscription.unsubscribe();
        }

        try {
            if (Capacitor.isNativePlatform()) {
                await StatusBar.show({animation: StatusBarAnimation.Fade});
            }
        } catch (e) {
            console.error(e);
        }
    }

    editImage() {
        this.editing = true;
    }

    async editDescription() {
        const image = this.image();
        if (image === null) {
            throw new Error('No image to edit description');
        }

        const popupRef = this.popupService.open(EditImageCommentPopupComponent, {
            data: {
                description: image.description
            }
        });

        const newDescription = await popupRef.result<string | null>()
        if (newDescription === null) {
            // User cancelled
            return;
        }

        image.description = newDescription ? newDescription : null;
        this.save.emit({image});
    }

    async cancelEditV2(editedImgSrc?: string) {
        const image = this.image();
        if (editedImgSrc && image !== null) {
            const blob = PictureUtil.dataURItoBlob(editedImgSrc);
            this.save.emit({image , blob});
        }

        this.editing = false;
        this.imageElement = null;
        this.close();
    }

    async cancelEditV3() {
        this.editing = false;
        this.imageElement = null;
        this.close();
    }

    async saveEditedImage(data: string, stopEditing = true) {
        const image = this.image();
        const blob = PictureUtil.dataURItoBlob(data);
        if (image === null) {
            throw new Error('No image to save');
        }
        this.save.emit({image, blob});
        this.editing = !stopEditing;
    }

    async saveEditedImageV2(data: DrawComponentsSaveState) {
        const image = this.image();
        if (image === null) {
            throw new Error('No image to save');
        }
        const questionPosition = paramMapGetNumberOrFail(this.route.snapshot.paramMap, 'question');
        const metadataAnswerProperties = (await this.projectJobAnswerMetaService.getCurrentAnswerForFilename(this.jobId!, questionPosition))!;
        const metadata: ProjectJobAnswerMeta = {
            answer_id: image.originalPhotoId,
            job: metadataAnswerProperties.job,
            comment: data.comment,
            history: data.history,
            file_created_at: null,
            photo_id: image.originalPhotoId,
            position: metadataAnswerProperties.position
        };
        await this.projectJobAnswerMetaService.updateLocalProjectJobAnswerMeta(metadataAnswerProperties, metadata);
    }

    async saveEditedImageV3(data: ImageAnnotationResult) {
        this.save.emit(data);

        this.editing = false;
    }

    async removeImage() {
        const image = this.image();
        if (image === null) {
            throw new Error('No image to remove');
        }
        this.remove.emit(image);
    }

    close() {
        // reset everything
        this.lastXY = createDeltaXY(0, 0);
        this.lastScale = 1;
        if (this.imageElement) {
            this.imageElement.nativeElement.style.transform = '';
            this.imageElement.nativeElement.src = '';
            this.imageElement.nativeElement = null;
            this.imageElement = null;
        }
        this.pinchEnd.next(true);
        this.panEnd.next(true);

        this.closed.emit();
    }

    onPinch(event: { scale: number, deltaX: number, deltaY: number }) {
        this.lastDeltaScale.next(event.scale);
        this.lastDeltaXY.next(createDeltaXY(event.deltaX, event.deltaY));
    }

    onPan(event: { deltaX: number, deltaY: number }) {
        this.lastDeltaXY.next(createDeltaXY(event.deltaX, event.deltaY));
    }

    onPanEnd() {
        this.panEnd.next(true);
    }

    onPinchEnd() {
        this.pinchEnd.next(true);
    }

    onWheelScroll(event: WheelEvent) {
        this.lastDeltaScale.next(event.deltaY > 0 ? 0.9 : 1.1);
        this.pinchEnd.next(true);
    }

    private async updateMetadata(image: AnnotatableImage) {
        try {
            // Clear the metadata to prevent previous metadata from showing when the image background is being swapped
            this.metadata = null;

            const jobId = paramMapGetNumberOrFail(this.route.snapshot.parent?.paramMap, 'job');
            const questionPosition = paramMapGetNumberOrFail(this.route.snapshot.paramMap, 'question');
            const metadataAnswerProperties = await this.projectJobAnswerMetaService.getCurrentAnswerForFilename(jobId, questionPosition);
            const projectJobAnswerMeta = metadataAnswerProperties !== null
                ? await this.projectJobAnswerMetaService.findProjectJobAnswerMeta(metadataAnswerProperties)
                : [];

            const metadata = projectJobAnswerMeta.find((meta) => {
                return meta.answer_id === image.originalPhotoId;
            });

            if (metadata) {
                const originalImageUrl = await this.imageService.getImageUrl(metadata.photo_id);

                // Check if our original image can be retrieved from the server
                try {
                    this.originalImage = await getLoadedImageElement(originalImageUrl);
                } catch (e) {
                    console.warn('Original image does not exist - falling back to no metadata', e);
                    this.originalImage = await getLoadedImageElement(this.imageUrl!);
                }

                // Update the metadata last to prevent race conditions with the original image being loaded before the cropping begins
                this.metadata = metadata;
            } else {
                console.warn('Original image does not exist - falling back to no metadata');
                this.originalImage = await getLoadedImageElement(this.imageUrl!);
            }
        } catch (error) {
            console.warn('Could not find metadata - falling back to no metadata', error);
            this.originalImage = await getLoadedImageElement(this.imageUrl!);
            console.error(error);
        }
    }
}
