import {DrawComponent, DrawComponentActionShape, DrawComponentState} from './draw-component';
import Konva from 'konva';

export class DrawComponentSquare extends DrawComponent {

    readonly drawingShapeDefaultConfig = {
        width: 100,
        height: 100,
    };
    readonly drawingShape: Konva.Rect;

    constructor(
        uuid: string,
        state: DrawComponentState,
    ) {
        super(uuid, DrawComponentActionShape.SQUARE, state);

        this.drawingShape = new Konva.Rect({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);
        this.draw();
    }

}
