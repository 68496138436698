/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import o from "../../core/Accessor.js";
import { byId as e } from "../../core/domUtils.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import { cast as r } from "../../core/accessorSupport/decorators/cast.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
function n(t) {
  return t && "nodeType" in t;
}
function d(t) {
  return t && "function" == typeof t.render;
}
const c = {
  component: "esri-component"
};
let p = class extends o {
  constructor() {
    super(...arguments), this.widget = null;
  }
  destroy() {
    this.node = null, this.widget?.destroy();
  }
  get id() {
    return this._get("id") ?? this.widget?.id ?? this.node?.id;
  }
  set id(t) {
    this._set("id", t);
  }
  set node(t) {
    const o = this._get("node");
    t !== o && (t && t.classList.add(c.component), o && o.classList.remove(c.component), this._set("node", t));
  }
  castNode(t) {
    return this.widget?.destroy(), t ? "string" == typeof t || n(t) ? (this._set("widget", null), e(t)) : (d(t) && !t.domNode && (t.domNode = document.createElement("div")), this._set("widget", t), t.domNode) : (this._set("widget", null), null);
  }
};
t([s()], p.prototype, "id", null), t([s()], p.prototype, "node", null), t([r("node")], p.prototype, "castNode", null), t([s({
  readOnly: !0
})], p.prototype, "widget", void 0), p = t([i("esri.views.ui.Component")], p);
const m = p;
export { m as default };