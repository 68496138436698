import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AnnotateToolConfiguration} from '../models/annotate-tool-configuration';
import {WorkspaceConfigService} from './workspace-config.service';
import {environment} from '../../environments/environment';
import {AnnotateComponentActionShape} from '../classes/annotate/annotate-component';

const defaultConfiguration:  AnnotateToolConfiguration = {
    palette: [],
    tools: [
        {
            title: 'arrow',
            identifier: 'arrow',
            icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25"%3E%3Crect width="14" height="1.5" x="4" y="11.75" fill="%23C0C" rx=".75"/%3E%3Cpath fill="%23C0C" d="M19.21 12.06a.5.5 0 0 1 0 .88l-7.47 4.15a.5.5 0 0 1-.74-.44v-8.3a.5.5 0 0 1 .74-.44l7.47 4.15Z"/%3E%3C/svg%3E',
            component: AnnotateComponentActionShape.ARROW,
            initialisationObject: {
                stroke: '#ffffff',
                strokeWidth: 5,
                pointerLength: 5,
                pointerWidth: 5
            }
        },
        {
            title: 'circle',
            identifier: 'circle',
            icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"%3E%3Ccircle cx="12" cy="12.5" r="7" stroke="%23C0C" stroke-width="1.5"/%3E%3C/svg%3E',
            component: AnnotateComponentActionShape.CIRCLE,
            initialisationObject: {
                stroke: '#ffffff',
                strokeWidth: 6
            }
        },
        {
            title: 'square',
            identifier: 'square',
            icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25"%3E%3Crect width="14" height="14" x="5.5" y="5.5" stroke="%23C0C" stroke-width="1.5" rx="1"/%3E%3C/svg%3E',
            component: AnnotateComponentActionShape.SQUARE,
            initialisationObject: {
                stroke: '#ffffff',
                strokeWidth: 6
            }
        },
        {
            title: 'star',
            identifier: 'star',
            icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25"%3E%3Cpath stroke="%23C0C" stroke-width="1.5" d="M11.82 5.89a.2.2 0 0 1 .36 0l1.9 4.08a.2.2 0 0 0 .16.12l4.47.54a.2.2 0 0 1 .1.35l-3.29 3.06a.2.2 0 0 0-.06.18l.87 4.42a.2.2 0 0 1-.3.22l-3.93-2.2a.2.2 0 0 0-.2 0l-3.93 2.2a.2.2 0 0 1-.3-.22l.87-4.41a.2.2 0 0 0-.06-.19l-3.3-3.06a.2.2 0 0 1 .11-.35l4.47-.54a.2.2 0 0 0 .16-.12l1.9-4.08Z"/%3E%3C/svg%3E',
            component: AnnotateComponentActionShape.ICON,
            initialisationObject: {
                iconName: 'star',
                iconColor: '#fff',
                width: 30,
                height: 30
            }
        },
        {
            title: 'line',
            identifier: 'line',
            icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none"%3E%3Cpath stroke="%23C0C" stroke-linecap="round" stroke-width="1.5" d="M7.5 12.5h10"/%3E%3C/svg%3E',
            component: AnnotateComponentActionShape.PENCIL,
            initialisationObject: {
                stroke: '#ffffff',
                strokeWidth: 5
            }
        }
    ]
};

@Injectable({
    providedIn: 'root'
})
export class AnnotateToolConfigService {
    constructor(
        private workspaceConfigService: WorkspaceConfigService
    ) {
    }

    shapeConfig(): Observable<AnnotateToolConfiguration> {
        return this.workspaceConfigService.getConfiguredValue('imageAnnotationShapes').pipe(
            map(value => {
                if (typeof value === 'string' && value !== '-') {
                    try {
                        const config = JSON.parse(value) as AnnotateToolConfiguration;
                        return  {
                            palette: config.palette ?? defaultConfiguration.palette,
                            tools: [...defaultConfiguration.tools, ...config.tools]
                        }
                    } catch (error) {
                        console.error('Error parsing shape value', error);
                        return defaultConfiguration;
                    }
                } else {
                    return defaultConfiguration;
                }
            }),
        )
    }

    version(): Observable<number> {
        return this.workspaceConfigService.getConfiguredValue('imageAnnotationVersion').pipe(
            map(config => config as number ?? environment.imageOptions.annotationVersion)
        )
    }
}
