// @ts-strict-ignore
import {DrawComponent, DrawComponentActionShape, DrawComponentState} from './draw-component';
import Konva from 'konva';

export class DrawComponentIcon extends DrawComponent {

    readonly drawingShapeDefaultConfig = {};
    readonly drawingShape: Konva.Image;

    constructor(
        uuid: string,
        state: DrawComponentState,
    ) {
        super(uuid, DrawComponentActionShape.ICON, state);

        const image = new Image();
        const imageSource = `${state.config.imageSource}`;

        state.config.image = image;

        this.drawingShape = new Konva.Image({...this.drawingShapeDefaultConfig, ...state.config, image});
        this.drawingLayer.add(this.drawingShape);
        this.draw();

        image.onload = () => {
            this.draw();
        };
        image.src = imageSource;
        image.toString = () => {
            return imageSource;
        };
    }

    /**
     * Sets the state without sending update events
     * Useful for replaying and reloading events
     */
    setState(newState: DrawComponentState) {
        super.setState(newState, ['image', 'imageSource']);
    }
}
