export function createCriticalErrorOverlay(message: string) {
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.right = '0';
    overlay.style.bottom = '0';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';

    document.body.appendChild(overlay);

    const errorContainer = document.createElement('div');
    errorContainer.style.position = 'fixed';
    errorContainer.style.top = '5px';
    errorContainer.style.left = '5px';
    errorContainer.style.right = '5px';
    errorContainer.style.bottom = '5px';
    errorContainer.style.border = '1px solid rgb(215,26,23)';
    errorContainer.style.borderRadius = '5px';
    errorContainer.style.backgroundColor = 'rgb(253,239,239)';
    errorContainer.style.padding = '5px';

    errorContainer.innerText = message;

    document.body.appendChild(errorContainer);
}
