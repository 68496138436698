import {Pipe, PipeTransform} from '@angular/core';
import {parse, format} from 'date-fns';
import {nl} from 'date-fns/locale/nl';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    private static readonly DEFAULT_PRESENTATION_FORMAT = 'dd-MM-yyyy HH:mm';
    private static readonly SUPPORTED_FORMATS = [
        'yyyy-MM-ddTHH:mm:ss',
        'dd-MM-yyyy HH:mm',
        'dd-MM-yyyy',
    ];

    /**
     * Takes a value and formats date
     */
    transform(value: Date | string, dateFormat?: string) {
        if (!value) {
            return '';
        }

        if (value instanceof Date) {
            return format(value, dateFormat || DateFormatPipe.DEFAULT_PRESENTATION_FORMAT, {locale: nl})
        }

        for (const parseFormat of DateFormatPipe.SUPPORTED_FORMATS) {
            const date = parse(value, parseFormat, new Date(), {locale: nl});
            if (!isNaN(date.getTime())) {
                return format(date, dateFormat || DateFormatPipe.DEFAULT_PRESENTATION_FORMAT, {locale: nl});
            }
        }

        console.warn('Cannot format date: ', value);
        return value;
    }
}
