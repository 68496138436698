import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ComponentRef,
    OnDestroy,
    Type,
    ViewChild,
} from '@angular/core';
import {Subject} from "rxjs";
import {PopupContentDirective} from "../../directive/popup-content.directive";
import {PopupConfig} from '../../models/popup-config';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
})
export class PopupComponent implements OnDestroy, AfterViewInit {
    componentRef?: ComponentRef<unknown>;
    childComponentType?: Type<unknown>;
    onClose = new Subject<void>();

    visible = false;

    @ViewChild(PopupContentDirective, { static: true }) modalInsertion!: PopupContentDirective;

    constructor(
        public popupConfig: PopupConfig<unknown>,
        private cd: ChangeDetectorRef,
    ) {
    }

    ngAfterViewInit(): void {
        if (!this.childComponentType) {
            console.warn('No child component type specified');
            return;
        }

        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        this.componentRef?.destroy();
    }

    loadChildComponent(componentType: Type<unknown>): void {
        const viewContainerRef = this.modalInsertion.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentType);
    }

    hide(): void {
        this.visible = false;
        this.onClose.next();
    }
}
