<div class="fixed inset-0 bg-black bg-opacity-40 transition-opacity p-6 z-50 flex"
     [class.opacity-0]="!visible"
     [class.pointer-events-none]="!visible"
     >
    <div class="pb-safe flex flex-col min-h-0 grow justify-end items-center gap-y-4 transition-transform" [class.translate-y-full]="!visible">
        <div class="grid bg-white p-6 grid-cols-1 gap-y-4 w-full border border-neutral-10 rounded">
            <div class="text-center">{{ title }}</div>
            <ng-content></ng-content>
        </div>

        <div (click)="hide()" *ngIf="cancellable" class="grid bg-white px-6 py-3 w-full border border-neutral-10 rounded hover:bg-gray-50 cursor-pointer text-base font-medium text-center">
            Annuleren
        </div>
    </div>
</div>
