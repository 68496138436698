import {Component} from '@angular/core';
import {PopupRef} from '../../helpers/popup-ref';

@Component({
    selector: 'app-popup-header',
    templateUrl: './popup-header.component.html',
    standalone: true,
})
export class PopupHeaderComponent {
    constructor(private popupRef: PopupRef) {
    }

    close(): void {
        this.popupRef.close();
    }
}
