/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { InputHandler as e } from "../../../input/InputHandler.js";
import { onVisibilityChange as i } from "../../../input/VisibilityChange.js";
class t extends e {
  constructor(e, t, s) {
    super(!0), this.view = e, this.keys = t, this._pressed = !1, this._keyToDirection = {
      [t.clockwiseOption1]: "clockwise",
      [t.clockwiseOption2]: "clockwise",
      [t.counterClockwiseOption1]: "counterClockwise",
      [t.counterClockwiseOption2]: "counterClockwise",
      [t.resetOption1]: "reset",
      [t.resetOption2]: "reset"
    }, this.registerIncoming("key-down", s, e => this._handleKeyDown(e)), this.registerIncoming("key-up", s, e => this._handleKeyUp(e)), this.registerIncoming("blur", s, () => this._handleStop()), this._visibilityHandle = i(e => e ? null : this._handleStop());
  }
  onUninstall() {
    this._visibilityHandle?.remove(), this._handleStop();
  }
  _handleKeyDown(e) {
    e.data.repeat || this._handleKey(e, !0);
  }
  _handleKeyUp(e) {
    this._handleKey(e, !1);
  }
  _handleStop() {
    this._pressed && (this._pressed = !1, this.view.mapViewNavigation.stop());
  }
  _handleKey(e, i) {
    const t = e.modifiers;
    if (t.size > 0 && !t.has("Shift") || !this.view.constraints.rotationEnabled) return;
    const s = this._keyToDirection[e.data.key];
    if (this._pressed = null != s, this._pressed) {
      if (e.preventDefault(), i) switch (this.view.mapViewNavigation.begin(), s) {
        case "clockwise":
          this.view.mapViewNavigation.continuousRotateClockwise();
          break;
        case "counterClockwise":
          this.view.mapViewNavigation.continuousRotateCounterclockwise();
          break;
        case "reset":
          this.view.mapViewNavigation.resetRotation();
      } else this._pressed = !1, this.view.mapViewNavigation.stop();
      e.stopPropagation();
    }
  }
}
export { t as KeyRotate };