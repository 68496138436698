import {FormControl, Validators} from '@angular/forms';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {NumberQuestion} from '../../../models/question/number-question';
import {convertToNumber} from '../../../utils/number-utils';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {numberQuestionValidator} from '../../../validators/number-question.validator';
import {ToastrService} from 'ngx-toastr';
import {QuestionTolerance, ToleranceMessage} from '../../../utils/question-tolerance';
import {Subject, Subscription} from 'rxjs';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';

@Component({
    selector: 'app-question-v2-number',
    templateUrl: './question-v2-number.component.html'
})
export class QuestionV2NumberComponent implements OnInit, OnDestroy {
    private currentForm: AnyProjectJobForm | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public currentQuestion: NumberQuestion | null = null;
    public subscriptions: Subscription[] = [];
    public tolerance: ToleranceMessage | null = null;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: NumberQuestion) {
        this.currentQuestion = question;

        this.control.clearValidators();
        this.control.addValidators(numberQuestionValidator(this.currentQuestion.totalDecimal));

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        }

        this.control.updateValueAndValidity();
        this.updateAnswer();
    }

    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true, validators: numberQuestionValidator() });

    isIOS = Capacitor.getPlatform() === 'ios';

    constructor(private toastr: ToastrService) {
    }

    ngOnInit() {
        this.subscriptions.push(this.control.valueChanges.subscribe(value => {
            this.updateTolerance(value);
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    submit() {
        this.doGoForward.next();
    }

    updateTolerance(value: string) {
        if (!this.currentQuestion) {
            return;
        }

        this.tolerance = this.currentQuestion.toleranceEnabled ? QuestionTolerance.validateNumberQuestionTolerance(this.currentQuestion, value) : null;
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    get toleranceMessage(): string {
        if (this.tolerance?.tolerant === false && this.control.value) {
            return this.tolerance.message;
        }

        return '';
    }

    validateAllowedKeys(event: KeyboardEvent) {
        const key = event.key as string;
        const isFloatingPointCharacter = /([.,])+$/.test(key);
        const isAllowedKey = (['Enter', 'Backspace'].includes(key) || /([0-9-])+$/.test(key));

        return isFloatingPointCharacter || isAllowedKey;
    }

    updateValueFromInputEvent(inputEvent: Event) {
        const value = (inputEvent.target as HTMLInputElement).value;
        if (value !== '') {
            // Only update value if raw value we got from the input is not empty
            // Value returns empty string while number is invalid
            this.control.patchValue(this.doConvertToNumber(value));
        }
    }

    doConvertToNumber(value: string): string {
        this.control.markAsTouched();
        return convertToNumber(value);
    }

    async showToastError() {
        if (this.control.errors?.['required']) {
            await this.toastr.error('Het antwoord mag niet leeg zijn');
        } else if (this.control.errors?.['maxDecimals']) {
            await this.toastr.error(`Het antwoord moet exact ${this.currentQuestion?.totalDecimal} ${this.currentQuestion?.totalDecimal === 1 ? 'decimaal' : 'decimalen'} bevatten`);
        } else {
            await this.toastr.error('Het antwoord is geen geldig nummer');
        }
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (latest) {
            this.control.setValue(latest?.value ?? '');
        } else if (this.currentQuestion.preFillEnabled) {
            this.control.setValue(this.currentQuestion.preFillValue ?? '');
        } else {
            this.control.reset();
        }

        this.updateTolerance(this.control.value);
    }
}
