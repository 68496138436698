<div class="searchbar p-3">
    <div class="searchbar__input">
        <div class="searchbar__input-container">
            <app-icon class="searchbar__icon" iconName="search"></app-icon>
            <input
                class="searchbar__text-input"
                type="search"
                [formControl]="searchControl"
                [placeholder]="'Zoek op titel of opdrachtnummer'|translate">
            <button
                *ngIf="searchControl.value"
                class="searchbar__add-tag-button"
                (click)="addTagFromValue()"
                type="button"
            >
                <app-icon iconName="add" [title]="'Voeg toe aan zoek criteria'|translate"></app-icon>
                {{ 'Tag'|translate }} <span class="searchbar__add-tag-button-suffix">{{ 'toevoegen'|translate }}</span>
            </button>
            <button class="searchbar__icon-button" *ngIf="searchControl.value" (click)="clearSearchInput()" type="button">
                <app-icon class="searchbar__icon" iconName="x-mark" [title]="'Leeg zoekveld'|translate"></app-icon>
            </button>
            <button  class="searchbar__icon-button" (click)="scanQrCode()">
                <app-icon class="searchbar__icon" iconName="qr-code-scan"></app-icon>
            </button>
        </div>

        <div *ifFeature="'jobFilterToday'" class="searchbar__input-actions">
            <button type="button" class="searchbar__action" (click)="toggleFilterToday();">
                <app-icon iconName="calendar"></app-icon>
            </button>
        </div>

        <div class="searchbar__input-actions" >
            <button type="button" class="searchbar__action searchbar__action--primary" (click)="openSortModal()">
                <app-icon iconName="sort"></app-icon>
            </button>
        </div>
    </div>

    <div class="searchbar__tags" *ngIf="tags && tags.length > 0">
        <div class="searchbar__tag" *ngFor="let tag of tags">
            <app-icon class="searchbar__icon" iconName="filter"></app-icon>
            <span class="searchbar__tag-label">{{ tag }}</span>
            <button class="searchbar__icon-button" (click)="deleteTag(tag)" type="button">
                <app-icon class="searchbar__icon" iconName="x-mark" [title]="'Zoekfilter verwijderen'|translate"></app-icon>
            </button>
        </div>
    </div>
</div>
