/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { createTask as p } from "../core/asyncUtils.js";
import { deprecated as t } from "../core/deprecate.js";
import o from "../core/Error.js";
import has from "../core/has.js";
import i from "../core/Logger.js";
import { throwIfAborted as s, wrapAbortWithTimeout as r, allSettledValues as a, createResolver as n } from "../core/promiseUtils.js";
import { watch as u, initial as c, whenOnce as l } from "../core/reactiveUtils.js";
import { property as h } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as d } from "../core/accessorSupport/decorators/subclass.js";
import { ViewEventPriorities as w } from "./input/InputManager.js";
function m(e) {
  return null != e && "open" in e && "declaredClass" in e;
}
function f(e) {
  return null != e && "declaredClass" in e && "dockOptions" in e;
}
const y = n => {
  let y = class extends n {
    constructor() {
      super(...arguments), this._popupSetupTask = null, this.popup = {}, this.popupEnabled = !0;
    }
    initialize() {
      this.addHandles([u(() => [this.ui, this.popup], ([e, p], t) => {
        const o = "popup",
          i = "manual";
        if (t) {
          const [e, p] = t;
          e && m(p) && (p.view = null, f(p) && e.remove(p, o));
        }
        e && m(p) && (p.view = this, f(p) && e.add(p, {
          key: o,
          position: i,
          internal: !0
        }));
      }, c), this.on("click", e => {
        this.popup && this.popupEnabled && ("mouse" !== e.pointerType || 0 === e.button) && (m(this.popup) ? this.popup.viewModel.handleViewClick(e) : e.async(async () => {
          await this.setupPopup(), m(this.popup) && !this.destroyed && this.ready && this.popupEnabled && this.popup.viewModel.handleViewClick(e);
        }));
      }, w.WIDGET)]), l(() => this.ready && this.popupEnabled && !this.updating).then(() => {
        import("../widgets/Popup.js");
      });
    }
    destroy() {
      this.destroyed || this.closePopup();
    }
    async openPopup(e) {
      if (m(this.popup)) return this.popup.open(e);
      try {
        if (await this.setupPopup(), !this.popup) return void i.getLogger(this).error(new o("view:null-popup", "Popup is null and can't be opened"));
        this.popup.open(e);
      } catch {}
    }
    closePopup() {
      this._popupSetupTask?.abort(), m(this.popup) && this.popup.close();
    }
    async fetchPopupFeatures(e, p) {
      return await this.when(), this._popupHitsToFeatures(await this._getPopupHits(e, p), p);
    }
    async setupPopup() {
      if (this._popupSetupTask?.abort(), this.popup && !m(this.popup)) return this._popupSetupTask = p(async e => {
        const {
          default: p
        } = await import("../widgets/Popup.js");
        if (s(e), !this.popup || m(this.popup)) return;
        const t = this.popup;
        delete t.open, delete t.close, this.popup = new p(t);
      }), this._popupSetupTask.promise;
    }
    async _popupHitsToFeatures({
      location: e,
      hits: p
    }, t) {
      const o = [],
        i = [];
      let s = !1;
      const n = r(t, has("popup-view-fetch-timeout") ?? P),
        u = e => {
          const p = new g(e);
          return i.push(p), o.push(p.promise), p;
        },
        c = e => {
          const p = i.at(-1);
          return p && p.layerView === e && !s ? p : u(e);
        };
      for (const r of p) if ("graphic" in r) {
        c(r.layerView).graphics.push(r.graphic), s = !1;
      } else o.push(r.layerView.fetchPopupFeaturesAtLocation(r.mapPoint, n)), s = !0;
      i.map(e => e.resolve(n));
      const l = a(o).then(e => e.filter(e => !!e).flat());
      return {
        pendingFeatures: o,
        allGraphicsPromise: l,
        location: e
      };
    }
    async _getPopupHits(e, p) {
      const {
        hits: t,
        location: o
      } = await this.popupHitTest(e);
      s(p);
      const i = [];
      for (const s of t) if ("graphic" in s) {
        if (this._isValidPopupGraphic(s.graphic, p)) {
          const e = this._isValidPopupGraphicsLayerView(s.layerView) ? s.layerView : void 0;
          i.push({
            graphic: s.graphic,
            layerView: e
          });
        }
      } else this._isValidPopupLocationLayerView(s.layerView) && i.push({
        mapPoint: s.mapPoint,
        layerView: s.layerView
      });
      return {
        hits: i,
        location: o
      };
    }
    _isValidPopupGraphic(e, p) {
      return e && !!e.getEffectivePopupTemplate(p?.defaultPopupTemplateEnabled);
    }
    _isValidPopupGraphicsLayerView(e) {
      return !e || (!("layer" in e) || !e.suspended) && "fetchPopupFeaturesFromGraphics" in e;
    }
    _isValidPopupLocationLayerView(e) {
      return (!("layer" in e) || !e.suspended) && "fetchPopupFeaturesAtLocation" in e;
    }
  };
  return e([h({
    cast(e) {
      return !e || m(e) || "object" == typeof e && (e.open = e => (t(i.getLogger(this), "view.popup is no longer created by default. view.popup.open() will stop working when the popup isn't created", {
        replacement: "Use view.openPopup() instead.",
        version: "4.27"
      }), this.openPopup(e)), e.close = () => (t(i.getLogger(this), "view.popup is no longer created by default. view.popup.close() will stop working when the popup isn't created", {
        replacement: "Use view.closePopup() instead.",
        version: "4.27"
      }), this.closePopup())), e;
    }
  })], y.prototype, "popup", void 0), e([h()], y.prototype, "popupEnabled", void 0), y = e([d("esri.views.PopupView")], y), y;
};
class g {
  constructor(e) {
    this.layerView = e, this._resolver = n(), this.graphics = [];
  }
  get promise() {
    return this._resolver.promise;
  }
  resolve(e) {
    const {
      layerView: p,
      graphics: t,
      _resolver: o
    } = this;
    if (!p) return o.resolve(t), o.promise;
    let i;
    return p.fetchPopupFeaturesFromGraphics(t, e).catch(e => (i = e, null)).then(e => {
      e ? o.resolve(e) : o.reject(i);
    }), o.promise;
  }
}
const P = 5e3;
export { y as PopupView };