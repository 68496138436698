import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {shareReplay, switchMap, take, takeLast, tap} from 'rxjs/operators';
import {infiniteScrollObservable} from '../../helpers/pagination';
import {ProjectFormService} from '../../services/project-form.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectForm} from '../../models/project-form';
import {RefreshEvent} from '../pull-to-refresh/pull-to-refresh.component';
import {paramMapGetNumberOrFail} from '../../utils/param-map-util';

@Component({
    selector: 'app-project-forms-list',
    templateUrl: './project-forms-list.component.html'
})
export class ProjectFormsListComponent {

    loadNext$ = new BehaviorSubject<unknown>(null);
    refresh$ = new BehaviorSubject<unknown>(null);
    search$ = new BehaviorSubject<string>('');

    loading = false;
    allPagesLoaded = false;

    @Input() paulaObjectType!: number;
    @Output() selected = new EventEmitter<ProjectForm>();

    projectForm$ = combineLatest([
        this.route.paramMap,
        this.search$,
        this.refresh$
    ]).pipe(
        switchMap(([paramMap, search]) => infiniteScrollObservable(
            this.loadNext$,
            (page) => {
                this.allPagesLoaded = false;
                return this.projectFormService.list(paramMapGetNumberOrFail(paramMap, 'project'), page, this.paulaObjectType, search).pipe(
                    tap(response => {
                        this.allPagesLoaded = response.last;
                    })
                );
            }
        )),
        shareReplay(1)
    );

    constructor(
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        private route: ActivatedRoute,
    ) {}

    select(projectForm: ProjectForm) {
        this.selected.emit(projectForm);
    }

    async loadMore() {
        if (this.loading || this.allPagesLoaded) {
            return;
        }

        this.loading = true;
        this.projectForm$.pipe(take(2), takeLast(1)).subscribe({
            error: err => {
                console.error('Failed to load more forms', err)
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
            }
        })
        this.loadNext$.next(null);
    }

    async doRefresh(event: RefreshEvent) {
        this.projectForm$.pipe(take(2), takeLast(1)).subscribe({
            error: err => {
                console.error('Failed to refresh forms', err);
                event.complete();
            },
            complete: () => event.complete()
        });
        this.refresh$.next(null);
    }

    onSearchChange(event: Event) {
        const input = event.target as HTMLInputElement;
        this.search$.next(input.value);
    }
}
