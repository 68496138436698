<div class="popup__header">Uitloggen?</div>

<div class="popup__content">
    Weet je zeker dat je wilt uitloggen?
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="close()">
        Annuleren
    </button>
    <button class="vwui-btn is-primary" (click)="logout()" [disabled]="loading">
        Uitloggen
        <app-spinner class="absolute right-4" [hidden]="!loading"></app-spinner>
    </button>
</div>
