import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AbstractLayeredFormNode, AnyLayeredFormNode, DatabaseLayeredFormNode, SubtypeLayeredFormNode} from '../../models/layered-form-node';
import {PopupRef} from '../../helpers/popup-ref';
import {AnyFormLayer} from '../../models/form-layer';
import {PopupConfig} from '../../models/popup-config';
import {v4 as generateUuid} from 'uuid';
import {NodeService} from '../../services/node.service';
import {FormService} from '../../services/form.service';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription} from 'rxjs';
import {NodeType} from '../../models/node-type';
import {createCriticalErrorOverlay} from '../../utils/create-critical-error-overlay';
import {FormUtils} from '../../utils/form-utils';
import {PaulaDatabaseRecord} from '../../models/paula-database';
import {Subtype} from '../../models/subtype';
import {switchMap} from 'rxjs/operators';
import {PaulaDatabaseService} from '../../services/paula-database.service';
import {Forms} from '../../utils/forms';
import {getAnsweredReferencedQuestionAttributeValue, getFilteredDatabaseRecords} from '../../utils/database-question-util';
import {controlValueChanges} from '../../utils/form-control-value';

interface NodeTypeState {
    options: TypeOption[];
    showWarning: boolean;
}
interface TypeOption {
    value: PaulaDatabaseRecord | Subtype;
    label: string;
}

@Component({
  selector: 'app-create-node-in-layer-popup',
  templateUrl: './create-node-in-layer-popup.component.html',
})
export class CreateNodeInLayerPopupComponent implements OnInit, OnDestroy {
    public form = new FormGroup({
        type: new FormControl<PaulaDatabaseRecord | Subtype | null>(null, Validators.required),
        name: new FormControl('', Validators.required),
    });
    public ignoreFilterControl = new FormControl<boolean>(false, {nonNullable: true});

    public layerSubject = new BehaviorSubject<AnyFormLayer | null>(null);
    public parent?: AnyLayeredFormNode;
    public form$ = this.formService.openForm$;
    public types$: Observable<NodeTypeState> = this.layerSubject
        .pipe(
            switchMap(layer => {
                if (FormUtils.isDatabaseLayer(layer)) {
                    return combineLatest([
                        this.paulaDatabaseService.get(layer.databaseId),
                        this.formService.openForm$,
                        controlValueChanges(this.ignoreFilterControl)
                    ]).pipe(
                        switchMap(async ([database, form, ignoreFilter]) => {
                            const referencedQuestionAnswer = await getAnsweredReferencedQuestionAttributeValue(
                                form,
                                layer,
                                this.parent
                            );

                            return getFilteredDatabaseRecords(
                                layer,
                                database,
                                referencedQuestionAnswer,
                                ignoreFilter
                            ).then(filtered => ({
                                options: filtered.records.map(record => ({
                                    value: record,
                                    label: record.name,
                                })),
                                showWarning: filtered.showWarning,
                            }));
                        })
                    );
                } else if (FormUtils.isSubtypeLayer(layer)) {
                    return of({
                        options: layer.subtypes.map(subtype => ({
                            value: subtype,
                            label: subtype.name,
                        })),
                        showWarning: false,
                    });
                } else {
                    return [];
                }
            }),
        );

    private subscriptions: Subscription[] = [];

    constructor(
        private formService: FormService,
        @Inject('PaulaDatabaseService')
        private paulaDatabaseService: PaulaDatabaseService,
        private nodeService: NodeService,
        private popupRef: PopupRef,
        {data}: PopupConfig<{ layer: AnyFormLayer, parent: AnyLayeredFormNode }>
    ) {
        this.parent = data?.parent;
        this.layerSubject.next(data?.layer ?? null);
        if (data?.layer.type === 'databaseLayer' && data?.layer.config.userCanIgnoreFilter) {
            this.ignoreFilterControl.enable();
        } else {
            this.ignoreFilterControl.disable();
        }
    }

    ngOnInit() {
        const subscription = this.form.get('type')?.valueChanges.subscribe(value => {
            if (value) {
                this.form.get('name')?.setValue(value.name);
            }
        });
        this.subscriptions.push(this.types$.subscribe(({options}) => {
            if(this.form.controls.type.value === null && options.length === 1) {
                // If there is only one option, select it
                this.form.controls.type.setValue(options[0].value)
            }
        }));

        if (subscription) {
            this.subscriptions.push(subscription);
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    async submit() {
        const layer = await firstValueFrom(this.layerSubject);

        if (!this.form.valid || !layer) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }


        const nodeType = FormUtils.isDatabaseLayer(layer) ? NodeType.DATABASE : NodeType.SUB_TYPE;

        const node: AbstractLayeredFormNode = {
            id: generateUuid(),
            name: this.form.value.name ?? '',
            parent: this.parent?.id ?? null,
            updatedAt: new Date(),
        }
        const form = await firstValueFrom(this.form$);

        if (!form) {
            createCriticalErrorOverlay('Form not found');
            return;
        }
        if (form.type !== 'layeredJobForm') {
            throw new Error('Form is not a layered job form');
        }

        if (nodeType === NodeType.SUB_TYPE) {
            const subtypeNode: SubtypeLayeredFormNode = {
                ...node,
                type: 'subtype',
                subtype: this.form.value.type as Subtype,
            }

            await this.nodeService.createNode(form, nodeType, subtypeNode);
            this.popupRef.close({ node: subtypeNode });
        } else if (nodeType === NodeType.DATABASE) {
            const databaseNode: DatabaseLayeredFormNode = {
                ...node,
                type: 'database',
                layer: layer.id,
                record: this.form.value.type as PaulaDatabaseRecord,
            }

            await this.nodeService.createNode(form, nodeType, databaseNode);
            this.popupRef.close({ node: databaseNode });
        }
    }

    ngSelectCompareWith(a: TypeOption, b: TypeOption['value']) {
        return a.value.name === b.name;
    }
}
