<div class="toolbar">
    <div class="toolbar__left" *ngIf="showLeftBtn">
        <button class="toolbar__custom-back-btn" *ngIf="!defaultHref" (click)="backButtonClick.emit()">
            <app-icon class="is-primary" iconName="chevron-left"></app-icon>
        </button>
        <button class="toolbar__custom-back-btn" *ngIf="defaultHref" [routerLink]="defaultHref">
            <app-icon class="is-primary" iconName="chevron-left"></app-icon>
        </button>
    </div>
    <div class="toolbar__title" [class.center-text]="showLeftBtn" [class.left]="showLogoutBtn">
        <ng-content></ng-content>
        <ng-content select=".logo"></ng-content>
    </div>
    <ng-container *ifFeature="['executorRejectJob']">
        <div class="toolbar__right" *ngIf="showRejectBtn">
            <button (click)="rejectButtonClick.emit()">
                <app-icon class="is-primary" iconName="exclamation"></app-icon>
            </button>
        </div>
    </ng-container>
    <div class="toolbar__right" *ngIf="showAttachmentBtn">
        <button (click)="attachmentButtonClick.emit()">
            <app-icon class="is-primary" iconName="paperclip"></app-icon>
        </button>
    </div>
    <div class="toolbar__right" [class.list]="isListMode" [class.close]="isCloseMode">
        <button *ngIf="rightBtnMode" (click)="rightButtonClick.emit()">
            <app-icon class="is-primary" [iconName]="rightBtnIcon"></app-icon>
        </button>
        <button class="pr-4" *ngIf="showLogoutBtn" (click)="openBugReportModal()">
            <app-icon class="is-primary" iconName="alert-circle"></app-icon>
        </button>
        <app-logout-button *ngIf="showLogoutBtn"></app-logout-button>
    </div>
</div>
