/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getFormatAndQuality as t, encode as i, encodeData as h, getMaximumResolutionScale as n } from "../../support/screenshotUtils.js";
const e = {
  flipY: !0,
  premultipliedAlpha: !0
};
async function o(h, n) {
  const o = await a(h, n),
    {
      format: r,
      quality: l
    } = t(n?.format, n?.quality);
  return i(o, {
    format: r,
    quality: l,
    rotation: 0,
    disableDecorations: !1
  }, e);
}
async function r(t, i) {
  const n = await a(t, i);
  return h(n, e);
}
function a(t, i) {
  const h = d(i || {}, u(t.stage, t.size), t.size, t.padding),
    n = l(i, t.allLayerViews);
  return t.stage.takeScreenshot(h, n, t.backgroundColor, i?.rotation);
}
function l(t = {}, i) {
  if (!t.layers) return;
  const h = [];
  return t.layers.forEach(t => {
    const n = i.find(i => i.layer.id === t.id);
    n?.container && h.push(n.container);
  }), h;
}
function u(t, i) {
  return Math.min(4, n(i, Math.min(4096, t.context.parameters.maxTextureSize)));
}
function d(t, i, h, n) {
  t.ignorePadding && (n = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  });
  let e = null;
  null != t.width && null != t.height ? e = [t.width, t.height] : null == t.width && null != t.height ? e = [t.height, t.height] : null != t.width && null == t.height ? e = [t.width, t.width] : null == t.width && null == t.height && (e = null);
  const o = h[0] - (n.left + n.right),
    r = h[1] - (n.top + n.bottom);
  let a,
    l,
    u = t.area || {
      x: 0,
      y: 0,
      width: o,
      height: r
    };
  if (e) {
    const t = o / r,
      i = e[0] / e[1];
    if (i > t) {
      const t = u.width / i;
      u = {
        x: u.x,
        y: Math.round(u.y + (u.height - t) / 2),
        width: u.width,
        height: Math.round(t)
      };
    } else {
      const t = u.height * i;
      u = {
        x: Math.round(u.x + (u.width - t) / 2),
        y: u.y,
        width: Math.round(t),
        height: u.height
      };
    }
  } else e = [u.width, u.height];
  return e[0] > u.width ? (a = Math.min(e[0] / u.width, i), l = e[0] / u.width / a) : (a = 1, l = e[0] / u.width), {
    cropArea: {
      x: Math.round((u.x + n.left) * a),
      y: Math.round((u.y + n.top) * a),
      width: Math.round(u.width * a),
      height: Math.round(u.height * a)
    },
    outputScale: l,
    resolutionScale: a
  };
}
export { d as createScreenshotRegion, r as takeRawScreenshot, o as takeScreenshot };