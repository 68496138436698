<div class="p-6">
    <app-popup-header>{{ layerName$|async|titlecase }} bewerken</app-popup-header>
    <app-popup-body>
        <div [formGroup]="form" class="space-y-4">
            <div class="space-y-2">
                <label class="text-sm font-medium block w-full">Type</label>

                <select formControlName="type" class="appearance-none px-4 py-3 text-sm rounded border border-neutral-20 focus:border-primary w-full bg-gray-100">
                    <option [ngValue]="type">{{ type.name }}</option>
                </select>
            </div>

            <div class="space-y-2">
                <label for="name" class="text-sm font-medium block w-full">{{ layerName$|async|titlecase }}  naam</label>
                <input type="text" class="appearance-none p-4 text-sm rounded border border-neutral-20 focus:border-primary w-full" [class.text-danger]="form.get('name').errors" formControlName="name" placeholder="Voer een naam in..." id="name">
            </div>

            <button class="vwui-btn is-primary" (click)="submit()">
                Opslaan
            </button>

            <button class="vwui-btn is-danger-outline" (click)="openDeletePopup()">
                Verwijderen
            </button>
        </div>
    </app-popup-body>
</div>
