<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type flex flex-col gap-4" [formGroup]="formGroup">
            @for (question of currentQuestion.config.questions; track question) {
                <div>
                    @switch (question.type) {
                        @case ('text') {
                            <label class="input-label" [for]="question.fieldName">
                                {{ question.label }}
                                @if (question.required) {
                                    <span class="text-danger">*</span>
                                }
                            </label>
                            <input type="text" [id]="question.fieldName" [formControlName]="question.fieldName"/>
                        }
                        @case ('number') {
                            <app-number-input
                                [label]="question.label"
                                [required]="question.required"
                                [formControlName]="question.fieldName">
                            </app-number-input>
                        }
                        @case ('choice') {
                            <label class="input-label mb-2" [for]="question.fieldName">
                                {{ question.label }}
                                @if (question.required) {
                                    <span class="text-danger">*</span>
                                }
                            </label>
                            <ng-select [labelForId]="question.fieldName"
                                       [formControlName]="question.fieldName"
                                       [items]="getSelectItems(question)"></ng-select>
                        }
                    }
                </div>
            }
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="isValid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
</form>
