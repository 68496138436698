import {Component} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PopupRef} from '../../../helpers/popup-ref';
import {PopupHeaderComponent} from '../../popup-header/popup-header.component';
import {PopupBodyComponent} from '../../popup-body/popup-body.component';
import {PopupConfig} from '../../../models/popup-config';

@Component({
    selector: 'app-edit-image-comment-popup',
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        PopupHeaderComponent,
        PopupBodyComponent,
    ],
    templateUrl: './edit-image-comment-popup.component.html'
})
export class EditImageCommentPopupComponent {
    control: FormControl<string>;

    constructor(
        private popupRef: PopupRef,
        config: PopupConfig<{ description: string | null }>
    ) {
        this.control = new FormControl(
            config.data?.description || '', {
                nonNullable: true,
            });
    }

    save(): void {
        this.popupRef.close(this.control.value);
    }

    cancel(): void {
        this.popupRef.close(null);
    }
}
