<div class="popup__header">{{'Tekst toevoegen'|translate}}</div>

<div class="popup__content">
    <input type="text" [formControl]="text" [placeholder]="'Opmerking...'|translate" />
    <div *ngIf="text.invalid && text.dirty && text.errors?.maxlength as maxlength" class="mt-1 text-danger text-sm">
        {{'Tekst overschrijdt de maximale lengte van'|translate}} {{maxlength.requiredLength}} {{'karakters'|translate}}
    </div>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="cancel()">
        {{'Sluiten'|translate}}
    </button>

    <button class="vwui-btn is-primary" (click)="confirm()" [disabled]="text.invalid && text.dirty">
        {{'Toevoegen'|translate}}
    </button>
</div>
