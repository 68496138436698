import {FormUtils} from './form-utils';
import {PaulaDatabase, PaulaDatabaseRecord} from '../models/paula-database';
import {AnyProjectJobForm} from '../models/project-job-form';
import {DatabaseQuestion} from '../models/question/database-question';
import {AnyLayeredFormNode} from '../models/layered-form-node';
import {DatabaseFormLayer} from '../models/database-form-layer';
import {findAllNodeParents} from './node-util';
import {Question} from '../models/question/question';
import {ProjectJobAnswer} from '../models/project-job-answer';

export async function getFilteredDatabaseRecords(
    question: DatabaseQuestion | DatabaseFormLayer,
    paulaDatabase: PaulaDatabase,
    referencedQuestionAnswer: string | null,
    ignoreFilter: boolean
) {
    // show all options if filter is ignored or no filter is configured
    if (ignoreFilter === true || question.config.referencedQuestion === null) {
        return { records: paulaDatabase.records, showWarning: false };
    }

    const records = paulaDatabase.records.filter((record) => {
        const recordValue = question.config.databaseAttribute !== null ? record.data[question.config.databaseAttribute] : null;
        if (referencedQuestionAnswer === null) {
            // If referenced value is null and ignoreFilter is allowed show all options
            // If referenced value is null and ignoreFilter is not allowed then show no options and show warning to user
            return question.config.userCanIgnoreFilter;
        }

        return 0 === recordValue?.localeCompare(referencedQuestionAnswer, undefined, {sensitivity: 'accent'});
    });

    return { records, showWarning: records.length === 0 && !question.config.userCanIgnoreFilter }
}

export async function getAnsweredReferencedQuestionAttributeValue(
    form: AnyProjectJobForm | undefined,
    question: DatabaseQuestion | DatabaseFormLayer | null,
    node: AnyLayeredFormNode | undefined
): Promise<string | null> {
    const referencedQuestionReference = question?.config.referencedQuestion;
    if (!referencedQuestionReference || !form) {
        return null;
    }

    // Determine if the referenced question refers to a database layer
    const layerMatch = /^LAYER\|(\d+)$/
    if (referencedQuestionReference.match(layerMatch)) {
        const layerId = parseInt(referencedQuestionReference.match(layerMatch)![1]);
        if (form.type !== 'layeredJobForm') {
            throw new Error(`Database question invalid reference question, form is not a layered job form but referenced question is a layer`);
        }
        if (node === undefined) {
            throw new Error(`Database question invalid reference question, node is undefined but referenced question is a layer`);
        }

        const referencedLayer = form.layers.find(layer => layer.order === layerId);
        if (!referencedLayer) {
            console.error(`Database question invalid reference question, referenced layer ${layerId} not found`);
            return null;
        }
        if (referencedLayer.type !== 'databaseLayer') {
            console.error(`Database question invalid reference question, referenced layer ${layerId} is not a database layer`);
            return null;
        }

        return getReferencedLayerAttributeValue(question, node, referencedLayer)
    }

    const {referencedQuestion, latestAnswer} = await getReferencedQuestionAndAnswer(form, referencedQuestionReference, node);
    if (referencedQuestion === null) {
        throw new Error(`Couldn't find question with reference '${referencedQuestionReference}'`);
    }
    if (latestAnswer === null) {
        return null;
    }

    if (referencedQuestion.type === 'choice') {
        return referencedQuestion.choices.find(choice => choice.id === latestAnswer.value)?.title || null;
    } else if (referencedQuestion.type === 'database') {
        const referencedAnswer: PaulaDatabaseRecord | null = latestAnswer.value !== null ? JSON.parse(latestAnswer.value) : null;
        const referencedAttribute = question.config.referencedAttribute;
        return getDatabaseRecordAttribute(referencedAnswer, referencedAttribute);
    } else {
        throw new Error(`Database question invalid reference question ${referencedQuestion.type}`);
    }
}

async function getReferencedLayerAttributeValue(
    question: DatabaseQuestion | DatabaseFormLayer,
    node: AnyLayeredFormNode,
    layer: DatabaseFormLayer
): Promise<string | null> {
    // Find all nodes that could be referenced (only parent layers qualify)
    const candidateNodes: AnyLayeredFormNode[] = await findAllNodeParents(node)
    candidateNodes.push(node);
    // Find the node that matches the layer
    for (const candidateNode of candidateNodes) {
        if (candidateNode.type === 'database' && candidateNode.layer === layer.id) {
            return getDatabaseRecordAttribute(candidateNode.record, question.config.referencedAttribute)
        }
    }

    return null;
}

async function getReferencedQuestionAndAnswer(
    form: AnyProjectJobForm,
    referencedQuestionReference: string,
    node: AnyLayeredFormNode | undefined,
): Promise<{
    referencedQuestion: Question | null,
    latestAnswer: ProjectJobAnswer | null
}> {
    if (node === undefined) {
        // Node is undefined for non-layered forms so just get question by reference
        const referencedQuestion = FormUtils.getQuestionByReference(form, referencedQuestionReference, undefined);
        return {
            referencedQuestion,
            latestAnswer: referencedQuestion ? FormUtils.getFormItemLastAnswer(form.answers, referencedQuestion.position, undefined) : null
        }
    }

    // For layered forms the question could be in current layer or in one of the parent layers

    // Find all nodes that could be referenced (only parent layers qualify)
    const candidateNodes: AnyLayeredFormNode[] = await findAllNodeParents(node)
    candidateNodes.push(node);
    // Find the node that matches the layer
    for (const candidateNode of candidateNodes) {
        const referencedQuestion = FormUtils.getQuestionByReference(form, referencedQuestionReference, candidateNode);
        if (referencedQuestion) {
            return {
                referencedQuestion,
                latestAnswer: FormUtils.getFormItemLastAnswer(form.answers, referencedQuestion.position, candidateNode)
            }
        }
    }

    return {
        referencedQuestion: null,
        latestAnswer: null
    };
}

function getDatabaseRecordAttribute(record: PaulaDatabaseRecord | null, referencedAttribute: string | null) {
    if (record === null || referencedAttribute === null) {
        return null;
    }

    if (referencedAttribute === 'name') {
        return record.name;
    } else {
        return record.data[referencedAttribute] || null;
    }
}
