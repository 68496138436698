/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function o(o, e, a) {
  const n = e - o.compared.sourceZoom,
    t = o.halfWindowPanAtZoom(n);
  return -o.halfWindowSize * (a.ascensionFactor * Math.LN2 * o.compared.pan + t) * Math.log(o.compared.sourceZoom / e) / (o.desiredPixelFlow * Math.LN2 * t);
}
function e(o, e, a) {
  const n = 1 / e,
    t = Math.log(o.compared.sourceZoom * n),
    i = 1 / o.desiredPixelFlow,
    r = 1 / Math.LN2,
    d = e - o.compared.sourceZoom,
    c = 1 / d,
    l = (a.ascensionFactor * Math.LN2 * o.compared.pan + o.halfWindowPanAtZoom(d)) / o.halfWindowPanAtZoom(1);
  return o.halfWindowSize * n * i * r * c * l - o.halfWindowSize * t * i * r * c + o.halfWindowSize * t * i * r * l / (d * d);
}
function a(o, e, a) {
  const n = e - o.compared.sourceZoom,
    t = 1 / n,
    i = 1 / e,
    r = Math.log(o.compared.sourceZoom * i),
    d = (a.ascensionFactor * Math.LN2 * o.compared.pan + o.halfWindowPanAtZoom(n)) / o.halfWindowPanAtZoom(1);
  return o.halfWindowSize * t * (-2 * t * i * d + 2 * t * r + 2 * i - 2 * r * d / (n * n) - d / (e * e)) / (o.desiredPixelFlow * Math.LN2);
}
function n(o, e) {
  return -o.halfWindowSize * Math.log(o.compared.sourceZoom / e) / (o.desiredPixelFlow * Math.LN2);
}
function t(o, e) {
  return o.halfWindowSize / (e * o.desiredPixelFlow * Math.LN2);
}
function i(o, e) {
  return -o.halfWindowSize / (e * e * o.desiredPixelFlow * Math.LN2);
}
function r(o, e, a) {
  return -o.compared.pan * o.halfWindowSize * (a.ascensionFactor + a.descensionFactor - 1) / (o.desiredPixelFlow * o.halfWindowPanAtZoom(e));
}
function d(o, e, a) {
  return o.compared.pan * o.halfWindowSize * (a.ascensionFactor + a.descensionFactor - 1) / (o.desiredPixelFlow * o.halfWindowPanAtZoom(e * e));
}
function c(o, e, a) {
  return -2 * o.compared.pan * o.halfWindowSize * (a.ascensionFactor + a.descensionFactor - 1) / (o.desiredPixelFlow * o.halfWindowPanAtZoom(e * e * e));
}
function l(o, e, a) {
  return o.halfWindowSize * (-o.halfWindowPanAtZoom(e) - a.descensionFactor * Math.LN2 * o.compared.pan + o.halfWindowPanAtZoom(o.compared.targetZoom)) * Math.log(e / o.compared.targetZoom) / (o.desiredPixelFlow * Math.LN2 * o.halfWindowPanAtZoom(-e + o.compared.targetZoom));
}
function m(o, e, a) {
  const n = Math.log(e / o.compared.targetZoom),
    t = 1 / o.desiredPixelFlow,
    i = 1 / Math.LN2,
    r = -e + o.compared.targetZoom,
    d = 1 / r,
    c = (-o.halfWindowPanAtZoom(e) - a.descensionFactor * Math.LN2 * o.compared.pan + o.halfWindowPanAtZoom(o.compared.targetZoom)) / o.halfWindowPanAtZoom(1);
  return -o.halfWindowSize * n * t * i * d + o.halfWindowSize * n * t * i * c / (r * r) + o.halfWindowSize * t * i * d * c / e;
}
function h(o, e, a) {
  const n = e - o.compared.targetZoom,
    t = 1 / n,
    i = 1 / e,
    r = Math.log(e / o.compared.targetZoom),
    d = (o.halfWindowPanAtZoom(e) + a.descensionFactor * Math.LN2 * o.compared.pan - o.halfWindowPanAtZoom(o.compared.targetZoom)) / o.halfWindowPanAtZoom(1);
  return o.halfWindowSize * t * (-2 * t * i * d - 2 * t * r + 2 * i + 2 * r * d / (n * n) - d / (e * e)) / (o.desiredPixelFlow * Math.LN2);
}
function s(o, e) {
  return o.halfWindowSize * Math.log(e / o.compared.targetZoom) / (o.desiredPixelFlow * Math.LN2);
}
function f(o, e) {
  return o.halfWindowSize / (e * o.desiredPixelFlow * Math.LN2);
}
function w(o, e) {
  return -o.halfWindowSize / (e * e * o.desiredPixelFlow * Math.LN2);
}
function p(o) {
  const e = o.compared.sourceZoom - o.compared.targetZoom;
  if (0 === e) return o.compared.pan * o.halfWindowSize / (o.desiredPixelFlow * o.halfWindowPanAtZoom(o.compared.sourceZoom));
  const a = Math.LN2 * o.compared.pan,
    n = e,
    t = o.halfWindowPanAtZoom(n),
    i = o.halfWindowSize * Math.log(o.compared.sourceZoom / o.compared.targetZoom) / (o.desiredPixelFlow * Math.LN2 * t);
  return o.compared.sourceZoom <= o.compared.targetZoom ? i * (a - t) : i * (a + t);
}
export { i as ddtAscensionZoomOnly, a as ddtAscensionZoomPan, w as ddtDescensionZoomOnly, h as ddtDescensionZoomPan, c as ddtPanion, t as dtAscensionZoomOnly, e as dtAscensionZoomPan, f as dtDescensionZoomOnly, m as dtDescensionZoomPan, d as dtPanion, n as tAscensionZoomOnly, o as tAscensionZoomPan, p as tBaseLine, s as tDescensionZoomOnly, l as tDescensionZoomPan, r as tPanion };