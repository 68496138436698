import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {WorkspaceConfigService} from './workspace-config.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {WorkspaceConfig} from '../models/workspace-config';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ifFeature]',
})
export class FeatureDirective {
    private featureNamesSubject = new BehaviorSubject<string[]>([]);
    private hasView = false;

    @Input()
    set ifFeature(value: string | string[]) {
        this.featureNamesSubject.next(typeof value === 'string' ? [value] : value);
    }

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        workspaceConfigService: WorkspaceConfigService
    ) {
        combineLatest([
            this.featureNamesSubject,
            workspaceConfigService.workspaceConfig$
        ]).pipe(
            takeUntilDestroyed()
        ).subscribe(([featureNames, config]) => {
            const active = FeatureDirective.featuresActive(featureNames, config);
            if (active && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!active && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }

        });
    }

    private static featuresActive(features: string[], config: WorkspaceConfig | null): boolean {
        if (config === null) {
            return false;
        }

        return features.every((feature) => config.features[feature] === true);
    }

}
