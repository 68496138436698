import {Component, AfterViewChecked, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from '../spinner/spinner.component';

export interface RefreshEvent {
  complete: () => void;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent
  ],
  selector: 'app-pull-to-refresh',
  styleUrl: './pull-to-refresh.component.css',
  templateUrl: './pull-to-refresh.component.html'
})
export class PullToRefreshComponent implements AfterViewChecked {
  @ViewChild('content', {static: true}) private content: ElementRef | null = null;
  @Output() refreshEvent = new EventEmitter<RefreshEvent>();
  @Output() loadMoreEvent = new EventEmitter<void>();

  refreshing = false;
  scrollY: number = 0;
  touchStartPos = 0;
  touchEndPos = 0;

  ngAfterViewChecked() {
    if (!this.content) {
      throw new Error('Content or toolbar element not found');
    }

    const content = this.content.nativeElement;
    const toolbar = document.querySelector('div[role=toolbar]')?.clientHeight;
    const nav = document.querySelector('div[role=navigation]')?.clientHeight;

    content.style.setProperty('--height', `${window.innerHeight - (toolbar ? toolbar : 0) - (nav ? nav : 0)}px`);
  }

  setScrollY(event: Event) {
    const target = event.target as HTMLDivElement;
    this.scrollY = target.scrollTop;

    // Percentage of total scroll height to trigger load more
    const loadMorePercentage = 90;

    if ((this.scrollY + target.clientHeight) / target.scrollHeight * 100 >= loadMorePercentage) {
      this.loadMoreEvent.emit();
    }
  }

  touchStart(event: TouchEvent) {
    this.touchStartPos = event.touches[0].screenY;
  }

  touchEnd(event: TouchEvent) {
    this.touchEndPos = event.changedTouches[0].screenY;

    if (this.scrollY === 0 && this.touchEndPos > this.touchStartPos && this.touchEndPos - this.touchStartPos > 100) {
      this.refresh();
    }
  }

  public refresh() {
    if (this.refreshing) {
      return;
    }
    this.refreshing = true;
    this.refreshEvent.next({
      complete: () => {
        this.stopRefresh();
      }
    })
  }

  public stopRefresh() {
    // Ignore call if we are not refreshing
    if (this.refreshing) {
      setTimeout(() => {
        this.refreshing = false;
      }, 800);
    }
  }
}
