<div class="popup__content">
    <h3>Wilt u de antwoorden uit een eerdere keuring overnemen?</h3>
    <p>
        <b>Opdrachtnaam</b>: {{source.title}}<br/>
        <b>Keurder</b>: {{latestSubmit(source).submittedBy}}<br/>
        <b>Keuringsdatum</b>: {{latestSubmit(source).submittedAt | dateFormat:'dd-MM-yyyy HH:mm:ss'}}<br/>
    </p>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="doCopy(false)">Nee</button>
    <button class="vwui-btn is-primary" (click)="doCopy(true)">Ja</button>
</div>
