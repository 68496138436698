<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>

    <app-form-image-control [formControl]="control" [jobId]="currentForm?.id"
                            [questionPosition]="currentQuestion?.position"></app-form-image-control>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="control.valid"></app-question-footer>
