<div class="question-heading px-6" *ngIf="question$ | async as question">
    <div class="question-heading__chapter" *ngIf="chapter$ | async as chapter">{{ chapter | chapterTitle }}</div>
    <div class="question-heading__chapter" *ngIf="node$ | async as node">
        <app-node-breadcrumbs *ngIf="nodePath$ | async as path" [path]="path" [current]="node.name"></app-node-breadcrumbs>
    </div>
    <div class="question-heading__title">{{ question | questionTitle }} <span class="text-danger" *ngIf="question.required">*</span></div>
</div>

<div class="question-heading px-6" *ngIf="isLocationQuestion$ | async as question">
    <div class="question-heading__title">Locatie</div>
</div>
