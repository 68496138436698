<div class="p-6">
    <app-project-job-form-submit-btn-v2 [canSubmit]="canSubmit$ | async"></app-project-job-form-submit-btn-v2>
</div>

<ng-container *ngIf="nodes$ | async as nodes; else loader">
    <app-tree class="block pb-safe-offset-24">
        <app-tree-level *ngFor="let node of rootNodes$ | async"
                        [tree]="nodes" [node]="node" [layers]="(form$ | async)?.layers"
                        [form]="form$ | async" [nodeStatuses]="(nodeStatuses$ | async) || {}"
                        (contextMenuTapped)="openEditNodePopup($event, nodes)">
        </app-tree-level>
    </app-tree>

    <app-empty-state-message *ngIf="nodes.length === 0">
        <div class="max-w-[15rem] text-neutral-40">
            <h5>Overzicht leeg</h5>
            <p>Druk op de toevoegen knop om een {{ leafLayer?.name | lowercase }} of sectie toe te voegen.</p>
        </div>
    </app-empty-state-message>
</ng-container>

<div class="fixed bottom-0 inset-x-0 pb-safe bg-white shadow-minimal">
    <div class="p-6">
        <button class="vwui-btn is-primary inline-flex gap-2 items-center" (click)="addSheet.show()">
            <app-icon iconName="plus"></app-icon>
            Toevoegen
        </button>
    </div>
</div>

<ng-template #loader>
    <div class="flex justify-center items-center">
        <app-spinner></app-spinner>
    </div>
</ng-template>

<app-action-sheet #addSheet title="Toevoegen">
    <app-action-sheet-button (click)="addNode(true); addSheet.hide()">Nieuw {{ leafLayer?.name | lowercase }} toevoegen</app-action-sheet-button>
    <app-action-sheet-button (click)="addNode(); addSheet.hide()">Nieuwe sectie toevoegen</app-action-sheet-button>
</app-action-sheet>
