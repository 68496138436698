<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <app-tolerance-message *ngIf="tolerance" [tolerance]="tolerance"></app-tolerance-message>

        <div class="question-type is-disabled" *ngIf="showPreviousAnswer">
            <ng-container *ngTemplateOutlet="field; context: { group: formulaFormForTolerance }"></ng-container>
        </div>

        <div class="question-type" [class.mt-4]="showPreviousAnswer">
            <ng-container *ngTemplateOutlet="field; context: { group: formulaForm }"></ng-container>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="isValid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
</form>

<ng-template #field let-group="group">
    <div [formGroup]="group" *ngIf="outputs$(group) | async as outputs">
        <ng-container *ngFor="let field of currentQuestion.formulaFields.fields" [ngSwitch]="field.type">
            <div class="mb-2" *ngSwitchCase="'input'">
                <app-number-input *ngIf="formulaForm?.controls[field.fieldName] as control" [formControl]="control" [label]="field.label">
                </app-number-input>

                <span class="text-danger" *ngIf="formulaForm?.controls[field.fieldName].hasError('pattern')">Antwoord is geen geldig getal</span>
            </div>

            <div *ngSwitchCase="'output'">
                <label class="input-label">{{ field.label }}</label>
                <p class="font-size-15 question-read-only-value">{{ outputs[field.fieldName] || '&nbsp;' }}</p>
            </div>
        </ng-container>
    </div>
</ng-template>
