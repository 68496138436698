import {Component, EventEmitter, Input, Output} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {AnyProjectJobForm, ProjectJobStatus} from '../../models/project-job-form';
import {ToastrService} from 'ngx-toastr';
import {FillFormAgainModalComponent} from '../fill-form-again-modal/fill-form-again-modal.component';
import {FormUtils} from '../../utils/form-utils';
import {QuestionTolerance} from '../../utils/question-tolerance';
import {ActivatedRoute, Router} from '@angular/router';
import {FormSyncService} from '../../services/form-sync.service';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../services/form.service';
import {numberParamInNestedRouteOrFail} from '../../utils/param-map-util';
import {NodeService} from '../../services/node.service';
import {PopupService} from '../../services/popup.service';
import {RejectInspectionFormModalComponent} from '../reject-inspection-form-modal/reject-inspection-form-modal.component';

@Component({
  selector: 'app-project-job-form-submit-btn-v2',
  templateUrl: './project-job-form-submit-btn-v2.component.html',
})
export class ProjectJobFormSubmitBtnV2Component {
    @Input() public canSubmit = false;
    @Output() public finishedSubmittingForm = new EventEmitter<void>();

    public submitPending$ = this.formService.submitPending$;
    public openForm$: Observable<AnyProjectJobForm | undefined> = this.formService.openForm$

    constructor(
        private toastr: ToastrService,
        private formService: FormService,
        private formSyncService: FormSyncService,
        private translationService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private nodeService: NodeService,
        private popupController: PopupService,
        private router: Router
    ) {
    }

    async submit() {
        const submitPending = await firstValueFrom(this.submitPending$);

        if (!this.canSubmit || submitPending) {
            return;
        }

        await this.saveAndSubmit()
    }

    async saveAndSubmit() {
        try {
            this.formService.submitPending$.next(true);
            const form = await firstValueFrom(this.openForm$);
            if (form === undefined) {
                throw new Error('No current form');
            }

            const transitionStatus = await this.determineFormApprovedOrRejected(form);
            await this.formSyncService.transition(
                this.projectId,
                this.jobId,
                transitionStatus
            );

            if (transitionStatus === ProjectJobStatus.Rejected) {
                this.popupController.open(RejectInspectionFormModalComponent, {
                    data: {
                        projectId: this.projectId,
                        jobId: this.jobId
                    }
                });
            } else {
                const synced = await this.formSyncService.isJobSynchronized(this.jobId)
                  && await this.nodeService.allNodesSynced(form)
                const message = this.translationService.instant(synced ? 'TOAST.form_sync_success' : 'TOAST.form_background_sync_success');
                await this.toastr.success(message);

                if (form !== null && form.askExecutorToFillFormAgain) {
                    this.popupController.open(FillFormAgainModalComponent, {
                        data: {
                            source: form
                        }
                    });
                }

                await this.closeForm();
            }
        } catch (error) {
            await this.toastr.error('Versturen mislukt');
            console.error('Submit failed', error);
        } finally {
            this.finishedSubmittingForm.next();
            this.formService.submitPending$.next(false);
        }
    }

    async closeForm() {
        await this.router.navigate(['projects', this.projectId, 'jobs']);
    }

    /**
     * We can only determine if the form needs to be rejected or not at this point, because we cannot do HTTP-requests here.
     * The actual transition will be determined in the form-sync service based on if the project requires verification or not.
     */
    private async determineFormApprovedOrRejected(form: AnyProjectJobForm): Promise<ProjectJobStatus.Approved | ProjectJobStatus.Rejected> {
        if (!form) {
            return ProjectJobStatus.Approved;
        }

        const questions = FormUtils.isLayeredForm(form)
            ? (await this.nodeService.findNodesByJobFormId(form.id)).flatMap(node => FormUtils.questionsForNode(form, node)
                .map((question) => ({ node, question })))
            : form.chapters.flatMap(chapter => chapter.questions
                .map((question) => ({ node: undefined, question })));

        for (const { node, question } of questions) {
            if (FormUtils.isQuestionVisible(form, question.position, node)) {
                const answer = FormUtils.getFormItemLastAnswer(form.answers, question.position, node);

                if(answer && !QuestionTolerance.getToleranceMessage(question, answer.value).tolerant) {
                    return ProjectJobStatus.Rejected;
                }
            }
        }

        return ProjectJobStatus.Approved;
    }

    get projectId() {
        return numberParamInNestedRouteOrFail(this.activatedRoute.root.snapshot, 'project');
    }

    get jobId() {
        return numberParamInNestedRouteOrFail(this.activatedRoute.root.snapshot, 'job');
    }
}
