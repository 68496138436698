/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as r } from "../../../core/accessorSupport/decorators/subclass.js";
let n = class extends t {
  constructor(e) {
    super(), this.nativeIndex = null, this._detectedDeviceType = "unknown", "standard" === e.mapping ? this._detectedDeviceType = "standard" : i.test(e.id) ? this._detectedDeviceType = "spacemouse" : this._detectedDeviceType = "unknown", this.nativeIndex = e.index;
  }
  get native() {
    const e = navigator.getGamepads ? navigator.getGamepads() : [];
    return null != this.nativeIndex && this.nativeIndex < e.length ? e[this.nativeIndex] : null;
  }
  get deviceType() {
    return this._detectedDeviceType;
  }
  get axisThreshold() {
    return a[this.deviceType];
  }
};
e([s({
  nonNullable: !0,
  readOnly: !0
})], n.prototype, "nativeIndex", void 0), e([s({
  type: String,
  readOnly: !0
})], n.prototype, "deviceType", null), e([s({
  type: Number,
  readOnly: !0
})], n.prototype, "axisThreshold", null), n = e([r("esri.views.input.gamepad.GamepadInputDevice")], n);
const o = n,
  i = new RegExp("^(3dconnexion|space(mouse|navigator|pilot|explorer))", "i"),
  a = {
    standard: .15,
    spacemouse: .025,
    unknown: 0
  };
export { o as default };