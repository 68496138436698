<div class="popup__header" *ngIf="title">
    {{ title }}
</div>

<div class="popup__content">
    <div id="qr-code-scanner"></div>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="cancel()">{{ 'Annuleer'|translate }}</button>
</div>
