import {Capacitor} from '@capacitor/core';
import {environment} from '../../environments/environment';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import ClearCache from '../plugins/clear-cache-plugin';
import {FileUtils} from './file-utils';

declare const ImageBlobReduce: () => {
    _calculate_size: (env: {
        image: {
            width: number;
            height: number;
        },
        transform_width: number;
        transform_height: number
    }) => unknown,
    toBlob: (blob: Blob) => Promise<Blob>
};
const resizer = ImageBlobReduce();

export interface BlobWithExif {
    blob: Blob;
    exif: { [key: string]: string } | null;
}

export class PictureUtil {

    // Same as in ImaginaryProperties.kt
    static maxWidth = environment.imageOptions.imageWidth;
    static maxHeight = environment.imageOptions.imageHeight;

    static async getPicturesFromGallery(maxPictureCount = 1, showLoader: () => Promise<unknown>): Promise<BlobWithExif[]> {
        /**
         * On Native platforms show loader before picking images because the native plugins do some processing in the background after
         * returning to the app and before resolving the picked images
         */
        if (Capacitor.isNativePlatform()) {
            await showLoader()
        }

        const images = await Camera.pickImages({
            quality: 100,
            limit: maxPictureCount
        });

        /**
         * On web only show loader after we get the images, this is needed because on web the promise never rejects if picking is cancelled
         */
        if (Capacitor.isNativePlatform() == false) {
            await showLoader();
        }

        const resizePromises = images.photos.slice(0, maxPictureCount).map(image => {
            return fetch(image.webPath)
                .then(response => response.blob())
                .then(async blob => {
                    // Detect mime mismatch by magic bytes
                    const detectedMimeType = await PictureUtil.detectMimeType(blob);

                    if (!blob.type.startsWith('image/')) {
                        throw new Error('Unsupported File Type');
                    }
                    if (detectedMimeType !== null && detectedMimeType !== blob.type) {
                        console.warn('Mime mismatch', blob.type, detectedMimeType);
                        return PictureUtil.resizeBlob(blob.slice(0, blob.size, detectedMimeType))
                    }

                    return PictureUtil.resizeBlob(blob);
                })
                .then(resizedBlob => {
                    URL.revokeObjectURL(image.webPath);

                    return {
                        blob: resizedBlob,
                        exif: image.exif
                    };
                });
        });
        const output = await Promise.all(resizePromises);

        // Remove the image from the device after we have consumed it on android
        // As the capacitor camera places it in a non-cached folder
        if (Capacitor.getPlatform() === 'android') {
            await ClearCache.clearCache();
        }

        return output;
    }

    /**
     * This function never returns more than 1 picture
     */
    static async getPictureFromCamera(showLoader: () => Promise<unknown>): Promise<BlobWithExif[]> {
        try {
            const imageData = await Camera.getPhoto({
                source: CameraSource.Camera,
                resultType: CameraResultType.Uri,
                correctOrientation: true,
                allowEditing: false,
                quality: 100
            });

            await showLoader();

            const blob = await fetch(imageData.webPath!).then(r => r.blob());
            const resizedBlob = await PictureUtil.resizeBlob(blob);
            URL.revokeObjectURL(imageData.webPath!);

            // Remove the image from the device after we have consumed it on android
            // As the capacitor camera places it in a non-cached folder
            if (Capacitor.getPlatform() === 'android') {
                await ClearCache.clearCache();
            }

            return [{
                blob: resizedBlob,
                exif: imageData.exif,
            }];
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Resize image to fit the max image dimensions and keep aspect ratio
     * @param blob image data
     * @param maxWidth max image width
     * @param maxHeight max image height
     */
    static async resizeBlob(blob: Blob, maxWidth = PictureUtil.maxWidth, maxHeight = PictureUtil.maxHeight) {
        try {
            resizer._calculate_size = (env) => {
                const scaleX = maxWidth / env.image.width;
                const scaleY = maxHeight / env.image.height;
                const scale = Math.min(scaleX, scaleY, 1);

                env.transform_width = Math.round(env.image.width * scale);
                env.transform_height = Math.round(env.image.height * scale);

                return env;
            };

            return await resizer.toBlob(blob);
        } catch (e) {
            console.warn('resizeBlob failed', e);
            // Fallback for unsupported image types
            return blob;
        }
    }

    static dataURItoBlob(dataURI: string, sliceSize = 512) {
        // convert base64 to raw binary data held in a string
        const base64Data = dataURI.split(',')[1];
        // separate out the mime component
        const contentType = dataURI.split(',')[0].split(':')[1].split(';')[0];
        return FileUtils.base64ToBlob(base64Data, contentType, sliceSize);
    }

    static async detectMimeType(blob: Blob): Promise<string | null> {
        // Detect mime mismatch by magic bytes
        const arrayBuff = await blob.slice(0, 8).arrayBuffer()
        const uint8Array = new Uint8Array(arrayBuff);
        if (uint8Array.byteLength >= 3 && uint8Array[0] === 0xFF && uint8Array[1] === 0xD8 && uint8Array[2] === 0xFF) {
            return 'image/jpeg';
        }
        if (uint8Array.byteLength >= 8
            && uint8Array[0] === 0x89
            && uint8Array[1] === 0x50
            && uint8Array[2] === 0x4E
            && uint8Array[3] === 0x47
            && uint8Array[4] === 0x0D
            && uint8Array[5] === 0x0A
            && uint8Array[6] === 0x1A
            && uint8Array[7] === 0x0A
        ) {
            return 'image/png';
        }
    }
}
