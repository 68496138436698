<div class="searchbar" (focusin)="$event.stopPropagation()">
    <div class="searchbar__input">
        <div class="searchbar__input-container">
            <app-icon class="searchbar__icon" iconName="search"></app-icon>
            <ng-container [ngSwitch]="searchType">
                <span *ngSwitchCase="'qrCode'" class="searchbar__qr-code-value">QR-code: {{ searchControl.value }}</span>
                <input
                    *ngSwitchCase="'text'"
                    class="searchbar__text-input"
                    type="search"
                    [formControl]="searchControl"
                    [placeholder]="'Zoek object'|translate">
            </ng-container>
            <button class="searchbar__icon-button" *ngIf="searchControl.value" (click)="clearSearchInput()" type="button">
                <app-icon class="searchbar__icon" iconName="x-mark" [title]="'Leeg zoekveld'|translate"></app-icon>
            </button>
            <button  class="searchbar__icon-button" (click)="scanQrCode()">
                <app-icon class="searchbar__icon" iconName="qr-code-scan"></app-icon>
            </button>
        </div>
    </div>
</div>
