import Dexie, {Table} from 'dexie';
import {Project} from '../models/project';
import {PaulaDatabaseWithLastModified} from '../models/paula-database';
import {createCriticalErrorOverlay} from '../utils/create-critical-error-overlay';
import {QueuedNodeSync} from '../models/queued-node-sync';

import {IndexedLayerNode} from '../models/layered-form-node';
import {environment} from '../../environments/environment';

// Workaround for TS not seeing the debug property on Dexie
(Dexie as unknown as {debug: boolean}).debug = !environment.production;

export class AppDB extends Dexie {
    projects!: Table<Project, number>
    paulaDatabases!: Table<PaulaDatabaseWithLastModified, number>
    nodes!: Table<IndexedLayerNode, string>
    nodeSyncQueue!: Table<QueuedNodeSync, string>

    constructor() {
        super('assetcheck');
        /**
         * Setup schema
         * IMPORTANT: Only specify indexed / searchable fields here, other fields don't need to be specified to be stored
         * @see https://dexie.org/docs/Tutorial/Design#database-versioning
         *
         * Indexes surrounded by [] are compound indexes, meaning they are indexed by the combination of the fields
         * Dexie also automatically adds a virtual index on prefixes of compound indexes, so [a, b, c] also has indexes on [a, b] and [a]
         * @see https://dexie.org/docs/Compound-Index
         */
        this.version(6).stores({
            projects: 'id,name',
            paulaDatabases: 'id',
            nodes: 'id,parent,[jobFormId+parent]',
            nodeSyncQueue: 'id,jobForm.id',
        })

        this.open().catch(err => {
            if (err instanceof Error && err.name === 'VersionError') {
                createCriticalErrorOverlay('CRITICAL ERROR: Browser database version is higher than source-code version. Please clear your browser storage and reload the page.');
                console.error(`Critical error opening dexie, ${err.message}`, err);
                throw err;
            }

            console.error('Failed to open db', err);
            throw err;
        })
    }
}

export const db = new AppDB()
