import {Question} from '../models/question/question';
import {TableQuestion} from '../models/question/table-question';
import {FormulaQuestion} from '../models/question/formula-question';
import {buildFormulaEvaluator} from './formula-evaluator';

export type ToleranceMessage = { tolerant: true; } | { tolerant: false; message: string };

export class QuestionTolerance {
    static getToleranceMessage(question: Question | null, value: string | null): ToleranceMessage {
        if (!value) {
            return {tolerant: true};
        }

        switch (question?.type) {
            case 'number':
                return this.validateNumberQuestionTolerance(question, value);
            case 'choice':
                return this.validateChoiceQuestionTolerance(question, value);
            case 'table':
            case 'list':
                return this.validateTableQuestionTolerance(question, value);
            case 'formula':
                return this.validateFormulaQuestionTolerance(question, value);
            default:
                return {tolerant: true};
        }
    }

    static validateChoiceQuestionTolerance(question: Question, value: string | string[]): ToleranceMessage {
        if (!question.toleranceEnabled) {
            return {tolerant: true};
        }

        const toleranceChoices = question.toleranceValue ? question.toleranceValue.split(',') : [];
        const selectedChoices = Array.isArray(value) ? value : value.split(',');

        // Not tolerant if 1 selected answer is not in tolerant choices
        if (!selectedChoices.every(choice => toleranceChoices.includes(choice))) {
            return {
                tolerant: false,
                message: 'Let op: een geselecteerde waarde voldoet niet aan de gestelde tolerantie'
            };
        }

        return {tolerant: true};
    }

    static validateNumberQuestionTolerance(question: Question, value: string): ToleranceMessage {
        if (!question.toleranceEnabled) {
            return {tolerant: true};
        }

        const tolerance = question.toleranceValue ? question.toleranceValue.split('^') : [];

        return {
            tolerant: this.validateNumberTolerance(value, tolerance),
            message:
                tolerance[0] === tolerance[1]
                    ? `Let op: de ingevoerde waarde voldoet niet aan de tolerantie van ${tolerance[0]}`
                    : `Let op: de ingevoerde waarde voldoet niet aan de tolerantie van minimaal ${tolerance[0]} en maximaal ${tolerance[1]}`
        };
    }

    static validateTableQuestionTolerance(question: TableQuestion, value: string | string[]): ToleranceMessage {
        const rowValues = Array.isArray(value) ? value : value.split(',');

        const rowsTolerant = this.validateTableRowTolerance(question, rowValues);
        if (!rowsTolerant.tolerant) {
            return rowsTolerant;
        }

        const averageTolerant = this.validateAverageTolerance(question, rowValues);
        if (!averageTolerant.tolerant) {
            return averageTolerant;
        }

        return {tolerant: true};
    }

    private static validateTableRowTolerance(question: TableQuestion, rowValues: string[]): ToleranceMessage {
        if (!question.toleranceEnabled) {
            return {tolerant: true};
        }

        const tolerance = question.toleranceValue ? question.toleranceValue.split('^') : [];
        let intolerantRowName = null;

        for (let i = 0; i < rowValues.length; i++) {
            if (rowValues[i] && !this.validateNumberTolerance(rowValues[i], tolerance)) {
                intolerantRowName = question.questionLabel + ' ' + (i + 1);
                break;
            }
        }

        return {
            tolerant: intolerantRowName === null,
            message:
                tolerance[0] === tolerance[1]
                    ? `Let op: de ingevoerde waarde voor de rij: '${intolerantRowName}' voldoet niet aan de tolerantie`
                    + ` van ${tolerance[0]}`
                    : `Let op: de ingevoerde waarde voor de rij: '${intolerantRowName}' voldoet niet aan de tolerantie`
                    + ` van minimaal ${tolerance[0]} en maximaal ${tolerance[1]}`
        };
    }

    private static validateAverageTolerance(question: TableQuestion, rowValues: string[]): ToleranceMessage {
        if (!question.averageTolerance) {
            return {tolerant: true};
        }

        const tolerance = question.averageTolerance ? question.averageTolerance.split('^') : [];
        const filledValues = rowValues.filter(it => it !== '');
        const total = filledValues.map(it => this.convertToNumber(it)).reduce((acc, current) => acc + current, 0);

        const average = total / filledValues.length;

        const tolerant = !(+average < +tolerance[0] || +average > +tolerance[1]);

        const averageString = ('' + Math.round(average * 100) / 100).replace('.', ',');

        return {
            tolerant: tolerant,
            message:
                tolerance[0] === tolerance[1]
                    ? `Let op: de gemiddelde waarde is: ${averageString} en voldoet niet aan de tolerantie van ${tolerance[0]}`
                    : `Let op: de gemiddelde waarde is: ${averageString} en voldoet niet aan de tolerantie`
                    + ` van minimaal ${tolerance[0]} en maximaal ${tolerance[1]}`
        };
    }

    private static validateNumberTolerance(value: string, tolerance: string[]): boolean {
        const numberValue = this.convertToNumber(value);
        // Validate value against min - max tolerance
        return !(+numberValue < +tolerance[0] || +numberValue > +tolerance[1]);
    }

    private static convertToNumber(value: string): number {
        return +('' + value).replace(',', '.');
    }

    static validateFormulaQuestionTolerance(question: FormulaQuestion, value: string | object): ToleranceMessage {
        try {
            const formulaAnswer = typeof value === 'string' ? JSON.parse(value) : value;
            const evaluateFormula = buildFormulaEvaluator(question.formulaFields.fields, formulaAnswer);

            for (const tolerance of question.formulaFields.tolerances) {
                if (evaluateFormula(tolerance.formula) === true) {
                    return {tolerant: false, message: tolerance.label};
                }
            }
        } catch (error) {
            console.error('Unable to parse value for formula question', {value, error});
        }

        return {tolerant: true};
    }
}
