<div class="popup__content">
    <h3>Wilt u dit formulier nogmaals invullen?</h3>
    <p>
        <b>Opdrachtnaam</b>: {{source.title}}<br/>
        <b>Opdrachtnummer</b>: {{source.code}}<br/>
    </p>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="doCloseModal()">Nee</button>
    <button class="vwui-btn is-primary" (click)="doFillFormAgain()">Ja</button>
</div>
