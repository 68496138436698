<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <div *ngIf="showLocationOnMap$ | async">
            <p class="mb-4">{{ 'FORM_QUESTION_LOCATION.form_question_location_notice'|translate }}</p>
            <app-point-map
                [coords$]="coords$"
                [centerCoords$]="centerCoords$"
                [currentPosition$]="currentPosition$"
                [objectLocationType]="objectLocationType$ | async"
                (newCoords)="onNewCoords($event)"
            >
            </app-point-map>
        </div>
        <div class="question-type is-location">
            <label class="input-label text-info">
                {{ (objectLocationType$ | async) === 'Linear' ?
                ('FORM_QUESTION_LOCATION.form_question_location_description_location_from'|translate) :
                ('FORM_QUESTION_LOCATION.form_question_location_description_location'|translate)
                }}
            </label>
            <div class="location-question-field">
                <input type="text" [formControl]="form.controls.locationFrom"
                       [placeholder]="'FORM_QUESTION_LOCATION.form_question_location_description'|translate" />

                <button type="button" class="vwui-btn gps-button" (click)="getGpsFrom()">
                    <app-icon iconName="crosshair"></app-icon>
                    <span class="gps-button__label">{{ 'FORM_QUESTION_LOCATION.form_question_location_use_gps'|translate }}</span>
                </button>
            </div>
        </div>
        <div *ngIf="form.controls.gpsLatitudeFrom.value && form.controls.gpsLongitudeFrom.value" class="vwui-alert gps-location">
            Lat: {{ form.controls.gpsLatitudeFrom.value|number:'1.6-6' }}
            Long: {{ form.controls.gpsLongitudeFrom.value|number:'1.6-6' }}
            <button type="button" (click)="clearGpsFrom()"><app-icon iconName="x"></app-icon></button>
        </div>

        <ng-container *ngIf="(objectLocationType$ | async) === 'Linear'">
            <div class="question-type is-location">
                <label class="input-label text-info">
                    {{ 'FORM_QUESTION_LOCATION.form_question_location_description_location_until'|translate }}</label>
                <div class="location-question-field">
                    <input type="text" [formControl]="form.controls.locationTo"
                           [placeholder]="'FORM_QUESTION_LOCATION.form_question_location_description'|translate" />
                    <button type="button" class="vwui-btn gps-button" (click)="getGpsTo()">
                        <app-icon iconName="crosshair"></app-icon>
                        <span class="gps-button__label">{{ 'FORM_QUESTION_LOCATION.form_question_location_use_gps'|translate }}</span>
                    </button>
                </div>
            </div>
            <div *ngIf="form.controls.gpsLatitudeTo.value && form.controls.gpsLongitudeTo.value" class="vwui-alert gps-location">
                Lat: {{ form.controls.gpsLatitudeTo.value|number:'1.6-6' }} &nbsp;&nbsp;&nbsp;&nbsp;
                Long: {{ form.controls.gpsLongitudeTo.value|number:'1.6-6' }}
                <button type="button" (click)="clearGpsTo()"><app-icon iconName="x"></app-icon></button>
            </div>
        </ng-container>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [isLocationQuestion]="true" [locationValue]="currentValue"
                         [canGoForward]="isValid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
</form>
