import {
    ApplicationRef, ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type,
} from '@angular/core';
import {PopupConfig} from "../models/popup-config";
import {PopupComponent} from "../components/popup/popup.component";
import {PopupRef} from "../helpers/popup-ref";
import {PopupInjector} from "../helpers/popup-injector";
import {take, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PopupService {
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
    ) {
    }

    open<T, U>(componentType: Type<T>, config: PopupConfig<U> = {}): PopupRef {
        const [dialogComponentRef, popupRef] = this.appendComponentToBody(config);
        dialogComponentRef.instance.childComponentType = componentType;

        setTimeout(() => {
            dialogComponentRef.instance.visible = true;
        });

        return popupRef;
    }

    private appendComponentToBody<U>(config: PopupConfig<U>): [ComponentRef<PopupComponent>, PopupRef] {
        const map = new WeakMap();
        map.set(PopupConfig, config || {});

        const ref = new PopupRef();
        map.set(PopupRef, ref);

        const injector = new PopupInjector(this.injector, map);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PopupComponent);
        const componentRef = componentFactory.create(injector);

        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<PopupComponent>).rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);

        componentRef.instance.onClose.pipe(
            take(1),
            tap(() => {
                setTimeout(() => {
                    this.removeComponentFromBody(componentRef);
                }, 500);
            })
        ).subscribe();

        ref.afterClosed.pipe(
            take(1),
            tap(() => {
                componentRef.instance.hide();
            })
        ).subscribe();

        return [componentRef, ref];
    }

    private removeComponentFromBody(componentRef: ComponentRef<PopupComponent>): void {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }
}
