/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { difference as t, isSome as s } from "../core/arrayUtils.js";
import i from "../core/Collection.js";
import { referenceSetter as o } from "../core/collectionUtils.js";
import n from "../core/Evented.js";
import { getOrCreateMapValue as l } from "../core/MapUtils.js";
import { removeMaybe as r } from "../core/maybe.js";
import c from "../core/ReactiveMap.js";
import { watch as a, on as h } from "../core/reactiveUtils.js";
import { symmetricDifference as u } from "../core/SetUtils.js";
import { property as d } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as p } from "../core/accessorSupport/decorators/subclass.js";
import { isSubtypeGroupLayer as f, isSubtypeSublayer as g } from "../layers/support/layerUtils.js";
import _ from "../rest/support/Query.js";
import { isSelectableLayer as m } from "./support/selectionUtils.js";
let y = class extends n.EventedAccessor {
  constructor(e) {
    super(e), this._selectionMap = new c(), this._sources = new i(), this._trashCan = [], this._layerEditHandles = new i(), this._vizTaskId = 0, this.showHighlight = !0;
  }
  initialize() {
    this.addHandles([a(() => [this.view, this.showHighlight], () => this._refreshVisualization()), h(() => this.sources, "change", e => {
      const t = this._selectionMap;
      for (const s of e.removed) t.delete(s);
      this._refreshListeners(), this._refreshVisualization();
    }, {
      onListenerAdd: () => this._refreshListeners()
    })]);
    const e = new i();
    this.view.when().then(() => {
      this.view.map && (this.view.map.allLayers.flatten(e => "sublayers" in e && e.sublayers ? e.sublayers : null).forEach(t => {
        (m(t) && !f(t) || g(t)) && e.add(t);
      }), this.sources = e);
    });
  }
  destroy() {
    this._layerEditHandles.drain(r);
  }
  get selections() {
    return Array.from(this._selectionMap.entries()).map(e => {
      const [t, s] = e;
      return {
        layer: t,
        selection: [...s.selection]
      };
    });
  }
  get count() {
    let e = 0;
    for (const t of this._selectionMap.values()) e += t.selection.length;
    return e;
  }
  get hasSelection() {
    return this.count > 0;
  }
  get sources() {
    return this._sources;
  }
  set sources(e) {
    o(e, this._sources);
  }
  async getSelectedFeatures(e, t = {}, s = "layerView") {
    const {
        view: i,
        selections: o
      } = this,
      n = (void 0 !== e ? o.filter(t => e.includes(t.layer)) : o).filter(e => e.selection.length > 0).map(async e => {
        const {
            layer: o,
            selection: n
          } = e,
          l = g(o) ? o.parent : o;
        if (null == l || !S(l)) return null;
        if ("layer" === s) return M(l, n, t);
        if (v(l)) return null;
        const r = await i.whenLayerView(l).catch(() => null);
        return r ? M(r, n, t) : null;
      });
    return (await Promise.all(n)).filter(e => null != e);
  }
  updateSelection(e) {
    const s = new Map();
    for (const [t, n] of this._selectionMap) s.set(t, [...n.selection]);
    let i = !1;
    const o = e.current.concat(e.added);
    for (const t of o) {
      const e = t.sourceLayer,
        o = t.getObjectId();
      if (this.sources.includes(e) && (m(e) || g(e)) && null !== o) {
        const t = l(s, e, () => []);
        t.includes(o) || (t.push(o), i = !0);
      }
    }
    for (const t of e.removed) {
      const e = t.sourceLayer,
        o = t.getObjectId();
      if (this.sources.includes(e) && (m(e) || g(e)) && null !== o) {
        const t = s.get(e),
          n = t?.indexOf(o);
        void 0 !== n && n >= 0 && (t?.splice(n, 1), i = !0);
      }
    }
    if (i) {
      const {
          _selectionMap: e,
          _trashCan: i
        } = this,
        o = [];
      for (const [n, l] of s) {
        const s = e.get(n);
        void 0 !== s && i.push(s), e.set(n, {
          selection: l
        }), o.push({
          layer: n,
          selection: l,
          ...t(void 0 !== s ? s.selection : [], l)
        });
      }
      this._onSelectionChange(o);
    }
  }
  setSelection(e, t) {
    this._setSelection(e, t);
  }
  getSelection(e) {
    const t = this._selectionMap.get(e);
    return t?.selection;
  }
  appendToSelection(e, t) {
    const s = this._selectionMap.get(e),
      i = void 0 !== s ? [...s.selection] : [];
    for (const o of t) i.includes(o) || i.push(o);
    this._setSelection(e, i);
  }
  removeFromSelection(e, t) {
    const s = this._selectionMap.get(e);
    if (!s) return;
    const i = [];
    for (const o of s.selection) t.includes(o) || i.push(o);
    this._setSelection(e, i);
  }
  toggleInSelection(e, t) {
    const s = this._selectionMap.get(e);
    if (!s || 0 === s.selection.length) return void this._setSelection(e, t);
    const i = new Set(s.selection),
      o = new Set(t),
      n = u(i, o);
    this._setSelection(e, Array.from(n));
  }
  clear() {
    const e = this._selectionMap.values();
    this._trashCan.push(...e);
    const t = [];
    for (const [s, i] of this._selectionMap.entries()) t.push({
      layer: s,
      added: [],
      removed: [...i.selection],
      selection: []
    });
    this._selectionMap.clear(), this._onSelectionChange(t);
  }
  _onSelectionChange(e) {
    this._refreshVisualization(), this.emit("selection-change", {
      view: this.view,
      changes: e
    });
  }
  _refreshVisualization() {
    if (null == this.view || null == this.sources) return;
    for (this._vizTaskId++; this._trashCan.length > 0;) {
      const e = this._trashCan.pop();
      e?.highlightHandle?.remove();
    }
    const {
        sources: e,
        view: t,
        _selectionMap: s,
        showHighlight: i
      } = this,
      o = this._vizTaskId;
    for (const n of e) {
      const e = s.get(n),
        l = g(n) ? n.parent : n;
      if (null != l && S(l)) {
        if (v(l)) continue;
        t.whenLayerView(l).then(t => {
          e?.highlightHandle?.remove(), null != e && i && o === this._vizTaskId && "highlight" in t && "function" == typeof t.highlight && e.selection.length > 0 && (e.highlightHandle = t.highlight(e.selection, "selection"));
        }).catch(() => {
          e?.highlightHandle?.remove();
        });
      }
    }
  }
  _refreshListeners() {
    this._layerEditHandles.drain(r);
    for (const e of this.sources) {
      const t = g(e) ? e.parent : e;
      if (null != t && m(t)) {
        const s = t.on("edits", t => {
          this._handleEditChanges(t, e);
        });
        this._layerEditHandles.push(s);
      }
    }
  }
  _handleEditChanges(e, t) {
    if (void 0 !== e.deletedFeatures && e.deletedFeatures.length > 0 && this._selectionMap.has(t)) {
      const i = e.deletedFeatures.filter(e => null == e.error).map(e => e.objectId).filter(s);
      this.removeFromSelection(t, i);
    }
  }
  _setSelection(e, s) {
    if (!this.sources.includes(e)) throw new Error(`Cannot set selection on layer ${e.title} because it is not in 'sources'`);
    const i = this._selectionMap.get(e);
    if (void 0 === i || !j(i, {
      selection: s
    })) {
      void 0 !== i && this._trashCan.push(i), this._selectionMap.set(e, {
        selection: [...s]
      });
      const o = {
        layer: e,
        selection: [...s],
        ...t(void 0 !== i ? i.selection : [], s)
      };
      this._onSelectionChange([o]);
    }
  }
};
e([d({
  readOnly: !0,
  nonNullable: !0
})], y.prototype, "selections", null), e([d({
  readOnly: !0,
  nonNullable: !0
})], y.prototype, "count", null), e([d({
  constructOnly: !0,
  nonNullable: !0
})], y.prototype, "view", void 0), e([d({
  readOnly: !0,
  nonNullable: !0
})], y.prototype, "hasSelection", null), e([d()], y.prototype, "showHighlight", void 0), e([d()], y.prototype, "sources", null), y = e([p("esri.views.SelectionManager")], y);
const v = e => g(e) ? !0 === e.parent?.isTable : e.isTable,
  w = e => void 0 !== e.layer,
  S = e => void 0 !== e?.when,
  j = (e, t) => {
    if (null == e && null == t) return !0;
    if (null != e && null == t || null == e && null != t) return !1;
    if (null != e && null != t && null != e.selection && null != t.selection) {
      const s = [...e.selection],
        i = [...t.selection];
      if (s.length !== i.length) return !1;
      s.sort(), i.sort();
      for (let e = 0; e < s.length; e++) if (s[e] !== i[e]) return !1;
    }
    return !0;
  },
  M = async (e, t, s = {}) => {
    let i;
    if (w(e)) {
      const o = e;
      i = void 0 === o ? null : await o.queryFeatures(new _({
        ...s,
        objectIds: t
      })).then(t => ({
        data: t,
        layer: e.layer
      }));
    } else {
      const o = e;
      i = void 0 === o ? null : await o.queryFeatures(new _({
        ...s,
        objectIds: t
      })).then(e => ({
        data: e,
        layer: o
      }));
    }
    return i;
  },
  b = y;
export { b as default };