import {Component, Input} from '@angular/core';
import {ReferenceImageQuestion} from '../../../models/question/reference-images-question';
import {BehaviorSubject} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {FormImageService} from '../../../services/form-image.service';
import {map} from 'rxjs/operators';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormControl, Validators} from '@angular/forms';
import {FormUtils} from '../../../utils/form-utils';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {ToastrService} from 'ngx-toastr';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {AnnotatableImage, ImageAnnotationResult} from '../../image-annotation-v3/annotatable-image';

@Component({
    selector: 'app-question-v2-reference-image',
    templateUrl: './question-v2-reference-image.component.html',
})
export class QuestionV2ReferenceImageComponent {
    public currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: ReferenceImageQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: ReferenceImageQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.updateAnswer();
    }

    public disabled = false;
    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    images: AnnotatableImage[] = [];

    selectedImage$ = new BehaviorSubject<AnnotatableImage | null>(null);
    selectedImageAnnotated$ = this.selectedImage$.pipe(
        map(image => !!image?.modifiedPhotoId)
    );

    constructor(
        private formImageService: FormImageService,
        private toastr: ToastrService
    ) {}

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    async showImageViewer(image: AnnotatableImage) {
        this.selectedImage$.next(image);
    }

    async removeImage(image: AnnotatableImage) {
        await this.formImageService.removeQueuedImage(image.modifiedPhotoId!);

        image.shapes = [];
        image.modifiedPhotoId = null;

        this.control.setValue(JSON.stringify(this.images));


        this.selectedImage$.next(null);
    }

    async showToastError() {
        if (this.control.errors?.['required']) {
            await this.toastr.error('Minimaal 1 referentie-afbeelding moet aangepast zijn');
        }
    }

    async replaceImage(annotationResult: ImageAnnotationResult) {
        if (annotationResult.image.modifiedPhotoId) {
            await this.formImageService.removeQueuedImage(annotationResult.image.modifiedPhotoId);
        }

        const imageToReplace = this.images.find(it => it.originalPhotoId === annotationResult.image.originalPhotoId);

        if (annotationResult.blob) {
            const newId = uuid();
            await this.formImageService.queueImage(newId, annotationResult.blob);
            imageToReplace!.modifiedPhotoId = newId;
        }

        if (annotationResult.shapes) {
            imageToReplace!.shapes = annotationResult.shapes;
        }

        this.selectedImage$.next(null);


        this.control.setValue(JSON.stringify(this.images));

        await this.formImageService.processQueuedImages();
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const previousAnswer = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (!previousAnswer) {
            this.images = this.currentQuestion.referenceImages.split(',').map(it => ({originalPhotoId: it}));
        } else if (previousAnswer.value?.startsWith('[')) {
            try {
                this.images = JSON.parse(previousAnswer.value) as AnnotatableImage[];
            } catch (error) {
                console.error('Failed to parse reference image answer', error);
                this.images = this.currentQuestion.referenceImages.split(',').map(it => ({originalPhotoId: it}));
            }
        } else {
            // Legacy answer format
            const annotatedImageIds = previousAnswer.value?.split(',') ?? [];

            this.images = this.currentQuestion.referenceImages.split(',').map((it, index) => ({
                originalPhotoId: it,
                modifiedPhotoId: annotatedImageIds[index] ?? '',
            }));
        }

        this.control.setValue(previousAnswer?.value ?? '');
    }
}
