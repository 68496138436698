<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>

    <div class="mb-4">
        <label class="input-label mb-2">
            {{ currentQuestion?.config?.firstLabel }}
            <span class="text-danger" *ngIf="currentQuestion.required">*</span>
        </label>
        <ng-select [formControl]="formGroup.controls.firstChoice"
                   [placeholder]="'Selecteer antwoord'|translate">
            <ng-option *ngFor="let choice of currentQuestion?.config?.firstChoices" [value]="choice">
                {{ choice }}
            </ng-option>
        </ng-select>
    </div>

    <div class="mb-4">
        <label class="input-label mb-2">
            {{ currentQuestion?.config?.secondLabel }}
            <span class="text-danger" *ngIf="currentQuestion.required">*</span>
        </label>
        <ng-select [formControl]="formGroup.controls.secondChoice"
                   [placeholder]="'Selecteer antwoord'|translate">
            <ng-option *ngFor="let choice of currentQuestion?.config?.secondChoices" [value]="choice">
                {{ choice }}
            </ng-option>
        </ng-select>
    </div>

    <div class="vwui-alert" *ngIf="formGroup.errors?.bothEmptyOrFilled">
        Het is niet mogelijk om één van de twee velden leeg te laten.
    </div>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="isValid"></app-question-footer>
