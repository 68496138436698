/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { deprecatedProperty as o } from "../core/deprecate.js";
import t from "../core/Logger.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import r from "./Widget.js";
import l from "./Locate/LocateViewModel.js";
import { loadCalciteComponents as n } from "./support/componentsUtils.js";
import { globalCss as a } from "./support/globalCss.js";
import "./support/widgetUtils.js";
import { messageBundle as c } from "./support/decorators/messageBundle.js";
import { vmEvent as p } from "./support/decorators/vmEvent.js";
import { tsx as d } from "./support/jsxFactory.js";
const m = {
  base: "esri-locate"
};
let g = class extends r {
  constructor(e, o) {
    super(e, o), this.messages = null, this.messagesCommon = null, this.viewModel = new l(), this._locate = () => {
      const {
        viewModel: e
      } = this;
      "locating" === e.state ? e.cancelLocate() : e.locate().catch(() => {});
    };
  }
  loadDependencies() {
    return n({
      button: () => import("@esri/calcite-components/dist/components/calcite-button.js"),
      loader: () => import("@esri/calcite-components/dist/components/calcite-loader.js"),
      tooltip: () => import("@esri/calcite-components/dist/components/calcite-tooltip.js")
    });
  }
  get geolocationOptions() {
    return this.viewModel.geolocationOptions;
  }
  set geolocationOptions(e) {
    this.viewModel.geolocationOptions = e;
  }
  get goToLocationEnabled() {
    return this.viewModel.goToLocationEnabled;
  }
  set goToLocationEnabled(e) {
    this.viewModel.goToLocationEnabled = e;
  }
  get goToOverride() {
    return this.viewModel.goToOverride;
  }
  set goToOverride(e) {
    this.viewModel.goToOverride = e;
  }
  get graphic() {
    return this.viewModel.graphic;
  }
  set graphic(e) {
    this.viewModel.graphic = e;
  }
  get icon() {
    return "gps-off";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get popupEnabled() {
    return this.viewModel.popupEnabled;
  }
  set popupEnabled(e) {
    this.viewModel.popupEnabled = e;
  }
  get rotationEnabled() {
    return o(t.getLogger(this), "rotationEnabled", {
      replacement: "use Track widget",
      version: "4.29",
      warnOnce: !0
    }), this.viewModel.rotationEnabled;
  }
  set rotationEnabled(e) {
    o(t.getLogger(this), "rotationEnabled", {
      replacement: "use Track widget",
      version: "4.29",
      warnOnce: !0
    }), this.viewModel.rotationEnabled = e;
  }
  get scale() {
    return this.viewModel.scale;
  }
  set scale(e) {
    this.viewModel.scale = e;
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  cancelLocate() {
    this.viewModel.cancelLocate();
  }
  locate() {
    return this.viewModel.locate();
  }
  render() {
    const {
        viewModel: e,
        icon: o
      } = this,
      {
        state: t
      } = e,
      i = "locating" === t,
      s = i ? this.messagesCommon.cancel : this.messages.title,
      r = "feature-unsupported" === t,
      l = this._localizeError(this.viewModel?.error);
    return d("div", {
      class: this.classes(m.base, a.widget)
    }, !r && d("calcite-button", {
      class: a.widgetButton,
      disabled: "disabled" === t,
      iconStart: i ? void 0 : o,
      kind: "neutral",
      label: s,
      onclick: this._locate,
      title: s
    }, i ? d("calcite-loader", {
      inline: !0,
      label: ""
    }) : null), l ? d("calcite-tooltip", {
      open: !0,
      overlayPositioning: "fixed",
      referenceElement: this.container
    }, l) : null);
  }
  _localizeError(e) {
    return e instanceof GeolocationPositionError ? e.code === GeolocationPositionError.PERMISSION_DENIED ? this.messages?.permissionError : e.code === GeolocationPositionError.TIMEOUT ? this.messages?.timeoutError : this.messages?.positionUnavailable : e instanceof Error ? e.message : void 0;
  }
};
e([i()], g.prototype, "geolocationOptions", null), e([i()], g.prototype, "goToLocationEnabled", null), e([i()], g.prototype, "goToOverride", null), e([i()], g.prototype, "graphic", null), e([i()], g.prototype, "icon", null), e([i()], g.prototype, "label", null), e([i(), c("esri/widgets/Locate/t9n/Locate")], g.prototype, "messages", void 0), e([i(), c("esri/t9n/common")], g.prototype, "messagesCommon", void 0), e([i()], g.prototype, "popupEnabled", null), e([i()], g.prototype, "rotationEnabled", null), e([i()], g.prototype, "scale", null), e([i()], g.prototype, "view", null), e([i({
  type: l
}), p(["locate", "locate-error"])], g.prototype, "viewModel", void 0), g = e([s("esri.widgets.Locate")], g);
const u = g;
export { u as default };