/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { prefersReducedMotion as e } from "../../core/a11yUtils.js";
import t from "../../core/Accessor.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import a from "./gamepad/GamepadSettings.js";
let m = class extends t {
  constructor(o) {
    super(o), this.browserTouchPanEnabled = !0, this.gamepad = new a(), this.momentumEnabled = !0, this.mouseWheelZoomEnabled = !0;
  }
  get effectiveMomentumEnabled() {
    return this.momentumEnabled && !e();
  }
};
o([r({
  type: Boolean
})], m.prototype, "browserTouchPanEnabled", void 0), o([r({
  type: a,
  nonNullable: !0
})], m.prototype, "gamepad", void 0), o([r({
  type: Boolean
})], m.prototype, "momentumEnabled", void 0), o([r({
  type: Boolean
})], m.prototype, "mouseWheelZoomEnabled", void 0), m = o([s("esri.views.navigation.Navigation")], m);
const p = m;
export { p as default };