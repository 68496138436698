import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccordionController {

    private accordionGroups: BehaviorSubject<{[key: string]: string}> = new BehaviorSubject({});

    constructor() {
    }

    open(groupId: string, id: string) {
        const current = this.accordionGroups.value;
        current[groupId] = id;
        this.accordionGroups.next(current);
    }

    asObservable(): Observable<{[key: string]: string}> {
        return this.accordionGroups.asObservable();
    }
}
