import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AnyLayeredFormNode} from '../../models/layered-form-node';

@Component({
  selector: 'app-node-breadcrumbs',
  templateUrl: './node-breadcrumbs.component.html',
})
export class NodeBreadcrumbsComponent {
    @Input() public path: AnyLayeredFormNode[] = [];
    @Input() public current: string | null = null;
    @Output() public nodeTapped = new EventEmitter<AnyLayeredFormNode>();
}
