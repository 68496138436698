import {Component, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {v4 as uuid} from 'uuid';
import {FormService} from '../../../services/form.service';
import {map} from 'rxjs/operators';
import {FormUtils} from '../../../utils/form-utils';
import {Question} from '../../../models/question/question';
import {ProjectJobAnswer, ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {FormImageService} from '../../../services/form-image.service';
import {QuestionChoice} from '../../../models/question-choice';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormControl, Validators} from '@angular/forms';
import {SignatureQuestion} from '../../../models/question/signature-question';
import {DatetimeQuestion} from '../../../models/question/datetime-question';
import {ChoiceQuestion} from '../../../models/question/choice-question';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {FileUtils} from '../../../utils/file-utils';

@Component({
    selector: 'app-question-v2-signature',
    templateUrl: './question-v2-signature.component.html'
})
export class QuestionV2SignatureComponent {
    public currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: SignatureQuestion | null = null;
    private currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }

    @Input({required: true}) set question(question: SignatureQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.control.updateValueAndValidity();

        this.updateAnswer();
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    public disabled = false;
    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    modalVisible$ = new BehaviorSubject(false);

    imageId: string | null = null;
    imageSrc: string | null = null;
    answeredQuestionsForSummary$: Observable<{ question: Question; answer: ProjectJobAnswer | null }[]> = this.formService.openForm$.pipe(
        map(form => {
            if (this.currentQuestion !== null && this.currentQuestion.showSummaryEnabled && this.currentForm !== null) {
                const allQuestions = FormUtils.getQuestions(this.currentForm, this.currentNode)
                let summaryQuestions: Question[] = [];

                if (allQuestions) {
                    for (const question of allQuestions) {
                        if (question.position === this.currentQuestion.position) {
                            break;
                        }
                        if (question.type === 'signature' && question.showSummaryEnabled) {
                            summaryQuestions = [];
                        } else if (question.addToSummaryEnabled) {
                            summaryQuestions.push(question);
                        }
                    }
                }
                return summaryQuestions.map(question => ({
                    question,
                    answer: FormUtils.getFormItemLastAnswer(form?.answers || [], question.position, this.currentNode)

                    // Remove all the non-answered questions from the list
                })).filter((item) => {
                    return !!item.answer;
                });
            } else {
                return [];
            }
        })
    );

    constructor(
        private formImageService: FormImageService,
        private toastr: ToastrService,
        private formService: FormService,
    ) {
    }

    public isQuestionChoiceSelected(question: Question, answer: ProjectJobAnswer, choice: QuestionChoice): boolean {
        if (question.type === 'choice' && -1 !== question.choices.findIndex(it => it.id === choice.id)) {
            return !!(answer.value && -1 !== answer.value.toString().indexOf(choice.id));
        }
        return false;
    }

    splitUuids(value: string) {
        return FormUtils.getImageUUIDsFromAnswer(value);
    }

    showSignaturePad() {
        if (!this.disabled) {
            this.modalVisible$.next(true);
        }
    }

    isDateQuestion(question: Question): DatetimeQuestion | null {
        return question.type === 'date' ? question : null
    }
    isChoiceQuestion(question: Question): ChoiceQuestion | null {
        return question.type === 'choice' ? question : null
    }

    async onSignatureSaved(signatureData: string) {
        try {
            const blob = FileUtils.base64ToBlob(signatureData, 'image/jpeg');

            if (blob) {
                const newUuid = uuid();

                // Add signature image to form image store
                await this.formImageService.queueImage(newUuid, blob);

                await this.updateImageUrl(newUuid);
                this.control.setValue(newUuid);
            }
        } catch (error) {
            console.error('Error saving signature', error);
            await this.toastr.error('Het aanmaken van een handtekening is mislukt');
        }
    }

    async onSignatureRemove() {
        // Check if existing signature image should be removed from local file storage
        if (await this.formImageService.findQueuedIndex(this.control.value) !== -1) {
            await this.formImageService.removeQueuedImage(this.control.value);
        }

        this.imageSrc = null;
        this.control.setValue('');
    }

    async updateImageUrl(uniqueId: string | null) {
        this.imageId = uniqueId;
        const imageUrl = uniqueId !== null ? await this.formImageService.getImageUrl(uniqueId) : null;
        // Ignore imageUrl if imageId has changed while we were fetching the imageUrl
        if (this.imageId === uniqueId) {
            this.imageSrc = imageUrl;
        }
    }

    showErrorToast() {
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn');
    }

    private async updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (latest && latest.value) {
            this.control.setValue(latest.value);
            await this.updateImageUrl(latest.value);
        } else {
            this.control.setValue('');
            await this.updateImageUrl(null);
        }
    }
}
