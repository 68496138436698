import {Component} from '@angular/core';

@Component({
    selector: 'app-popup-body',
    templateUrl: './popup-body.component.html',
    standalone: true,
})
export class PopupBodyComponent {

}
