<div class="modal" [class.is-visible]="visible$|async" [class.is-invisible]="(visible$|async) !== true">
  <div class="modal-container">
    <div class="modal-container__header">
      <ng-content select=".modal-title"></ng-content>
      <button type="button" class="modal-container__close-btn" (click)="hide()" *ngIf="showCloseBtn">
        <app-icon iconName="x"></app-icon>
      </button>
    </div>
    <div class="modal-container__content">
      <ng-content></ng-content>
    </div>
    <div class="modal-container__actions">
      <ng-content select=".modal-actions"></ng-content>
    </div>
  </div>
  <div class="modal-backdrop" (click)="onBackdropClick()"></div>
</div>
