import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent {
    @Input({required: true}) visible$!: BehaviorSubject<boolean>;
    @Input() showCloseBtn = true;
    @Input() allowBackdropClose = true;

    @Output() modalClosed = new EventEmitter<void>();

    constructor() {}

    hide() {
        this.visible$.next(false);
        this.modalClosed.emit();
    }

    onBackdropClick() {
        if (this.allowBackdropClose) {
            this.hide();
        }
    }
}
