<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type">
            <label class="input-label">Antwoord</label>

            <div class="vwui-alert is-warning mt-2 mb-2" *ngIf="toleranceMessage">
                <app-icon iconName="oval-exclamation"></app-icon>
                {{ toleranceMessage }}
            </div>

            <input *ngIf="!isIOS else iosInput"
                   type="text"
                   inputmode="decimal"
                   [formControl]="control"
                   (input)="updateValueFromInputEvent($event)">

            <ng-template #iosInput>
                <input type="number"
                       [formControl]="control"
                       (keydown)="validateAllowedKeys($event)"
                       (input)="updateValueFromInputEvent($event)">
            </ng-template>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="control.valid" (tapOnInvalidButton)="showToastError()"></app-question-footer>
</form>
