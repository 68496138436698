<div [class.mb-2]="levelNumber === 1">
    <div class="py-4 px-6 flex items-center gap-2 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-100"
         [class.bg-blue-40]="levelNumber === 1"
         [class.bg-blue-30]="levelNumber === 2"
         [class.bg-white]="levelNumber > 2"
         (click)="tapHeader()">
        <app-status-indicator [status]="nodeStatuses[currentNode?.id]?.self"></app-status-indicator>

        <div class="flex-none transition-transform transform w-6 h-4 flex items-center justify-center" [class.opacity-0]="!hasChildren" [class.rotate-180]="expanded">
            <img src="/assets/svg-icons/chevron-down.svg" alt="Toggle">
        </div>

        <div class="flex-1 text-base font-normal text-ellipsis overflow-hidden">{{ currentNode?.name }}</div>

        <div class="flex-none ml-auto flex items-center justify-center hover:opacity-50" (click)="handleContextMenuTap($event)">
            <img src="/assets/svg-icons/context-menu.svg" alt="Open context menu" />
        </div>
    </div>

    <div class="overflow-hidden" [class.max-h-none]="expanded" [class.max-h-0]="!expanded">
        <div *ngIf="currentQuestions.length && !isLastLevel">
            <div class="py-4 px-6 flex items-center gap-2 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-100"
                 [class.bg-blue-30]="levelNumber === 1"
                 [class.bg-white]="levelNumber > 1"
                 (click)="toggleGeneralQuestions()">
                <app-status-indicator [status]="nodeStatuses[currentNode?.id]?.allQuestions"></app-status-indicator>

                <div class="transition-transform transform w-6 h-4 flex items-center justify-center" [class.rotate-180]="expandedGeneralQuestions">
                    <img src="/assets/svg-icons/chevron-down.svg" alt="Toggle">
                </div>

                <div class="text-base font-normal">Algemeen</div>
            </div>
            <div class="overflow-hidden" [class.max-h-none]="expandedGeneralQuestions" [class.max-h-0]="!expandedGeneralQuestions">
                <ng-container *ngTemplateOutlet="questions"></ng-container>
            </div>
        </div>

        <app-tree-level *ngFor="let child of children"
                        [node]="child"
                        [layers]="layers"
                        [tree]="fullTree"
                        [form]="currentForm"
                        [levelNumber]="levelNumber + 1"
                        [nodeStatuses]="nodeStatuses"
                        (leafTapped)="leafTapped.next($event)"
                        (contextMenuTapped)="contextMenuTapped.next($event)"></app-tree-level>

        <ng-container *ngIf="currentQuestions.length && isLastLevel">
            <ng-container *ngTemplateOutlet="questions"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #questions>
    <div *ngFor="let question of nodeStatuses[currentNode?.id]?.questions" class="py-4 px-6 flex items-center gap-2 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-100"
         (click)="openQuestion(currentNode, question)">
        <app-status-indicator [status]="question.status"></app-status-indicator>
        <div class="text-base font-normal ml-8 text-ellipsis overflow-hidden">{{ question.title }}</div>
    </div>
</ng-template>
