/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { on as t } from "../core/reactiveUtils.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import s from "./Widget.js";
import o from "./Attribution/AttributionViewModel.js";
import { globalCss as n } from "./support/globalCss.js";
import { accessibleHandler as l } from "./support/decorators/accessibleHandler.js";
import { messageBundle as p } from "./support/decorators/messageBundle.js";
import { tsx as d } from "./support/jsxFactory.js";
import "./support/widgetUtils.js";
const c = "esri-attribution",
  a = {
    base: c,
    poweredBy: `${c}__powered-by`,
    sources: `${c}__sources`,
    open: `${c}--open`,
    sourcesOpen: `${c}__sources--open`,
    link: `${c}__link`
  };
let h = class extends s {
  constructor(e, t) {
    super(e, t), this._isOpen = !1, this._attributionTextOverflowed = !1, this._prevSourceNodeHeight = 0, this._resizeObserver = new ResizeObserver(e => e.forEach(({
      target: e
    }) => this._checkSourceTextOverflow(e))), this.itemDelimiter = " | ", this.messages = null, this.viewModel = new o();
  }
  initialize() {
    this.addHandles(t(() => this.viewModel?.items, "change", () => this.scheduleRender()));
  }
  destroy() {
    this._resizeObserver?.disconnect();
  }
  get _isInteractive() {
    return this._isOpen || this._attributionTextOverflowed;
  }
  get attributionText() {
    return this.viewModel.items.reduce((e, t) => (e.includes(t.text) || e.push(t.text), e), []).join(this.itemDelimiter);
  }
  get icon() {
    return "description";
  }
  set icon(e) {
    this._overrideIfSome("icon", e);
  }
  get label() {
    return this.messages?.widgetLabel ?? "";
  }
  set label(e) {
    this._overrideIfSome("label", e);
  }
  get view() {
    return this.viewModel.view;
  }
  set view(e) {
    this.viewModel.view = e;
  }
  render() {
    const e = {
      [a.open]: this._isOpen
    };
    return d("div", {
      bind: this,
      class: this.classes(a.base, n.widget, e),
      dir: "ltr",
      onclick: this._toggleState,
      onkeydown: this._toggleState
    }, this._renderSourcesNode(), this._renderPoweredBy());
  }
  _renderPoweredBy() {
    return d("div", {
      class: a.poweredBy
    }, "Powered by", " ", d("a", {
      class: a.link,
      href: "https://www.esri.com/",
      rel: "noreferrer",
      target: "_blank"
    }, "Esri"));
  }
  _renderSourcesNode() {
    const e = this._isOpen,
      t = this._isInteractive,
      i = t ? 0 : void 0,
      {
        attributionText: r
      } = this,
      s = {
        [a.sourcesOpen]: e,
        [n.interactive]: t
      };
    return d("div", {
      afterCreate: this._afterSourcesNodeCreate,
      bind: this,
      class: this.classes(a.sources, s),
      innerHTML: r,
      tabIndex: i
    });
  }
  _afterSourcesNodeCreate(e) {
    this._prevSourceNodeHeight = e.clientWidth, this._resizeObserver.observe(e);
  }
  _checkSourceTextOverflow(e) {
    let t = !1;
    const {
        clientHeight: i,
        clientWidth: r,
        scrollWidth: s
      } = e,
      o = s > r,
      n = this._attributionTextOverflowed !== o;
    if (this._attributionTextOverflowed = o, n && (t = !0), this._isOpen) {
      const e = i < this._prevSourceNodeHeight;
      this._prevSourceNodeHeight = i, e && (this._isOpen = !1, t = !0);
    }
    t && this.scheduleRender();
  }
  _toggleState() {
    this._isInteractive && (this._isOpen = !this._isOpen);
  }
};
e([i()], h.prototype, "_isOpen", void 0), e([i()], h.prototype, "_isInteractive", null), e([i()], h.prototype, "_attributionTextOverflowed", void 0), e([i()], h.prototype, "_prevSourceNodeHeight", void 0), e([i({
  readOnly: !0,
  dependsOn: ["viewModel.items.length", "itemDelimiter"]
})], h.prototype, "attributionText", null), e([i()], h.prototype, "icon", null), e([i()], h.prototype, "itemDelimiter", void 0), e([i()], h.prototype, "label", null), e([i(), p("esri/widgets/Attribution/t9n/Attribution")], h.prototype, "messages", void 0), e([i()], h.prototype, "view", null), e([i({
  type: o
})], h.prototype, "viewModel", void 0), e([l()], h.prototype, "_toggleState", null), h = e([r("esri.widgets.Attribution")], h);
const u = h;
export { u as default };