<ng-template #loadingTemplate>
    <div class="flex h-full justify-center items-center">
        <app-spinner></app-spinner>
    </div>
</ng-template>

<app-pull-to-refresh (refreshEvent)="doRefresh($event)" (loadMoreEvent)="loadMore()">
    <ng-container *ngIf="(projectJobs$|async) as projectJobs; else loadingTemplate">
        <ng-container *ngIf="projectJobs.length > 0; else empty">
            <ul class="vwui-list">
                <ng-container *ngFor="let projectJob of projectJobs">
                    <li class="vwui-list-item" (click)="open(projectJob)">
                        @for (line of (projectJob|templatedProjectJobFormValue:(jobTitleTemplate$|async)); track line) {
                            <h3 class="vwui-list-item__title">{{ line }}</h3>
                        }
                        @for (line of (projectJob|templatedProjectJobFormValue:(jobSubtitleTemplate$|async)); track line) {
                            <p class="vwui-list-item__label">{{ line }}</p>
                        }
                        <ng-container
                            *ngIf="findLastStatusSubmitComment(projectJob) !== '' && projectJob.status === 'Rejected'">
                            <div class="vwui-alert vwui-alert--list-warning is-warning">
                                <app-icon iconName="oval-exclamation"></app-icon>
                                {{ findLastStatusSubmitComment(projectJob) }}
                            </div>
                        </ng-container>
                        <app-icon *jobHasUnsyncedChanges="projectJob.id"
                                  class="vwui-list-item__status-icon text-warning" iconName="not-synced"></app-icon>
                        <app-icon class="vwui-list-item__arrow-right" iconName="chevron-right"></app-icon>
                    </li>
                </ng-container>
            </ul>

            <div class="flex justify-center items-center p-6" *ngIf="loading">
                <app-spinner></app-spinner>
            </div>
        </ng-container>

        <ng-template #empty>
            <app-empty-state-message imgSrc="/assets/empty-form-state.svg">
                {{ emptyStateMessage }}
            </app-empty-state-message>
        </ng-template>
    </ng-container>
</app-pull-to-refresh>
