<div class="p-6">
    <app-popup-header>Opmerking bewerken</app-popup-header>
    <app-popup-body>
        <div class="space-y-4">

            <div class="space-y-2">
                <label for="name" class="text-sm font-medium block w-full">Opmerking</label>
                <input type="text" class="appearance-none p-4 text-sm rounded border border-neutral-20 focus:border-primary w-full"
                       [class.text-danger]="control.errors" [formControl]="control" placeholder="Voer een omschrijving in..." id="name">
            </div>

            <div class="flex flex-wrap gap-2">
                <button class="vwui-btn w-auto flex-auto" (click)="cancel()" type="button">
                    Annuleren
                </button>
                <button class="vwui-btn is-primary w-auto flex-auto" (click)="save()" type="button">
                    Opslaan
                </button>
            </div>
        </div>
    </app-popup-body>
</div>
