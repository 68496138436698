<div class="mb-4">
    <p class="hyphens-auto break-words" *ngIf="showDescriptionText" [innerHTML]="SanitizeHtml(descriptionText, {allowedTags: [ 'a', 'p', 'br' ] })"></p>

    <ng-container *ngIf="showDescriptionImage">
        <app-memsafe-img *ngIf="descriptionImageSrc" class="question-description-image" [src]="descriptionImageSrc" (click)="showImageViewer()"></app-memsafe-img>
        <app-image-viewer [image]="imageViewerVisible$|async" (closed)="imageViewerVisible$.next(null)"></app-image-viewer>
    </ng-container>

    <a class="question-more-info-btn" *ngIf="moreInfo" (click)="showMoreInfoModal()">{{'COMPONENTS.QUESTION_DESCRIPTION.more_info' | translate}}</a>

    <app-modal *ngIf="moreInfo" [visible$]="infoModalVisible$">
        <h1 class="modal-title">{{'COMPONENTS.QUESTION_DESCRIPTION.more_info' | translate}}</h1>
        <p class="hyphens-auto break-words" [innerHTML]="SanitizeHtml(moreInfo, { allowedTags: [ 'a', 'p', 'br' ] })"></p>
    </app-modal>
</div>
