import Dexie, {Table} from 'dexie';
import {AnyProjectJobForm} from '../models/project-job-form';
import {createCriticalErrorOverlay} from '../utils/create-critical-error-overlay';

class TemporaryAppDB extends Dexie {
    projectJobForms!: Table<AnyProjectJobForm, number>

    constructor() {
        super('temporary-assetcheck');
        /**
         * Setup schema
         * IMPORTANT: Only specify indexed / searchable fields here, other fields don't need to be specified to be stored
         * @see https://dexie.org/docs/Tutorial/Design#database-versioning
         */
        this.version(1).stores({
            projectJobForms: 'id',
        })

        this.open().catch(err => {
            if (err instanceof Error && err.name === 'VersionError') {
                createCriticalErrorOverlay('CRITICAL ERROR: Browser database version is higher than source-code version. Please clear your browser storage and reload the page.');
                console.error(`Critical error opening dexie, ${err.message}`, err);
                throw err;
            }

            console.error('Failed to open db', err);
            throw err;
        })
    }
}

export const temporaryDb = new TemporaryAppDB()
