import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-action-sheet',
  templateUrl: './action-sheet.component.html',
})
export class ActionSheetComponent {
    @Input() public title = '';
    @Input() public cancellable = true;
    public visible = false;

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
    }

    toggle() {
        this.visible = !this.visible;
    }
}
