<div class="popup__header">
    {{ removeFileExtension(attachment?.originalFilename) }}
</div>

<div class="popup__content">
    <article class="prose" [innerHTML]="sanitizedHtml">

    </article>
</div>

<div class="popup__footer">
    <button class="vwui-btn is-primary" (click)="close()">
        Sluiten
    </button>
</div>
