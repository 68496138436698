/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { tBaseLine as o, tPanion as e, dtPanion as n, ddtPanion as a, tAscensionZoomOnly as t, tAscensionZoomPan as c, dtAscensionZoomOnly as s, dtAscensionZoomPan as r, ddtAscensionZoomOnly as i, ddtAscensionZoomPan as m, tDescensionZoomOnly as u, tDescensionZoomPan as l, dtDescensionZoomOnly as d, dtDescensionZoomPan as p, ddtDescensionZoomOnly as x, ddtDescensionZoomPan as F } from "./functions.js";
function f(f, Z) {
  let b = D(f, Z);
  const h = {
      ascensionFactor: null != Z.ascensionFactor ? Z.ascensionFactor : .5,
      descensionFactor: null != Z.descensionFactor ? Z.descensionFactor : .5
    },
    M = 0 === h.ascensionFactor,
    P = 0 === h.descensionFactor,
    g = M ? t : c,
    k = M ? s : r,
    N = M ? i : m,
    j = P ? u : l,
    w = P ? d : p,
    z = P ? x : F,
    A = o => g(f, o, h) + e(f, o, h) + j(f, o, h),
    I = o => k(f, o, h) + n(f, o, h) + w(f, o, h),
    S = o => N(f, o, h) + a(f, o, h) + z(f, o, h);
  let q = A(b);
  const v = o(f);
  let y;
  const B = Z.maximumIterations || 20,
    C = null != Z.maximumDistance ? Z.maximumDistance : 1 / 0;
  for (y = 0; y < B; y++) {
    const o = Z.desiredSlope ?? 1e-6;
    let e = I(b);
    Math.abs(e) > o && (e += o);
    const n = e / S(b);
    if (isNaN(n) || b >= C && n < 0) {
      if (!isFinite(C)) return null;
      b = C, q = A(b);
      break;
    }
    if (b -= n, b < f.compared.sourceZoom || b < f.compared.targetZoom) return null;
    const a = A(b);
    if (Math.abs(a - q) / q <= .005) break;
    q = a;
  }
  return q > v * (1 - .3) || b < f.compared.sourceZoom || b < f.compared.targetZoom ? null : b;
}
function D(o, e) {
  const n = Math.max(o.compared.sourceZoom, o.compared.targetZoom),
    a = o.source.zoomAtPixelsPerPan(o.desiredPixelFlow / o.compared.pan) / 2;
  return a < n ? null != e.maximumDistance ? n + (e.maximumDistance - n) / 2 : 1.5 * n : e.maximumDistance ? Math.min(e.maximumDistance, a) : a;
}
export { f as optimalDistance };