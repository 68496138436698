import {Component, Inject, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PaulaObstructionCode} from '../../models/paula-obstruction-code';
import {ObstructionCodeService} from '../../services/obstruction-code.service';
import {map} from 'rxjs/operators';
import {PageResponse} from '../../models/page-response';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Forms} from '../../utils/forms';
import {AnyProjectJobForm} from '../../models/project-job-form';
import {FormSyncService} from '../../services/form-sync.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-reject-job-modal',
    templateUrl: './reject-job-modal.component.html'
})
export class RejectJobModalComponent {

    @Input({required: true}) visible$!: BehaviorSubject<boolean>;
    @Input() job: AnyProjectJobForm | undefined;

    form = new FormGroup({
        obstructionCode: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
        obstructionRemarks: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(1024)]),
    });

    obstructionCodes$ = this.obstructionCodeService.list().pipe(
        map<PageResponse<PaulaObstructionCode>, PaulaObstructionCode[]>(data => data.content),
    );

    constructor(
        @Inject('ObstructionCodeService') private obstructionCodeService: ObstructionCodeService,
        private formSyncService: FormSyncService,
        private router: Router
    ) {
    }

    errorClass(control: keyof (typeof this.form)['controls']) {
        return (this.form.controls[control].touched && this.form.controls[control].errors !== null)
            ? 'validation-error'
            : '';
    }

    close() {
        this.visible$.next(false);
    }

    async submit() {
        if (!this.form.valid) {
            Forms.updateValueAndValidityRecursive(this.form);
            return;
        }

        if (!this.job) {
            throw new Error('No job');
        }

        const {obstructionCode, obstructionRemarks} = this.form.getRawValue();

        await this.formSyncService.rejectWithObstruction(
            this.job.project,
            this.job.id,
            obstructionCode,
            obstructionRemarks
        );

        await this.router.navigate(['projects', this.job.project, 'jobs'])

        this.visible$.next(false);
    }
}
