// @ts-strict-ignore
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export const MAX_NUMBER_DECIMALS = 12;

export function numberQuestionValidator(totalDecimals?: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        if (!control.value) {
            return null;
        }

        if (!validateNumberInput(control.value)) {
            return {number: false};
        }

        if (totalDecimals === undefined) {
            return null;
        }

        const maxDecimals = Math.min(totalDecimals, MAX_NUMBER_DECIMALS);

        return validateDecimals(control.value, maxDecimals) ? null : {maxDecimals};
    };
}

export function validateNumberInput(value: string): boolean {
    let regexString = `^[-+]?\\d+[,.]?\\d*`;

    regexString += '$';
    const regex = new RegExp(regexString);

    return regex.test(value.toString());
}

export function validateDecimals(value: string, decimals: number = MAX_NUMBER_DECIMALS): boolean {
    let regexString = `^[-+]?\\d+`;
    if (decimals !== 0) {
        regexString += `[,.]\\d{${decimals}}`;
    }

    regexString += '$';
    const regex = new RegExp(regexString);

    return regex.test(value.toString());
}
