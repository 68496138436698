import {Component, Input} from '@angular/core';
import {PhotoQuestion} from '../../../models/question/photo-question';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormControl, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';

@Component({
    selector: 'app-question-v2-photo',
    templateUrl: './question-v2-photo.component.html',
})
export class QuestionV2PhotoComponent {
    public currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: PhotoQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: PhotoQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.updateAnswer();
    }

    public disabled = false;
    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);

        this.control.setValue(latest?.value ?? '');
    }
}
