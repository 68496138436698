<app-question-header></app-question-header>

<app-question-body>
    <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
    ></app-question-description>

    <div class="question-type">
        <label class="input-label">Antwoord</label>

        <div class="question-type mt-4">
            <label class="input-label text-info">
                {{ (currentQuestion.multipoint ? 'FORM_QUESTION_TYPE_LOCATION.from' : 'FORM_QUESTION_TYPE_LOCATION.current')|translate }}</label>
            <div class="location-question-field">
                <button type="button" class="vwui-btn gps-button" (click)="getGpsFrom()">
                    <app-icon iconName="crosshair"></app-icon>
                    <span class="gps-button__label">{{ 'FORM_QUESTION_LOCATION.form_question_location_use_gps'|translate }}</span>
                </button>
            </div>
        </div>
        <div *ngIf="locationForm.get('gpsLatitudeFrom').value" class="vwui-alert gps-location">
            Lat: {{ locationForm.get('gpsLatitudeFrom').value|number:'1.6-6' }}
            Long: {{ locationForm.get('gpsLongitudeFrom').value|number:'1.6-6' }}
            <button type="button" (click)="clearGpsFrom()"><app-icon iconName="x"></app-icon></button>
        </div>

        <ng-container *ngIf="currentQuestion.multipoint">
            <div class="question-type mt-4">
                <label class="input-label text-info">
                    {{ 'FORM_QUESTION_TYPE_LOCATION.to'|translate }}</label>
                <div class="location-question-field">
                    <button type="button" class="vwui-btn gps-button" (click)="getGpsTo()">
                        <app-icon iconName="crosshair"></app-icon>
                        <span class="gps-button__label">{{ 'FORM_QUESTION_LOCATION.form_question_location_use_gps'|translate }}</span>
                    </button>
                </div>
            </div>
            <div *ngIf="locationForm.get('gpsLatitudeTo').value" class="vwui-alert gps-location">
                Lat: {{ locationForm.get('gpsLatitudeTo').value|number:'1.6-6' }} &nbsp;&nbsp;&nbsp;&nbsp;
                Long: {{ locationForm.get('gpsLongitudeTo').value|number:'1.6-6' }}
                <button type="button" (click)="clearGpsTo()"><app-icon iconName="x"></app-icon></button>
            </div>
        </ng-container>
    </div>
</app-question-body>
<app-question-footer [currentValue]="currentValue" [canGoForward]="locationForm.valid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
