<ul class="vwui-list">
    <ng-container *ngIf="attachments === null">
        <div class="flex w-full h-full justify-center items-center">
            <app-spinner></app-spinner>
        </div>
    </ng-container>
    <ng-container *ngFor="let attachment of attachments">
        <li class="vwui-list-item cursor-pointer" (click)="openAttachment(attachment)">
            <app-spinner [hidden]="downloadingAttachmentId !== attachment.id"></app-spinner>

            <app-icon class="mr-2" [hidden]="downloadingAttachmentId === attachment.id" [iconName]="attachmentIcon(attachment)"></app-icon>
            <span [hidden]="downloadingAttachmentId === attachment.id">{{ formatFilename(attachment) }}</span>
        </li>
    </ng-container>
    <ng-container *ngIf="attachments?.length === 0">
        <p>Geen bijlagen</p>
    </ng-container>
</ul>
