import {Component, Input, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, FormRecord, Validators} from '@angular/forms';
import {ProjectJobAnswer, ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {FormulaQuestion} from '../../../models/question/formula-question';
import {Subject, Subscription} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {calculateOutputFieldValues} from '../../../utils/formula-evaluator';
import {QuestionTolerance, ToleranceMessage} from '../../../utils/question-tolerance';
import {ToastrService} from 'ngx-toastr';
import {numberQuestionValidator} from '../../../validators/number-question.validator';

@Component({
  selector: 'app-question-v2-formula',
  templateUrl: './question-v2-formula.component.html',
})
export class QuestionV2FormulaComponent implements OnDestroy {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: FormulaQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;
    public showPreviousAnswer = false;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: FormulaQuestion) {
        this.currentQuestion = question;

        this.updateAnswer();
    }
    public formulaForm: FormRecord<FormControl<string | null>> | null = null;
    public formulaFormForTolerance: FormGroup | null = null;
    public tolerance: ToleranceMessage | null = null;
    public formulaFormSubscriptions: Subscription[] = [];

    constructor(private toastr: ToastrService) {
    }

    ngOnDestroy() {
        this.unsubscribeFormulaFormSubscriptions();
    }

    submit() {
        this.doGoForward.next();
    }

    get isValid() {
        return this.formulaForm?.valid ?? false;
    }

    get currentValue(): ProjectJobAnswerValue {
        // Value is empty string if all fields are empty
        return {
            value: this.isFormulaFormEmpty() ? '' : JSON.stringify(this.formulaForm?.value),
            remarkText: null,
            remarkImage: null,
        }
    }

    private isFormulaFormEmpty() {
        const formValue = this.formulaForm?.value || {};
        for (const key in formValue) {
            if (formValue[key] !== null && formValue[key] !== '') {
                return false;
            }
        }
        return true;
    }

    showErrorToast() {
        this.formulaForm?.markAllAsTouched();
        this.toastr.error('Alle velden van de formulevraag moeten ingevuld worden en een geldige waarde bevatten');
    }

    private updateTolerance(values: object) {
        if (!this.currentQuestion) {
            return;
        }

        this.tolerance = this.calculateTolerance(this.currentQuestion, values);
    }

    private calculateTolerance(question: FormulaQuestion, values: object | string): ToleranceMessage {
        return QuestionTolerance.validateFormulaQuestionTolerance(
            question,
            values,
        );
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        this.unsubscribeFormulaFormSubscriptions();

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        this.formulaForm = this.buildFormulaForm(this.currentQuestion, latest);

        const previousAnswer = FormUtils.getPreviousAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        this.formulaFormForTolerance = this.buildFormulaForm(this.currentQuestion, previousAnswer);

        this.showPreviousAnswer = !!previousAnswer && this.currentForm.status === 'Rejected'
            && !this.calculateTolerance(this.currentQuestion, previousAnswer.value ?? '').tolerant;
    }

    outputs$(form: FormGroup) {
        if (!this.currentQuestion) {
            return null;
        }

        const question = this.currentQuestion;

        return form.valueChanges
            .pipe(
                startWith(form.value),
                filter(value => !!value),
                map(value => calculateOutputFieldValues(question.formulaFields.fields, value)),
            );
    }

    private buildFormulaForm(question: FormulaQuestion, answer: ProjectJobAnswer | null = null): FormRecord<FormControl<string | null>> {
        const group = new FormRecord(
            question.formulaFields.fields
                .filter(field => field.type === 'input')
                .reduce<Record<string, FormControl>>((controls, field) => {
                    const validators = question.required ? [Validators.required, numberQuestionValidator()] : [numberQuestionValidator()];
                    controls[field.fieldName] = new FormControl<string | null>(null, validators);

                    return controls;
                }, {}),
        );

        this.formulaFormSubscriptions.push(
            group.valueChanges.subscribe(value => this.updateTolerance(value)),
        );

        const value = answer?.value;

        if (value) {
            const parsedValue = JSON.parse(value);
            group.patchValue(parsedValue);
        }

        return group;
    }

    private unsubscribeFormulaFormSubscriptions() {
        this.formulaFormSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
