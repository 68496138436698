<div class="accordion-group" (click)="controller.open(accordionGroupId, accordionId)" [class.collapsed]="(collapsed$|async) !== true" [class.open]="(collapsed$|async)">
    <span class="accordion-group__start-icon" [class]="startIcon">
        <app-icon [iconName]="startIcon === 'indeterminate' ? 'check' : startIcon"></app-icon>
    </span>
    <ng-content select=".accordion-group__content"></ng-content>
    <div class="accordion-group__icon-right">
        <app-icon [iconName]="(collapsed$|async) ? 'chevron-down' : 'chevron-up'"></app-icon>
    </div>
</div>

<div class="accordion-list" [class.open]="(collapsed$|async)" [class.collapsed]="(collapsed$|async) !== true">
    <ng-content select="app-accordion-list-item"></ng-content>
</div>
