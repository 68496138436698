import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {PopupConfig} from '../../models/popup-config';
import {AnyProjectJobForm} from '../../models/project-job-form';
import {Observable} from 'rxjs';
import {FormService} from '../../services/form.service';
import {PopupRef} from '../../helpers/popup-ref';

@Component({
    selector: 'app-reject-inspection-form-modal',
    templateUrl: './reject-inspection-form-modal.component.html'
})
export class RejectInspectionFormModalComponent {
    @Input() projectId: number | undefined;
    @Input() jobId: number | undefined;
    public openForm$: Observable<AnyProjectJobForm | undefined> = this.formService.openForm$

    constructor(
        private formService: FormService,
        private router: Router,
        private popupRef: PopupRef,
        {data}: PopupConfig<{ projectId: number, jobId: number }>
    ) {
        if (data) {
            this.projectId = data.projectId;
            this.jobId = data.jobId;
        }
    }

    async closeForm() {
        await this.router.navigate(['projects', this.projectId, 'jobs'])

        this.popupRef.close();
    }

    async resetForm() {
        await this.router.navigate(['projects', this.projectId, 'jobs', this.jobId, 'v2'])

        this.popupRef.close();
    }
}
