/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { Milliseconds as t, millisecondsFromSeconds as i, Seconds as e } from "../../../core/time.js";
import { inOutCoastQuad as n, outExpo as s } from "../easing.js";
import { Definition as a } from "./Definition.js";
import { InterpolateComponents as o } from "./InterpolateComponents.js";
import { defaultSettings as r } from "./Settings.js";
import { Path as m } from "./apex/Path.js";
const h = new o();
let p = class {
  get time() {
    return this._time;
  }
  get isLinear() {
    return 1 === this.path.segments.length;
  }
  constructor(i) {
    this._time = t(0), this._easing = n, this.definition = new a(i), this.path = new m();
  }
  update(t, a, o) {
    this.definition.update(t, a, o), this.path.update(this.definition, o), this._time = this._applyTimeSettings(i(isFinite(this.path.time) ? this.path.time : e(0)), o), this._easing = o.easing ?? (this._time >= 1e3 ? n : s);
  }
  cameraAt(t, i) {
    t = Math.min(Math.max(0, t), 1), t = this._normalizedEasing(t);
    const e = this.path.interpolateComponentsAt(t, h);
    i.interpolate(this.definition.source, this.definition.target, e);
  }
  _normalizedEasing(t) {
    const i = this._easing(0, this._time),
      e = this._easing(1, this._time);
    return (this._easing(t, this._time) - i) / (e - i);
  }
  _applyTimeSettings(i, e) {
    const n = null != e.speedFactor ? e.speedFactor : 1,
      s = e.minDuration ?? r.minDuration / n,
      a = e.maxDuration ?? r.maxDuration / n;
    return i = null != e.duration ? e.duration : t(Math.min(Math.max(i / n, s), a));
  }
};
export { p as Animation };