<div class="actionbar fixed inset-x-0 bottom-0">
    <button type="button" class="vwui-btn" [disabled]="(canGoBack$ | async) !== true" (click)="goBack()">Vorige</button>
    <button type="button" class="vwui-btn is-primary"
            [class.vwui-btn__relative]="showSpinner$ | async"
            (click)="goForward()"
            [class.disabled]="!canGoForward">
        Volgende
        <app-spinner class="absolute right-4 w-5 h-5" [hidden]="(showSpinner$ | async) !== true"></app-spinner>
    </button>
    <input type="submit" hidden>
</div>
