import {firstValueFrom, Subject} from "rxjs";

export class PopupRef {
    constructor() {
    }

    afterClosed = new Subject<unknown>();

    close<T>(result?: T) {
        this.afterClosed.next(result);
    }

    result<T>(): Promise<T | undefined> {
        return firstValueFrom(this.afterClosed) as Promise<T | undefined>;
    }
}
