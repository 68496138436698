import {
    AfterContentInit,
    Component,
    ContentChildren,
    OnDestroy,
    QueryList
} from '@angular/core';
import {TreeLevelComponent} from "../tree-level/tree-level.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
})
export class TreeComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(TreeLevelComponent, { descendants: true }) public levels!: QueryList<TreeLevelComponent>;
    private expandedNodes: string[] = [];
    private subscriptions: Subscription[] = [];

    ngAfterContentInit() {
        this.subscriptions.push(
            this.levels.changes.subscribe(value => {
                this.attachToLevels(value);
            }),
        );

        this.attachToLevels(this.levels);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    isExpanded(nodeId: string): boolean {
        return this.expandedNodes.includes(nodeId);
    }

    toggleExpanded(nodeId: string): boolean {
        if (this.isExpanded(nodeId)) {
            this.expandedNodes = this.expandedNodes.filter(id => id !== nodeId);

            return false;
        } else {
            this.expandedNodes.push(nodeId);

            return true;
        }
    }

    private attachToLevels(value: QueryList<TreeLevelComponent>) {
        value.forEach(level => level.attachToTreeComponent(this));
    }
}
