import Konva from 'konva';
import {Subject} from 'rxjs';
import {AnnotateComponentsViewportConfig} from './photo-annotation-editor';

export enum AnnotateComponentActionType {
    ADD = 'add',
    CHANGE = 'change',
    REMOVE = 'remove',
    VIEWPORT = 'viewport',
}

export enum AnnotateComponentActionShape {
    ARROW = 'arrow',
    CIRCLE = 'circle',
    ICON = 'icon',
    PENCIL = 'pencil',
    SQUARE = 'square',
    TEXT = 'text',
    VIEWPORT = 'viewport',
    LINE = 'line'
}

export interface AnnotateComponentAction {
    type: AnnotateComponentActionType;
    shape: AnnotateComponentActionShape;
    uuid: string;
    state: AnnotateComponentState;
}

export interface AnnotateComponentPoint {
    x: number;
    y: number;
}

export interface AnnotateComponentState {
    originPoint?: AnnotateComponentPoint;
    config: Konva.ShapeConfig;
    viewportConfig?: AnnotateComponentsViewportConfig;
    draggablePoints?: boolean;
}

export abstract class AnnotateComponent {

    public static UI_SCALING = window.devicePixelRatio || 1;
    public static VIEWPORT_SCALE = Konva.pixelRatio || 1;

    readonly uuid: string;
    readonly shape: AnnotateComponentActionShape;
    readonly transformer: Konva.Transformer;
    readonly deleteButton: Konva.Group;
    readonly drawingLayer: Konva.Group;
    readonly onAction$: Subject<AnnotateComponentAction>;
    readonly state: AnnotateComponentState;
    readonly transformerAnchors = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

    abstract drawingShapeDefaultConfig: Konva.ShapeConfig;
    abstract drawingShape: Konva.Arrow | Konva.Circle | Konva.Image | Konva.Line | Konva.Rect | Konva.Group | null;

    protected constructor(
        uuid: string,
        shape: AnnotateComponentActionShape,
        state: AnnotateComponentState,
    ) {
        this.uuid = uuid;
        this.shape = shape;
        this.state = state;
        this.transformer = new Konva.Transformer().hide();
        this.deleteButton = new Konva.Group();
        this.drawingLayer = new Konva.Group();
        this.transformer.add(this.deleteButton);
        this.drawingLayer.add(this.transformer);
        this.onAction$ = new Subject();

        this.updateDeleteButton();
        this.updateTransformer();
        this.addDeleteButton();
        this.addEvents();
    }

    getDrawingLayer() {
        return this.drawingLayer;
    }

    onAction() {
        return this.onAction$;
    }

    draw() {
        const {originPoint, config} = this.state;

        if (originPoint) {
            this.drawingLayer.setPosition({
                x: originPoint.x,
                y: originPoint.y,
            });
        }

        this.drawingShape?.setAttrs(config);
    }

    showTransformTool() {
        if (this.drawingShape) {
            this.drawingLayer.draggable(true);
            this.transformer.zIndex(0.9);
            this.drawingShape.zIndex(0);
            this.updateTransformer();
            this.transformer.nodes([this.drawingShape]);
            this.updateDeleteButton();
            this.transformer.show();
        }
    }

    hideTransformTool() {
        this.drawingLayer.draggable(false);
        this.transformer.detach();
        this.transformer.hide();
    }

    emitAction(type: AnnotateComponentActionType) {
        this.updateState();
        this.onAction$.next({
            type,
            shape: this.shape,
            uuid: this.uuid,
            state: JSON.parse(JSON.stringify(this.state)),
        });
    }

    updateColor(color: string) {
        if (this.state.config.stroke) {
            this.state.config.stroke = color;
        }
        if (this.state.config.iconColor) {
            this.state.config.iconColor = color;
        }
        this.draw();
    }

    /**
     * Sets the state without sending update events
     * Useful for replaying and reloading events
     */
    setState(newState: AnnotateComponentState, ignoredProperties: string[] = []) {
        this.state.originPoint = newState.originPoint;

        for (const key in newState.config) {
            if (typeof newState.config[key] !== 'function' && !ignoredProperties.find((ignoredKey) => ignoredKey === key)) {
                if (this.state.config) {
                    this.state.config[key] = newState.config[key];
                }
            }
        }

        this.state.originPoint = newState.originPoint;
        this.draw();
    }

    updateState() {
        const {originPoint, config} = this.state;

        // Make sure the position of the drawing shape, which is being manipulated by scaling, is appended to the origin position
        // To get the real position to place it on
        if (originPoint && config && this.drawingShape) {
            originPoint.x = this.drawingLayer.getPosition().x + this.drawingShape.getPosition().x;
            originPoint.y = this.drawingLayer.getPosition().y + this.drawingShape.getPosition().y;
            config.width = this.drawingShape.width();
            config.height = this.drawingShape.height();
            config.scaleX = this.drawingShape.scaleX();
            config.scaleY = this.drawingShape.scaleY();
            config.rotation = this.drawingShape.rotation();

            // The shapes position should not be updated - Only the drawing layers position should
            config.x = 0;
            config.y = 0;
        }
    }

    updateTransformer() {
        this.transformer.enabledAnchors(this.transformerAnchors);
        this.transformer.setAttrs({
            padding: 12,
            borderStroke: '#9C45FA',
            borderStrokeWidth: 2,
            anchorFill: '#fff',
            anchorStroke: '#9C45FA',
            anchorCornerRadius: 6,
            anchorSize: 12,
        });
    }

    protected onChange() {
        this.emitAction(AnnotateComponentActionType.CHANGE);
    }

    protected addEvents() {
        this.transformer.on('transform', this.onUpdate.bind(this));
        this.transformer.on('transformend', this.onChange.bind(this));
        this.drawingLayer.on('dragmove', this.onUpdate.bind(this));
        this.drawingLayer.on('dragend', this.onChange.bind(this));
        this.drawingLayer.on('click tap', this.onClick.bind(this));
    }

    protected onClick(event: unknown) {
        this.showTransformTool();
    }

    protected onUpdate() {
        this.updateState();
        this.updateDeleteButton();
        this.updateTransformer();
    }

    private addDeleteButton() {
        Konva.Image.fromURL('assets/svg-icons/annotate/delete.svg', image => {
            if (!image) return;

            this.deleteButton
                .add(image)
                .on('click tap', this.onDelete.bind(this));

            this.updateDeleteButton();
        });
    }

    protected updateDeleteButton() {
        this.deleteButton.x(this.transformer.getWidth() + 30);
        this.deleteButton.y(-60);
    }

    private onDelete() {
        this.drawingLayer.destroy();
        this.emitAction(AnnotateComponentActionType.REMOVE);
    }
}
