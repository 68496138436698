<div class="bg-white w-full flex flex-col" *ngIf="currentLayer$ | async as currentLayer">
    <div class="pt-safe w-full flex-grow-0">
        <div class="flex justify-between gap-2 items-center px-6 pt-6">
            <h2 class="m-0 text-neutral-50 font-normal">{{ ((lastLayer$ | async)?.name | titlecase) ?? 'Sectie' }} toevoegen</h2>
        </div>
    </div>

    <div class="p-6 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-200 w-full bg-white flex-grow-0">
        <app-node-breadcrumbs [path]="path$ | async" [current]="currentLayer.name | titlecase" (nodeTapped)="navigateTo($event)"></app-node-breadcrumbs>
    </div>

    <div class="flex-grow">
        <div *ngFor="let node of currentNodes$ | async"
             class="full px-6 py-4 text-dark text-sm cursor-pointer hover:bg-gray-50 border-b border-t-0 border-l-0 border-r-0 border-solid border-gray-100"
             (click)="select(node)">
            {{ node.name }}
        </div>

        <div class="py-3" *ngIf="(currentNodes$ | async)?.length === 0">
            <app-empty-state-message>
                <div class="max-w-[15rem] text-neutral-40">
                    <h5>Geen resultaten</h5>
                    <p>Druk op nieuw {{ currentLayer.name | lowercase }} om een item toe te voegen.</p>
                </div>
            </app-empty-state-message>
        </div>
    </div>

    <div class="bg-white shadow-minimal pb-safe flex-grow-0">
        <div class="p-6">
            <div class="w-full bg-white cursor-pointer hover:bg-gray-50 rounded h-10 flex items-center justify-center font-medium text-base gap-2" (click)="openPopup()">
                <app-icon iconName="plus"></app-icon>
                Nieuw {{ currentLayer.name | lowercase }}
            </div>
        </div>
    </div>
</div>
