<div class="p-6">
    <app-popup-header>{{ layerName$|async|titlecase }} verwijderen</app-popup-header>
    <app-popup-body>
        <div class="space-y-4">
            <div *ngIf="canDelete">
                Weet u zeker dat u element <span class="font-medium">{{ node?.name }}</span> wilt verwijderen?
            </div>

            <div *ngIf="!canDelete">
                Het is niet mogelijk om het element <span class="font-medium">{{ node?.name }}</span> te verwijderen omdat
                er nog onderliggende informatie aan gekoppeld is.
            </div>

            <button class="vwui-btn is-danger" (click)="confirm()" *ngIf="canDelete">
                Verwijderen
            </button>

            <button class="vwui-btn" (click)="cancel()">
                Sluiten
            </button>
        </div>
    </app-popup-body>
</div>
