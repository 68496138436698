import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {firstValueFrom, Subscription} from 'rxjs';
import {QrCodeScannerModalComponent} from '../qr-code-scanner-modal/qr-code-scanner-modal.component';
import {ProjectJobSortModalComponent} from '../project-job-sort-modal/project-job-sort-modal.component';
import {PopupService} from '../../services/popup.service';

const DEFAULT_JOB_SORT = 'title';

export interface ProjectJobSearchState {
    search: string;
    searchTags: string[];
    sort: string;
}

export function loadStoredJobSearchState(): ProjectJobSearchState {
    const searchTags: string[] = JSON.parse(localStorage.getItem('projectJobFilter') || '[]');
    const sort = localStorage.getItem('projectJobSort') || DEFAULT_JOB_SORT;

    return {search: '', searchTags, sort};
}

@Component({
    selector: 'app-project-jobs-search',
    templateUrl: './project-jobs-search.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectJobsSearchComponent),
            multi: true
        }
    ]
})
export class ProjectJobsSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
    tags: string[] = [];
    sort: string = DEFAULT_JOB_SORT;

    private onTouched = () => {
    };
    private onChange = (_: ProjectJobSearchState) => {
    };

    searchControl = new FormControl<string>('', {nonNullable: true});
    searchControlSubscription: Subscription | null = null;

    constructor(
        private toastr: ToastrService,
        private popupService: PopupService) {
    }

    writeValue(state: ProjectJobSearchState): void {
        this.searchControl.patchValue(state.search, {emitEvent: false});
        this.tags = state.searchTags;
        this.sort = state.sort;
    }

    registerOnChange(fn: (val: ProjectJobSearchState) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    ngOnInit() {
        this.searchControlSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(250)
        ).subscribe(value => {
            this.emitNewState();
        });
    }

    ngOnDestroy() {
        this.searchControlSubscription?.unsubscribe();
    }

    addTagFromValue() {
        const value = this.searchControl.value.trim();
        if (this.addTag(value)) {
            this.searchControl.patchValue('', {emitEvent: false});
        }
    }

    deleteTag(filter: string) {
        const index = this.tags.indexOf(filter);
        if (index > -1) {
            this.tags.splice(index, 1);
            this.saveTagsToLocalStorage();
            this.emitNewState();
        }
    }

    toggleFilterToday() {
        const date = new Date();
        const today = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        const index: number = this.tags.indexOf(today);
        if (index == -1) {
            this.addTag(today);
        } else {
            this.tags.splice(index, 1);
        }
        this.emitNewState();
    }

    clearSearchInput() {
        this.searchControl.patchValue('', {emitEvent: false});
        this.emitNewState();
    }

    async scanQrCode() {
        const modal = this.popupService.open(QrCodeScannerModalComponent, {
            data: {
                title: 'Zoek opdracht via QR- of barcode'
            }
        });

        const result = await firstValueFrom(modal.afterClosed);

        if (typeof result === 'string') {
            this.searchControl.patchValue(result, {emitEvent: false});
            this.emitNewState();
        }
    }

    async openSortModal() {
        const modal = this.popupService.open(ProjectJobSortModalComponent, { data: this.sort });

        const result = await firstValueFrom(modal.afterClosed);

        if (typeof result === 'string') {
            this.sort = result;
            localStorage.setItem('projectJobSort', this.sort);
            this.emitNewState();
        }

    }

    private addTag(value: string): boolean {
        if (this.tags.length >= 10) {
            this.toastr.error('Je hebt het maximum van 10 tags bereikt');

            return false;
        }

        this.tags.push(value);
        this.saveTagsToLocalStorage();

        return true;
    }

    private emitNewState() {
        this.onTouched();
        this.onChange({
            search: this.searchControl.value.trim(),
            searchTags: [...this.tags],
            sort: this.sort
        });
    }

    private saveTagsToLocalStorage() {
        localStorage.setItem('projectJobFilter', JSON.stringify(this.tags));
    }
}
