import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class DateHttpInterceptorService implements HttpInterceptor {
    // Migrated from AngularJS https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
    static readonly iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    static readonly propertyNamesToConvert = [
        'createdAt', 'updatedAt', 'submittedAt'
    ]

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<unknown>) => {
                if (event instanceof HttpResponse) {
                    const body = event.body;
                    this.convertToDate(body);
                }
            })
        );
    }

    convertToDate(body: unknown) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        const bodyObj = body as Record<string, unknown>;
        for (const key of Object.keys(bodyObj)) {
            const value = bodyObj[key];
            // Only convert the property if it is in the list of properties to convert
            if (DateHttpInterceptorService.isIso8601(value) && DateHttpInterceptorService.propertyNamesToConvert.includes(key)) {
                bodyObj[key] = new Date(value);
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    static isIso8601(value: unknown): value is string {
        if (value === null || value === undefined || typeof value !== 'string') {
            return false;
        }

        return this.iso8601.test(value);
    }
}

export function dateReviver(key: string, value: unknown): unknown {
    if (DateHttpInterceptorService.propertyNamesToConvert.includes(key) && DateHttpInterceptorService.isIso8601(value)) {
        // Convert ISO8601 date strings to Date objects
        return new Date(value);
    }

    return value;
}
