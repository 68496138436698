/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { NumericIdentifiableMixin as o } from "../../../core/Identifiable.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as i } from "../../../core/accessorSupport/decorators/subclass.js";
var a;
let c = a = class extends o(t) {
  constructor(e) {
    super(e), this._lodByScale = {}, this._scales = [], this.effectiveLODs = null, this.effectiveMinZoom = -1, this.effectiveMaxZoom = -1, this.effectiveMinScale = 0, this.effectiveMaxScale = 0, this.lods = null, this.minZoom = -1, this.maxZoom = -1, this.minScale = 0, this.maxScale = 0, this.snapToZoom = !0;
  }
  initialize() {
    let e,
      {
        lods: t,
        minScale: o,
        maxScale: s,
        minZoom: i,
        maxZoom: a
      } = this,
      c = -1,
      l = -1,
      r = !1,
      n = !1;
    if (0 !== o && 0 !== s && o < s && ([o, s] = [s, o]), !t?.length) return this._set("effectiveMinScale", o), void this._set("effectiveMaxScale", s);
    t = t.map(e => e.clone()), t.sort((e, t) => t.scale - e.scale), t.forEach((e, t) => e.level = t);
    for (const f of t) !r && o > 0 && o >= f.scale && (c = f.level, r = !0), !n && s > 0 && s >= f.scale && (l = e ? e.level : -1, n = !0), e = f;
    -1 === i && (i = 0 === o ? 0 : c), -1 === a && (a = 0 === s ? t.length - 1 : l), i = Math.max(i, 0), i = Math.min(i, t.length - 1), a = Math.max(a, 0), a = Math.min(a, t.length - 1), i > a && ([i, a] = [a, i]), o = t[i].scale, s = t[a].scale, t.splice(0, i), t.splice(a - i + 1, t.length), t.forEach((e, t) => {
      this._lodByScale[e.scale] = e, this._scales[t] = e.scale;
    }), this._set("effectiveLODs", t), this._set("effectiveMinZoom", i), this._set("effectiveMaxZoom", a), this._set("effectiveMinScale", o), this._set("effectiveMaxScale", s);
  }
  constrain(e, t) {
    if (t && e.scale === t.scale) return e;
    const o = this.effectiveMinScale,
      s = this.effectiveMaxScale,
      i = e.targetGeometry,
      a = t && t.targetGeometry,
      c = 0 !== s && e.scale < s,
      l = 0 !== o && e.scale > o;
    if (c || l) {
      const c = l ? o : s;
      if (t && a) {
        const o = (c - t.scale) / (e.scale - t.scale);
        i.x = a.x + (i.x - a.x) * o, i.y = a.y + (i.y - a.y) * o;
      }
      e.scale = c;
    }
    return this.snapToZoom && this.effectiveLODs && (e.scale = this._getClosestScale(e.scale)), e;
  }
  fit(e) {
    if (!this.effectiveLODs || !this.snapToZoom) return this.constrain(e, null);
    const t = this.scaleToZoom(e.scale),
      o = Math.abs(t - Math.floor(t));
    return e.scale = this.zoomToScale(o > .99 ? Math.round(t) : Math.floor(t)), e;
  }
  zoomToScale(e) {
    if (!this.effectiveLODs) return 0;
    e -= this.effectiveMinZoom, e = Math.max(0, e);
    const t = this._scales;
    if (e <= 0) return t[0];
    if (e >= t.length) return t[t.length - 1];
    const o = Math.floor(e),
      s = Math.ceil(e);
    return t[o] + (e - o) * (t[s] - t[o]);
  }
  scaleToZoom(e) {
    if (!this.effectiveLODs) return -1;
    const t = this._scales;
    let o, s;
    if (e >= t[0]) return this.effectiveMinZoom;
    if (e <= t[t.length - 1]) return this.effectiveMaxZoom;
    for (let i = 0; i < t.length - 1; i++) {
      if (o = t[i], s = t[i + 1], s === e) {
        return i + this.effectiveMinZoom + 1;
      }
      if (o > e && s < e) {
        return i + this.effectiveMinZoom + 1 - (e - s) / (o - s);
      }
    }
    return -1;
  }
  snapToClosestScale(e) {
    if (!this.effectiveLODs) return e;
    const t = this.scaleToZoom(e);
    return this.zoomToScale(Math.round(t));
  }
  snapToNextScale(e, t = .5) {
    if (!this.effectiveLODs) return e * t;
    const o = Math.round(this.scaleToZoom(e));
    return this.zoomToScale(o + 1);
  }
  snapToPreviousScale(e, t = 2) {
    if (!this.effectiveLODs) return e * t;
    const o = Math.round(this.scaleToZoom(e));
    return this.zoomToScale(o - 1);
  }
  clone() {
    return new a({
      lods: this.lods,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      minScale: this.minScale,
      maxScale: this.maxScale
    });
  }
  _getClosestScale(e) {
    return this._lodByScale[e] || (e = this._scales.reduce((t, o) => Math.abs(o - e) <= Math.abs(t - e) ? o : t, this._scales[0])), this._lodByScale[e].scale;
  }
};
e([s({
  readOnly: !0
})], c.prototype, "effectiveLODs", void 0), e([s({
  readOnly: !0
})], c.prototype, "effectiveMinZoom", void 0), e([s({
  readOnly: !0
})], c.prototype, "effectiveMaxZoom", void 0), e([s({
  readOnly: !0
})], c.prototype, "effectiveMinScale", void 0), e([s({
  readOnly: !0
})], c.prototype, "effectiveMaxScale", void 0), e([s()], c.prototype, "lods", void 0), e([s()], c.prototype, "minZoom", void 0), e([s()], c.prototype, "maxZoom", void 0), e([s()], c.prototype, "minScale", void 0), e([s()], c.prototype, "maxScale", void 0), e([s()], c.prototype, "snapToZoom", void 0), c = a = e([i("esri.views.2d.constraints.ZoomConstraint")], c);
const l = c;
export { l as default };