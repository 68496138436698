/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../geometry.js";
import i from "../../Viewpoint.js";
import { JSONSupport as e } from "../../core/JSONSupport.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import { toRadian as r } from "../../core/libs/gl-matrix-2/math/common.js";
import { fromTranslation as a, rotate as n, translate as p, invert as m } from "../../core/libs/gl-matrix-2/math/mat2d.js";
import { create as c } from "../../core/libs/gl-matrix-2/factories/mat2df32.js";
import { create as h } from "../../core/libs/gl-matrix-2/factories/mat2df64.js";
import { set as l, identity as f, translate as w, rotate as d, multiply as v } from "../../core/libs/gl-matrix-2/math/mat3.js";
import { create as x } from "../../core/libs/gl-matrix-2/factories/mat3f32.js";
import { copy as u, transformMat2d as j } from "../../core/libs/gl-matrix-2/math/vec2.js";
import { fromValues as g } from "../../core/libs/gl-matrix-2/factories/vec2f32.js";
import { create as y } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import { isVec2 as R } from "../../core/libs/gl-matrix-2/types/vec2.js";
import { getClosestDenormalizedXToReference as _ } from "../../geometry/support/normalizeUtils.js";
import { copy as z, getWorldWidth as b, getMatrix as M, getResolution as S, getExtent as N, getTransform as T, getTransformNoRotation as D, getWorldScreenWidth as V } from "./viewpointUtils.js";
import G from "../../geometry/Extent.js";
import W from "../../geometry/Point.js";
var C;
const L = [0, 0];
let O = C = class extends e {
  constructor(t) {
    super(t), this._viewpoint2D = {
      center: y(),
      rotation: 0,
      scale: 0,
      spatialReference: void 0
    }, this.center = [0, 0], this.extent = new G(), this.id = 0, this.inverseTransform = h(), this.resolution = 0, this.rotation = 0, this.scale = 0, this.transform = h(), this.transformNoRotation = h(), this.displayMat3 = x(), this.displayViewMat3 = x(), this.viewMat3 = x(), this.viewMat2d = c(), this.worldScreenWidth = 0, this.size = [0, 0];
  }
  set pixelRatio(t) {
    this._set("pixelRatio", t), this._update();
  }
  set size(t) {
    this._set("size", t), this._update();
  }
  set viewpoint(t) {
    if (t) {
      const i = this._viewpoint2D,
        e = t.targetGeometry;
      i.center[0] = e.x, i.center[1] = e.y, i.rotation = t.rotation, i.scale = t.scale, i.spatialReference = e.spatialReference;
    }
    this._update();
  }
  copy(t) {
    const i = this.size,
      e = this.viewpoint;
    return e && i ? (this.viewpoint = z(e, t.viewpoint), this._set("size", u(i, t.size))) : (this.viewpoint = t.viewpoint.clone(), this._set("size", [t.size[0], t.size[1]])), this._set("pixelRatio", t.pixelRatio), this;
  }
  clone() {
    return new C({
      size: this.size,
      viewpoint: this.viewpoint.clone(),
      pixelRatio: this.pixelRatio
    });
  }
  toMap(t, i, e) {
    return R(i) ? j(t, i, this.inverseTransform) : (L[0] = i, L[1] = e, j(t, L, this.inverseTransform));
  }
  toScreen(t, i, e) {
    return R(i) ? j(t, i, this.transform) : (L[0] = i, L[1] = e, j(t, L, this.transform));
  }
  toScreenNoRotation(t, i, e) {
    return R(i) ? j(t, i, this.transformNoRotation) : (L[0] = i, L[1] = e, j(t, L, this.transformNoRotation));
  }
  wrapMapCoordinate(t, i) {
    u(t, i);
    const [e] = i,
      [s] = this.center,
      {
        extent: o,
        spatialReference: r
      } = this;
    let {
      xmin: a,
      xmax: n
    } = o;
    if (r.isWrappable) {
      const t = b(r) / 2;
      a = Math.max(a, s - t), n = Math.min(n, s + t);
    }
    return (e < a || e > n) && (t[0] = _(e, s, r)), t;
  }
  getScreenTransform(t, i) {
    const {
        center: e
      } = this._viewpoint2D,
      s = this._get("pixelRatio") || 1,
      o = this._get("size");
    return M(t, e, o, i, 0, s), t;
  }
  _update() {
    const {
        center: t,
        spatialReference: e,
        scale: s,
        rotation: o
      } = this._viewpoint2D,
      c = this._get("pixelRatio") || 1,
      h = this._get("size"),
      x = new i({
        targetGeometry: new W(t[0], t[1], e),
        scale: s,
        rotation: o
      });
    if (this._set("viewpoint", x), !h || !e || !s) return;
    this.resolution = S(x), this.rotation = o, this.scale = s, this.spatialReference = e, u(this.center, t);
    const j = 0 !== h[0] ? 2 / h[0] : 0,
      y = 0 !== h[1] ? -2 / h[1] : 0;
    l(this.displayMat3, j, 0, 0, 0, y, 0, -1, 1, 1);
    const R = f(this.viewMat3),
      _ = g(h[0] / 2, h[1] / 2),
      z = g(-h[0] / 2, -h[1] / 2),
      b = r(o);
    w(R, R, _), d(R, R, b), w(R, R, z), v(this.displayViewMat3, this.displayMat3, R);
    const M = a(this.viewMat2d, _);
    return n(M, M, b), p(M, M, z), N(this.extent, x, h), T(this.transform, x, h, c), m(this.inverseTransform, this.transform), D(this.transformNoRotation, x, h, c), this.worldScreenWidth = V(this.spatialReference, this.resolution), this._set("id", this.id + 1), this;
  }
};
t([s({
  readOnly: !0
})], O.prototype, "id", void 0), t([s({
  value: 1,
  json: {
    write: !0
  }
})], O.prototype, "pixelRatio", null), t([s({
  json: {
    write: !0
  }
})], O.prototype, "size", null), t([s()], O.prototype, "spatialReference", void 0), t([s({
  type: i,
  json: {
    write: !0
  }
})], O.prototype, "viewpoint", null), O = C = t([o("esri.views.2d.ViewState")], O);
const U = O;
export { U as default };