<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type">
            <label class="input-label">Antwoord</label>

            <ng-container *ngIf="!currentQuestion?.multiline">
                <input type="text" [formControl]="control" [maxLength]="maxLength">
            </ng-container>

            <ng-container *ngIf="currentQuestion?.multiline">
                <textarea [formControl]="control" [maxLength]="maxLength"></textarea>
            </ng-container>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="isValid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
</form>
