import {IndexedLayerNode} from '../models/layered-form-node';
import {db} from '../db/db';

export async function findAllNodeParents(node?: { parent: IndexedLayerNode['parent'] }): Promise<IndexedLayerNode[]> {
    if (!node) {
        return [];
    }

    const parents: IndexedLayerNode[] = [];
    let parent = node.parent;

    while (parent) {
        const parentNode = await db.nodes.get(parent);

        if (!parentNode) {
            throw new Error(`Parent node with id ${parent} not found`);
        }

        parents.unshift(parentNode);

        parent = parentNode.parent;
    }

    return parents;
}
