import {AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {AnnotatePathComponent} from './annotate-path-component';
import {Line} from 'konva/lib/shapes/Line'

export class AnnotateComponentPencil extends AnnotatePathComponent {

    readonly transformerAnchors = ['bottom-right'];
    readonly drawingShapeDefaultConfig = {
        hitStrokeWidth: 30,
        points: [0, 0, 150, 0],
    };
    readonly drawingShape: Line;

    constructor(
        uuid: string,
        state: AnnotateComponentState,
    ) {
        super(uuid, AnnotateComponentActionShape.PENCIL, state);

        this.drawingShape = new Line({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);

        this.addDraggablePoints();
        this.draw();
    }

    updateState() {
        super.updateState();

        if (this.state.config) {
            this.state.config.points = this.drawingShape.points();
        }
    }

}
