<div class="question-type" [class.is-disabled]="disabled">
    <label [id]="inputId" *ngIf="label" class="input-label">
        {{ label }}
        @if (required) {
            <span class="text-danger">*</span>
        }
    </label>
    <input [id]="inputId"
        *ngIf="!isIOS else iosInput"
        type="text"
        inputmode="decimal"
        [disabled]="disabled"
        [ngModel]="value"
        (ngModelChange)="value = doConvertToNumber($event)"
    />
</div>

<ng-template #iosInput>
    <input [id]="inputId"
        type="number"
        [disabled]="disabled"
        [ngModel]="value"
        (keydown)="validateAllowedKeys($event)"
        (input)="updateValueFromInputEvent($event)"
    />
</ng-template>
