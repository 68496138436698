<div class="image-tools" [class.image-tools--visible]="visible">
    <div class="image-tools__accordion">
        <div class="image-tools__accordion-header">
            <p><app-icon (click)="close()" iconName="chevron-left" ></app-icon> Schetsobjecten</p>
        </div>
        <ng-container *ngFor="let imageTool of imageTools">
            <div class="image-tools__accordion__item" (click)="chooseImageTool(imageTool)">
                <p>
                    <ng-container *ngIf="imageTool.icon">
                        <app-memsafe-img class="image-tools__accordion__item-icon-left" [src]="imageTool.icon">
                        </app-memsafe-img>
                    </ng-container>
                    {{ imageTool.title }}
                </p>
            </div>
        </ng-container>
    </div>
</div>
