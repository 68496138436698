/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { watch as o, initial as e } from "../../core/reactiveUtils.js";
import { property as i } from "../../core/accessorSupport/decorators/property.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import r from "./Component.js";
import n from "./UI.js";
import a from "../../widgets/Attribution.js";
import d from "../../widgets/Compass.js";
import p from "../../widgets/NavigationToggle.js";
import c from "../../widgets/Zoom.js";
function m(t) {
  return void 0 !== t?.view;
}
let h = class extends n {
  constructor(t) {
    super(t), this._defaultPositionLookup = {
      attribution: "manual",
      compass: "top-left",
      "navigation-toggle": "top-left",
      zoom: "top-left"
    }, this.components = [], this._updateViewAwareWidgets = t => {
      this.components.forEach(o => {
        const e = this._find(o),
          i = e?.widget;
        m(i) && (i.view = t);
      });
    }, this._componentsWatcher = (t, o) => {
      this._removeComponents(o), this._addComponents(t), this._adjustPadding(t);
    };
  }
  initialize() {
    this.addHandles([o(() => this.components, this._componentsWatcher, e), o(() => this.view, this._updateViewAwareWidgets, e)]);
  }
  _add(t, o, e, i, s) {
    let r = t;
    if ("string" == typeof t && this._defaultPositionLookup[t]) {
      if (this._find(t)) return;
      r = this._createComponent(t);
    }
    super._add(r, o, e, i, s);
  }
  _removeComponents(t) {
    t.forEach(t => {
      const o = this._find(t);
      o && (this.remove(o), o.destroy());
    });
  }
  _adjustPadding(t) {
    if (!t.includes("attribution") && !this._isOverridden("padding")) {
      const {
        top: t
      } = this.padding;
      this.padding = t;
    }
  }
  _addComponents(t) {
    this.constructed && t.forEach(t => this.add(this._createComponent(t), this._defaultPositionLookup[t]));
  }
  _createComponent(t) {
    const o = this._createWidget(t);
    return new r({
      id: t,
      node: o
    });
  }
  _createWidget(t) {
    const o = this.view;
    switch (t) {
      case "attribution":
        return new a({
          view: o
        });
      case "compass":
        return new d({
          view: o
        });
      case "navigation-toggle":
        return new p({
          view: o
        });
      case "zoom":
        return new c({
          view: o
        });
    }
  }
};
t([i()], h.prototype, "components", void 0), h = t([s("esri.views.ui.DefaultUI")], h);
const u = h;
export { u as default };