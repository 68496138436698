/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { byId as t, reparent as s, empty as i } from "../core/domUtils.js";
import { on as r } from "../core/events.js";
import o from "../core/Logger.js";
import { destroyMaybe as n, removeMaybe as a } from "../core/maybe.js";
import { watch as h, initial as d } from "../core/reactiveUtils.js";
import { addFrameTask as l } from "../core/scheduling.js";
import { property as u } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as c } from "../core/accessorSupport/decorators/subclass.js";
import p from "./overlay/ViewOverlay.js";
const m = [0, 0];
function f(e) {
  const t = (e.ownerDocument || window.document).defaultView,
    s = e.getBoundingClientRect();
  return m[0] = s.left + (t?.pageXOffset ?? 0), m[1] = s.top + (t?.pageYOffset ?? 0), m;
}
function g(e) {
  e && (i(e), e.parentNode && e.parentNode.removeChild(e));
}
function y(e) {
  const t = document.createElement("div");
  return e.appendChild(t), t;
}
const _ = 16,
  v = 750,
  w = 512,
  C = 2,
  H = i => {
    let m = class extends i {
      constructor(...e) {
        super(...e), this._freqInfo = {
          freq: _,
          time: v
        }, this._overlayRenderTaskHandle = null, this.height = 0, this.messagesCommon = null, this.overlay = null, this.position = null, this.resizing = !1, this.root = null, this.surface = null, this.suspended = !0, this.ui = null, this.userContent = null, this.width = 0, this.widthBreakpoint = null, this.addHandles([h(() => this.cursor, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-cursor", e);
        }), h(() => this.navigating, e => {
          const {
            surface: t
          } = this;
          t && t.setAttribute("data-navigating", e.toString());
        })]);
      }
      initialize() {
        this.addHandles([h(() => this.ui, (e, t) => this._handleUIChange(e, t), d), this.on("focus", () => this.notifyChange("focused")), this.on("blur", () => this.notifyChange("focused"))]);
      }
      destroy() {
        this.destroyed || (this.ui = n(this.ui), this.container = null);
      }
      get container() {
        return this._get("container") ?? null;
      }
      set container(e) {
        const i = this._get("container"),
          r = t(e);
        if (r || "string" != typeof e || o.getLogger(this).error("#container", `element with id '${e}' not found`), i === r) return;
        if (this._stopMeasuring(), i && (i.classList.remove("esri-view"), this._overlayRenderTaskHandle && (this._overlayRenderTaskHandle.remove(), this._overlayRenderTaskHandle = null), this.overlay && (this.overlay.destroy(), this._set("overlay", null)), this.root && (g(this.root), this._set("root", null)), this.userContent && (s(this.userContent, i), g(this.userContent), this._set("userContent", null))), !r) return this._set("width", 0), this._set("height", 0), this._set("position", null), this._set("suspended", !0), this._set("surface", null), void this._set("container", null);
        r.classList.add("esri-view");
        const n = document.createElement("div");
        n.className = "esri-view-user-storage", s(r, n), r.appendChild(n), this._set("userContent", n);
        const d = document.createElement("div");
        d.className = "esri-view-root", r.insertBefore(d, r.firstChild), this._set("root", d);
        const u = document.createElement("div");
        u.className = "esri-view-surface", u.setAttribute("role", "application"), u.tabIndex = 0, d.appendChild(u), this._set("surface", u);
        const c = new p();
        d.appendChild(c.surface), this._set("overlay", c), this.addHandles(h(() => c.needsRender, e => {
          e && !this._overlayRenderTaskHandle ? this._overlayRenderTaskHandle = l({
            render: () => this.overlay?.render()
          }) : this._overlayRenderTaskHandle = a(this._overlayRenderTaskHandle);
        })), this.forceDOMReadyCycle(), this._set("container", r), this._startMeasuring();
      }
      get focused() {
        const e = document.activeElement === this.surface;
        return document.hasFocus() && e;
      }
      get size() {
        return [this.width, this.height];
      }
      blur() {
        this.surface?.blur();
      }
      focus() {
        this.surface?.focus();
      }
      pageToContainer(e, t, s) {
        const i = this.position;
        return e -= i ? i[0] : 0, t -= i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      containerToPage(e, t, s) {
        const i = this.position;
        return e += i ? i[0] : 0, t += i ? i[1] : 0, s ? (s[0] = e, s[1] = t) : s = [e, t], s;
      }
      _handleUIChange(e, t) {
        this.removeHandles("ui"), t && t !== e && t.destroy(), e && (e.view = this, this.addHandles(h(() => this.root, t => {
          e.container = t ? y(t) : null;
        }, d), "ui")), this._set("ui", e);
      }
      _stopMeasuring() {
        this.removeHandles("measuring"), this._get("resizing") && this._set("resizing", !1);
      }
      _startMeasuring() {
        const e = this._freqInfo;
        e.freq = _, e.time = v, this.addHandles([r(window, "resize", () => {
          e.freq = _, e.time = v;
        }), l({
          prepare: e => {
            const t = this._measure(),
              s = this._freqInfo;
            if (s.time += e.deltaTime, t && (s.freq = _, this._get("resizing") || this._set("resizing", !0)), s.time < s.freq) return;
            s.time = 0;
            const i = this._position();
            s.freq = i || t ? _ : Math.min(v, s.freq * C), !t && s.freq >= w && this._get("resizing") && this._set("resizing", !1);
          }
        })], "measuring"), this._measure(), this._position();
      }
      _measure() {
        const e = this.container,
          t = e ? e.clientWidth : 0,
          s = e ? e.clientHeight : 0;
        if (0 === t || 0 === s) return this.suspended || this._set("suspended", !0), !1;
        const i = this.width,
          r = this.height;
        return t === i && s === r ? (this.suspended && this._set("suspended", !1), !1) : (this._set("width", t), this._set("height", s), this.suspended && this._set("suspended", !1), this.emit("resize", {
          oldWidth: i,
          oldHeight: r,
          width: t,
          height: s
        }), !0);
      }
      _position() {
        const e = this.container,
          t = this.position,
          s = e && f(e);
        return !!s && (!t || s[0] !== t[0] || s[1] !== t[1]) && (this._set("position", [s[0], s[1]]), !0);
      }
      forceDOMReadyCycle() {}
    };
    return e([u()], m.prototype, "container", null), e([u({
      readOnly: !0
    })], m.prototype, "focused", null), e([u({
      readOnly: !0
    })], m.prototype, "height", void 0), e([u()], m.prototype, "messagesCommon", void 0), e([u({
      type: p
    })], m.prototype, "overlay", void 0), e([u({
      readOnly: !0
    })], m.prototype, "position", void 0), e([u({
      readOnly: !0
    })], m.prototype, "resizing", void 0), e([u({
      readOnly: !0
    })], m.prototype, "root", void 0), e([u({
      value: null,
      readOnly: !0
    })], m.prototype, "size", null), e([u({
      readOnly: !0
    })], m.prototype, "surface", void 0), e([u({
      readOnly: !0
    })], m.prototype, "suspended", void 0), e([u()], m.prototype, "ui", void 0), e([u({
      readOnly: !0
    })], m.prototype, "userContent", void 0), e([u({
      readOnly: !0
    })], m.prototype, "width", void 0), e([u()], m.prototype, "widthBreakpoint", void 0), m = e([c("esri.views.DOMContainer")], m), m;
  };
export { H as DOMContainer };