/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { createScreenPoint as e } from "../../../core/screenUtils.js";
const t = {
  maximumClickDelay: 300,
  movementUntilMouseDrag: 1.5,
  movementUntilPenDrag: 6,
  movementUntilTouchDrag: 6,
  holdDelay: 500,
  maximumDoubleClickDelay: 250,
  maximumDoubleClickDistance: 10,
  maximumDoubleTouchDelay: 350,
  maximumDoubleTouchDistance: 35
};
function n(e, t) {
  return Math.abs(t.x - e.x) + Math.abs(t.y - e.y);
}
function r(e, t) {
  const n = t.x - e.x,
    r = t.y - e.y;
  return Math.sqrt(n * n + r * r);
}
function o(t, n) {
  if (n ? (n.radius = 0, n.center.x = 0, n.center.y = 0) : n = {
    radius: 0,
    center: e()
  }, 0 === t.length) return n;
  if (1 === t.length) return n.center.x = t[0].x, n.center.y = t[0].y, n;
  if (2 === t.length) {
    const [e, r] = t,
      [o, u] = [r.x - e.x, r.y - e.y];
    return n.radius = Math.sqrt(o * o + u * u) / 2, n.center.x = (e.x + r.x) / 2, n.center.y = (e.y + r.y) / 2, n;
  }
  let r = 0,
    o = 0;
  for (let e = 0; e < t.length; e++) r += t[e].x, o += t[e].y;
  r /= t.length, o /= t.length;
  const u = t.map(e => e.x - r),
    c = t.map(e => e.y - o);
  let i = 0,
    a = 0,
    l = 0,
    m = 0,
    s = 0,
    x = 0,
    y = 0;
  for (let e = 0; e < u.length; e++) {
    const t = u[e],
      n = c[e],
      r = t * t,
      o = n * n;
    i += r, a += o, l += t * n, m += r * t, s += o * n, x += t * o, y += n * r;
  }
  const h = .5 * (m + x),
    D = .5 * (s + y),
    g = i * a - l * l,
    f = (h * a - D * l) / g,
    b = (i * D - l * h) / g,
    p = e(f + r, b + o);
  return {
    radius: Math.sqrt(f * f + b * b + (i + a) / t.length),
    center: p
  };
}
function u(e) {
  const {
      native: t
    } = e,
    {
      pointerId: n,
      button: r,
      pointerType: o
    } = t;
  return "mouse" === o ? `${n}:${r}` : `${o}`;
}
export { t as DefaultParameters, r as euclideanDistance, o as fitCircleLSQ, u as getPointerId, n as manhattanDistance };