import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appPopupContent]'
})
export class PopupContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
