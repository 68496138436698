<div class="image-annotation-v3" [class.image-annotation-v3--visible]="visible">
    <div class="image-annotation-v3__header" #toolbar>
        <button class="image-annotation-v3__header__button" (click)="close()">
            {{'Annuleren'|translate}}
        </button>
        <button class="image-annotation-v3__header__button ml-auto" (click)="undo()" [disabled]="!canUndo">
            <svg width="24" height="23" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5855 6.46871C1.5855 6.46871 1.58565 6.46852 1 6C0.414348 5.53148 0.41459 5.53118 0.41459 5.53118L0.415866 5.52959L0.418847 5.52588L0.429151 5.51314L0.466773 5.46713C0.499276 5.42766 0.546471 5.37093 0.607256 5.29942C0.728776 5.15646 0.904909 4.95404 1.12682 4.71196C1.56973 4.22879 2.19957 3.58256 2.94537 2.93405C3.68889 2.2875 4.56296 1.62523 5.49492 1.12147C6.42104 0.620869 7.45342 0.25 8.5 0.25C12.5041 0.25 15.75 3.49594 15.75 7.5C15.75 11.4919 12.4263 14.75 8.75 14.75C8.33579 14.75 8 14.4142 8 14C8 13.5858 8.33579 13.25 8.75 13.25C11.5737 13.25 14.25 10.6878 14.25 7.5C14.25 4.32436 11.6756 1.75 8.5 1.75C7.79658 1.75 7.01646 2.00413 6.2082 2.44103C5.40579 2.87477 4.62361 3.4625 3.92963 4.06595C3.23793 4.66744 2.64902 5.27121 2.23255 5.72554C2.02478 5.95221 1.86107 6.14042 1.75017 6.27089C1.69474 6.3361 1.65258 6.3868 1.62478 6.42056L1.59401 6.45818L1.58698 6.46687L1.5855 6.46871Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 1C1.41421 1 1.75 1.33579 1.75 1.75V5.25H5.25C5.66421 5.25 6 5.58579 6 6C6 6.41421 5.66421 6.75 5.25 6.75H1C0.585786 6.75 0.25 6.41421 0.25 6V1.75C0.25 1.33579 0.585786 1 1 1Z" fill="white"/>
            </svg>
        </button>
        <button class="image-annotation-v3__header__button mr-auto" (click)="redo()" [disabled]="!canRedo">
            <svg width="24" height="23" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4145 6.46871C14.4145 6.46871 14.4143 6.46852 15 6C15.5857 5.53148 15.5854 5.53118 15.5854 5.53118L15.5841 5.52959L15.5812 5.52588L15.5708 5.51314L15.5332 5.46713C15.5007 5.42766 15.4535 5.37093 15.3927 5.29942C15.2712 5.15646 15.0951 4.95404 14.8732 4.71196C14.4303 4.22879 13.8004 3.58256 13.0546 2.93405C12.3111 2.2875 11.437 1.62523 10.5051 1.12147C9.57896 0.620869 8.54658 0.25 7.5 0.25C3.49594 0.25 0.25 3.49594 0.25 7.5C0.25 11.4919 3.5737 14.75 7.25 14.75C7.66421 14.75 8 14.4142 8 14C8 13.5858 7.66421 13.25 7.25 13.25C4.4263 13.25 1.75 10.6878 1.75 7.5C1.75 4.32436 4.32436 1.75 7.5 1.75C8.20342 1.75 8.98354 2.00413 9.7918 2.44103C10.5942 2.87477 11.3764 3.4625 12.0704 4.06595C12.7621 4.66744 13.351 5.27121 13.7674 5.72554C13.9752 5.95221 14.1389 6.14042 14.2498 6.27089C14.3053 6.3361 14.3474 6.3868 14.3752 6.42056L14.406 6.45818L14.413 6.46687L14.4145 6.46871Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 1C14.5858 1 14.25 1.33579 14.25 1.75V5.25H10.75C10.3358 5.25 10 5.58579 10 6C10 6.41421 10.3358 6.75 10.75 6.75H15C15.4142 6.75 15.75 6.41421 15.75 6V1.75C15.75 1.33579 15.4142 1 15 1Z" fill="white"/>
            </svg>
        </button>
        <button class="image-annotation-v3__header__button" (click)="sendSaveEvent()">
            {{'Opslaan'|translate}}
        </button>
    </div>
    <div class="image-annotation-v3__container" (click)="openToolMenu = null" #container></div>

    <div class="image-annotation-v3__tools">
        <div class="image-annotation-v3__tools-menu" [class.open]="openToolMenu === 'shape'">
            <ng-container *ngFor="let tool of toolConfig.tools">
                <button *ngIf="tool.component !== 'text'" type="button" class="image-annotation-v3__tool-button" (click)="chooseImageTool(tool)">
                    <img [src]="replaceIconColors(tool.icon)" width="32" height="auto" [alt]="tool.title" />
                </button>
            </ng-container>
        </div>
        <button type="button" class="image-annotation-v3__tool-button" [class.active]="currentTool === 'pencil'" (click)="changeTool('pencil')">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6363 2.03034C14.1008 1.49481 13.2325 1.49481 12.697 2.03034L3.34387 11.3834L2.69742 13.9692L5.28321 13.3228L14.6363 3.96968C15.1718 3.43415 15.1718 2.56588 14.6363 2.03034ZM11.6363 0.969684C12.7576 -0.151637 14.5757 -0.151638 15.697 0.969682C16.8183 2.091 16.8183 3.90902 15.697 5.03034L6.05009 14.6772L1.84854 15.7276C1.59296 15.7915 1.3226 15.7166 1.13631 15.5303C0.950026 15.3441 0.87514 15.0737 0.939035 14.8181L1.98943 10.6166L11.6363 0.969684Z" fill="white"/>
            </svg>
        </button>

        <button type="button" class="image-annotation-v3__tool-button" [class.active]="currentTool === 'shape'" (click)="changeTool('shape', true)">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04006 6.79202C7.54528 7.53419 6.45471 7.53418 5.95993 6.79202L2.89419 2.19341C2.3404 1.36271 2.93589 0.250031 3.93426 0.250031H10.0657C11.0641 0.250031 11.6596 1.36271 11.1058 2.19341L8.04006 6.79202ZM7 5.64795L9.59861 1.75003H4.40138L7 5.64795Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 8.50001C8.25 7.80965 8.80964 7.25001 9.5 7.25001H14.5C15.1904 7.25001 15.75 7.80965 15.75 8.50001V13.5C15.75 14.1904 15.1904 14.75 14.5 14.75H9.5C8.80964 14.75 8.25 14.1904 8.25 13.5V8.50001ZM14.25 8.75001H9.75V13.25H14.25V8.75001Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 7.25001C1.92893 7.25001 0.25 8.92894 0.25 11C0.25 13.0711 1.92893 14.75 4 14.75C6.07107 14.75 7.75 13.0711 7.75 11C7.75 8.92894 6.07107 7.25001 4 7.25001ZM1.75 11C1.75 9.75737 2.75736 8.75001 4 8.75001C5.24264 8.75001 6.25 9.75737 6.25 11C6.25 12.2427 5.24264 13.25 4 13.25C2.75736 13.25 1.75 12.2427 1.75 11Z" fill="white"/>
            </svg>
        </button>

        <button type="button" class="image-annotation-v3__tool-button" [class.active]="currentTool === 'text'" (click)="openTextModal()">
            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.249878 1.6665C0.249878 1.25229 0.585664 0.916504 0.999878 0.916504H11.6665C12.0808 0.916504 12.4165 1.25229 12.4165 1.6665V3.6665C12.4165 4.08072 12.0808 4.4165 11.6665 4.4165C11.2523 4.4165 10.9165 4.08072 10.9165 3.6665V2.4165H7.08325V11.583H8.33325C8.74747 11.583 9.08325 11.9188 9.08325 12.333C9.08325 12.7472 8.74747 13.083 8.33325 13.083H6.34905C6.3438 13.0831 6.33853 13.0832 6.33325 13.0832C6.32797 13.0832 6.32271 13.0831 6.31745 13.083H4.33325C3.91904 13.083 3.58325 12.7472 3.58325 12.333C3.58325 11.9188 3.91904 11.583 4.33325 11.583H5.58325V2.4165H1.74988V3.6665C1.74988 4.08072 1.41409 4.4165 0.999878 4.4165C0.585664 4.4165 0.249878 4.08072 0.249878 3.6665V1.6665Z" fill="white"/>
            </svg>
        </button>

        <div class="image-annotation-v3__tools-menu" [class.open]="openToolMenu === 'color'">
            <button *ngFor="let color of palette;" type="button" class="image-annotation-v3__tool-button" [class.active]="color === currentColor" (click)="selectColor(color)">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path [attr.fill]="color" d="M8.0024 0.00244141C5.88047 0.0030664 3.84607 0.846185 2.34625 2.34676C0.846408 3.84676 0.00424334 5.88116 0.00488318 8.00244C0.00552374 10.1237 0.848889 12.1575 2.34993 13.6568C3.8504 15.1562 5.88543 15.9981 8.00736 15.9975C10.1293 15.9968 12.1637 15.1537 13.6635 13.6532C15.1634 12.1532 16.0055 10.1188 16.0049 7.99748C16.0018 5.87748 15.1571 3.845 13.6573 2.34628C12.1568 0.847529 10.1231 0.00436141 8.0024 0.00260141V0.00244141Z"/>
                </svg>
            </button>
        </div>
        <button type="button" class="image-annotation-v3__tool-button" [class.active]="openToolMenu === 'color'" (click)="changeTool('color', true)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path [attr.fill]="currentColor" d="M8.0024 0.00244141C5.88047 0.0030664 3.84607 0.846185 2.34625 2.34676C0.846408 3.84676 0.00424334 5.88116 0.00488318 8.00244C0.00552374 10.1237 0.848889 12.1575 2.34993 13.6568C3.8504 15.1562 5.88543 15.9981 8.00736 15.9975C10.1293 15.9968 12.1637 15.1537 13.6635 13.6532C15.1634 12.1532 16.0055 10.1188 16.0049 7.99748C16.0018 5.87748 15.1571 3.845 13.6573 2.34628C12.1568 0.847529 10.1231 0.00436141 8.0024 0.00260141V0.00244141Z"/>
            </svg>
        </button>
    </div>
</div>
