<app-modal [visible$]="visible$">
    <h1 class="modal-title">
        Opdracht obstructie
    </h1>
    <form [formGroup]="form">
        <p>
            <label>Reden voor obstructie:
                <select *ngIf="(obstructionCodes$|async) as items" formControlName="obstructionCode" [class]="errorClass('obstructionCode')">
                    <option *ngFor="let item of items" [value]="item.code">{{item.name}}</option>
                </select>
            </label>
        </p>
            <p>
            <label>Toelichting:<br/>
                <textarea formControlName="obstructionRemarks" [class]="errorClass('obstructionRemarks')"></textarea>
            </label>
        </p>
    </form>

    <div class="modal-actions">
        <button class="modal-btn" (click)="close()">Annuleren</button>
        <button class="modal-btn" (click)="submit()">Opslaan</button>
    </div>
</app-modal>
