import {defer, firstValueFrom, merge, Observable, of} from "rxjs";
import {AbstractControl} from "@angular/forms";
import {filter} from "rxjs/operators";

export function controlValueChanges<T extends AbstractControl>(control: T): Observable<T["value"]> {
    return merge(
        defer(() => of(control.value)),
        control.valueChanges
    )
}

export function waitIfValidationPending(form: AbstractControl) {
    if (form.pending) {
        return firstValueFrom(form.statusChanges.pipe(filter(status => status !== 'PENDING')))
    } else {
        return Promise.resolve()
    }
}
