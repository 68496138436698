import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnswerOverviewMenuService {
  $open = new Subject<void>;

  constructor() { }
}
