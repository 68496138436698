import {Component, Inject, Input} from '@angular/core';
import {ProjectServiceInterface} from '../../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ProjectObjectService} from '../../services/project-object.service';
import {ProjectFormService} from '../../services/project-form.service';
import {PaulaObjectLocationType} from '../../models/question/object-question';
import {firstValueFrom, Observable, Subscription} from 'rxjs';
import {Forms} from '../../utils/forms';
import {PaulaObjectType} from '../../models/paula-object-type';
import {CompletableEvent} from '../../models/completableEvent';
import {Identifiable} from '../../models/identifiable';
import {PaulaObject} from '../../models/paula-object';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'app-create-paula-object',
    templateUrl: './create-paula-object.component.html'
})
export class CreatePaulaObjectComponent {
    _objectTypes: PaulaObjectType[] = [];

    private saveSubscription: Subscription | null = null;

    @Input() projectId: number | null = null;
    @Input()
    set objectTypes(value: PaulaObjectType[]) {
        this._objectTypes = value;
        if (value.length === 1 && this.form.controls.objectType.value === null) {
            this.form.controls.objectType.setValue(value[0])
        }
    }

    @Input()
    set saveObject$(value: Observable<CompletableEvent<PaulaObject>>) {
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe()
        }
        this.saveSubscription = value.subscribe(async (event) => this.saveAndSubmit(event))
    }

    saving = false;

    readonly form = new FormGroup({
        objectId: new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.maxLength(255)]}),
        objectOmschrijvingKort: new FormControl<string>('', {nonNullable: true, validators: [Validators.required, Validators.maxLength(255)]}),
        objectType: new FormControl<Identifiable | null>(null, {validators: Validators.required}),
        objectLocationType: new FormControl<PaulaObjectLocationType>('Linear', {nonNullable: true, validators: Validators.required}),

    });

    constructor(
        @Inject('ProjectService') private projectService: ProjectServiceInterface,
        @Inject('ProjectFormService') private projectFormService: ProjectFormService,
        @Inject('ProjectObjectService') private paulaObjectService: ProjectObjectService,
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
    ) {
    }

    async saveAndSubmit(event: CompletableEvent<PaulaObject>) {
        if (this.form.valid == false || this.saving) {
            Forms.updateValueAndValidityRecursive(this.form);
            await this.toastr.error('Vul alle verplichte velden in (in het rood aangegeven)')
            event.complete(null)
            return;
        }

        try {
            this.saving = true;
            const formValue = this.form.getRawValue();
            const objectType = formValue.objectType
            if (objectType === null) {
                throw new Error('objectType is null');
            }
            const projectId = this.projectId;
            if (projectId === null) {
                throw new Error('projectId is null');
            }

            const createdObject = await firstValueFrom(this.paulaObjectService.create(projectId, {
                ...formValue,
                objectType
            }));

            event.complete(createdObject)
        } catch (ex) {
            console.error('Failed to create object', ex);
            if (ex instanceof HttpErrorResponse && ex.error?.fieldErrors?.objectId == 'Already in use') {
                await this.toastr.error('ObjectID is al in gebruik');
            } else {
                await this.toastr.error('Object aanmaken mislukt');
            }

            event.complete(null)
        } finally {
            this.saving = false;
        }

    }
}
