<div>
    <div class="flex answer-overview__actions">
        <app-project-job-form-submit-btn-v2 [canSubmit]="canSubmit$ | async" (finishedSubmittingForm)="close()"></app-project-job-form-submit-btn-v2>
        <app-project-job-form-pdf-btn></app-project-job-form-pdf-btn>
    </div>
</div>
<div class="scroll-container overflow-y-auto">
    <ng-container *ngIf="(answerOverviewData$|async) as data">
        <ng-container *ngFor="let chapter of data.visibleChapters; trackBy: trackChapterById">
            <app-accordion-group accordionGroupId="answerOverview" [startIcon]="chapter.icon" [accordionId]="''+chapter.id">
                <div class="accordion-group__content">
                    {{ chapter.title }}
                    <div class="text-danger" *ngIf="chapter.missingAnswers">
                        {{ 'Je hebt nog niet alle verplichte vragen ingevuld'|translate }}
                    </div>
                </div>
                <app-accordion-list-item *ngFor="let question of chapter.visibleQuestions"
                                         [startIcon]="chapter.questionIconMap.get(question.id)"
                                         (click)="openQuestion(data.openForm, question)">
                    <span>
                        {{ question | questionTitle }}
                        <span class="text-danger" *ngIf="question && question.required">&nbsp;*</span>
                    </span>
                </app-accordion-list-item>
            </app-accordion-group>
        </ng-container>
    </ng-container>
</div>
