// @ts-strict-ignore
import {
    BlurZone,
    DrawComponent,
    DrawComponentAction,
    DrawComponentActionShape,
    DrawComponentActionType,
    DrawComponentState
} from './draw-component';
import {DrawComponentArrow} from './draw-component-arrow';
import {DrawComponentCircle} from './draw-component-circle';
import {DrawComponentIcon} from './draw-component-icon';
import {DrawComponentPencil} from './draw-component-pencil';
import {DrawComponentSquare} from './draw-component-square';
import {Subject, Subscription} from 'rxjs';
import Konva from 'konva';
import {v4 as uuid4} from 'uuid';
import {DrawComponentText} from './draw-component-text';
import {Node} from 'konva/lib/Node';

export type DrawComponentShape
    = DrawComponentArrow
    | DrawComponentCircle
    | DrawComponentIcon
    | DrawComponentPencil
    | DrawComponentSquare
    | DrawComponentText;

export interface DrawComponentsViewportConfig {
    src: string;
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
    crop: {
        src?: string;
        x: number;
        y: number;
        width: number;
        height: number;
        scale: number;
    };
}

export interface DrawComponentsSaveState {
    history: DrawComponentAction[];
    comment: string;
    src: string;
}

export class DrawComponents {

    readonly container: HTMLDivElement;
    readonly backgroundImage: HTMLImageElement;
    readonly drawingLayer: Konva.Layer;
    readonly blurGroup: Konva.Group;
    readonly onChange$: Subject<DrawComponentAction>;
    private viewport: Konva.Stage;
    private sourceLayer: Konva.Layer;
    public components: DrawComponentShape[] = [];
    private subscriptions: { id: string, subscription: Subscription }[] = [];
    public selectedUuid: string = null;

    private blurMode = false;
    private blurZones: BlurZone[] = [];
    private previousBlurPosition: { x: number, y: number } = null;

    private currentViewportConfig: DrawComponentsViewportConfig;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private callbackReferences: Record<string, any> = {
        'mousedown touchstart': () => {
        },
        'mousemove touchmove': () => {
        },
        'mouseup touchend': () => {
        }
    };
    private VISUALLY_PLEASING_BLUR_RADIUS = 30;

    constructor(
        container: HTMLDivElement,
        backgroundImage: HTMLImageElement,
        actions: DrawComponentAction[],
        selectedUid: string = null
    ) {
        this.selectedUuid = selectedUid;

        this.container = container;
        this.backgroundImage = backgroundImage;
        this.drawingLayer = new Konva.Layer();
        this.blurGroup = new Konva.Group({
            draggable: false,
            clipFunc: () => {
            }
        });

        this.onChange$ = new Subject();

        this.createViewport().then(() => {
            this.viewport.on('click tap', this.onClick.bind(this));
            this.executeActions(actions);
        });
    }

    async fitContainerInParentElement() {
        await this.nextRenderFrame();

        if (this.currentViewportConfig) {
            const offsetWidth = this.container.parentElement.offsetWidth;
            const offsetHeight = this.container.parentElement.offsetHeight;

            const scale = Math.min(
                offsetWidth / this.currentViewportConfig.crop.width,
                offsetHeight / this.currentViewportConfig.crop.height,
            );

            if (this.viewport.scale().x !== scale) {
                DrawComponent.VIEWPORT_SCALE = scale;
                this.viewport.width(this.currentViewportConfig.crop.width * scale);
                this.viewport.height(this.currentViewportConfig.crop.height * scale);
                this.viewport.scale({x: scale, y: scale});
                this.viewport.draw();
            }
        }
    }

    get onChange() {
        return this.onChange$;
    }

    addComponent(shape: DrawComponentActionShape, config: Konva.ShapeConfig) {
        // Ensure the added elements are big enough to interact with
        const cropWidth = this.currentViewportConfig.crop.width;
        const cropHeight = this.currentViewportConfig.crop.height;
        const scaling = this.getDynamicScaling();
        const offsetX = (cropWidth / 100) * 16;
        const offsetY = (cropHeight / 100) * 16;
        const offsetMin = Math.min(offsetX, offsetY);
        const circleRadius = (config as Konva.CircleConfig).radius || DrawComponentCircle.DefaultConfigRadius;
        const offsetCircleRadius = shape === DrawComponentActionShape.CIRCLE ? (circleRadius * scaling) : 0;

        config.scaleX = config.scaleY = scaling;

        // Get the proper origin of the drawing layer
        // So adding a component will always happen within the viewport
        const transform = this.drawingLayer.getAbsoluteTransform().copy();
        transform.invert();
        const screenOriginPosition = {x: 0, y: 0};
        const drawingOriginPosition = transform.point(screenOriginPosition);

        const component = this.createComponent(shape, {
            originPoint: {
                x: drawingOriginPosition.x + offsetMin + offsetCircleRadius,
                y: drawingOriginPosition.y + offsetMin + offsetCircleRadius,
            },
            config,
        });

        this.bindComponent(component);
        this.onClick({
            target: component.drawingShape,
        });
        component.emitAction(DrawComponentActionType.ADD);
    }

    /**
     * Execute a single action without triggering the change event
     * This is used during the initial loading and updating the viewport
     */
    public executeAction(action: DrawComponentAction, shouldDraw = false) {
        let component;
        if (action.type === DrawComponentActionType.ADD) {
            component = this.createComponent(action.shape, action.state, action.uuid);
            this.bindComponent(component);
        } else if (action.type === DrawComponentActionType.REMOVE) {
            component = this.findComponent(action.uuid);
            if (component) {
                this.removeComponent(component);
            }
        } else if (action.type === DrawComponentActionType.CHANGE) {
            component = this.findComponent(action.uuid);
            if (component) {
                component.setState(action.state);
            }
        } else if (action.type === DrawComponentActionType.VIEWPORT) {
            this.updateViewport(action.state.viewportConfig);
        } else if (action.type === DrawComponentActionType.BLUR) {
            this.setBlurZones(JSON.parse(JSON.stringify(action.state.blurZones)));
        }

        if (shouldDraw) {
            if (component) {
                component.draw();
            }
            this.drawingLayer.draw();
        }
    }

    public getEditedSrc(): string {
        this.components.forEach((component) => {
            component.hideTransformTool();
        });

        const viewportConfig = this.currentViewportConfig;
        this.viewport.setPosition({
            x: viewportConfig.x - viewportConfig.crop.x,
            y: viewportConfig.y - viewportConfig.crop.y,
        });

        const dataUrl = this.viewport.toDataURL({
            pixelRatio: (viewportConfig.crop.width / this.viewport.width()),
        });

        this.viewport.setPosition({
            x: 0,
            y: 0,
        });

        return dataUrl;
    }

    setBlurMode(blurMode: boolean) {
        if (this.blurMode !== blurMode) {
            // Remove all previous blur events
            Object.keys(this.callbackReferences).forEach((eventString) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.viewport.off(eventString, this.callbackReferences[eventString] as any);
            });

            this.blurMode = blurMode;
            this.drawingLayer.opacity(blurMode ? 0.2 : 1);
            this.drawingLayer.listening(!blurMode);
            this.selectedUuid = null;
            this.updateSelection();
            this.drawingLayer.draw();
            if (this.blurMode) {
                this.callbackReferences['mousedown touchstart'] = this.onBlurStart.bind(this);
                this.viewport.on('mousedown touchstart', this.callbackReferences['mousedown touchstart']);
                this.callbackReferences['mousemove touchmove'] = this.onBlurMove.bind(this);
                this.viewport.on('mousemove touchmove', this.callbackReferences['mousemove touchmove']);
                this.callbackReferences['mouseup touchend'] = this.onBlurEnd.bind(this);
                this.viewport.on('mouseup touchend', this.callbackReferences['mouseup touchend']);
            }
        }
    }

    destroy() {
        if (this.viewport) {
            Object.keys(this.callbackReferences).forEach((eventString) => {
                this.viewport.off(eventString, this.callbackReferences[eventString]);
            });

            // Safari fix for canvas memory clearing
            this.viewport.width(0);
            this.viewport.height(0);
            this.viewport.clearCache();
            this.viewport.destroy();
        }
    }

    private addBlurZone(x: number, y: number) {
        this.blurZones.push({
            x: x,
            y: y,
            radius: this.VISUALLY_PLEASING_BLUR_RADIUS,
            scale: this.getDynamicScaling(),
        });

        this.updateBlurZones();
    }

    private getDynamicScaling() {
        const cropWidth = this.currentViewportConfig.crop.width;
        const cropHeight = this.currentViewportConfig.crop.height;
        const cropRatioX = cropWidth / this.currentViewportConfig.width;
        const cropRatioY = cropHeight / this.currentViewportConfig.height;
        const cropRatio = Math.min(cropRatioX, cropRatioY);

        // Device pixel ratio of 3 seems to be out of scale on Android, so max the scaling at 2
        return Math.min(2, (DrawComponent.UI_SCALING / this.currentViewportConfig.crop.scale)) * cropRatio;
    }

    private updateBlurZones() {
        this.blurGroup.clipFunc((ctx) => {
            this.blurZones.forEach((blurZone) => {
                ctx.moveTo(blurZone.x, blurZone.y);
                ctx.arc(blurZone.x, blurZone.y, blurZone.radius * blurZone.scale, 0, Math.PI * 2);
            });
        });

        this.sourceLayer.draw();
    }

    private async createViewport() {
        this.viewport = new Konva.Stage({
            container: this.container,
            width: this.backgroundImage.width,
            height: this.backgroundImage.height,
        });

        await this.addSourceLayer(this.backgroundImage).then(() => {
            this.viewport.add(this.drawingLayer);
            this.updateViewport(this.getInitialViewportConfigForImage(this.backgroundImage));
            this.addBlurGroup(this.backgroundImage);
        });
    }

    private async addSourceLayer(image: HTMLImageElement) {
        const sourceLayer = new Konva.Layer({
            name: 'source-layer',
            listening: false,
        });
        const imageComponent = new Konva.Image({
            name: 'source-image',
            x: 0,
            y: 0,
            width: image.width,
            height: image.height,
            image: image,
            perfectDrawEnabled: false,
            listening: false,
            draggable: false,
            opacity: 1,
        });
        sourceLayer.add(imageComponent);
        this.sourceLayer = sourceLayer;
        this.viewport.add(sourceLayer);
    }

    private addBlurGroup(image: HTMLImageElement) {
        if (this.sourceLayer) {
            const blurImage = new Konva.Image({
                name: 'source-image',
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
                image: image,
                perfectDrawEnabled: false,
                listening: false,
                draggable: false,
                opacity: 1,
            });
            blurImage.cache();
            blurImage.filters([Konva.Filters.Pixelate]);
            blurImage.pixelSize(16 * this.getDynamicScaling());
            this.blurGroup.add(blurImage);
            this.sourceLayer.add(this.blurGroup);
        }
    }

    private updateViewport(viewportConfig: DrawComponentsViewportConfig) {
        this.viewport.width(viewportConfig.crop.width);
        this.viewport.height(viewportConfig.crop.height);

        if (this.sourceLayer) {
            // Ensure the positioning and scaling is normalized to the cropped area
            this.sourceLayer.setPosition({
                x: viewportConfig.x - viewportConfig.crop.x,
                y: viewportConfig.y - viewportConfig.crop.y,
            });
            this.sourceLayer.scale({
                x: viewportConfig.crop.scale,
                y: viewportConfig.crop.scale
            });
            this.sourceLayer.draw();
        }

        // Ensure the positioning and scaling is normalized to the cropped area
        // So we don't have to deal with calculating back positioning to the original image size
        this.drawingLayer.setPosition({
            x: viewportConfig.x - viewportConfig.crop.x,
            y: viewportConfig.y - viewportConfig.crop.y
        });
        this.drawingLayer.scale({
            x: viewportConfig.crop.scale,
            y: viewportConfig.crop.scale
        });

        this.drawingLayer.draw();

        this.currentViewportConfig = viewportConfig;
    }

    private async executeActions(actions: DrawComponentAction[]) {
        actions
            .forEach((action) => {
                this.executeAction(action);
            });

        await this.nextRenderFrame();

        if (this.selectedUuid) {
            this.updateSelection();
        }

        await this.nextRenderFrame();

        this.drawingLayer.draw();
    }

    private findComponent(uuid: string): DrawComponent {
        return this.components.find((component) => {
            return component.uuid === uuid;
        });
    }

    private createComponent(shape: DrawComponentActionShape, state: DrawComponentState, uuid = uuid4()) {
        // Clone the state so we don't update any references accidentally
        const clonedState = JSON.parse(JSON.stringify(state));

        let component;
        switch (shape) {
            case DrawComponentActionShape.ARROW:
                component = new DrawComponentArrow(uuid, clonedState);
                break;
            case DrawComponentActionShape.CIRCLE:
                component = new DrawComponentCircle(uuid, clonedState);
                break;
            case DrawComponentActionShape.ICON:
                component = new DrawComponentIcon(uuid, clonedState);
                break;
            case DrawComponentActionShape.PENCIL:
                component = new DrawComponentPencil(uuid, clonedState);
                break;
            case DrawComponentActionShape.SQUARE:
                component = new DrawComponentSquare(uuid, clonedState);
                break;
            case DrawComponentActionShape.TEXT:
                component = new DrawComponentText(uuid, clonedState);
                break;
            default:
                throw new Error(`Unsupported tool ${shape}`);
        }

        // Give the main component the name draw component so our click handlers work on combined draw components as well
        // ( Text component consists of two components within a single group )
        if (component) {
            component.drawingShape.name('draw-component');
        }
        return component;
    }

    private removeComponent(component: DrawComponent) {
        // Clear the existing subscriptions
        this.subscriptions.forEach((handler) => {
            if (handler.id === component.uuid) {
                handler.subscription.unsubscribe();
            }
        });
        this.subscriptions = this.subscriptions.filter((handler) => {
            return handler.id !== component.uuid;
        });

        component.drawingLayer.remove();
        this.components = this.components.filter((existingComponent) => {
            return existingComponent === component;
        });
    }

    private bindComponent(component: DrawComponentShape) {
        this.components.push(component);
        this.drawingLayer.add(component.getDrawingLayer());
        component.draw();
        this.subscriptions.push({
            id: component.uuid,
            subscription: component.onAction().subscribe((action) => {
                this.drawingLayer.draw();
                this.onChange$.next(action);
            })
        });

        this.drawingLayer.draw();
    }

    private onClick(event: { target: Node }) {
        let selectComponent: DrawComponentShape;

        const target = event.target.name() === 'draw-component' ? event.target : event.target.findAncestor('.draw-component');
        this.components.forEach((component) => {
            if (target === component.drawingShape) {
                selectComponent = component;
            }
        });

        if (selectComponent) {
            selectComponent.showTransformTool();
            this.selectedUuid = selectComponent.uuid;
        } else {
            this.selectedUuid = null;
        }

        this.updateSelection();
    }

    private onBlurStart() {
        const transform = this.blurGroup.getAbsoluteTransform().copy();
        transform.invert();
        let position = this.blurGroup.getStage().getPointerPosition();
        position = transform.point(position);
        this.previousBlurPosition = position;
        this.addBlurZone(position.x, position.y);
    }

    private onBlurMove() {
        if (this.previousBlurPosition !== null) {
            const transform = this.blurGroup.getAbsoluteTransform().copy();
            transform.invert();
            let position = this.blurGroup.getStage().getPointerPosition();
            position = transform.point(position);

            const distanceFromPreviousPoint = Math.sqrt(
                Math.pow(this.previousBlurPosition.x - position.x, 2) +
                Math.pow(this.previousBlurPosition.y - position.y, 2)
            );

            const distanceThreshold = 20;
            if (distanceFromPreviousPoint > distanceThreshold) {
                this.previousBlurPosition = position;
                this.addBlurZone(position.x, position.y);
            }
        }
    }

    private onBlurEnd() {
        this.previousBlurPosition = null;
        this.onChange$.next({
            uuid: uuid4(),
            type: DrawComponentActionType.BLUR,
            shape: DrawComponentActionShape.BLUR,
            state: {
                blurZones: JSON.parse(JSON.stringify(this.blurZones))
            }
        });
    }

    private setBlurZones(blurZones: BlurZone[]) {
        this.blurZones = blurZones;
        this.updateBlurZones();
    }

    private updateSelection() {
        this.components.forEach((component) => {
            if (component.uuid !== this.selectedUuid) {
                component.hideTransformTool();
            } else {
                component.showTransformTool();
            }
        });

        this.drawingLayer.draw();
    }

    private getInitialViewportConfigForImage(image: HTMLImageElement): DrawComponentsViewportConfig {
        return {
            x: 0,
            y: 0,
            width: image.width,
            height: image.height,
            scale: 1,
            src: '',
            crop: {
                x: 0,
                y: 0,
                width: image.width,
                height: image.height,
                scale: 1,
            }
        };
    }

    private nextRenderFrame(): Promise<void> {
        return new Promise((resolve) => requestAnimationFrame(() => resolve()));
    }

}
