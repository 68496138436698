/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import "../../../core/has.js";
import { destroyMaybe as o } from "../../../core/maybe.js";
import { when as i, watch as r, initial as n } from "../../../core/reactiveUtils.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as a } from "../../../core/accessorSupport/decorators/subclass.js";
import { DoubleClickZoom as c } from "./handlers/DoubleClickZoom.js";
import { DoubleTapDragZoom as p } from "./handlers/DoubleTapDragZoom.js";
import { DragPan as m } from "./handlers/DragPan.js";
import { DragRotate as l } from "./handlers/DragRotate.js";
import { GamepadNavigation as u } from "./handlers/GamepadNavigation.js";
import { KeyPan as w } from "./handlers/KeyPan.js";
import { KeyRotate as h } from "./handlers/KeyRotate.js";
import { KeyZoom as d } from "./handlers/KeyZoom.js";
import { MouseWheelZoom as v } from "./handlers/MouseWheelZoom.js";
import { PinchRotateAndZoom as g } from "./handlers/PinchAction.js";
import { BrowserEventSource as f } from "../../input/BrowserEventSource.js";
import { InputManager as j, ViewEventPriorities as y } from "../../input/InputManager.js";
import { PreventContextMenu as _ } from "../../input/handlers/PreventContextMenu.js";
import { DoubleTapDrag as D } from "../../input/recognizers/DoubleTapDrag.js";
import { Drag as A } from "../../input/recognizers/Drag.js";
import { ImmediateDoubleClick as M } from "../../input/recognizers/ImmediateDoubleClick.js";
import { PointerClickHoldAndDrag as P } from "../../input/recognizers/PointerClickHoldAndDrag.js";
import { SingleAndDoubleClick as b } from "../../input/recognizers/SingleAndDoubleClick.js";
const T = {
  counter: "Control",
  pan: {
    left: "ArrowLeft",
    right: "ArrowRight",
    up: "ArrowUp",
    down: "ArrowDown"
  },
  zoom: {
    zoomIn: ["=", "+"],
    zoomOut: ["-", "_"]
  },
  rotate: {
    clockwiseOption1: "a",
    clockwiseOption2: "A",
    counterClockwiseOption1: "d",
    counterClockwiseOption2: "D",
    resetOption1: "n",
    resetOption2: "N"
  }
};
let z = class extends t {
  initialize() {
    const e = () => this.view?.ready;
    this.addHandles([i(() => !e(), () => this._disconnect()), i(e, () => this._connect())]);
  }
  destroy() {
    this._disconnect();
  }
  get latestPointerType() {
    return this._inputManager?.latestPointerType;
  }
  get latestPointerLocation() {
    return this._inputManager?.latestPointerLocation;
  }
  get multiTouchActive() {
    return this._inputManager?.multiTouchActive ?? !1;
  }
  isModifierKeyDown(e) {
    return this._inputManager?.isModifierKeyDown(e) ?? !1;
  }
  _disconnect() {
    this.view.viewEvents.disconnect(), this._inputManager = o(this._inputManager);
  }
  _connect() {
    const e = this.view.surface,
      t = new f(e, this.view.input),
      o = [new M(), new P(), new b(), new A(this.view.navigation), new D()],
      i = new j({
        eventSource: t,
        recognizers: o
      });
    i.installHandlers("prevent-context-menu", [new _()], y.INTERNAL), i.installHandlers("navigation", [new g(this.view), new u(this.view), new v(this.view), new c(this.view), new c(this.view, [T.counter]), new m(this.view, "primary"), new w(this.view, T.pan), new d(this.view, T.zoom), new h(this.view, T.rotate), new l(this.view, "secondary"), new p(this.view, "touch")], y.INTERNAL), this.view.viewEvents.connect(i), this._source = t, this._inputManager = i, r(() => this.view?.navigation?.browserTouchPanEnabled, e => {
      this._source && (this._source.browserTouchPanningEnabled = !e);
    }, n);
  }
  get test() {}
};
e([s()], z.prototype, "view", void 0), e([s()], z.prototype, "latestPointerType", null), e([s()], z.prototype, "latestPointerLocation", null), e([s()], z.prototype, "multiTouchActive", null), z = e([a("esri.views.2d.input.MapViewInputManager")], z);
const k = z;
export { k as default };