/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { find as t } from "../../core/iteratorUtils.js";
import { someMap as e } from "../../core/MapUtils.js";
import { clamp as o } from "../../core/mathUtils.js";
import { createScreenPoint as a } from "../../core/screenUtils.js";
import { areToolManipulatorsEditable as i } from "./interactiveToolUtils.js";
import { createScreenPointFromEvent as r } from "../support/screenUtils.js";
class n {
  constructor() {
    this._pointerLocations = new Map(), this._hoveredManipulators = new Map(), this._grabbedManipulators = new Map(), this._draggedManipulators = new Map(), this._stopDrag = !1, this._revertToNullActiveTool = !1, this._cursor = null;
  }
  get cursor() {
    return this._cursor;
  }
  hasFocusedManipulators() {
    return this._grabbedManipulators.size > 0 || this._draggedManipulators.size > 0;
  }
  handleInputEvent(t, e) {
    const a = () => t.stopPropagation();
    switch (t.type) {
      case "pointer-move":
        p(t.pointerType) && this._pointerLocations.set(t.pointerId, {
          x: t.x,
          y: t.y,
          pointerType: t.pointerType
        });
        break;
      case "drag":
        this._grabbedManipulators.size > 0 && (this._stopDrag = !0), this._stopDrag && (a(), "end" === t.action && (this._stopDrag = !1));
        break;
      case "pointer-down":
        {
          if (!u(t)) break;
          const o = r(t),
            i = l(o, t.pointerType, e.forEachTool);
          if (null == i) break;
          const n = i.manipulator,
            s = i.tool;
          null == n || null == s || !n.interactive || n.grabbable && n.grabbableForEvent(t) || !n.grabbing || n.dragging || this._releaseManipulatorBeforeDragging(n, t, e), null != n && null != s && n.interactive && n.grabbable && n.grabbableForEvent(t) && !n.grabbing && (this._grabbedManipulators.set(t.pointerId, {
            manipulator: n,
            tool: s,
            start: o,
            pointerType: t.pointerType
          }), 1 === this._grabbedManipulators.size && null == e.activeTool && (this._revertToNullActiveTool = !0, e.setActiveTool(i.tool)), n.grabbing = !0, n.events.emit("grab-changed", {
            action: "start",
            pointerType: t.pointerType,
            screenPoint: o
          }), a());
          break;
        }
      case "pointer-up":
        this._draggedManipulators.has(t.pointerId) || this._handlePointerEnd(t, e);
        break;
      case "pointer-drag":
        {
          if (!u(t)) break;
          const i = this._grabbedManipulators.get(t.pointerId),
            n = i?.manipulator,
            s = i?.tool;
          if (null == n || null == s) break;
          const l = r(t);
          l.x = o(l.x, 0, e.view.width), l.y = o(l.y, 0, e.view.height);
          const p = i.start,
            c = this._draggedManipulators.get(t.pointerId);
          switch (t.action) {
            case "start":
            case "update":
              "update" !== t.action && 1 !== this._grabbedManipulators.size || (n.dragging = !0, c ? n.events.emit("drag", {
                action: "update",
                start: p,
                screenPoint: l
              }) : n.events.emit("drag", {
                action: "start",
                start: p,
                screenPoint: l,
                pointerType: t.pointerType
              }), this._draggedManipulators.set(t.pointerId, {
                tool: s,
                manipulator: n,
                start: p
              }));
              break;
            case "end":
              n.dragging = !1, c && n.events.emit("drag", {
                action: "end",
                start: p,
                screenPoint: l
              }), this._draggedManipulators.delete(t.pointerId), this._handlePointerEnd(t, e);
          }
          a();
          break;
        }
      case "immediate-click":
        {
          const o = r(t),
            i = l(o, t.pointerType, e.forEachTool);
          if (c(t) || e.forEachTool(t => {
            if ((null == i || i.tool !== t || t.automaticManipulatorSelection) && t.manipulators) {
              let e = !1;
              t.manipulators.forEach(({
                manipulator: t
              }) => {
                t.selected && (t.selected = !1, e = !0);
              }), e && t.onManipulatorSelectionChanged && t.onManipulatorSelectionChanged();
            }
          }), null == i) break;
          const {
            manipulator: n,
            tool: s
          } = i;
          if (!n.interactive) break;
          n.selectable && s.automaticManipulatorSelection && (n.selected = !n.selected, s.onManipulatorSelectionChanged && s.onManipulatorSelectionChanged());
          const p = t.native.shiftKey;
          n.events.emit("immediate-click", {
            screenPoint: o,
            button: t.button,
            pointerType: t.pointerType,
            shiftKey: p,
            stopPropagation: a
          }), d(n, a);
          break;
        }
      case "click":
        {
          const o = r(t),
            i = l(o, t.pointerType, e.forEachTool),
            n = i?.manipulator;
          if (null == n || !n.interactive) break;
          const s = t.native.shiftKey;
          n.events.emit(t.type, {
            screenPoint: o,
            button: t.button,
            pointerType: t.pointerType,
            shiftKey: s
          }), a();
          break;
        }
      case "double-click":
        {
          const o = r(t),
            i = l(o, t.pointerType, e.forEachTool),
            n = null != i ? i.manipulator : null;
          if (null == n || !n.interactive) break;
          const s = t.native.shiftKey;
          n.events.emit("double-click", {
            screenPoint: o,
            button: t.button,
            pointerType: t.pointerType,
            shiftKey: s,
            stopPropagation: a
          }), d(n, a);
          break;
        }
      case "immediate-double-click":
        {
          const o = r(t),
            i = l(o, t.pointerType, e.forEachTool),
            n = null != i ? i.manipulator : null;
          if (null == n || !n.interactive) break;
          const s = t.native.shiftKey;
          n.events.emit("immediate-double-click", {
            screenPoint: o,
            button: t.button,
            pointerType: t.pointerType,
            shiftKey: s,
            stopPropagation: a
          }), d(n, a);
          break;
        }
    }
    this._onFocusChange(e.forEachTool);
  }
  _releaseManipulatorBeforeDragging(t, e, o) {
    t.grabbing = !1, t.events.emit("grab-changed", {
      action: "end",
      pointerType: e.pointerType,
      screenPoint: r(e)
    }), this._grabbedManipulators.forEach(({
      manipulator: e
    }, o) => {
      e === t && this._grabbedManipulators.delete(o);
    }), this._afterManipulatorRelease(o.setActiveTool);
  }
  _handlePointerEnd(t, e) {
    const o = this._grabbedManipulators.get(t.pointerId)?.manipulator;
    null != o && o.grabbing && (o.grabbing = !1, o.events.emit("grab-changed", {
      action: "end",
      pointerType: t.pointerType,
      screenPoint: r(t)
    }), this._grabbedManipulators.delete(t.pointerId), this._afterManipulatorRelease(e.setActiveTool));
  }
  _onFocusChange(t) {
    this._updateCursor(), this._updateFocusedManipulatorTools(t);
  }
  _updateCursor() {
    this._grabbedManipulators.size > 0 ? this._cursor = s(this._grabbedManipulators) || "grabbing" : this._hoveredManipulators.size > 0 ? this._cursor = s(this._hoveredManipulators) || "pointer" : this._cursor = null;
  }
  _updateFocusedManipulatorTools(e) {
    const o = new Set(),
      a = new Set();
    this._grabbedManipulators.forEach(({
      tool: t
    }) => {
      o.add(t);
    }), this._hoveredManipulators.forEach(({
      tool: t
    }) => {
      a.add(t);
    }), e(e => {
      e.hasGrabbedManipulators = o.has(e), e.hasHoveredManipulators = a.has(e);
      const i = this._grabbedManipulators.values(),
        r = t(i, ({
          tool: t
        }) => t === e);
      e.firstGrabbedManipulator = null != r ? r.manipulator : null;
    });
  }
  clearPointers(t, {
    forEachTool: e,
    setActiveTool: o
  }, a = !0, i) {
    const r = (e, o) => e === t && (null == i || i === o);
    this._grabbedManipulators.forEach(({
      tool: t,
      manipulator: e,
      pointerType: o
    }, a) => {
      r(t, e) && (this._grabbedManipulators.delete(a), e.grabbing = !1, e.events.emit("grab-changed", {
        action: "end",
        screenPoint: null,
        pointerType: o
      }));
    }), this._draggedManipulators.forEach(({
      tool: t,
      manipulator: e
    }, o) => {
      r(t, e) && (this._draggedManipulators.delete(o), e.dragging = !1, e.events.emit("drag", {
        action: "cancel"
      }));
    }), a && this._hoveredManipulators.forEach(({
      tool: t,
      manipulator: e
    }, o) => {
      r(t, e) && (this._hoveredManipulators.delete(o), e.hovering = !1);
    }), this._afterManipulatorRelease(o), this._onFocusChange(e);
  }
  updateHoveredStateFromKnownPointers(t) {
    this._pointerLocations.forEach((e, o) => {
      this._updateHoveredStateForPointerAtScreenPosition(a(e.x, e.y), o, e.pointerType, t);
    });
  }
  handleHoverEvent(t, e) {
    "pointer-up" !== t.type && "immediate-click" !== t.type && "pointer-move" !== t.type || !p(t.pointerType) || this._updateHoveredStateForPointerAtScreenPosition(r(t), t.pointerId, t.pointerType, e);
  }
  _updateHoveredStateForPointerAtScreenPosition(t, e, o, a) {
    let i = l(t, o, a);
    const r = this._hoveredManipulators.get(e)?.manipulator;
    null == i || i.manipulator.interactive || (i = null), null != i && r === i.manipulator || (null != r && (r.hovering = !1), null != i ? (i.manipulator.hovering = !0, this._hoveredManipulators.set(e, i)) : this._hoveredManipulators.delete(e), this._onFocusChange(a));
  }
  _afterManipulatorRelease(t) {
    0 === this._grabbedManipulators.size && this._revertToNullActiveTool && (t(null), this._revertToNullActiveTool = !1);
  }
}
function s(t) {
  let o = null;
  return e(t, ({
    manipulator: t
  }) => !(null == t || !t.interactive) && (t.grabbing && t.grabCursor ? (o = t.grabCursor, !0) : !!t.cursor && (o = t.cursor, !0))), o;
}
function l(t, e, o) {
  let a = null;
  return o(o => {
    if (null == o.manipulators || !i(o)) return !1;
    const r = o.manipulators.intersect(t, e);
    return null != r && (a = {
      tool: o,
      manipulator: r
    }, !0);
  }), a;
}
function p(t) {
  return "mouse" === t;
}
function u(t) {
  return "mouse" !== t.pointerType || 0 === t.button;
}
function c(t) {
  return !!t.native.shiftKey;
}
function d(t, e) {
  t?.consumesClicks && e();
}
export { n as ToolViewManagerManipulatorState };