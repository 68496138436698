import {FileUtils} from './file-utils';

export function getLoadedImageElement(url: string): Promise<HTMLImageElement> {
    let safeUrl = url;
    if (FileUtils.isDataUri(url)) {
        safeUrl = FileUtils.dataURIToBlobURI(url);
    }

    return new Promise<HTMLImageElement>((resolve, reject) => {
        const imageElement = new Image();
        imageElement.crossOrigin = 'anonymous';
        imageElement.onload = () => resolve(imageElement);
        imageElement.onerror = err => reject(err);
        imageElement.src = safeUrl;
    });
}
