"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCapturedShape = getCapturedShape;
exports.createEvent = createEvent;
exports.hasPointerCapture = hasPointerCapture;
exports.setPointerCapture = setPointerCapture;
exports.releaseCapture = releaseCapture;
const Global_1 = require("./Global");
const Captures = new Map();
const SUPPORT_POINTER_EVENTS = Global_1.Konva._global['PointerEvent'] !== undefined;
function getCapturedShape(pointerId) {
  return Captures.get(pointerId);
}
function createEvent(evt) {
  return {
    evt,
    pointerId: evt.pointerId
  };
}
function hasPointerCapture(pointerId, shape) {
  return Captures.get(pointerId) === shape;
}
function setPointerCapture(pointerId, shape) {
  releaseCapture(pointerId);
  const stage = shape.getStage();
  if (!stage) return;
  Captures.set(pointerId, shape);
  if (SUPPORT_POINTER_EVENTS) {
    shape._fire('gotpointercapture', createEvent(new PointerEvent('gotpointercapture')));
  }
}
function releaseCapture(pointerId, target) {
  const shape = Captures.get(pointerId);
  if (!shape) return;
  const stage = shape.getStage();
  if (stage && stage.content) {}
  Captures.delete(pointerId);
  if (SUPPORT_POINTER_EVENTS) {
    shape._fire('lostpointercapture', createEvent(new PointerEvent('lostpointercapture')));
  }
}