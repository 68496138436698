import {Component, Input} from '@angular/core';

export type QuestionStatusIndicator = 'ok'|'wip'|'intolerant';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
})
export class StatusIndicatorComponent {
    @Input() public status: QuestionStatusIndicator = 'wip';
}
