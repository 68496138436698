import {Identifiable} from './identifiable';
import {ProjectJobFormChapter} from './project-job-form-chapter';
import {ProjectJobAnswer} from './project-job-answer';
import {ProjectJobFormExtra} from './project-job-form-extra';
import {ProjectJobFormSubmit} from './project-job-form-submit';
import {PaulaObjectLocationType} from './question/object-question';
import {AnyFormLayer} from './form-layer';
import {AnyLayeredFormNode} from './layered-form-node';

export const FORM_START_LOCATION_QUESTION_POSITION = -1;
export const FORM_END_LOCATION_QUESTION_POSITION = 999999;
export const NODE_FIRST_QUESTION_POSITION = 1;

export interface AbstractProjectJobForm extends Identifiable {
    readonly code: string;
    readonly title: string;
    readonly status: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly createdBy: string;
    readonly answers: ProjectJobAnswer[];
    readonly answerRevision: number;
    readonly askLocation: boolean;
    readonly showLocationOnMap: boolean;
    readonly askExecutorToFillFormAgain: boolean;
    readonly originalProjectForm: number | null;
    readonly extraFields: ProjectJobFormExtra;
    readonly objectOmschrijvingKort: string;
    readonly objectOmschrijvingLang: string;
    readonly objectLocationType: PaulaObjectLocationType;
    readonly submits: ProjectJobFormSubmit[];
    readonly project: number;
    readonly copyAnswers: boolean;
    readonly obstructionAllowed: boolean;
}

export interface ProjectJobForm extends AbstractProjectJobForm {
    readonly type: 'jobForm'
    readonly chapters: ProjectJobFormChapter[];
}

export interface LayeredProjectJobForm extends AbstractProjectJobForm {
    readonly type: 'layeredJobForm';
    readonly layers: AnyFormLayer[];
    /** @deprecated query dexie `db.nodes` instead */
    readonly nodes: AnyLayeredFormNode[];
}

export type AnyProjectJobForm = ProjectJobForm | LayeredProjectJobForm;

export enum ProjectJobStatus {
    Approved = 'Approved',
    AvailableForVerification = 'AvailableForVerification',
    Rejected = 'Rejected'
}
