export type ProjectJobFormExtra = ProjectJobFormOriginatedFields & ProjectJobLocationAnswer;

export interface ProjectJobFormOriginatedFields {
    originatedChoice: string;
    originatedQuestion: number;
}

export interface ProjectJobLocationAnswer {
    locationFrom: string | null; // location description
    gpsLatitudeFrom: number | null;
    gpsLongitudeFrom: number | null;

    locationTo: string | null; // location description
    gpsLatitudeTo: number | null;
    gpsLongitudeTo: number | null;
}

export function extractLocationAnswer(extraFields: ProjectJobFormExtra) {
    const {gpsLatitudeFrom, gpsLongitudeFrom, locationFrom, gpsLatitudeTo, gpsLongitudeTo, locationTo} = extraFields;

    return {
        locationFrom: locationFrom === null ? '' : locationFrom,
        gpsLatitudeFrom,
        gpsLongitudeFrom,
        locationTo: locationTo === null ? '' : locationTo,
        gpsLatitudeTo,
        gpsLongitudeTo,
    };
}
