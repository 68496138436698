import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ProjectJobForm} from '../../models/project-job-form';
import {filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {FormService} from '../../services/form.service';
import {AccordionController} from '../../controllers/accordion.controller';
import {Question} from '../../models/question/question';
import {FormUtils} from '../../utils/form-utils';
import {QuestionTolerance} from '../../utils/question-tolerance';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {numberParamInNestedRoute} from '../../utils/param-map-util';
import {AccordionIcon} from '../accordion-group/accordion-group.component';
import {ProjectJobAnswer} from '../../models/project-job-answer';
import {Questions} from '../../utils/questions';

export interface AnswerOverviewModel {
    openForm: ProjectJobForm;
    visibleChapters: AnswerOverviewModelChapter[];
}
export interface AnswerOverviewModelChapter {
    id: number;
    title: string;
    missingAnswers: boolean;
    icon: AccordionIcon;
    visibleQuestions: Question[];
    questionIconMap: Map<number, AccordionIcon>;
}

@Component({
  selector: 'app-answer-overview-menu-v2',
  templateUrl: './answer-overview-menu-v2.component.html',
  styleUrls: ['./answer-overview-menu-v2.component.scss'],
})
export class AnswerOverviewMenuV2Component implements OnInit, OnDestroy {
    @Output() sideNavClose = new EventEmitter<boolean>();

    openForm$: Observable<ProjectJobForm> = this.formService.openForm$.pipe(
        filter((form): form is ProjectJobForm => form?.type === 'jobForm'),
        tap(() => this.cd && this.cd.markForCheck()),
    );
    currentPosition$: Observable<number | null> = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => numberParamInNestedRoute(this.activatedRoute.snapshot, 'question')),
        shareReplay({bufferSize: 1, refCount: true})
    );

    answerOverviewData$: Observable<AnswerOverviewModel> = combineLatest([
        this.openForm$,
        this.currentPosition$
    ]).pipe(
        map(([form, currentPosition]) => {
            // !!! Only supports non layered forms!!!

            const visibleChapters: AnswerOverviewModelChapter[] = [];
            const selectedChoiceUUIDs = new Set<string>;

            for (const chapter of form.chapters) {
                // If chapter has question dependency, check if it is fulfilled
                if (chapter.questionDependency.length > 0 && !chapter.questionDependency.some(dep => selectedChoiceUUIDs.has(dep.id))) {
                    // Skip chapter is none of the dependencies are fulfilled
                    continue;
                }

                let missingAnswers = false;
                let intolerantAnswers = false;
                let allQuestionsAnswered = true;
                const visibleQuestions: Question[] = [];
                const questionIconMap = new Map<number, AccordionIcon>();
                // build visible questions
                for (const question of chapter.questions) {
                    if (question.questionDependency.length > 0 && !question.questionDependency.some(dep => selectedChoiceUUIDs.has(dep.id))) {
                        // Skip question if none of the dependencies are fulfilled
                        continue;
                    }

                    const answer = FormUtils.getFormItemLastAnswer(form.answers, question.position, undefined);

                    if (question.type === 'choice') {
                        if (answer && answer.value !== null) {
                            answer.value.split(',').forEach(it => selectedChoiceUUIDs.add(it));
                        }
                    }

                    // If we get here then question is visible
                    if (answer === null) {
                        allQuestionsAnswered = false;

                        if (question.required) {
                            missingAnswers = true;
                        }
                    }

                    const answerTolerant = QuestionTolerance.getToleranceMessage(question, answer ? answer.value : null).tolerant
                    if (!answerTolerant) {
                        intolerantAnswers = true;
                    }

                    visibleQuestions.push(question);
                    questionIconMap.set(question.id, this.determineQuestionIcon(question, currentPosition, answer, answerTolerant));
                }

                visibleChapters.push({
                    id: chapter.id,
                    title: Questions.getChapterTitle(chapter),
                    missingAnswers,
                    icon: this.determineChapterIcon(missingAnswers, intolerantAnswers, allQuestionsAnswered),
                    visibleQuestions: visibleQuestions,
                    questionIconMap: questionIconMap
                });
            }

            return {
                openForm: form,
                visibleChapters: visibleChapters
            }
        }),
        shareReplay({bufferSize: 1, refCount: true})
    );

    canSubmit$ = combineLatest([this.formService.openFormInvalidQuestions$, this.openForm$])
        .pipe(
            map(([invalidQuestions, projectJobForm]) => invalidQuestions.length === 0 && FormUtils.isLocationQuestionValid(projectJobForm)),
        );

    subscriptions: Subscription[] = [];

    constructor(
        private formService: FormService,
        private accordionService: AccordionController,
        private cd: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(combineLatest([
            this.openForm$,
            this.currentPosition$,
        ]).subscribe(([openForm, currentPosition]) => {
            const chapter = currentPosition === null ? null : FormUtils.getCurrentChapter(openForm, currentPosition)
            if (chapter) {
                this.accordionService.open('answerOverview', chapter.id.toString());
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        this.subscriptions = [];
    }

    determineQuestionIcon(
        question: Question,
        currentPosition: number | null,
        answer: ProjectJobAnswer | null,
        answerTolerant: boolean
    ) {
        if (currentPosition === question.position) {
            return 'arrow-right';
        }

        if (answer && !answerTolerant) {
            return 'oval-exclamation';
        }

        return (answer && answer.value !== '') ? (question.required ? 'indeterminate' : 'check') : 'oval-open';
    }

    determineChapterIcon(
        missingAnswers: boolean,
        intolerantAnswers: boolean,
        allQuestionsAnswered: boolean
    ) {
        if (intolerantAnswers) {
            return 'oval-exclamation';
        } else if (!missingAnswers) {
            // If all required questions are answered then chapter is indeterminate
            return 'indeterminate';
        } else if (allQuestionsAnswered) {
            // If all questions are answered then chapter is checked
            return 'check';
        } else {
            return 'oval-open';
        }
    }

    trackChapterById(index: number, item: AnswerOverviewModelChapter){
        return item.id;
    }

    close() {
        this.sideNavClose.emit(true);
    }

    async openQuestion(form: ProjectJobForm,question: Question) {
        this.close();
        await this.router.navigate(['/projects', form.project, 'jobs', form.id, 'v2', 'questions', question.position])
    }
}
