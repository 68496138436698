/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import e from "../../core/Accessor.js";
import { watch as i, sync as o } from "../../core/reactiveUtils.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
import { set as p, subtract as a, copy as n } from "../../core/libs/gl-matrix-2/math/vec2.js";
import { create as d } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import { addPadding as c } from "./viewpointUtils.js";
import l from "./ViewState.js";
var h, m;
let g = h = class extends e {
  constructor() {
    super(...arguments), this.left = 0, this.top = 0, this.right = 0, this.bottom = 0;
  }
  clone() {
    return new h({
      left: this.left,
      top: this.top,
      right: this.right,
      bottom: this.bottom
    });
  }
};
t([s()], g.prototype, "left", void 0), t([s()], g.prototype, "top", void 0), t([s()], g.prototype, "right", void 0), t([s()], g.prototype, "bottom", void 0), g = h = t([r("esri.views.2d.PaddedViewState.Padding")], g);
let f = m = class extends l {
  constructor(...t) {
    super(...t), this.paddedViewState = new l(), this._updateContent = (() => {
      const t = d();
      return () => {
        const e = this._get("size"),
          i = this._get("padding");
        if (!e || !i) return;
        const o = this.paddedViewState;
        p(t, i.left + i.right, i.top + i.bottom), a(t, e, t), n(o.size, t);
        const s = o.viewpoint;
        s && (this.viewpoint = s);
      };
    })(), this.addHandles(i(() => [this.size, this.padding], () => this._updateContent(), o)), this.padding = new g(), this.size = [0, 0];
  }
  set padding(t) {
    this._set("padding", t || new g());
  }
  set viewpoint(t) {
    if (t) {
      this.paddedViewState.viewpoint = t;
      const e = this._viewpoint2D;
      if (this._get("padding")) {
        const i = t.clone();
        c(i, t, this._get("size"), this._get("padding"));
        const o = i.targetGeometry;
        e.center[0] = o.x, e.center[1] = o.y, e.rotation = i.rotation, e.scale = i.scale, e.spatialReference = o.spatialReference;
      } else {
        const i = t.targetGeometry;
        e.center[0] = i.x, e.center[1] = i.y, e.rotation = t.rotation, e.scale = t.scale, e.spatialReference = i.spatialReference;
      }
      this._update();
    }
  }
  clone() {
    return new m({
      padding: this.padding.clone(),
      size: this.size.slice(),
      viewpoint: this.paddedViewState.viewpoint.clone(),
      pixelRatio: this.pixelRatio
    });
  }
};
t([s()], f.prototype, "paddedViewState", void 0), t([s({
  type: g
})], f.prototype, "padding", null), t([s()], f.prototype, "viewpoint", null), f = m = t([r("esri.views.2d.PaddedViewState")], f);
const w = f;
export { w as default };