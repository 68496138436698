// @ts-strict-ignore
import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import Konva from 'konva';
import {DomSanitizer} from '@angular/platform-browser';
import {DrawComponentsViewportConfig} from '../../classes/draw/draw-components';

export interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
}

@Component({
    selector: 'app-image-crop',
    templateUrl: './image-crop.component.html'
})
export class ImageCropComponent implements OnDestroy, AfterViewInit {
    @ViewChild('stageContainer', {static: true}) stageContainer: ElementRef;
    @ViewChild('hostContainer', {static: true}) hostContainer: ElementRef;

    @Input()
    set viewportConfig(config: DrawComponentsViewportConfig) {
        this._viewportConfig = config;

        if (this._viewportConfig && this._viewportConfig.src) {
            this.getLoadedImage().then((image) => {
                if (this.stage) {
                    this.destroyStage();
                }

                this.createStage(image.width, image.height);

                // Make the pointer size relative to the original image size
                this.pointerRadius = Math.round( image.width / 20 );

                this.cropRect = {
                    x: this._viewportConfig.crop.x,
                    y: this._viewportConfig.crop.y,
                    width: this._viewportConfig.crop.width,
                    height: this._viewportConfig.crop.height
                };
                this.addSourceLayer(image);
                this.addCroppingLayer(image);
            });
        }
    }

    @Input() visible = true;

    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<DrawComponentsViewportConfig>();

    private stage: Konva.Stage;
    private sourceLayer: Konva.Layer;
    private shapeLayer: Konva.Layer;
    private _viewportConfig: DrawComponentsViewportConfig;

    private imageWidth: number;
    private imageHeight: number;

    private pointerRadius = 25;
    private cropRect: Rectangle;
    private minCropSize = {
        width: 100,
        height: 100
    };

    private lastTouchDistance = 0;

    constructor(private sanitizer: DomSanitizer, private zone: NgZone) {
    }

    ngOnDestroy(): void {
        this.destroyStage();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.fitStageIntoParentContainer();
    }

    doCancel() {
        this.cancel.emit();
    }

    doSave() {
        const sourceImage = this.stage.findOne('.source-image');
        const originalPosition = sourceImage.getPosition();

        this.save.emit({
            src: '',
            x: originalPosition.x,
            y: originalPosition.y,
            width: this.imageWidth,
            height: this.imageHeight,
            scale: 1.0,
            crop: {
                src: '',
                x: this.cropRect.x,
                y: this.cropRect.y,
                width: this.cropRect.width,
                height: this.cropRect.height,
                scale: sourceImage.scaleX(),
            },
        });

        this.cancel.emit();
    }

    ngAfterViewInit(): void {
        this.fitStageIntoParentContainer();
    }

    private createStage(width: number, height: number) {
        this.zone.runOutsideAngular(() => {
            if (this.stage) {
                throw new Error('Stage already created');
            }

            this.imageWidth = width;
            this.imageHeight = height;

            this.stage = new Konva.Stage({
                container: this.stageContainer.nativeElement,
                width: this.imageWidth,
                height: this.imageHeight
            });

            this.addZoomEventListeners();
            this.fitStageIntoParentContainer();
        });
    }

    private addZoomEventListeners() {
        const scaleFactor = 1.05;
        this.stage.on('touchmove', (e) => {
            e.evt.preventDefault();
            const sourceImage: Konva.Image = this.stage.findOne('.source-image');
            const touch1 = e.evt.touches[0];
            const touch2 = e.evt.touches[1];

            if (touch1 && touch2) {
                e.evt.stopPropagation();
                const dist = this.getDistance(
                    {
                        x: touch1.clientX,
                        y: touch1.clientY,
                    },
                    {
                        x: touch2.clientX,
                        y: touch2.clientY,
                    }
                );

                if (!this.lastTouchDistance) {
                    this.lastTouchDistance = dist;
                }

                const newScale = Math.max( 1, (sourceImage.scaleX() * dist) / this.lastTouchDistance );
                this.zoomSourceToNewScale(newScale);
                this.lastTouchDistance = dist;
            }
        });

        this.stage.on('touchend', () => {
            this.lastTouchDistance = 0;
        });

        this.stage.on('wheel', (e) => {
            e.evt.preventDefault();
            const sourceImage: Konva.Image = this.stage.findOne('.source-image');
            const oldScale = sourceImage.scaleX();
            const newScale = Math.max( 1, e.evt.deltaY > 0 ? oldScale / scaleFactor : oldScale * scaleFactor );

            this.zoomSourceToNewScale(newScale);
        });
    }

    private fitStageIntoParentContainer() {
        if (!this.stage) {
            console.warn('stage not initialized yet');
            return;
        }
        const containerWidth = this.stageContainer.nativeElement.offsetWidth;
        let availableHeight = this.hostContainer.nativeElement.offsetHeight;
        const header = this.hostContainer.nativeElement.querySelector('.image-annotation__crop-header');
        const footer = this.hostContainer.nativeElement.querySelector('.image-annotation__footer');
        if (header) {
            availableHeight -= header.offsetHeight;
        }
        if (footer) {
            availableHeight -= footer.offsetHeight;
        }

        const scale = Math.min(
            containerWidth / this.imageWidth,
            availableHeight / this.imageHeight
        );

        const scaleChanged = this.stage.scale().x !== scale;
        this.stage.width(this.imageWidth * scale);
        this.stage.height(this.imageHeight * scale);
        this.stage.scale({x: scale, y: scale});
        this.stage.draw();
        if (scaleChanged) {
            setTimeout(() => this.fitStageIntoParentContainer());
        }
    }

    private addSourceLayer(imageSrc: HTMLImageElement) {
        this.sourceLayer = new Konva.Layer({
            listening: true
        });
        const image = new Konva.Image({
            name: 'source-image',
            x: this._viewportConfig.x,
            y: this._viewportConfig.y,
            width: imageSrc.width,
            height: imageSrc.height,
            image: imageSrc,
            perfectDrawEnabled: false,
            listening: true,
            draggable: true,
        });

        image.scale({x: this._viewportConfig.crop.scale, y: this._viewportConfig.crop.scale});
        image.on('dragmove', (e) => {
            this.limitSourceImageToViewport(image);
        });

        image.on('dragend', (e) => {
            this.shapeLayer.draw();
        });

        // Add the image to the layer
        this.sourceLayer.add(image);

        // Add the layer to the stage
        this.stage.add(this.sourceLayer);
    }

    private addCroppingLayer(imageSrc: HTMLImageElement) {
        this.shapeLayer = new Konva.Layer({
            listening: true
        });

        const cropRect = this.cropRect;
        const interactiveGroup = new Konva.Group({
            name: 'interaction-group'
        });

        const enclosureRect = new Konva.Rect({
            name: 'enclosure',
            x: cropRect.x,
            y: cropRect.y,
            width: cropRect.width,
            height: cropRect.height,
            draggable: true,
        });

        // Add a second - non transparent - image to the layer
        const croppedImage = new Konva.Image({
            name: 'crop-area',
            x: 0,
            y: 0,
            width: this.imageWidth,
            height: this.imageHeight,
            image: imageSrc,
            perfectDrawEnabled: false,
            listening: false
        });

        const sourceImage = this.stage.findOne('.source-image');
        croppedImage.setPosition({x: cropRect.x, y: cropRect.y});
        croppedImage.scale({x: this._viewportConfig.crop.scale, y: this._viewportConfig.crop.scale});
        croppedImage.crop({
            x: ( -sourceImage.getPosition().x + this.cropRect.x ) / croppedImage.scaleX(),
            y: ( -sourceImage.getPosition().y + this.cropRect.y ) / croppedImage.scaleY(),
            width: this.cropRect.width / croppedImage.scaleX(),
            height: this.cropRect.height / croppedImage.scaleY(),
        });
        croppedImage.width(enclosureRect.width() / croppedImage.scaleX());
        croppedImage.height(enclosureRect.height() / croppedImage.scaleY());

        // Add the darkened overlay to the image layer
        const overlayRect = new Konva.Rect({
            name: 'overlay',
            x: 0,
            y: 0,
            width: this.imageWidth,
            height: this.imageHeight,
            fill: 'black',
            stroke: 'black',
            strokeWidth: 0,
            opacity: 0.6,
            perfectDrawEnabled: false,
            hitGraphEnabled: false,
            listening: false,
        });

        this.shapeLayer.add( overlayRect );
        interactiveGroup.add( croppedImage );
        interactiveGroup.add( enclosureRect );
        interactiveGroup.add(this.createHandle('top-left', cropRect.x, cropRect.y));
        interactiveGroup.add(this.createHandle('top-right', cropRect.x + cropRect.width, cropRect.y));
        interactiveGroup.add(this.createHandle('bottom-left', cropRect.x, cropRect.y + cropRect.height));
        interactiveGroup.add(this.createHandle('bottom-right', cropRect.x + cropRect.width, cropRect.y + cropRect.height));
        this.shapeLayer.add(interactiveGroup);
        this.stage.add(this.shapeLayer);
        this.shapeLayer.draw();

        enclosureRect.on('dragmove', (e) => {
            this.limitPositionToViewport(e.target, true );
            const position = e.target.getPosition();
            this.cropRect.x = position.x;
            this.cropRect.y = position.y;

            this.updateCropRectView();
        });
    }

    private createHandle(name: string, x: number, y: number): Konva.Circle {
        const circle = new Konva.Circle({
            name,
            x,
            y,
            radius: this.pointerRadius,
            hitStrokeWidth: this.pointerRadius * 4,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 4,
            opacity: 0.5,
            draggable: true,
        });

        circle.on('dragmove', this.dragHandler);
        return circle;
    }

    private dragHandler = (e: Konva.KonvaEventObject<unknown>) => {
        this.limitPositionToViewport(e.target);

        const topLeft = this.stage.findOne('.top-left');
        const bottomRight = this.stage.findOne('.bottom-right');
        if (e.target.name() === 'bottom-right') {
            this.limitPositionToBounds(e.target, {
                x: topLeft.getPosition().x + this.minCropSize.width,
                y: topLeft.getPosition().y + this.minCropSize.height,
                width: this.imageWidth,
                height: this.imageHeight
            }, false);

            this.cropRect.width = e.target.getPosition().x - topLeft.getPosition().x;
            this.cropRect.height = e.target.getPosition().y - topLeft.getPosition().y;
        } else if (e.target.name() === 'bottom-left') {

            this.limitPositionToBounds(e.target, {
                x: 0,
                y: topLeft.getPosition().y + this.minCropSize.height,
                width: bottomRight.getPosition().x - this.minCropSize.width,
                height: this.imageHeight,
            }, false);

            this.cropRect.x = e.target.getPosition().x;
            this.cropRect.width = bottomRight.getPosition().x - e.target.getPosition().x;
            this.cropRect.height = e.target.getPosition().y - topLeft.getPosition().y;
        } else if (e.target.name() === 'top-left') {
            this.limitPositionToBounds(e.target, {
                x: 0,
                y: 0,
                width: bottomRight.getPosition().x - this.minCropSize.width,
                height: bottomRight.getPosition().y - this.minCropSize.height,
            }, false);

            this.cropRect.x = e.target.getPosition().x;
            this.cropRect.y = e.target.getPosition().y;
            this.cropRect.width = bottomRight.getPosition().x - e.target.getPosition().x;
            this.cropRect.height = bottomRight.getPosition().y - e.target.getPosition().y;
        } else if (e.target.name() === 'top-right') {
            this.limitPositionToBounds(e.target, {
                x: topLeft.getPosition().x + this.minCropSize.width,
                y: 0,
                width: this.imageWidth,
                height: bottomRight.getPosition().y - this.minCropSize.height,
            }, false);

            this.cropRect.width = e.target.getPosition().x - topLeft.getPosition().x;
            this.cropRect.y = e.target.getPosition().y;
            this.cropRect.height = bottomRight.getPosition().y - e.target.getPosition().y;
        }

        this.updateCropRectView();
    }

    private limitPositionToViewport(node: Konva.Node, withSizeLimits = false) {
        this.limitPositionToBounds(node, {
            x: 0,
            y: 0,
            width: this.imageWidth,
            height: this.imageHeight,
        }, withSizeLimits);
    }

    private limitPositionToBounds(node: Konva.Node, maxBounds: Rectangle, withSizeLimits: boolean) {
        const nodePosition = node.getPosition();
        const minX = maxBounds.x;
        const maxX = maxBounds.x + maxBounds.width;
        const minY = maxBounds.y;
        const maxY = maxBounds.y + maxBounds.height;

        let positionMoved = false;
        const minXCoordinate = Math.max(minX, nodePosition.x);
        const minYCoordinate = Math.max(minY, nodePosition.y);

        // Check if the bottom right corner falls outside of the viewport
        if (withSizeLimits) {
            const bottomRightNodePosition = {
                x: nodePosition.x + node.attrs.width,
                y: nodePosition.y + node.attrs.height
            };
            if (bottomRightNodePosition.x > maxX || bottomRightNodePosition.y > maxY) {

                node.setPosition({
                    x: bottomRightNodePosition.x <= maxX ? minXCoordinate :
                        nodePosition.x - ( bottomRightNodePosition.x - maxX ),
                    y: bottomRightNodePosition.y <= maxY ? minYCoordinate :
                        nodePosition.y - ( bottomRightNodePosition.y - maxY ),
                });
                positionMoved = true;
            }
        }

        if (!positionMoved && ( nodePosition.x < minX || nodePosition.x > maxX ||
            nodePosition.y < minY || nodePosition.y > maxY ) ) {
            node.setPosition({
                x: Math.min(minXCoordinate, maxX),
                y: Math.min(minYCoordinate, maxY)
            });
        }
    }

    private limitSourceImageToViewport(image: Konva.Image) {
        const xMargin = this.imageWidth * image.scaleX() - this.imageWidth;
        const yMargin = this.imageHeight * image.scaleY() - this.imageHeight;

        this.limitPositionToBounds(image, {
            x: -xMargin,
            y: -yMargin,
            width: xMargin,
            height: yMargin,
        }, false);

        this.updateCropRectView();
        const interactiveGroup = this.stage.findOne('.interaction-group');
        interactiveGroup.draw();
    }

    private zoomSourceToNewScale( newScale: number ) {
        const sourceImage: Konva.Image = this.stage.findOne('.source-image');
        const croppedImage: Konva.Image = this.stage.findOne('.crop-area');

        sourceImage.scale({ x: newScale, y: newScale });
        this.limitSourceImageToViewport(sourceImage);
        croppedImage.crop({
            x: ( -sourceImage.getPosition().x + this.cropRect.x ) / newScale,
            y: ( -sourceImage.getPosition().y + this.cropRect.y ) / newScale,
            width: this.cropRect.width / newScale,
            height: this.cropRect.height / newScale,
        });
        croppedImage.scale({x: newScale, y: newScale});
        croppedImage.width(this.cropRect.width / newScale);
        croppedImage.height(this.cropRect.height / newScale);

        this.stage.batchDraw();
    }

    private updateCropRectView() {
        const enclosureRect: Konva.Rect = this.stage.findOne('.enclosure');
        const sourceImage = this.stage.findOne('.source-image');
        const overlayRect = this.stage.findOne('.overlay');
        enclosureRect.setPosition({x: this.cropRect.x, y: this.cropRect.y});
        enclosureRect.width(this.cropRect.width);
        enclosureRect.height(this.cropRect.height);

        // In case our cropped area is the same as the viewport size
        // Just let the mouse event listeners trickle through to the source image so we can pan in case we have zoomed it
        if (this.cropRect.x === 0 && this.cropRect.y === 0
            && this.cropRect.width === this.imageWidth && this.cropRect.height === this.imageHeight) {
            enclosureRect.listening(false);
            overlayRect.visible(false);
        } else {
            enclosureRect.listening(true);
            overlayRect.visible(true);
        }

        const croppedImage: Konva.Image = this.stage.findOne('.crop-area');
        croppedImage.width(this.cropRect.width / croppedImage.scaleX());
        croppedImage.height(this.cropRect.height / croppedImage.scaleY());

        const topLeftCircle = this.stage.findOne('.top-left');
        const topRightCircle = this.stage.findOne('.top-right');
        const bottomLeftCircle = this.stage.findOne('.bottom-left');
        const bottomRightCircle = this.stage.findOne('.bottom-right');

        croppedImage.setPosition({x: this.cropRect.x, y: this.cropRect.y});
        topLeftCircle.setPosition({x: this.cropRect.x, y: this.cropRect.y});
        topRightCircle.setPosition({x: this.cropRect.x + this.cropRect.width, y: this.cropRect.y});
        bottomLeftCircle.setPosition({x: this.cropRect.x, y: this.cropRect.y + this.cropRect.height});
        bottomRightCircle.setPosition({x: this.cropRect.x + this.cropRect.width, y: this.cropRect.y + this.cropRect.height});

        croppedImage.crop({
            x: ( -sourceImage.getPosition().x + this.cropRect.x ) / croppedImage.scaleX(),
            y: ( -sourceImage.getPosition().y + this.cropRect.y ) / croppedImage.scaleY(),
            width: this.cropRect.width / croppedImage.scaleX(),
            height: this.cropRect.height / croppedImage.scaleY(),
        });
    }

    private destroyStage() {
        if (this.stage) {

            // Safari fix for canvas memory clearing
            this.stage.destroy();
            this.stage.width(0);
            this.stage.height(0);
            this.stage = null;
        }
    }

    private getDistance(p1: {x: number, y: number}, p2: {x: number, y: number}) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    private async getLoadedImage() {
        return new Promise<HTMLImageElement>(((resolve, reject) => {
            const imageElement = new Image();
            imageElement.crossOrigin = 'anonymous';
            imageElement.onload = () => resolve(imageElement);
            imageElement.onerror = err => reject(err);
            imageElement.src = this._viewportConfig.src;
        }));
    }
}
