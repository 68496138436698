import {Component} from '@angular/core';
import {AnyProjectJobForm} from '../../models/project-job-form';
import {Router} from "@angular/router";
import {PopupRef} from '../../helpers/popup-ref';
import {PopupConfig} from '../../models/popup-config';

@Component({
  selector: 'app-fill-form-again-modal',
  templateUrl: './fill-form-again-modal.component.html'
})
export class FillFormAgainModalComponent {

    source: AnyProjectJobForm | undefined;

    constructor(
        private router: Router,
        private popupRef: PopupRef,
        {data}: PopupConfig<{ source: AnyProjectJobForm }>
    ) {
        if (data) {
            this.source = data.source;
        }
    }

    async doFillFormAgain() {
        if (this.source == undefined) {
            throw new Error("Source is missing and required")
        }

        await this.router.navigate(['/projects', this.source.project, 'jobs', 'new'],
            {queryParams: {form: this.source.originalProjectForm, code: this.source.code}});

        await this.doCloseModal();
    }

   async doCloseModal() {
        await this.popupRef.close();
    }
}
