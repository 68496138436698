/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../../../Graphic.js";
import { isSome as r } from "../../../core/arrayUtils.js";
import { isIterable as i } from "../../../core/iteratorUtils.js";
import { clipContainsPoint as t } from "./clippingUtils.js";
import { isSupportedScreenPointEvent as a, createScreenPointFromSupportedEvent as l } from "../../support/screenUtils.js";
async function s(e, i, s) {
  const f = a(i) ? l(e, i) : i;
  if (!e.ready || isNaN(f.x) || isNaN(f.y)) return {
    screenPoint: f,
    results: []
  };
  let d = new Set();
  const y = new Set();
  let u = !1,
    h = null,
    g = null;
  s?.include ? o(s.include, n(e, e => {
    d.add(e), p(e, e => y.add(e));
  }, (e, r) => {
    y.add(e), d.add(r);
  }, e => {
    h || (h = new Set()), h.add(e);
  }, e => d.add(e), () => u = !0)) : (u = !0, d = new Set(e.allLayerViews), d.forEach(e => {
    p(e, e => y.add(e));
  })), s?.exclude && o(s.exclude, n(e, e => {
    d.delete(e), p(e, e => y.delete(e));
  }, e => y.delete(e), e => {
    g || (g = new Set()), g.add(e);
  }));
  const m = e.toMap(f),
    w = e.allLayerViews.filter(r => !r.suspended && d.has(r) && r.clips.every(r => t(e, r, f, m))).reverse();
  let V = [...(u ? e.graphicsView.hitTest(m).map(e => ({
    type: "graphic",
    graphic: e,
    layer: null,
    mapPoint: m
  })) : []), ...(await Promise.all(w.map(e => e.hitTest(m, f)).toArray()))].filter(r).flat().filter(r);
  return V = V.filter(e => "graphic" !== e.type || "subtype-group" !== e.layer?.type || y.has(e.graphic.layer)), h && (V = V.filter(e => !("graphic" in e) || !e.graphic || h?.has(c(e.graphic)))), g && (V = V.filter(e => !("graphic" in e) || !e.graphic || !g?.has(c(e.graphic)))), {
    screenPoint: f,
    results: V
  };
}
function n(r, i, t, a, l, s) {
  return n => {
    if (n instanceof e) {
      if (n.layer === r) s?.();else {
        const e = r.allLayerViews.find(e => e.layer === n.layer);
        e && l?.(e);
      }
      a(c(n));
    } else if ("layer" in n && "element" in n) ;else if ("subtype-sublayer" === n.type) {
      const e = r.allLayerViews.find(e => e.layer === n.parent);
      e && t(n, e);
    } else {
      const e = r.allLayerViews.find(e => e.layer === n);
      e && i(e);
    }
  };
}
function o(e, r) {
  if (e) if (i(e)) {
    for (const t of e) if (i(t)) for (const e of t) r(e);else r(t);
  } else r(e);
}
function c(e) {
  const r = e.getObjectId();
  return r ? `${e.layer?.uid ?? e.sourceLayer?.uid ?? "MapView"}/${r}` : `"MapView/${e.uid}`;
}
function p({
  layer: e
}, r) {
  "subtype-group" === e?.type && e.sublayers.forEach(e => {
    r(e);
  });
}
export { s as hitTest };