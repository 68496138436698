import {AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Locate from '@arcgis/core/widgets/Locate';
import {filter, map, pairwise, startWith} from 'rxjs/operators';
import {PaulaObjectLocationType} from '../../models/question/object-question';
import Color from '@arcgis/core/Color';

export interface PointMapCoords {
    longitude: number | null;
    latitude: number | null;
}

const EMPTY_POINT_MAP_COORDS: PointMapCoords = {latitude: null, longitude: null};

enum NextCoordType {
    FROM,
    TO
}

@Component({
    selector: 'app-point-map',
    templateUrl: './point-map.component.html',
})
export class PointMapComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input({required: true})
    coords$!: Observable<PointMapCoords[]>;

    @Input({required: true})
    currentPosition$!: Observable<PointMapCoords>;

    @Input({required: true})
    centerCoords$!: Observable<PointMapCoords>;

    @Input({required: true})
    objectLocationType!: PaulaObjectLocationType;

    @Output()
    newCoords = new EventEmitter<PointMapCoords[]>();

    @Output()
    pointMapLoaded = new EventEmitter<void>();

    @ViewChild('pointMapContainer') private pointMapContainer!: ElementRef;

    private pointMapGraphicsLayer: GraphicsLayer | undefined;
    private pointMapGraphicsLayerCurrent: GraphicsLayer | undefined;
    private pointMapView: MapView | undefined;
    private pointMap: Map | undefined;
    private pointMapReadySubject = new BehaviorSubject<boolean>(false);

    private nextCoordType: NextCoordType = NextCoordType.FROM;
    private zoomSubject = new BehaviorSubject(12);
    private currCoords: PointMapCoords[] = [EMPTY_POINT_MAP_COORDS, EMPTY_POINT_MAP_COORDS];

    private subscriptions: Subscription[] = [];

    constructor(
        private zone: NgZone
    ) {}

    @Input()
    set zoom(newZoom: number) {
        this.zoomSubject.next(newZoom);
    }

    ngOnInit(): void {
        this.subscriptions.push(
            // Updates the coordinates selection (from and to)
            combineLatest([
                this.pointMapReadySubject.asObservable().pipe(startWith(false)),
                this.coords$.pipe(startWith<PointMapCoords[]>([EMPTY_POINT_MAP_COORDS, EMPTY_POINT_MAP_COORDS])),
                this.currentPosition$.pipe(startWith<PointMapCoords>(EMPTY_POINT_MAP_COORDS))
            ]).pipe(
                filter(([ready]) => ready),
                map(([_, coords, position]) => ({coords, position}))
            ).subscribe(({coords, position}) => {
                if (this.pointMapGraphicsLayer && this.pointMapGraphicsLayerCurrent) {
                    this.pointMapGraphicsLayer.removeAll();
                    this.pointMapGraphicsLayerCurrent.removeAll();
                }

                if (coords) {
                    if (coords.filter(val => val.latitude !== null && val.longitude !== null).length >= 2) {
                        this.drawGraphicLine(coords);
                    }
                    coords.forEach((coordinate, index) => {
                        if (coordinate.latitude !== null && coordinate.longitude !== null) {
                            this.drawGraphicPoint(coordinate, index === 0 ? '#008CBD' : '#50C892');
                        }
                    });
                }
                if (position.latitude !== null && position.longitude !== null) {
                    this.drawGraphicPointPosition(position);
                }
            }),
            // Updates the zoom level
            combineLatest([this.pointMapReadySubject.asObservable(), this.zoomSubject.asObservable()]).pipe(
                filter(([ready, _]) => ready),
                map(([_, zoom]) => zoom)
            ).subscribe(zoom => {
                if (this.pointMapView) {
                    this.pointMapView.set({zoom});
                }
            }),
            // Update center
            combineLatest([this.pointMapReadySubject.asObservable(), this.centerCoords$, this.coords$]).pipe(
                filter(([ready, center, coords]) => ready && center !== undefined),
                map(([_, center, coords]) => {
                    return coords[0].latitude !== null && coords[0].longitude !== null ? coords[0] : center;
                })
            ).subscribe(center => {
                if (this.pointMapView) {
                    this.pointMapView.set({center})
                }
            }),
            this.coords$.subscribe(val => this.currCoords = val),
            this.coords$.pipe(
                startWith<PointMapCoords[]>([EMPTY_POINT_MAP_COORDS, EMPTY_POINT_MAP_COORDS]),
                pairwise()
            ).subscribe(([prev, curr]) => {
                const [prevFrom, prevTo] = prev;
                const [currFrom, currTo] = curr;

                if (this.objectLocationType === 'Linear') {
                    if ((prevTo.longitude !== currTo.longitude || prevTo.latitude !== currTo.latitude)) {
                        if (currTo.latitude !== null && currTo.longitude !== null) {
                            this.nextCoordType = NextCoordType.FROM;
                        } else {
                            this.nextCoordType = NextCoordType.TO;
                        }
                    }
                    if ((prevFrom.longitude !== currFrom.longitude || prevFrom.latitude !== currFrom.latitude)
                        && (currFrom.latitude !== null && currFrom.longitude !== null)
                    ) {
                        this.nextCoordType = NextCoordType.TO;
                    }
                } else {
                    this.nextCoordType = NextCoordType.FROM;
                }
            })
        );
    }

    async ngAfterViewInit() {
        if (!this.pointMap) {
            this.createMap();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.destroyMap();
    }

    private createMap() {
        try {
            this.pointMap = new Map({
                basemap: 'topo-vector',
            });

            this.pointMapView = new MapView({
                container: this.pointMapContainer.nativeElement,
                map: this.pointMap,
                popup: {
                    collapseEnabled: false,
                },
                highlightOptions: {
                    color: new Color([100, 100, 100]),
                    fillOpacity: 0,
                    haloOpacity: 1,
                },
            });

            const locate = new Locate({
                view: this.pointMapView,
                graphic: undefined,
            });

            this.pointMapView.ui.move('zoom', 'top-right');
            this.pointMapView.ui.add(locate, 'top-right');
            this.pointMapGraphicsLayer = new GraphicsLayer();
            this.pointMapGraphicsLayerCurrent = new GraphicsLayer();
            this.pointMap.addMany([this.pointMapGraphicsLayer, this.pointMapGraphicsLayerCurrent]);
            this.pointMapView.on('click', (event) => {
                this.zone.run(() => {
                    if (this.nextCoordType === NextCoordType.FROM) {
                        this.newCoords.emit([
                            {
                                longitude: event.mapPoint.longitude,
                                latitude: event.mapPoint.latitude,
                            },
                            EMPTY_POINT_MAP_COORDS
                        ]);
                    } else {
                        this.newCoords.emit([
                            this.currCoords[0],
                            {
                                longitude: event.mapPoint.longitude,
                                latitude: event.mapPoint.latitude,
                            }
                        ]);
                    }
                });
            });

            this.pointMapReadySubject.next(true);
            this.pointMapLoaded.emit();
        } catch (error) {
            console.error('Unable to create map', error);
        }
    }

    private destroyMap() {
        this.pointMapReadySubject.next(false);
        if (this.pointMapView) {
            this.pointMapView.destroy();
            this.pointMapView = undefined;
        }
        if (this.pointMap) {
            this.pointMap.destroy();
            this.pointMap = undefined;
        }
    }

    /**
     * @throws Error when called before map is ready
     */
    private drawGraphicPoint(coordinate: PointMapCoords, color: string) {
        this.throwOnMapNotReady();
        if (!this.pointMapGraphicsLayer) {
            throw new Error('pointMapGraphicsLayer not set');
        }

        const point = {
            type: 'point',
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
        };
        const simpleMarkerSymbol = {
            type: 'simple-marker',
            color: color,
            outline: {
                color: [255, 255, 255],
                width: 1,
            },
        };
        const pointGraphic = new Graphic({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            geometry: point as any, // type definition incomplete for autocast
            symbol: simpleMarkerSymbol,
        });
        this.pointMapGraphicsLayer.add(pointGraphic);
    }

    /**
     * @throws Error when called before map is ready
     */
    private drawGraphicLine(coords: PointMapCoords[]) {
        this.throwOnMapNotReady();
        if (!this.pointMapGraphicsLayer) {
            throw new Error('pointMapGraphicsLayer not set');
        }

        const paths = coords.map(coordinate => [coordinate.longitude, coordinate.latitude]);
        const simpleLineSymbol = {
            type: 'simple-line',
            color: [0, 0, 0],
            width: 2,
        };
        const polyline = {
            type: 'polyline',
            paths: paths
        };
        const pointGraphic = new Graphic({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            geometry: polyline as any, // type definition incomplete for autocast
            symbol: simpleLineSymbol,
        });

        this.pointMapGraphicsLayer.add(pointGraphic);
    }

    /**
     * @throws Error when called before map is ready
     */
    private drawGraphicPointPosition(coordinate: PointMapCoords) {
        this.throwOnMapNotReady();
        if (!this.pointMapGraphicsLayerCurrent) {
            throw new Error('pointMapGraphicsLayerCurrent not set');
        }

        const {latitude, longitude} = coordinate;
        const point = {
            type: 'point',
            longitude,
            latitude,
        };
        const simpleMarkerSymbol = {
            type: 'simple-marker',
            color: [4, 127, 171],
            outline: {
                color: '#bcdfea',
                width: 3,
            },
        };
        const pointGraphic = new Graphic({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            geometry: point as any, // type definition incomplete for auto cast
            symbol: simpleMarkerSymbol,
        });

        this.pointMapGraphicsLayerCurrent.add(pointGraphic);
    }

    private throwOnMapNotReady() {
        if (!this.pointMapReadySubject.value) {
            throw new Error('Unready map');
        }
    }
}
