import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ProjectJobAttachment} from '../../models/project-job-attachment';
import {ProjectJobAttachmentService} from '../../services/project-job-attachment.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {blobToBase64} from '../../utils/blob-to-base64';
import {FileOpener} from '@capacitor-community/file-opener';
import {FileUtils} from '../../utils/file-utils';
import {ToastrService} from 'ngx-toastr';
import {PopupService} from '../../services/popup.service';
import {ViewHtmlAttachmentModalComponent} from '../view-html-attachment-modal/view-html-attachment-modal.component';

@Component({
    selector: 'app-attachments-overview-menu',
    templateUrl: './attachments-overview-menu.component.html',
})
export class AttachmentsOverviewMenuComponent implements OnInit {
    @Output() sideNavClose = new EventEmitter<void>();

    subscription: Subscription | null = null;
    attachments: ProjectJobAttachment[] | null = null;
    downloadingAttachmentId: number | null = null;

    private readonly projectJobFormRegex = /^\/projects\/(\d+)\/jobs\/(\d+)/;

    constructor(
        @Inject('ProjectJobAttachmentService') private service: ProjectJobAttachmentService,
        private popupService: PopupService,
        private toastr: ToastrService,
        private router: Router) {
    }

    ngOnInit() {
        const routeUrl = this.router.routerState.snapshot.url;
        const results = this.projectJobFormRegex.exec(routeUrl);

        if (results === null) {
            return;
        }

        const projectJobAttachments = this.service.getAttachments(+results[1], +results[2]);
        projectJobAttachments.subscribe(attachments => {
            this.attachments = attachments?.toSorted(this.sortFile) || null;
        })
    }

    formatFilename(attachment: ProjectJobAttachment): string {
        if (attachment.contentType === 'text/html') {
            // Remove the file extension for html files
            return attachment.originalFilename.split('.').slice(0, -1).join('.');
        }

        return attachment.originalFilename;
    }

    attachmentIcon(attachment: ProjectJobAttachment): string {
        switch (attachment.contentType) {
            case 'video/mp4':
            case 'application/x-mpegURL':
            case 'video/MP2T':
            case 'video/3gpp':
            case 'video/quicktime':
            case 'video/x-msvideo':
            case 'video/x-ms-wmv':
                return 'video';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'printer';
            case 'text/plain':
            case 'text/html':
                return 'text';
            case 'application/pdf':
                return 'pdf';
            case 'image/jpeg':
            case 'image/bmp':
            case 'image/gif':
            case 'image/pipeg':
            case 'image/svg+xml':
            case 'image/tiff':
            case 'image/png':
                return 'image';
            default:
                return 'file';
        }
    }

    async openAttachment(attachment: ProjectJobAttachment) {
        if (this.downloadingAttachmentId !== null) {
            await this.toastr.error('Er is al een bijlage aan het downloaden');
            return;
        }
        if (attachment.contentType === 'text/html') {
            this.sideNavClose.emit();
            await this.openHtmlAttachmentViewModal(attachment);
            return;
        }

        try {
            this.downloadingAttachmentId = attachment.id;
            const httpResponse = await fetch(attachment.src).then(it => it.blob());
            const fileName = attachment.originalFilename;

            if (httpResponse !== null) {
                // Open natively on Mobile, let the browser handle it on Desktop / Web
                if (Capacitor.isNativePlatform()) {
                    // Save the file to the cache directory
                    const writtenFile = await Filesystem.writeFile({
                        directory: Directory.Cache,
                        path: fileName,
                        data: await blobToBase64(httpResponse),
                    })
                    await FileOpener.open({
                        filePath: writtenFile.uri
                    })
                } else {
                    FileUtils.downloadBlobAsFile(httpResponse, fileName);
                }
            }
        } catch (e) {
            console.error('Error while opening attachment', e);
            await this.toastr.error('Bijlage kon niet worden geopend');
        } finally {
            this.sideNavClose.emit();
            this.downloadingAttachmentId = null;
        }
    }

    async openHtmlAttachmentViewModal(attachment: ProjectJobAttachment) {
        try {
            this.downloadingAttachmentId = attachment.id;
            const attachmentContent = await fetch(attachment.src).then(it => {
                if (!it.ok) {
                    throw new Error(`Failed to load file content: ${it.statusText}`);
                }

                return it.text();
            });

            this.downloadingAttachmentId = null;

            if (attachmentContent !== null) {
                const popup = this.popupService.open(
                    ViewHtmlAttachmentModalComponent, {
                        fullscreen: true,
                        data: {
                            attachment: attachment,
                            attachmentContent: attachmentContent
                        }
                    }
                );

                // Wait for the modal to close
                await firstValueFrom(popup.afterClosed);
            }
        } catch (e) {
            console.error('Error while opening attachment', e);
            await this.toastr.error('Bijlage kon niet worden geopend');
        } finally {
            this.downloadingAttachmentId = null;
        }
    }

    private sortFile(a: ProjectJobAttachment, b: ProjectJobAttachment): number {
        // sort by contentType html first then by filename
        if (a.contentType === 'text/html' && b.contentType !== 'text/html') {
            return -1;
        } else if (a.contentType !== 'text/html' && b.contentType === 'text/html') {
            return 1;
        } else {
            return a.originalFilename!.localeCompare(b.originalFilename!);
        }
    }

}
