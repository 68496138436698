import {Component, Inject, Input} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {PaulaDatabaseRecord} from '../../../models/paula-database';
import {BehaviorSubject, combineLatest, Observable, switchMap} from 'rxjs';
import {PaulaDatabaseService} from '../../../services/paula-database.service';
import {DatabaseQuestion} from '../../../models/question/database-question';
import {filter} from 'rxjs/operators';
import {getAnsweredReferencedQuestionAttributeValue, getFilteredDatabaseRecords} from '../../../utils/database-question-util';
import {controlValueChanges} from '../../../utils/form-control-value';

@Component({
  selector: 'app-question-v2-database',
  templateUrl: './question-v2-database.component.html',
})
export class QuestionV2DatabaseComponent {

    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: DatabaseQuestion | null = null;
    private currentNodeSubject = new BehaviorSubject<AnyLayeredFormNode | undefined>(undefined);

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.formSubject.next(form);

        this.updateAnswer();
    }

    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNodeSubject.next(node);
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: DatabaseQuestion) {
        this.currentQuestion = question;
        this.questionSubject.next(question);

        if (this.currentQuestion.required) {
            this.recordControl.setValidators([Validators.required]);
        } else {
            this.recordControl.clearValidators();
        }

        this.ignoreFilterControl.setValue(false);

        this.updateAnswer();
    }
    public recordControl = new FormControl<PaulaDatabaseRecord | null>(null);
    public ignoreFilterControl = new FormControl<boolean>(false, {nonNullable: true});

    private formSubject = new BehaviorSubject<AnyProjectJobForm | null>(null);
    private questionSubject = new BehaviorSubject<DatabaseQuestion | null>(null);

    public referencedQuestionAnswer$: Observable<string | null> = combineLatest([
        this.formSubject.pipe(filter((form): form is AnyProjectJobForm => form !== null)),
        this.questionSubject.pipe(filter((question): question is DatabaseQuestion => question !== null)),
        this.currentNodeSubject
    ]).pipe(
        switchMap(([form, question, node]) => getAnsweredReferencedQuestionAttributeValue(form, question, node))
    );

    public selectableRecords$: Observable<{ records: PaulaDatabaseRecord[], showWarning: boolean }> = combineLatest([
        this.questionSubject,
        this.referencedQuestionAnswer$,
        controlValueChanges(this.ignoreFilterControl)
    ]).pipe(
        switchMap(async ([question, referencedQuestionAnswer, ignoreFilter]) => {
            if (question === null) {
                return { records: [], showWarning: false };
            }

            const paulaDatabase = await this.paulaDatabaseService.get(question.config.database);

            return getFilteredDatabaseRecords(question, paulaDatabase, referencedQuestionAnswer, ignoreFilter);
        })
    );

    constructor(@Inject('PaulaDatabaseService') private paulaDatabaseService: PaulaDatabaseService) {
    }

    get isValid() {
        return this.recordControl.valid;
    }

    get currentValue(): ProjectJobAnswerValue {
        const value = this.recordControl.value;

        return {
            value: value ? JSON.stringify(value) : '',
            remarkText: null,
            remarkImage: null,
        }
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNodeSubject.value);
        const value = latest?.value;

        if (value) {
            this.recordControl.setValue(JSON.parse(value));
        } else {
            this.recordControl.reset(null);
        }
    }
}
