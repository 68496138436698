<ng-container [ngSwitch]="type">
    <form action="" (submit)="submit()" class="h-full">
        <div class="flex flex-col h-full">
            <div class="modal-body tabular-question__modal">
                <h3 class="mb-1">
                    <ng-template [ngSwitchCase]="'add'">{{ ('Rij toevoegen'|translate) }}</ng-template>
                    <ng-template [ngSwitchCase]="'edit'">{{ ('Rij bewerken'|translate) }}</ng-template>
                    <ng-template [ngSwitchCase]="'delete'">{{ ('Rij verwijderen?'|translate) }}</ng-template>
                </h3>

                <p *ngIf="type !== 'delete' else deleteRowMessage"
                   [class.ng-invalid]="rowLabelControl.dirty && rowLabelControl.invalid">
                    <label class="input-label">
                        {{ 'Rij-label'|translate }} <span class="text-danger">*</span>
                    </label>

                    <input type="text" [formControl]="rowLabelControl" [placeholder]="'Vul een uniek rij-label in'|translate" />

                    <ng-container *ngIf="rowLabelControl.dirty">
                        <span class="text-danger" *ngIf="rowLabelControl.errors?.['unique']">{{ 'Het ingevoerde label is niet uniek'|translate }}</span>
                        <span class="text-danger" *ngIf="rowLabelControl.errors?.['required']">{{ 'Rij-label is verplicht'|translate }}</span>
                    </ng-container>
                </p>

                <ng-template #deleteRowMessage>
                    <p>{{ 'Weet je zeker dat je deze rij wilt verwijderen?'|translate }}</p>
                </ng-template>
            </div>

            <div class="modal-actions modal-container__actions">
                <button type="button" class="modal-btn" (click)="cancel()">{{ 'Annuleer'|translate }}</button>

                <button type="button" *ngSwitchCase="'add'" class="modal-btn is-primary" (click)="addOrUpdateRow()">{{ ('Toevoegen'|translate) }}</button>
                <button type="button" *ngSwitchCase="'edit'" class="modal-btn is-primary" (click)="addOrUpdateRow()">{{ ('Opslaan'|translate) }}</button>
                <button type="button" *ngSwitchCase="'delete'"  class="modal-btn is-primary text-danger" (click)="deleteRow()">{{ ('Rij verwijderen'|translate) }}</button>

                <input type="submit" hidden>
            </div>
        </div>
    </form>
</ng-container>
