// @ts-strict-ignore
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {ImageTool} from '../../models/image-tool';

@Component({
    selector: 'app-image-tools',
    templateUrl: './image-tools.component.html'
})
export class ImageToolsComponent {

    @Input() visible = true;
    @Input() imageTools: ImageTool[];

    @Output() closeTools = new EventEmitter();
    @Output() choose = new EventEmitter<ImageTool>();

    public close() {
        this.closeTools.emit();
    }

    public chooseImageTool(imageTool: ImageTool) {
        this.choose.emit(imageTool);
        this.close();
    }

}
