<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>

    <div class="question-type">
        <label class="input-label">Antwoord</label>

        <div class="flex gap-1 mb-4">
            <button class="vwui-btn is-primary flex-auto" type="button" [disabled]="!measureSupported" (click)="openRailStraight()">
                {{ 'Nieuwe meting'|translate }}
            </button>
            <button class="vwui-btn is-danger flex-none w-12" type="button" [disabled]="disabled || parsedValue === null" (click)="clearAnswer()">
                <app-icon iconName="trash-2"></app-icon>
            </button>
        </div>

        <ng-container *ngIf="parsedValue">
            <label class="input-label">{{'Snelheidsklasse'|translate}}</label>
            <ng-select class="mt-2 mb-4" [disabled]="disabled"
                       [(ngModel)]="parsedValue.speed_class_index" [clearable]="false" [searchable]="false">
                <ng-option *ngFor="let item of parsedValue.speed_class_labels; index as i" [value]="i">
                    {{item}}
                </ng-option>
            </ng-select>

            <label class="input-label">{{'Pv_min'|translate}}</label>
            <p class="font-size-15 question-read-only-value">{{parsedValue.pv_min}}</p>
            <label class="input-label">{{'Pv_max'|translate}}</label>
            <p class="font-size-15 question-read-only-value">{{parsedValue.pv_max}}</p>
            <label class="input-label">{{'qi'|translate}}</label>
            <p class="font-size-15 question-read-only-value">{{parsedValue.qis[parsedValue.speed_class_index]}}</p>

            <app-image-thumbnail class="image-grid__image" [uuid]="parsedValue.qi_images[parsedValue.speed_class_index]"
                                 [jobId]="currentForm?.id" [questionPosition]="currentQuestion?.position">
            </app-image-thumbnail>
        </ng-container>
    </div>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="isValid"></app-question-footer>
