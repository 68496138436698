/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import t from "../core/Accessor.js";
import { createScreenPoint as r } from "../core/screenUtils.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
let i = class extends t {
  constructor(o) {
    super(o), this.factor = 1.5, this.offset = r(0, 0), this.position = null, this.size = 120, this.maskUrl = null, this.maskEnabled = !0, this.overlayUrl = null, this.overlayEnabled = !0, this.visible = !0;
  }
  get version() {
    return this.commitProperty("factor"), this.commitProperty("offset"), this.commitProperty("position"), this.commitProperty("visible"), this.commitProperty("size"), this.commitProperty("maskUrl"), this.commitProperty("maskEnabled"), this.commitProperty("overlayUrl"), this.commitProperty("overlayEnabled"), (this._get("version") || 0) + 1;
  }
};
o([e({
  type: Number
})], i.prototype, "factor", void 0), o([e({
  nonNullable: !0
})], i.prototype, "offset", void 0), o([e()], i.prototype, "position", void 0), o([e({
  type: Number,
  range: {
    min: 0
  }
})], i.prototype, "size", void 0), o([e()], i.prototype, "maskUrl", void 0), o([e()], i.prototype, "maskEnabled", void 0), o([e()], i.prototype, "overlayUrl", void 0), o([e()], i.prototype, "overlayEnabled", void 0), o([e({
  readOnly: !0
})], i.prototype, "version", null), o([e({
  type: Boolean
})], i.prototype, "visible", void 0), i = o([s("esri.views.Magnifier")], i);
const p = i;
export { p as default };