import {Injector, ProviderToken} from "@angular/core";

export class PopupInjector implements Injector {
    constructor(
        private parentInjector: Injector,
        private additionalTokens: WeakMap<ProviderToken<unknown>, unknown>
    ) {
    }

    get<T>(token: ProviderToken<T>, notFoundValue?: T): T {
        const value = this.additionalTokens.get(token);

        if (value) {
            return value as T;
        }

        return this.parentInjector.get<T>(token, notFoundValue);
    }
}
