import {Observable, of} from 'rxjs';
import {catchError, concatMap, map, retry, scan, takeWhile} from 'rxjs/operators';
import {PageResponse} from '../models/page-response';

export function infiniteScrollObservable<T>(
    loadMoreSubject: Observable<unknown>,
    loadFunction: (page: number) => Observable<PageResponse<T>>
): Observable<T[]> {
    return loadMoreSubject.pipe(
        scan<unknown, number, number>((previousPage) => {
            return previousPage + 1;
        }, -1),
        concatMap<number, Observable<PageResponse<T>>>((page) => loadFunction(page)),
        retry(3), // Retry retrieving at most 3 times
        // Take data while last page hasn't been reached yet
        takeWhile(value => {
            return !value.last;
        }, true),
        // Only return items, not the other paginated properties
        map<PageResponse<T>, T[]>((it) => it.content),
        scan<T[], T[]>((acc, value) => {
            return acc.concat(value);
        }, []),
        catchError((err) => {
            // Catch error to prevent observable finishing / erroring
            console.error('Failed retrieving data:', err);
            return of([]);
        }),
    );
}
