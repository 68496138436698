<app-modal
        [showCloseBtn]="false"
        [visible$]="showSignaturePad$"
        (modalClosed)="closeModal()"
>
    <div class="signature-pad" [class.has-signature]="hasSignature">
        <canvas class="signature-pad__canvas" #signaturePadCanvas></canvas>
        <div class="signature-pad__clear" (click)="clearSignature()">
            <app-icon iconName="refresh"></app-icon>
        </div>
    </div>

    <div class="modal-actions">
        <button class="modal-btn" (click)="closeModal()">Annuleren</button>
        <button class="modal-btn is-primary" (click)="saveSignature()">Opslaan</button>
    </div>
</app-modal>
