<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>

    <div class="image-grid">
        <div *ngFor="let image of images; let i = index" class="image-grid__item"
             (click)="showImageViewer(image)">
            <app-image-thumbnail class="image-grid__image" [uuid]="image.modifiedPhotoId ?? image.originalPhotoId"
                                 [jobId]="currentForm?.id" [questionPosition]="currentQuestion?.position"
                                 [comment]="!!image.description">
            </app-image-thumbnail>
        </div>
    </div>

    <app-image-viewer
        [image]="selectedImage$|async"
        [canEdit]="true"
        [canRemove]="!!selectedImage$.value?.modifiedPhotoId"
        (remove)="removeImage($event)"
        (save)="replaceImage($event)"
        (closed)="selectedImage$.next(null)"
    ></app-image-viewer>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="control.valid" (tapOnInvalidButton)="showToastError()"></app-question-footer>
