import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PointMapCoords} from '../../point-map/point-map.component';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import {filter, take, timeout} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Geolocation} from '@capacitor/geolocation';
import {Position as GeolocationPosition} from '@capacitor/geolocation/dist/esm/definitions';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {LocationQuestion} from '../../../models/question/location-question';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {Forms} from '../../../utils/forms';

@Component({
    selector: 'app-question-v2-location',
    templateUrl: './question-v2-location.component.html',
})
export class QuestionV2LocationComponent implements OnInit, OnDestroy {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: LocationQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: LocationQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.locationForm.controls.gpsLatitudeFrom.addValidators(Validators.required);
            if (this.currentQuestion.multipoint) {
                this.locationForm.controls.gpsLatitudeTo.addValidators(Validators.required);
            }
        } else {
            Object.values(this.locationForm.controls).map(control => control.removeValidators(Validators.required));
        }

        Forms.updateValueAndValidityRecursive(this.locationForm);

        this.updateAnswer();
    }

    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    constructor(
        private ngZone: NgZone,
        private toastr: ToastrService
    ) {
        this.subscriptions.push(
            this.locationForm.valueChanges.subscribe(value => {
                this.control.setValue(JSON.stringify(value));
            })
        );
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    locationForm = new FormGroup({
        gpsLatitudeFrom: new FormControl<number | null>(null),
        gpsLatitudeTo: new FormControl<number | null>(null),
        gpsLongitudeFrom: new FormControl<number | null>(null),
        gpsLongitudeTo: new FormControl<number | null>(null),
    });

    private subscriptions: Subscription[] = [];
    private gpsHandler: string | null = null;
    private currentPositionSubject = new BehaviorSubject<PointMapCoords|undefined>(undefined);

    currentPosition$: Observable<PointMapCoords | undefined> = this.currentPositionSubject.asObservable().pipe(
        filter(val => val !== undefined)
    );

    ngOnInit() {
        Geolocation.watchPosition({
            enableHighAccuracy: true
        }, (position: GeolocationPosition | null) => {
            this.ngZone.run(() => {
                if (position) {
                    const {latitude, longitude} = position.coords;
                    this.currentPositionSubject.next({latitude, longitude});
                }
            });
        }).then(gpsHandler => this.gpsHandler = gpsHandler);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => it.unsubscribe());
        if (this.gpsHandler) {
            Geolocation.clearWatch({id: this.gpsHandler});
        }
    }

    showErrorToast() {
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn');
    }

    async getGpsFrom() {
        try {
            const currentPosition = await firstValueFrom(this.getCurrentPosition());
            this.locationForm.patchValue({
                gpsLatitudeFrom: currentPosition?.latitude,
                gpsLongitudeFrom: currentPosition?.longitude
            });
        } catch (error) {
            console.error(error);
            this.showPositionFetchError();
        }
    }

    async getGpsTo() {
        try {
            const currentPosition = await firstValueFrom(this.getCurrentPosition());
            this.locationForm.patchValue({
                gpsLatitudeTo: currentPosition?.latitude,
                gpsLongitudeTo: currentPosition?.longitude
            });
        } catch (error) {
            console.error(error);
            this.showPositionFetchError();
        }
    }

    private showPositionFetchError() {
        (async function (toastr: ToastrService, message: string) {
            return toastr.error(message);
        })(this.toastr, 'Het ophalen van de GPS-locatie is mislukt.');
    }

    private getCurrentPosition(): Observable<PointMapCoords | undefined> {
        return this.currentPosition$.pipe(
            timeout(8000),
            filter(val => !!val),
            take(1)
        );
    }

    clearGpsFrom() {
        this.locationForm.patchValue({gpsLatitudeFrom: null, gpsLongitudeFrom: null});
    }

    clearGpsTo() {
        this.locationForm.patchValue({gpsLatitudeTo: null, gpsLongitudeTo: null});
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (latest?.value) {
            this.locationForm.setValue(JSON.parse(latest.value));
        } else {
            this.locationForm.reset()
        }
    }
}
