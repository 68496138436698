<div class="image-annotation" [class.image-annotation--visible]="visible" #hostContainer>
    <div class="image-annotation__crop-header">
        <button class="image-annotation__crop-header__button mr-auto" (click)="doCancel()">
            Annuleren
        </button>
        <button class="image-annotation__crop-header__button ml-auto" (click)="doSave()">
            Uitsnijden
        </button>
    </div>
    <div class="image-annotation__container" #stageContainer></div>
</div>
