// @ts-strict-ignore
import {DrawComponent, DrawComponentActionShape, DrawComponentState} from './draw-component';
import Konva from 'konva';

export class DrawComponentText extends DrawComponent {

    readonly drawingShapeDefaultConfig = {
        text: 'LMAO',
    };
    readonly drawingShape: Konva.Group;
    readonly drawingText: Konva.Text;
    readonly drawingBackground: Konva.Rect;

    constructor(
        uuid: string,
        state: DrawComponentState,
    ) {
        super(uuid, DrawComponentActionShape.TEXT, state);

        const textConfig = state.config.textConfig ? {
                ...this.drawingShapeDefaultConfig, ...state.config.textConfig
            } : {...this.drawingShapeDefaultConfig};

        this.drawingText = new Konva.Text(textConfig);

        if (this.state.config && this.state.config.backgroundRect) {
            this.drawingBackground = new Konva.Rect({...state.config.backgroundRect});
            this.updateTextBackground();
        }

        this.drawingShape = new Konva.Group(state.config);
        this.drawingShape.add(this.drawingBackground);
        this.drawingShape.add(this.drawingText);
        this.drawingLayer.add(this.drawingShape);

        this.addEvents();
        this.draw();
    }

    private updateTextBackground() {
        if (this.state.config && this.state.config.backgroundRect) {
            // Calculate the dynamic width and height including the padding
            let dynamicWidth = this.drawingText.getWidth();
            let dynamicHeight = this.drawingText.getHeight();
            if (this.state.config.backgroundRect.padding && this.state.config.backgroundRect.padding.length === 4) {
                dynamicWidth += this.state.config.backgroundRect.padding[1] + this.state.config.backgroundRect.padding[3];
                dynamicHeight += this.state.config.backgroundRect.padding[0] + this.state.config.backgroundRect.padding[2];
                this.drawingBackground.setPosition({
                    x: -this.state.config.backgroundRect.padding[3],
                    y: -this.state.config.backgroundRect.padding[0]
                });
            }

            this.drawingBackground.width( dynamicWidth );
            this.drawingBackground.height( dynamicHeight );
        }
    }

}
