/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getEpsilon as e } from "../../../core/libs/gl-matrix-2/math/common.js";
import { CompareResult as t } from "./Camera.js";
import { defaultSettings as r } from "./Settings.js";
class s {
  constructor(e) {
    this._createCamera = e, this.compared = new t(), this.segmentStart = 0, this.segmentEnd = 1, this.settings = {
      desiredScreenFlow: r.desiredScreenFlow
    }, this.source = e(), this.target = e();
  }
  clone() {
    const e = new s(this._createCamera);
    return e.copyFrom(this), e;
  }
  copyFrom(e) {
    this.update(e.source, e.target, e.settings);
  }
  update(e, t, s) {
    this.source !== e && this.source.copyFrom(e), this.target !== t && this.target.copyFrom(t), this.source.compareTo(this.target, this.compared), this.settings.desiredScreenFlow = null != s.desiredScreenFlow ? s.desiredScreenFlow : r.desiredScreenFlow, this.desiredPixelFlow = this.settings.desiredScreenFlow * this.target.size, this.halfWindowSize = this.target.size / 2;
  }
  halfWindowPanAtZoom(e) {
    const t = this.target.pixelsPerPanAtZoom(e);
    return this.halfWindowSize / t;
  }
  get hasZoom() {
    return Math.abs(this.compared.sourceZoom - this.compared.targetZoom) > 1e-5;
  }
  get hasPan() {
    return this.compared.pan > e();
  }
  get hasFov() {
    return Math.abs(this.compared.fov) > e();
  }
  get hasRotate() {
    return this.compared.rotate > e();
  }
}
export { s as Definition };