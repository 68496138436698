import {Pipe, PipeTransform} from '@angular/core';
import {AnyProjectJobForm} from '../models/project-job-form';
import {DateFormatPipe} from './date-format.pipe';

@Pipe({
    name: 'templatedProjectJobFormValue',
    standalone: true,
    pure: true
})
export class TemplatedProjectJobFormValuePipe implements PipeTransform {
    dateFormat = new DateFormatPipe();

    transform(job: AnyProjectJobForm, template: string): string[] {
        return template.split('\n').map(line => line
            .replace(/%title%/ig, job.title)
            .replace(/%objectOmschrijvingKort%/ig, job.objectOmschrijvingKort)
            .replace(/%objectOmschrijvingLang%/ig, job.objectOmschrijvingLang)
            .replace('%createdAt%', this.dateFormat.transform(job.createdAt, 'dd-MM-yyyy'))
            .replace('%createdBy%', job.createdBy || 'Systeem')
            .replace('%code%', job.code)
        ).filter(line => line.trim() !== '');
    }
}
