import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

export class Forms {
    static updateValueAndValidityRecursive(control: AbstractControl) {
        const queue = [control];

        while (queue.length) {
            const formControl = queue.pop();

            if (!formControl) {
                return;
            }

            formControl.markAsTouched();
            formControl.updateValueAndValidity();

            if (formControl instanceof FormGroup) {
                for (const controlKey in formControl.controls) {
                    if (controlKey in formControl.controls) {
                        queue.push(formControl.controls[controlKey]);
                    }
                }
            } else if (formControl instanceof FormArray) {
                for (const controlKey in formControl.controls) {
                    if (controlKey in formControl.controls) {
                        queue.push(formControl.controls[controlKey]);
                    }
                }
            }
        }
    }

    static firstInvalidIndex(formArray: FormArray): number | null {
        for (const index in formArray.controls) {
            if (formArray.controls[index].invalid) {
                return +index;
            }
        }
        return null;
    }
}
