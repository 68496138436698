import {FormulaField} from '../models/formula-config';

const dependencyRegex = /[a-z]+/gi;

export function buildFormulaEvaluator(
    fields: FormulaField[],
    inputValues: { [key: string]: unknown },
) {
    const rawOutputValues: {[key: string]: unknown} = {};

    const evaluateFormula: (formula: string) => (null | unknown) = (formula: string) => {
        const fieldDependencies = formula.match(dependencyRegex) || [];
        let fieldFormula = formula;

        for (const fieldDependency of fieldDependencies) {
            const fieldDependencyValue = calculateFieldValue(fieldDependency);
            if (fieldDependencyValue === null || fieldDependencyValue === undefined) {
                // If any dep is null or undefined then formula result is null
                return null;
            } else {
                fieldFormula = fieldFormula.replaceAll(fieldDependency, fieldDependencyValue as string);
            }
        }

        try {
            return new Function(`"use strict";return ${fieldFormula}`)();
        } catch (e) {
            console.error(`Failed to evaluate expression "${fieldFormula}"`, e);
        }
    };

    const calculateFieldValue = (fieldName: string) => {
        const field = fields.find(it => it.fieldName === fieldName);
        if (field === undefined) {
            throw new Error(`Unknown field ${fieldName} used in formula`);
        }

        if (fieldName in rawOutputValues) {
            return rawOutputValues[fieldName];
        }

        if (field?.type === 'input') {
            if (fieldName in inputValues && inputValues[fieldName] !== null && inputValues[fieldName] !== '') {
                const inputValue = inputValues[fieldName];
                return Number(inputValue);
            } else {
                return null;
            }
        }

        rawOutputValues[fieldName] = evaluateFormula(field.formula);

        return rawOutputValues[fieldName];
    };

    return evaluateFormula;
}

export function calculateOutputFieldValues(
    fields: FormulaField[],
    inputValues: { [key: string]: number }
): { [key: string]: string | null } {
    const evaluateFormula = buildFormulaEvaluator(fields, inputValues);

    const outputValues: {[key: string]: string | null} = {};
    // Round outputs to specified amount of decimals
    fields.forEach(field => {
        if (field.type === 'output') {
            const outputValue = (evaluateFormula(field.formula) as number | null)?.toFixed(field.totalDecimal)
            outputValues[field.fieldName] = outputValue !== undefined ? outputValue : null;
        }
    });

    return outputValues;
}
