/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import s from "../core/Accessor.js";
import r from "../core/Collection.js";
import { watch as o, initial as t } from "../core/reactiveUtils.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as a } from "../core/accessorSupport/decorators/subclass.js";
let n = class extends s {
  constructor(e) {
    super(e), this.view = null, this.baseLayerViews = new r(), this.referenceLayerViews = new r(), this._loadingHandle = o(() => this.view?.map?.basemap, e => {
      e && e.load().catch(() => {});
    }, t);
  }
  destroy() {
    this._set("view", null), this._loadingHandle && (this._loadingHandle.remove(), this._loadingHandle = null);
    for (const e of this.baseLayerViews) e.destroy();
    this.baseLayerViews.length = 0;
    for (const e of this.referenceLayerViews) e.destroy();
    this.referenceLayerViews.length = 0;
  }
  get suspended() {
    return !this.view || this.view.suspended;
  }
  get updating() {
    if (this.view?.suspended) return !1;
    const e = this.view?.map?.basemap;
    return !!e && !!e.loaded && (this.baseLayerViews.some(e => e.updating) || this.referenceLayerViews.some(e => e.updating));
  }
};
e([i({
  constructOnly: !0
})], n.prototype, "view", void 0), e([i({
  readOnly: !0
})], n.prototype, "baseLayerViews", void 0), e([i({
  readOnly: !0
})], n.prototype, "referenceLayerViews", void 0), e([i({
  readOnly: !0
})], n.prototype, "suspended", null), e([i({
  type: Boolean,
  readOnly: !0
})], n.prototype, "updating", null), n = e([a("esri.views.BasemapView")], n);
export { n as BasemapView };