// @ts-strict-ignore
import Konva from 'konva';
import {Subject} from 'rxjs';
import {DrawComponentsViewportConfig} from './draw-components';

export enum DrawComponentActionType {
    ADD = 'add',
    CHANGE = 'change',
    REMOVE = 'remove',
    VIEWPORT = 'viewport',
    BLUR = 'blur',
}

export enum DrawComponentActionShape {
    ARROW = 'arrow',
    CIRCLE = 'circle',
    ICON = 'icon',
    PENCIL = 'pencil',
    SQUARE = 'square',
    VIEWPORT = 'viewport',
    BLUR = 'blur',
    TEXT = 'text'
}

export interface DrawComponentAction {
    type: DrawComponentActionType;
    shape: DrawComponentActionShape;
    uuid: string;
    state: DrawComponentState;
}

export interface DrawComponentPoint {
    x: number;
    y: number;
}

export interface BlurZone {
    x: number;
    y: number;
    radius: number;
    scale: number;
}

export interface DrawComponentState {
    originPoint?: DrawComponentPoint;
    config?: Konva.ShapeConfig;
    viewportConfig?: DrawComponentsViewportConfig;
    blurZones?: BlurZone[];
}

export abstract class DrawComponent {

    public static UI_SCALING = window.devicePixelRatio || 1;
    public static VIEWPORT_SCALE = Konva.pixelRatio || 1;

    readonly uuid: string;
    readonly shape: DrawComponentActionShape;
    readonly transformer: Konva.Transformer;
    readonly deleteButton: Konva.Group;
    readonly drawingLayer: Konva.Group;
    readonly onAction$: Subject<DrawComponentAction>;
    readonly state: DrawComponentState;
    readonly transformerAnchors = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

    abstract drawingShapeDefaultConfig: Konva.ShapeConfig;
    abstract drawingShape: Konva.Arrow | Konva.Circle | Konva.Image | Konva.Line | Konva.Rect | Konva.Group;

    constructor(
        uuid: string,
        shape: DrawComponentActionShape,
        state: DrawComponentState,
    ) {
        this.uuid = uuid;
        this.shape = shape;
        this.state = state;
        this.transformer = new Konva.Transformer().hide();
        this.deleteButton = new Konva.Group();
        this.drawingLayer = new Konva.Group();
        this.transformer.add(this.deleteButton);
        this.drawingLayer.add(this.transformer);
        this.onAction$ = new Subject();

        this.updateDeleteButton();
        this.updateTransformer();
        this.addDeleteButton();
        this.addEvents();
    }

    getDrawingLayer() {
        return this.drawingLayer;
    }

    onAction() {
        return this.onAction$;
    }

    draw() {
        const {originPoint, config} = this.state;

        this.drawingLayer.setPosition({
            x: originPoint.x,
            y: originPoint.y,
        });
        this.drawingShape.setAttrs(config);
    }

    showTransformTool() {
        this.drawingLayer.draggable(true);
        this.transformer.zIndex(0.9);
        this.drawingShape.zIndex(0);
        this.updateTransformer();
        this.transformer.nodes([this.drawingShape]);
        this.updateDeleteButton();
        this.transformer.show();
    }

    hideTransformTool() {
        this.drawingLayer.draggable(false);
        this.transformer.detach();
        this.transformer.hide();
    }

    emitAction(type: DrawComponentActionType) {
        this.updateState();
        this.onAction$.next({
            type,
            shape: this.shape,
            uuid: this.uuid,
            state: JSON.parse(JSON.stringify(this.state)),
        });
    }

    /**
     * Sets the state without sending update events
     * Useful for replaying and reloading events
     */
    setState(newState: DrawComponentState, ignoredProperties: string[] = []) {
        this.state.originPoint = newState.originPoint;

        for (const key in newState.config) {
            if (typeof newState.config[key] !== 'function' && !ignoredProperties.find((ignoredKey) => ignoredKey === key)) {
                this.state.config[key] = newState.config[key];
            }
        }

        this.state.originPoint = newState.originPoint;
        this.draw();
    }

    updateState() {
        const {originPoint, config} = this.state;

        // Make sure the position of the drawing shape, which is being manipulated by scaling, is appended to the origin position
        // To get the real position to place it on
        originPoint.x = this.drawingLayer.getPosition().x + this.drawingShape.getPosition().x;
        originPoint.y = this.drawingLayer.getPosition().y + this.drawingShape.getPosition().y;
        config.width = this.drawingShape.width();
        config.height = this.drawingShape.height();
        config.scaleX = this.drawingShape.scaleX();
        config.scaleY = this.drawingShape.scaleY();
        config.rotation = this.drawingShape.rotation();

        // The shapes position should not be updated - Only the drawing layers position should
        config.x = 0;
        config.y = 0;
    }

    updateTransformer() {
        this.transformer.enabledAnchors(this.transformerAnchors);
        this.transformer.setAttrs({
            padding: 12,
            borderStroke: '#fff',
            borderStrokeWidth: 2,
            anchorFill: '#fff',
            anchorStroke: '#fff',
            anchorCornerRadius: 6,
            anchorSize: 12,
        });
    }

    protected onChange() {
        this.emitAction(DrawComponentActionType.CHANGE);
    }

    protected addEvents() {
        this.transformer.on('transform', this.onUpdate.bind(this));
        this.transformer.on('transformend', this.onChange.bind(this));
        this.drawingLayer.on('dragmove', this.onUpdate.bind(this));
        this.drawingLayer.on('dragend', this.onChange.bind(this));
        this.drawingLayer.on('click tap', this.onClick.bind(this));
    }

    protected onClick(event: unknown) {
        this.showTransformTool();
    }

    protected onUpdate() {
        this.updateState();
        this.updateDeleteButton();
        this.updateTransformer();
    }

    private addDeleteButton() {
        this.deleteButton
            .add(new Konva.Circle({radius: 12, fill: '#fff'}))
            .add(new Konva.Line({points: [-5, -5, 5, 5], stroke: '#db5353', strokeWidth: 2}))
            .add(new Konva.Line({points: [5, -5, -5, 5], stroke: '#db5353', strokeWidth: 2}))
            .on('click tap', this.onDelete.bind(this));

        this.updateDeleteButton();
    }

    protected updateDeleteButton() {
        this.deleteButton.x(this.transformer.getWidth() + 30);
        this.deleteButton.y(-30);
    }

    private onDelete() {
        this.drawingLayer.destroy();
        this.emitAction(DrawComponentActionType.REMOVE);
    }

}
