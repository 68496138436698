<form [formGroup]="form">
    <div class="question">
        <div class="question-content">
            <div class="question-type with-margin">
                <label for="objectId" class="input-label">ObjectID</label>
                <input type="text" id="objectId"
                       formControlName="objectId">
                <div *ngIf="form.controls.objectId.getError('maxlength') as error" class="text-danger">
                    <small>
                        {{ 'Maximaal' + ' ' + error['requiredLength'] + ' ' + 'karakters' }}
                        ({{ 'huidige invoer' + ': ' + error['actualLength'] }})
                    </small>
                </div>
            </div>
            <div class="question-type with-margin">
                <label for="objectOmschrijvingKort" class="input-label">Korte omschrijving</label>
                <input type="text" id="objectOmschrijvingKort"
                       formControlName="objectOmschrijvingKort">
                <div *ngIf="form.controls.objectOmschrijvingKort.getError('maxlength') as error" class="text-danger">
                    <small>
                        {{ 'Maximaal' + ' ' + error['requiredLength'] + ' ' + 'karakters' }}
                        ({{ 'huidige invoer' + ': ' + error['actualLength'] }})
                    </small>
                </div>
            </div>


            <div class="question-type with-margin">
                <label class="input-label">Objecttype</label>
                <ng-select formControlName="objectType" [items]="_objectTypes" bindLabel="title">
                </ng-select>
            </div>

            <div class="question-type with-margin">
                <label class="input-label">Locatietype object</label>
                <ng-select formControlName="objectLocationType">
                    <ng-option value="Linear">Lineair</ng-option>
                    <ng-option value="Point">Punt</ng-option>
                </ng-select>
            </div>
        </div>
    </div>
</form>
