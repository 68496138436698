<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type">
            <label class="input-label">Datum</label>
            <input type="date" [formControl]="dateGroup.controls.date">

            <ng-container *ngIf="currentQuestion.time">
                <label class="input-label mt-4">Tijd</label>
                <input type="time" [formControl]="dateGroup.controls.time">
            </ng-container>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="control.valid"></app-question-footer>
</form>
