/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { isLayerFromCatalog as t } from "../catalog/catalogUtils.js";
import { getEffectiveLayerCapabilities as o, getEffectiveEditingEnabled as i } from "./layerUtils.js";
function n(t) {
  return "object" == typeof t && null != t && "loaded" in t && !0 === t.loaded && "type" in t;
}
function e(e) {
  return !(!n(e) || !o(e)?.operations?.supportsEditing || "editingEnabled" in e && !i(e) || t(e));
}
export { e as isEditableLayer };