<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>

    <div class="question-type">
        <label class="input-label">Antwoord</label>

        <ng-container *ngIf="selectableRecords$|async as selectableRecords">
            <ng-select bindLabel="name"
                       [items]="selectableRecords.records"
                       [formControl]="recordControl"
                       placeholder="Kies record"
                       class="mb-4"
            ></ng-select>

            <ng-container *ngIf="currentQuestion.config.userCanIgnoreFilter">
                <label class="input-label mb-2">{{'Filter negeren'|translate}}</label>
                <div class="toggle">
                    <input type="checkbox" [formControl]="ignoreFilterControl" />
                </div>
            </ng-container>

            <ng-container *ngIf="selectableRecords.showWarning">
            <span class="text-danger">
                {{ 'Geen antwoord opties omdat de gerefereerde vraag niet correct is ingevuld'|translate }}
            </span>
            </ng-container>
        </ng-container>
    </div>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="isValid"></app-question-footer>
