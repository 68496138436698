// @ts-strict-ignore
import {DrawComponent, DrawComponentActionShape, DrawComponentState} from './draw-component';
import Konva from 'konva';

export class DrawComponentCircle extends DrawComponent {

    static readonly DefaultConfigRadius = 50;

    readonly drawingShapeDefaultConfig = {
        radius: DrawComponentCircle.DefaultConfigRadius,
    };
    readonly drawingShape: Konva.Circle;

    constructor(
        uuid: string,
        state: DrawComponentState,
    ) {
        super(uuid, DrawComponentActionShape.CIRCLE, state);

        this.drawingShape = new Konva.Circle({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);
        this.draw();
    }

    updateState() {
        super.updateState();

        this.state.config.radius = this.drawingShape.radius();
    }

    updateTransformer() {
        super.updateTransformer();

        this.transformer.rotateEnabled( false);
    }

}
