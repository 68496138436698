import {AnnotateComponent, AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {Image as KonvaImage} from 'konva/lib/shapes/Image';
import {AnnotateToolConfiguration} from '../../models/annotate-tool-configuration';

export class AnnotateComponentIcon extends AnnotateComponent {

    readonly drawingShapeDefaultConfig = {};
    readonly drawingShape: KonvaImage | null = null;

    private readonly htmlImage = new Image()
    private readonly imageSource: string;

    constructor(
        uuid: string,
        state: AnnotateComponentState,
        shapeConfig: AnnotateToolConfiguration
    ) {
        super(uuid, AnnotateComponentActionShape.ICON, state);

        this.htmlImage = new Image();
        this.htmlImage.onload = () => {
            this.draw();
        };

        const toolConfig = shapeConfig.tools.find(it => it.identifier === state.config?.iconName);
        if (toolConfig) {
            this.imageSource = toolConfig.icon!;
        } else {
            throw new Error(`Icon not found: ${state.config?.iconName}`);
        }

        this.drawingShape = new KonvaImage({...this.drawingShapeDefaultConfig, ...state.config, image: this.htmlImage});
        this.drawingLayer.add(this.drawingShape);
        this.draw();

        this.updateHtmlImageSrc();
    }

    updateColor(color: string) {
        super.updateColor(color);
        this.updateHtmlImageSrc();
    }

    /**
     * Sets the state without sending update events
     * Useful for replaying and reloading events
     */
    setState(newState: AnnotateComponentState) {
        super.setState(newState, ['image', 'imageSource']);
        this.updateHtmlImageSrc();
    }

    private updateHtmlImageSrc() {
        this.htmlImage.src = AnnotateComponentIcon.replaceIconColor(this.imageSource, this.state.config.iconColor ?? '#fff');
    }

    static replaceIconColor(
        iconDataUri: string,
        foregroundColor: string,
        backgroundColor: string = '#FFF'
    ) {
        const decoded = this.decodeIconUri(iconDataUri);
        const recolored = decoded
            .replaceAll(/#CC00CC|#C0C/g, foregroundColor)
            .replaceAll(/#00CC00|#0C0/g, backgroundColor)

        return this.encodeIconUri(recolored);
    }

    private static decodeIconUri(dataUri: string) {
        const parts = dataUri.split(',');

        if (parts[0].endsWith('base64')) {
            return atob(parts[1]);
        } else {
            // Assume no special encoding
            return decodeURIComponent(parts[1]);
        }
    }

    private static encodeIconUri(svg: string) {
        const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;

        return `data:image/svg+xml,${svg.replace(symbols, encodeURIComponent)}`;
    }
}
