<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type is-choice">
            <label class="input-label mb-5">Antwoord</label>
                <div *ngIf="toleranceMessage$ | async as toleranceMessage" class="vwui-alert is-warning mt-2 mb-2">
                    <app-icon iconName="oval-exclamation"></app-icon>
                    {{ toleranceMessage }}
                </div>
            <div class="flex flex-col gap-6 mb-4">
                <ng-container *ngFor="let choice of currentQuestion.choices">
                    <ng-container [ngSwitch]="currentQuestion.multiple">
                        <label class="checkbox" *ngSwitchCase="true">
                        <span class="checkbox__input">
                            <input
                                type="checkbox"
                                [name]="choice.title"
                                [value]="choice.id"
                                [checked]="isChecked(choice)"
                                (click)="onClick($event)">
                            <app-icon iconName="check" class="checkbox__icon"></app-icon>
                        </span>
                            <span class="checkbox__label">{{ choice.title }}</span>
                        </label>

                        <label class="radio" *ngSwitchDefault>
                        <span class="radio__input">
                            <input
                                type="radio"
                                [value]="choice.id"
                                [checked]="isChecked(choice)"
                                [name]="choice.title"
                                (click)="onClick($event)">
                        </span>
                            <span class="radio__label">{{ choice.title }}</span>
                        </label>
                    </ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="textRemark || imageRemark">
                <div class="vwui-alert is-primary mb-4" *ngIf="requiresTextRemark || requiresImageRemark">
                    {{remarkAlertMessage()}}
                </div>

                <ng-container *ngIf="textRemark">
                    <label class="input-label" for="remarkText">
                        Opmerking <span class="text-danger" *ngIf="requiresTextRemark">*</span>
                    </label>
                    <input id="remarkText" class="mb-4" [formControl]="remarkControls?.controls.remarkText" type="text" [maxLength]="255"/>
                </ng-container>

                <ng-container *ngIf="imageRemark">
                    <label class="input-label">
                        Afbeelding <span class="text-danger" *ngIf="requiresImageRemark">*</span>
                    </label>
                    <app-form-image-control
                        [maxImageCount]="6"
                        [formControl]="remarkControls?.controls.remarkImage"
                        [jobId]="currentForm?.id"
                        [questionPosition]="currentQuestion?.position">
                    </app-form-image-control>
                </ng-container>
            </ng-container>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="control.valid && remarkControls.valid"></app-question-footer>
</form>
