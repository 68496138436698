import {Component, Input} from '@angular/core';
import {QrCodeQuestion} from '../../../models/question/qr-code-question';
import {ToastrService} from 'ngx-toastr';
import {QrCodeScannerModalComponent} from '../../qr-code-scanner-modal/qr-code-scanner-modal.component';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormControl, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {FormUtils} from '../../../utils/form-utils';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {PopupService} from '../../../services/popup.service';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-question-v2-qr-code',
    templateUrl: './question-v2-qr-code.component.html',
})
export class QuestionV2QrCodeComponent {
    private currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: QrCodeQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: QrCodeQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.updateAnswer();
    }

    constructor(
        private toastr: ToastrService,
        private popupService: PopupService) {
    }

    public control = new FormControl({ value: '', disabled: false }, { nonNullable: true });

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value,
            remarkText: null,
            remarkImage: null,
        }
    }

    async scanQrCode() {
        const modal = this.popupService.open(QrCodeScannerModalComponent, {
            data: {
                title: 'Scan QR- of barcode'
            }
        });

        const result = await firstValueFrom(modal.afterClosed);

        if (typeof result === 'string') {
            this.control.setValue(result);
        }
    }

    showToastError() {
        this.toastr.error('Het antwoord van een verplichte vraag mag niet leeg zijn')
    }

    clearAnswer() {
        this.control.setValue('');
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        this.control.setValue(latest?.value ?? '');
    }
}
