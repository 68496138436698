import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AppStateUploader} from '../../services/app-insights/app-state-uploader';
import {ToastrService} from 'ngx-toastr';
import {PopupRef} from '../../helpers/popup-ref';
import {WorkspaceConfigService} from '../../services/workspace-config.service';

@Component({
    selector: 'app-report-bug-modal',
    templateUrl: './report-bug-modal.component.html'
})
export class ReportBugModalComponent {
    loading = false;

    descriptionControl = new FormControl('', {nonNullable: true});

    headerText$ = this.workspaceConfigService.getTextSetting('bugReportHeader', 'Probleem melden');

    constructor(
        private stateUploader: AppStateUploader,
        private toastr: ToastrService,
        private popupRef: PopupRef,
        private workspaceConfigService: WorkspaceConfigService
    ) {
    }

    close() {
        return this.popupRef.close();
    }

    async reportBug() {
        try {
            this.loading = true;
            await this.stateUploader.reportBug(
                this.descriptionControl.value
            );

            this.toastr.success('Probleem is gemeld');
            this.popupRef.close();
        } catch (ex) {
            console.error('Failed to report bug', ex);
            this.toastr.error('Er is iets misgegaan bij het melden van het probleem');
        } finally {
            this.loading = false;
        }
    }
}
