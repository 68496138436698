"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HSV = void 0;
const Factory_1 = require("../Factory");
const Node_1 = require("../Node");
const Validators_1 = require("../Validators");
const HSV = function (imageData) {
  const data = imageData.data,
    nPixels = data.length,
    v = Math.pow(2, this.value()),
    s = Math.pow(2, this.saturation()),
    h = Math.abs(this.hue() + 360) % 360;
  const vsu = v * s * Math.cos(h * Math.PI / 180),
    vsw = v * s * Math.sin(h * Math.PI / 180);
  const rr = 0.299 * v + 0.701 * vsu + 0.167 * vsw,
    rg = 0.587 * v - 0.587 * vsu + 0.33 * vsw,
    rb = 0.114 * v - 0.114 * vsu - 0.497 * vsw;
  const gr = 0.299 * v - 0.299 * vsu - 0.328 * vsw,
    gg = 0.587 * v + 0.413 * vsu + 0.035 * vsw,
    gb = 0.114 * v - 0.114 * vsu + 0.293 * vsw;
  const br = 0.299 * v - 0.3 * vsu + 1.25 * vsw,
    bg = 0.587 * v - 0.586 * vsu - 1.05 * vsw,
    bb = 0.114 * v + 0.886 * vsu - 0.2 * vsw;
  let r, g, b, a;
  for (let i = 0; i < nPixels; i += 4) {
    r = data[i + 0];
    g = data[i + 1];
    b = data[i + 2];
    a = data[i + 3];
    data[i + 0] = rr * r + rg * g + rb * b;
    data[i + 1] = gr * r + gg * g + gb * b;
    data[i + 2] = br * r + bg * g + bb * b;
    data[i + 3] = a;
  }
};
exports.HSV = HSV;
Factory_1.Factory.addGetterSetter(Node_1.Node, 'hue', 0, (0, Validators_1.getNumberValidator)(), Factory_1.Factory.afterSetFilter);
Factory_1.Factory.addGetterSetter(Node_1.Node, 'saturation', 0, (0, Validators_1.getNumberValidator)(), Factory_1.Factory.afterSetFilter);
Factory_1.Factory.addGetterSetter(Node_1.Node, 'value', 0, (0, Validators_1.getNumberValidator)(), Factory_1.Factory.afterSetFilter);