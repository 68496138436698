import {Pipe, PipeTransform} from '@angular/core';
import {Project} from '../models/project';

@Pipe({
    name: 'templatedProjectValue',
    standalone: true,
    pure: true
})
export class TemplatedProjectValuePipe implements PipeTransform {
    transform(project: Project, template: string): string[] {
        return template.split('\n').map(line => line
            .replace(/%name%/ig, project.name)
            .replace(/%code%/ig, project.code)
        ).filter(line => line.trim() !== '');
    }
}
