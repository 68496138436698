<app-pull-to-refresh (refreshEvent)="doRefresh($event)" (loadMoreEvent)="loadMore()">
    <div>
        <div class="searchbar p-3">
            <div class="searchbar__input">
                <div class="searchbar__input-container">
                    <app-icon class="searchbar__icon" iconName="search"></app-icon>
                    <input
                        class="searchbar__text-input"
                        type="search"
                        (keyup)="onSearchChange($event)"
                        [value]="search$.value"
                        [placeholder]="'Zoek formulier'|translate">
                    <button class="searchbar__icon-button" *ngIf="search$.value" (click)="search$.next('')" type="button">
                        <app-icon class="searchbar__icon" iconName="x-mark" [title]="'Leeg zoekveld'|translate"></app-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #loadingTemplate>
        <div class="flex h-full justify-center items-center">
            <app-spinner></app-spinner>
        </div>
    </ng-template>

    <div *ngIf="(projectForm$|async) as projectForms else loadingTemplate">
        <ng-container *ngIf="projectForms.length > 0; else empty">
                <ul class="vwui-list">
                    <ng-container *ngFor="let projectForm of projectForms">
                        <li class="vwui-list-item" (click)="select(projectForm)">
                            <h3 class="vwui-list-item__title">
                                {{ projectForm.title }}
                            </h3>
                            <app-icon class="vwui-list-item__arrow-right" iconName="chevron-right"></app-icon>
                        </li>
                    </ng-container>
                </ul>

                <div class="flex p-6 justify-center items-center" *ngIf="loading">
                    <app-spinner></app-spinner>
                </div>
        </ng-container>
        <ng-template #empty>
            <app-empty-state-message>
                Er zijn geen formulieren
            </app-empty-state-message>
        </ng-template>
    </div>
</app-pull-to-refresh>
