<div class="image-annotation-v2" [class.image-annotation-v2--visible]="visible">
    <div class="image-annotation-v2__overlay" #toolbar>
        <button class="image-annotation-v2__btn image-annotation-v2__btn--left" (click)="close()">
            <app-icon iconName="chevron-left"></app-icon>
        </button>

        <div class="image-annotation-v2__btn-group">
            <button *ngIf="showRemove" class="image-annotation-v2__btn" (click)="removeImage()">
                <app-icon iconName="trash-2"></app-icon>
            </button>
            <button class="image-annotation-v2__btn" (click)="cropImage()">
                <app-icon iconName="crop-sharp"></app-icon>
            </button>
            <button *ifFeature="['enableImageComment']" class="image-annotation-v2__btn" (click)="addingComment()">
                <app-icon class="image-annotation-v2__btn-comment-icon" [iconName]="comment ? 'comment-filled' : 'comment'"></app-icon>
            </button>
            <button class="image-annotation-v2__btn" [ngClass]="{'image-annotation-v2__btn--selected' : privacyBlur}" (click)="toggleBlur()">
                <app-icon iconName="privacy"></app-icon>
            </button>
            <button class="image-annotation-v2__btn" (click)="openTools()" *ngIf="toolConfig.tools.length > 0">
                <app-icon iconName="edit-2"></app-icon>
            </button>
        </div>
        <div class="image-annotation-v2__btn-lower-group">
            <button class="image-annotation-v2__btn--stroke image-annotation-v2__btn" (click)="redo()" *ngIf="canRedo">
                <app-icon iconName="redo"></app-icon>
            </button>
            <button class="image-annotation-v2__btn--stroke image-annotation-v2__btn" (click)="undo()" *ngIf="canUndo">
                <app-icon iconName="undo"></app-icon>
            </button>
        </div>
    </div>
    <div class="image-annotation-v2__container" #container></div>
    <app-modal [visible$]="textfieldVisible$">
        <h1 class="modal-title">
            {{ 'COMPONENTS.IMAGE_ANNOTATION.textfield' | translate}}
        </h1>
        <div class="modal-body">
            <input type="text" [(ngModel)]="givenText" #textComponent/>
            <footer>
                <button class="vwui-btn" (click)="closeTextComponent()">
                    {{ 'COMPONENTS.IMAGE_ANNOTATION.button.cancel' | translate}}
                </button>
                <button class="vwui-btn is-primary" (click)="addTextComponent()">
                    {{ 'COMPONENTS.IMAGE_ANNOTATION.button.add' | translate}}
                </button>
            </footer>
        </div>
    </app-modal>
    <app-image-tools [visible]="toolsVisible" [imageTools]="toolConfig.tools" (choose)="chooseImageTool($event)"
                     (closeTools)="closeTools()"></app-image-tools>
    <app-image-crop [viewportConfig]="cropConfig" [visible]="cropping" (cancel)="cancelCrop()" (save)="saveCroppedImage($event)"></app-image-crop>
    <app-create-comment *ifFeature="['enableImageComment']"  [comment]="comment" (save)="saveComment($event)" [visible]="isAddingComment" (cancel)="closeAddComment()"></app-create-comment>
</div>

