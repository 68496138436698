<div class="p-6">
    <app-popup-header>{{ layerSubject.value?.name|titlecase }} aanmaken</app-popup-header>
    <app-popup-body>
        <div [formGroup]="form" class="space-y-4">
            <div class="space-y-2">
                <label class="text-sm font-medium block w-full">Type</label>

                <ng-container *ngIf="types$ | async as typeState">
                    <ng-select formControlName="type"
                               [placeholder]="'Selecteer type'|translate"
                               [items]="typeState.options"
                               [compareWith]="ngSelectCompareWith"
                               bindLabel="label" bindValue="value"
                               appendTo="body">
                    </ng-select>
                    <ng-container *ngIf="typeState.showWarning">
                    <span class="text-danger">
                        {{ 'Geen antwoord opties omdat de gerefereerde vraag niet correct is ingevuld'|translate }}
                    </span>
                    </ng-container>
                </ng-container>

            </div>

            <div class="space-y-2" *ngIf="ignoreFilterControl.enabled">
                <label class="input-label mb-2">{{'Filter negeren'|translate}}</label>
                <div class="toggle">
                    <input type="checkbox" [formControl]="ignoreFilterControl" />
                </div>
            </div>


            <div class="space-y-2">
                <label class="text-sm font-medium block w-full">{{ layerSubject.value?.name|titlecase }} naam</label>
                <input type="text" class="appearance-none p-4 text-sm rounded border border-neutral-20 focus:border-primary w-full" [class.text-danger]="form.get('name').errors" formControlName="name" placeholder="Voer een naam in..." id="name">
            </div>

            <button class="vwui-btn is-primary" (click)="submit()">
                Opslaan
            </button>
        </div>
    </app-popup-body>
</div>
