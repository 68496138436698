// @ts-strict-ignore
import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {merge, Observable, Subscription} from 'rxjs';
import {Subscriptions} from '../../utils/subscriptions';
import {delay} from 'rxjs/operators';
import {ProjectJobNavItem} from '../../pages/project-jobs/project-jobs.page';

@Component({
    selector: 'app-project-jobs-navbar',
    templateUrl: './project-jobs-navbar.component.html'
})
export class ProjectJobsNavbarComponent implements OnInit, OnDestroy {

    @HostBinding('class') classes = 'navbar';
    @ViewChild('container') container: ElementRef;
    @ViewChild('underline', {static: true}) underline: ElementRef;
    @Input() active$: Observable<ProjectJobNavItem>;
    @Input() projectJobStatuses$: Observable<ProjectJobNavItem[]>;
    @Output() filterChanged = new EventEmitter<ProjectJobNavItem>();

    subscriptions: Subscription[] = [];

    constructor() {
    }

    ngOnInit() {
        this.subscriptions.push(
            merge(
                this.projectJobStatuses$,
                this.active$,
            )
                .pipe(
                    delay(100) // the querySelector will find the wrong element without this delay
                )
                .subscribe(() => {
                    this.updateUnderline();
                })
        );
    }

    @HostListener('window:resize')
    updateUnderline() {
        // Set properties to animate the navbar
        if (this.container && this.underline) {
            const activeElement = (this.container.nativeElement as HTMLElement).querySelector('.active');
            if (activeElement) {
                const clientRect = activeElement.getBoundingClientRect() as DOMRect;
                const underlineStyle = (this.underline.nativeElement as HTMLElement).style;

                underlineStyle.setProperty('--left', clientRect.x + 'px');
                underlineStyle.setProperty('--width', clientRect.width + 'px');
            }
        }
    }

    ngOnDestroy(): void {
        Subscriptions.unsubscribeAll(this.subscriptions);
    }

    selectFilter(filter: ProjectJobNavItem) {
        this.filterChanged.emit(filter);
    }
}
