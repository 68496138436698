/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "./chunks/tslib.es6.js";
import e from "./Color.js";
import o from "./core/Collection.js";
import { referenceSetter as t } from "./core/collectionUtils.js";
import { typeCast as s } from "./core/compilerUtils.js";
import a from "./core/Error.js";
import { JSONSupportMixin as i } from "./core/JSONSupport.js";
import { clone as n } from "./core/lang.js";
import l from "./core/Loadable.js";
import { loadAll as p } from "./core/loadAll.js";
import c from "./core/Logger.js";
import { destroyMaybe as y } from "./core/maybe.js";
import { isAbortError as u, throwIfAborted as d } from "./core/promiseUtils.js";
import { property as m } from "./core/accessorSupport/decorators/property.js";
import { Integer as f } from "./core/accessorSupport/ensureType.js";
import { subclass as h } from "./core/accessorSupport/decorators/subclass.js";
import { writer as g } from "./core/accessorSupport/decorators/writer.js";
import { NavigationConstraint as v } from "./ground/NavigationConstraint.js";
import { transparencyToOpacity as j, opacityToTransparency as w } from "./webdoc/support/opacityUtils.js";
var S;
let C = S = class extends i(l) {
  constructor(r) {
    super(r), this.opacity = 1, this.surfaceColor = null, this.navigationConstraint = null, this.layers = new o();
    const e = r => {
        r.parent && r.parent !== this && "remove" in r.parent && r.parent.remove(r), r.parent = this, "elevation" !== r.type && "base-elevation" !== r.type && c.getLogger(this).error(`Layer '${r.title}, id:${r.id}' of type '${r.type}' is not supported as a ground layer and will therefore be ignored. Only layers of type 'elevation' are supported.`);
      },
      t = r => {
        r.parent = null;
      };
    this.addHandles([this.layers.on("after-add", r => e(r.item)), this.layers.on("after-remove", r => t(r.item))]);
  }
  initialize() {
    this.when().catch(r => {
      u(r) || c.getLogger(this).error("#load()", "Failed to load ground", r);
    }), this.resourceInfo && this.read(this.resourceInfo.data, this.resourceInfo.context);
  }
  destroy() {
    const r = this.layers.removeAll();
    for (const e of r) y(e);
    this.layers.destroy();
  }
  normalizeCtorArgs(r) {
    return r && "resourceInfo" in r && (this._set("resourceInfo", r.resourceInfo), delete (r = {
      ...r
    }).resourceInfo), r;
  }
  set layers(r) {
    this._set("layers", t(r, this._get("layers")));
  }
  writeLayers(r, e, o, t) {
    const i = [];
    r ? (t = {
      ...t,
      layerContainerType: "ground"
    }, r.forEach(r => {
      if ("write" in r) {
        const e = {};
        s(r)().write(e, t) && i.push(e);
      } else t?.messages && t.messages.push(new a("layer:unsupported", `Layers (${r.title}, ${r.id}) of type '${r.declaredClass}' cannot be persisted in the ground`, {
        layer: r
      }));
    }), e.layers = i) : e.layers = i;
  }
  load(r) {
    return this.addResolvingPromise(this._loadFromSource(r)), Promise.resolve(this);
  }
  loadAll() {
    return p(this, r => {
      r(this.layers);
    });
  }
  async queryElevation(r, e) {
    await this.load({
      signal: e?.signal
    });
    const {
      ElevationQuery: o
    } = await import("./layers/support/ElevationQuery.js");
    d(e);
    const t = new o(),
      s = this.layers.filter(L).toArray();
    return t.queryAll(s, r, e);
  }
  async createElevationSampler(r, e) {
    await this.load({
      signal: e?.signal
    });
    const {
      ElevationQuery: o
    } = await import("./layers/support/ElevationQuery.js");
    d(e);
    const t = new o(),
      s = this.layers.filter(L).toArray();
    return t.createSamplerAll(s, r, e);
  }
  clone() {
    const r = {
      opacity: this.opacity,
      surfaceColor: n(this.surfaceColor),
      navigationConstraint: n(this.navigationConstraint),
      layers: this.layers.slice()
    };
    return this.loaded && (r.loadStatus = "loaded"), new S({
      resourceInfo: this.resourceInfo
    }).set(r);
  }
  read(r, e) {
    this.resourceInfo || this._set("resourceInfo", {
      data: r,
      context: e
    }), super.read(r, e);
  }
  _loadFromSource(r) {
    const e = this.resourceInfo;
    return e ? this._loadLayersFromJSON(e.data, e.context, r) : Promise.resolve();
  }
  async _loadLayersFromJSON(r, e, o) {
    const t = e?.origin || "web-scene",
      s = e?.portal || null,
      a = e?.url || null,
      {
        populateOperationalLayers: i
      } = await import("./layers/support/layersCreator.js");
    d(o);
    const n = [];
    if (r.layers && Array.isArray(r.layers)) {
      const e = {
        context: {
          origin: t,
          url: a,
          portal: s,
          layerContainerType: "ground"
        },
        defaultLayerType: "ArcGISTiledElevationServiceLayer"
      };
      n.push(i(this.layers, r.layers, e));
    }
    await Promise.allSettled(n);
  }
};
function I(r) {
  return r && "createElevationSampler" in r;
}
function L(r) {
  return "elevation" === r.type || I(r);
}
r([m({
  json: {
    read: !1
  }
})], C.prototype, "layers", null), r([g("layers")], C.prototype, "writeLayers", null), r([m({
  readOnly: !0
})], C.prototype, "resourceInfo", void 0), r([m({
  type: Number,
  nonNullable: !0,
  range: {
    min: 0,
    max: 1
  },
  json: {
    type: f,
    read: {
      reader: j,
      source: "transparency"
    },
    write: {
      writer: (r, e) => {
        e.transparency = w(r);
      },
      target: "transparency"
    }
  }
})], C.prototype, "opacity", void 0), r([m({
  type: e,
  json: {
    type: [f],
    write: (r, e) => {
      e.surfaceColor = r.toJSON().slice(0, 3);
    }
  }
})], C.prototype, "surfaceColor", void 0), r([m({
  type: v,
  json: {
    write: !0
  }
})], C.prototype, "navigationConstraint", void 0), C = S = r([h("esri.Ground")], C);
const b = C;
export { b as default };