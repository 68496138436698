import {Component, Input, OnDestroy} from '@angular/core';
import {ChoiceQuestion} from '../../../models/question/choice-question';
import {AnyProjectJobForm} from '../../../models/project-job-form';
import {FormUtils} from '../../../utils/form-utils';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectJobAnswerValue} from '../../../models/project-job-answer';
import {QuestionChoice} from '../../../models/question-choice';
import {Subject, Subscription} from 'rxjs';
import {AnyLayeredFormNode} from '../../../models/layered-form-node';
import {controlValueChanges} from '../../../utils/form-control-value';
import {map} from 'rxjs/operators';
import {QuestionTolerance} from '../../../utils/question-tolerance';
import {Forms} from '../../../utils/forms';

@Component({
    selector: 'app-question-v2-choice',
    templateUrl: './question-v2-choice.component.html',
})
export class QuestionV2ChoiceComponent implements OnDestroy {
    public currentForm: AnyProjectJobForm | null = null;
    public currentQuestion: ChoiceQuestion | null = null;
    public currentNode: AnyLayeredFormNode | undefined = undefined;

    public textRemark = false;
    public imageRemark = false;
    public requiresTextRemark = false;
    public requiresImageRemark = false;
    public doGoForward = new Subject<void>();

    @Input({required: true}) set form(form: AnyProjectJobForm) {
        this.currentForm = form;
        this.updateAnswer();
    }
    @Input({required: true}) set node(node: AnyLayeredFormNode | undefined) {
        this.currentNode = node;
        this.updateAnswer();
    }
    @Input({required: true}) set question(question: ChoiceQuestion) {
        this.currentQuestion = question;

        if (this.currentQuestion.required) {
            this.control.addValidators(Validators.required);
        } else {
            this.control.removeValidators(Validators.required);
        }

        this.updateAnswer();
    }

    public control = new FormControl<string[]>([], { nonNullable: true });

    public remarkControls = new FormGroup({
        remarkText: new FormControl<string | null>(null),
        remarkImage: new FormControl<string | null>(null)
    });
    public subscription: Subscription;

    public toleranceMessage$ = controlValueChanges(this.control).pipe(
        map(selectedChoices => {
            if (!this.currentQuestion || !this.currentQuestion.toleranceEnabled) {
                return null;
            }

            const tolerance = QuestionTolerance.validateChoiceQuestionTolerance(this.currentQuestion, selectedChoices);
            if (tolerance.tolerant === false) {
                return tolerance.message
            }

            return null;
        })
    )

    constructor() {
        this.subscription = this.control.valueChanges.subscribe((value) => {
            const remarkChoices = this.currentQuestion?.choices.filter(choice => this.control.value.includes(choice.id) && (choice.textRemark || choice.imageRemark));

            this.textRemark = remarkChoices?.some(choice => choice.textRemark) || false;
            this.imageRemark = remarkChoices?.some(choice => choice.imageRemark) || false;

            this.requiresTextRemark = remarkChoices?.some(choice => choice.textRemarkRequired) || false;
            this.requiresImageRemark = remarkChoices?.some(choice => choice.imageRemarkRequired) || false;

            this.updateRemarkControls();
        });
    }

    get currentValue(): ProjectJobAnswerValue {
        return {
            value: this.control.value.join(','),
            remarkText: this.remarkControls.controls.remarkText.value,
            remarkImage:  this.remarkControls.controls.remarkImage.value,
        }
    }

    isChecked(choice: QuestionChoice) {
        return this.control.value.includes(choice.id);
    }

    onClick(event: Event) {
        if (event.target) {
            const {value} = event.target as HTMLInputElement;

            if (this.control.value.includes(value)) {
                this.control.value.splice(this.control.value.indexOf(value), 1);
            } else if (this.currentQuestion?.multiple) {
                this.control.value.push(value);
            } else {
                this.control.setValue([value]);
            }

            this.control.updateValueAndValidity();
        }
    }

    submit() {
        this.doGoForward.next();
    }

    updateRemarkControls() {
        const { remarkText, remarkImage } = this.remarkControls.controls;

        if (this.requiresTextRemark) {
            remarkText.setValidators(Validators.required);
        } else {
            remarkText.clearValidators();
        }
        if (this.requiresImageRemark) {
            remarkImage.setValidators(Validators.required);
        } else {
            remarkImage.clearValidators();
        }

        Forms.updateValueAndValidityRecursive(this.remarkControls);
    }

    remarkAlertMessage() {
        const choices = this.control.value ?? [];
        let description = choices.length > 1 ? 'Bij de gekozen antwoorden zijn ' : 'Bij het gekozen antwoord is ';
        if (this.requiresTextRemark && this.requiresImageRemark) {
            description += 'een extra opmerking en foto verplicht';
        } else if (this.requiresTextRemark && !this.requiresImageRemark) {
            description += 'een extra opmerking verplicht';
        } else if (this.requiresImageRemark && !this.requiresTextRemark) {
            description += 'een extra foto verplicht';
        }

        return description;
    }

    private updateAnswer() {
        if (!this.currentForm || !this.currentQuestion) {
            return;
        }

        const latest = FormUtils.getLatestAnswer(this.currentForm, this.currentQuestion.position, this.currentNode);
        if (latest) {
            this.control.setValue(latest?.value?.split(',') ?? []);
        } else if (this.currentQuestion.preFillEnabled) {
            this.control.setValue(this.currentQuestion.preFillValue?.split(',') ?? []);
        } else {
            this.control.reset([]);
        }

        if (latest) {
            const remarkText = latest.remarkText;
            this.remarkControls.controls.remarkText.setValue(remarkText);

            const remarkImage = latest.remarkImage;
            this.remarkControls.controls.remarkImage.setValue(remarkImage);
        } else {
            this.remarkControls.reset();
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
