import {AnnotateComponent, AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {Rect} from 'konva/lib/shapes/Rect'

export class AnnotateComponentSquare extends AnnotateComponent {

    readonly drawingShapeDefaultConfig = {
        width: 100,
        height: 100,
    };
    readonly drawingShape: Rect;

    constructor(
        uuid: string,
        state: AnnotateComponentState,
    ) {
        super(uuid, AnnotateComponentActionShape.SQUARE, state);

        this.drawingShape = new Rect({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);
        this.draw();
    }

}
