<div class="popup__header">
    Opdrachten sorteren
</div>

<div class="popup__content">
    <div [class.mb-4]="!last" *ngFor="let option of sortOptions; let last = last">
        <label class="radio">
            <span class="radio__input">
        <input
            type="radio"
            name="sort"
                    [value]="option.value"
                    [formControl]="control">
            </span>
            <span class="radio__label">{{ option.label }}</span>
        </label>
    </div>
</div>

<div class="popup__footer">
    <button class="vwui-btn" (click)="cancel()">
        Annuleren
    </button>
    <button class="vwui-btn is-primary" (click)="saveSort()">
        Ok
    </button>
</div>
