// @ts-strict-ignore
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    ViewChild
} from '@angular/core';
import SignaturePad from 'signature_pad';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-signature-pad',
    templateUrl: './signature-pad.component.html'
})
export class SignaturePadComponent implements AfterViewInit, OnChanges {
    hasSignature = false;
    private signaturePad: SignaturePad;

    @Input() showSignaturePad$ = new BehaviorSubject(false);
    @Input() signatureImageSrc: string;

    @Output() saved = new EventEmitter<string>();
    @Output() remove = new EventEmitter<void>();

    @ViewChild('signaturePadCanvas', { static: true }) signaturePadCanvas: ElementRef;

    @HostListener('window:resize', [])
    onWindowResize() {
        this.setupSignaturePad();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.setupSignaturePad());
    }

    ngOnChanges(): void {
        if (this.signatureImageSrc) {
            this.loadSignature(this.signatureImageSrc);
        } else {
            this.clearSignature();
        }
    }

    clearSignature() {
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
        this.signatureImageSrc = null;
        this.hasSignature = false;
    }

    loadSignature(data: string) {
        this.signaturePad.fromDataURL(data);
        this.hasSignature = true;
    }

    /**
     * Return base64 string from data url after saving
     */
    saveSignature() {
        if (this.hasSignature) {
            const imageData = this.signaturePad.toDataURL('image/jpeg').split(',')[1];
            this.saved.emit(imageData);
        } else {
            this.clearSignature();
            this.remove.emit();
        }

        this.closeModal();
    }

    closeModal() {
        this.showSignaturePad$.next(false);
    }

    private setupSignaturePad() {
        const canvas = this.signaturePadCanvas.nativeElement as HTMLCanvasElement;
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        if (this.signaturePad) {
            const signatureData = this.signaturePad.toData();

            this.signaturePad.clear();
            this.signaturePad.fromData(signatureData);
        } else {
            this.signaturePad = new SignaturePad(canvas, {
                backgroundColor: 'rgb(255,255,255)'
            });
            this.signaturePad.addEventListener('beginStroke', () => {
                setTimeout(() => this.hasSignature = true);
            })
        }
    }
}
