<ng-container *ngIf="question$ | async as question;">
    <!-- switch question type -->
    <ng-container [ngSwitch]="question.type">
        <app-question-v2-text *ngSwitchCase="'text'" [question]="textQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-text>
        <app-question-v2-number *ngSwitchCase="'number'" [question]="numberQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-number>
        <app-question-v2-tabular *ngSwitchCase="'tabular'" [question]="tabularQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-tabular>
        <app-question-v2-table *ngSwitchCase="'table'" [question]="tableQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-table>
        <app-question-v2-table *ngSwitchCase="'list'" [question]="tableQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-table>
        <app-question-v2-formula *ngSwitchCase="'formula'" [question]="formulaQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-formula>
        <app-question-v2-signature *ngSwitchCase="'signature'" [question]="signatureQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-signature>
        <app-question-v2-datetime *ngSwitchCase="'date'" [question]="dateQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-datetime>
        <app-question-v2-object *ngSwitchCase="'object'" [question]="objectQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-object>
        <app-question-v2-qr-code *ngSwitchCase="'qrCode'" [question]="qrCodeQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-qr-code>
        <app-question-v2-photo *ngSwitchCase="'photo'" [question]="photoQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-photo>
        <app-question-v2-railstraight *ngSwitchCase="'railStraight'" [question]="railStraightQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-railstraight>
        <app-question-v2-database *ngSwitchCase="'database'" [question]="databaseQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-database>
        <app-question-v2-choice-matrix *ngSwitchCase="'choiceMatrix'" [question]="choiceMatrixQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-choice-matrix>
        <app-question-v2-reference-image *ngSwitchCase="'referenceImage'" [question]="referenceImageQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-reference-image>
        <app-question-v2-choice *ngSwitchCase="'choice'" [question]="choiceQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-choice>
        <app-question-v2-location *ngSwitchCase="'location'" [question]="locationQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-location>
        <app-question-v2-bundled *ngSwitchCase="'bundled'" [question]="bundledQuestion(question)" [form]="form$ | async" [node]="node$ | async"></app-question-v2-bundled>
        <div *ngSwitchDefault>Unknown question type: {{ question.type }}</div>
    </ng-container>
</ng-container>

<ng-container *ngIf="isLocationQuestion$ | async as question;">
    <app-question-v2-job-location></app-question-v2-job-location>
</ng-container>
