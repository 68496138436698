import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {SearchComponent} from './search/search.component';
import {IconComponent} from './icon/icon.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuestionDescriptionComponent} from './question-description/question-description.component';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';
import {ModalComponent} from './modal/modal.component';
import {AccordionGroupComponent} from './accordion-group/accordion-group.component';
import {AccordionListItemComponent} from './accordion-list-item/accordion-list-item.component';
import {ProjectJobsNavbarComponent} from './project-jobs-navbar/project-jobs-navbar.component';
import {ProjectJobsListComponent} from './project-jobs-list/project-jobs-list.component';
import {DateFormatPipe} from '../pipes/date-format.pipe';
import {RouterModule} from '@angular/router';
import {EmptyStateMessageComponent} from './empty-state-message/empty-state-message.component';
import {ImageThumbnailComponent} from './image-thumbnail/image-thumbnail.component';
import {SignaturePadComponent} from './signature-pad/signature-pad.component';
import {FloatingButtonComponent} from './floating-button/floating-button.component';
import {ProjectFormsListComponent} from './project-forms-list/project-forms-list.component';
import {ObjectListComponent} from './object-list/object-list.component';
import {ObjectListModalComponent} from './object-list-modal/object-list-modal.component';
import {ObjectDetailComponent} from './object-detail/object-detail.component';
import {FormImageControlComponent} from './form-image-control/form-image-control.component';
import {ImageAnnotationComponent} from './image-annotation/image-annotation.component';
import {ImageCropComponent} from './image-crop/image-crop.component';
import {ImageAnnotationV2Component} from './image-annotation-v2/image-annotation-v2.component';
import {LogoutButtonComponent} from './logout-button/logout-button.component';

import {ImageToolsComponent} from './image-tools/image-tools.component';
import {TranslateModule} from '@ngx-translate/core';
import {CreateCommentComponent} from './create-comment/create-comment.component';
import {PositionFormatPipe} from '../pipes/position-format.pipe';
import {MemsafeImgComponent} from './memsafe-img/memsafe-img.component';
import {ProjectJobFormSubmitBtnComponent} from './project-job-form-submit-btn/project-job-form-submit-btn.component';
import {PointMapComponent} from './point-map/point-map.component';
import {ChapterTitlePipe} from '../pipes/chapter-title.pipe';
import {QuestionTitlePipe} from '../pipes/question-title.pipe';
import {ShowToleranceAnswerPipe} from '../pipes/showToleranceAnswer.pipe';
import {RemarkAlertDescriptionPipe} from '../pipes/remarkAlertDescription.pipe';
import {CopyAnswersModalComponent} from './copy-answers-modal/copy-answers-modal.component';
import {AttachmentsOverviewMenuComponent} from './attachments-overview-menu/attachments-overview-menu.component';
import {RejectJobModalComponent} from './reject-job-modal/reject-job-modal.component';
import {SortablejsModule} from '../library/ngx-sortablejs/sortablejs.module';
import {QuestionTypeTabularModalComponent} from './question-v2/question-v2-tabular/question-type-tabular-modal.component';
import {FillFormAgainModalComponent} from './fill-form-again-modal/fill-form-again-modal.component';
import {ProjectJobsSearchComponent} from './project-jobs-search/project-jobs-search.component';
import {ProjectHasUnsyncedChangesDirective} from '../directive/project-has-unsynced-changes.directive';
import {JobHasUnsyncedChangesDirective} from '../directive/job-has-unsynced-changes.directive';
import {QrCodeScannerModalComponent} from './qr-code-scanner-modal/qr-code-scanner-modal.component';
import {ProjectJobsObjectSearchComponent} from './project-jobs-object-search/project-jobs-object-search.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ProjectJobFormPdfBtnComponent} from './project-job-form-pdf-btn/project-job-form-pdf-btn.component';
import {FeatureDirective} from '../services/feature-toggle.service';
import {CreatePaulaObjectComponent} from './create-paula-object/create-paula-object.component';
import {TreeLevelComponent} from './tree-level/tree-level.component';
import {StatusIndicatorComponent} from './status-indicator/status-indicator.component';
import {ActionSheetComponent} from './action-sheet/action-sheet.component';
import {ActionSheetButtonComponent} from './action-sheet-button/action-sheet-button.component';
import {PopupComponent} from './popup/popup.component';
import {PopupContentDirective} from '../directive/popup-content.directive';
import {EditLayeredNodePopupComponent} from './edit-layered-node-popup/edit-layered-node-popup.component';
import {PopupHeaderComponent} from './popup-header/popup-header.component';
import {PopupBodyComponent} from './popup-body/popup-body.component';
import {DeleteLayeredNodePopupComponent} from './delete-layered-node-popup/delete-layered-node-popup.component';
import {CreateNodeInLayerPopupComponent} from './create-node-in-layer-popup/create-node-in-layer-popup.component';
import {TreeComponent} from './tree/tree.component';
import {AddNodeComponent} from './add-node/add-node.component';
import {QuestionRouterComponent} from './question-router/question-router.component';
import {QuestionHeaderComponent} from './question-header/question-header.component';
import {QuestionFooterComponent} from './question-footer/question-footer.component';
import {QuestionV2TextComponent} from './question-v2/question-v2-text/question-v2-text.component';
import {QuestionBodyComponent} from './question-body/question-body.component';
import {AnswerOverviewMenuV2Component} from './answer-overview-menu-v2/answer-overview-menu-v2.component';
import {ProjectJobFormSubmitBtnV2Component} from './project-job-form-submit-btn-v2/project-job-form-submit-btn-v2.component';
import {ProjectJobLayeredOverviewComponent} from './project-job-layered-overview/project-job-layered-overview.component';
import {NodeBreadcrumbsComponent} from './node-breadcrumbs/node-breadcrumbs.component';
import {QuestionV2NumberComponent} from './question-v2/question-v2-number/question-v2-number.component';
import {QuestionV2JobLocationComponent} from './question-v2/question-v2-job-location/question-v2-job-location.component';
import {QuestionV2TabularComponent} from './question-v2/question-v2-tabular/question-v2-tabular.component';
import {QuestionV2TableComponent} from './question-v2/question-v2-table/question-v2-table.component';
import {QuestionV2FormulaComponent} from './question-v2/question-v2-formula/question-v2-formula.component';
import {ToleranceMessageComponent} from './tolerance-message/tolerance-message.component';
import {QuestionV2SignatureComponent} from './question-v2/question-v2-signature/question-v2-signature.component';
import {QuestionV2DatetimeComponent} from './question-v2/question-v2-datetime/question-v2-datetime.component';
import {QuestionV2ObjectComponent} from './question-v2/question-v2-object/question-v2-object.component';
import {QuestionV2QrCodeComponent} from './question-v2/question-v2-qr-code/question-v2-qr-code.component';
import {QuestionV2PhotoComponent} from './question-v2/question-v2-photo/question-v2-photo.component';
import {QuestionV2RailstraightComponent} from './question-v2/question-v2-railstraight/question-v2-railstraight.component';
import {QuestionV2DatabaseComponent} from './question-v2/question-v2-database/question-v2-database.component';
import {QuestionV2ChoiceMatrixComponent} from './question-v2/question-v2-choice-matrix/question-v2-choice-matrix.component';
import {QuestionV2ReferenceImageComponent} from './question-v2/question-v2-reference-images/question-v2-reference-image.component';
import {QuestionV2ChoiceComponent} from './question-v2/question-v2-choice/question-v2-choice.component';
import {QuestionV2LocationComponent} from './question-v2/question-v2-location/question-v2-location.component';
import {ReportBugModalComponent} from './report-bug-modal/report-bug-modal.component';
import {NumberInputComponent} from './number-input/number-input.component';
import {InputErrorComponent} from './input-error/input-error.component';
import {PortalModule} from '@angular/cdk/portal';
import {LogoutConfirmationModalComponent} from './logout-confirmation-modal/logout-confirmation-modal.component';
import {ProjectJobSortModalComponent} from './project-job-sort-modal/project-job-sort-modal.component';
import { ViewHtmlAttachmentModalComponent } from './view-html-attachment-modal/view-html-attachment-modal.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PullToRefreshComponent} from './pull-to-refresh/pull-to-refresh.component';
import {RejectInspectionFormModalComponent} from './reject-inspection-form-modal/reject-inspection-form-modal.component';
import {ImageAnnotationV3Component} from './image-annotation-v3/image-annotation-v3.component';
import {ImageAnnotationTextPopupComponent} from './image-annotation-text-popup/image-annotation-text-popup.component';
import {QuestionV2BundledComponent} from './question-v2/question-v2-bundled/question-v2-bundled.component';
import {TemplatedProjectValuePipe} from '../pipes/templated-project-value.pipe';
import {TemplatedProjectJobFormValuePipe} from '../pipes/templated-project-job-value.pipe';

@NgModule({
    declarations: [
        ToolbarComponent,
        SearchComponent,
        IconComponent,
        QuestionDescriptionComponent,
        ImageViewerComponent,
        ModalComponent,
        AccordionGroupComponent,
        AccordionListItemComponent,
        AttachmentsOverviewMenuComponent,
        ProjectJobsNavbarComponent,
        ProjectJobsListComponent,
        DateFormatPipe,
        EmptyStateMessageComponent,
        SignaturePadComponent,
        ImageThumbnailComponent,
        FloatingButtonComponent,
        ProjectFormsListComponent,
        ObjectListComponent,
        ObjectListModalComponent,
        ObjectDetailComponent,
        FormImageControlComponent,
        ImageAnnotationComponent,
        ImageCropComponent,
        ImageAnnotationV2Component,
        ImageAnnotationV3Component,
        MemsafeImgComponent,
        ImageToolsComponent,
        CreateCommentComponent,
        PositionFormatPipe,
        ProjectJobFormSubmitBtnComponent,
        LogoutButtonComponent,
        PointMapComponent,
        ChapterTitlePipe,
        QuestionTitlePipe,
        ShowToleranceAnswerPipe,
        RemarkAlertDescriptionPipe,
        CopyAnswersModalComponent,
        RejectJobModalComponent,
        ProjectJobsSearchComponent,
        ProjectJobsObjectSearchComponent,
        QuestionTypeTabularModalComponent,
        QrCodeScannerModalComponent,
        FillFormAgainModalComponent,
        ProjectHasUnsyncedChangesDirective,
        JobHasUnsyncedChangesDirective,
        ProjectJobFormPdfBtnComponent,
        FeatureDirective,
        PopupContentDirective,
        CreatePaulaObjectComponent,
        TreeLevelComponent,
        StatusIndicatorComponent,
        ActionSheetComponent,
        ActionSheetButtonComponent,
        PopupComponent,
        EditLayeredNodePopupComponent,
        DeleteLayeredNodePopupComponent,
        AddNodeComponent,
        CreateNodeInLayerPopupComponent,
        TreeComponent,
        QuestionRouterComponent,
        QuestionHeaderComponent,
        QuestionFooterComponent,
        QuestionV2TextComponent,
        QuestionV2TableComponent,
        QuestionV2RailstraightComponent,
        QuestionV2ChoiceMatrixComponent,
        QuestionBodyComponent,
        AnswerOverviewMenuV2Component,
        ProjectJobFormSubmitBtnV2Component,
        ProjectJobLayeredOverviewComponent,
        NodeBreadcrumbsComponent,
        QuestionV2NumberComponent,
        QuestionV2FormulaComponent,
        ToleranceMessageComponent,
        QuestionV2SignatureComponent,
        QuestionV2DatetimeComponent,
        QuestionV2ObjectComponent,
        QuestionV2JobLocationComponent,
        QuestionV2TabularComponent,
        QuestionV2QrCodeComponent,
        QuestionV2PhotoComponent,
        QuestionV2DatabaseComponent,
        QuestionV2SignatureComponent,
        QuestionV2ReferenceImageComponent,
        QuestionV2ChoiceComponent,
        QuestionV2LocationComponent,
        ReportBugModalComponent,
        NumberInputComponent,
        InputErrorComponent,
        LogoutConfirmationModalComponent,
        ProjectJobSortModalComponent,
        ViewHtmlAttachmentModalComponent,
        RejectInspectionFormModalComponent,
        ImageAnnotationTextPopupComponent,
        QuestionV2BundledComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        SortablejsModule,
        NgSelectModule,
        NgOptimizedImage,
        PortalModule,
        SpinnerComponent,
        ScrollingModule,
        OverlayModule,
        PullToRefreshComponent,
        TemplatedProjectValuePipe,
        TemplatedProjectJobFormValuePipe,
        PopupHeaderComponent,
        PopupBodyComponent
    ],
    exports: [
        ToolbarComponent,
        SearchComponent,
        IconComponent,
        QuestionDescriptionComponent,
        ImageViewerComponent,
        ModalComponent,
        AccordionGroupComponent,
        AccordionListItemComponent,
        AttachmentsOverviewMenuComponent,
        ProjectJobsNavbarComponent,
        ProjectJobsListComponent,
        FloatingButtonComponent,
        ProjectFormsListComponent,
        ObjectListComponent,
        FormImageControlComponent,
        ImageAnnotationV2Component,
        ImageAnnotationV3Component,
        ProjectJobFormSubmitBtnComponent,
        ProjectJobFormPdfBtnComponent,
        LogoutButtonComponent,
        MemsafeImgComponent,
        PointMapComponent,
        RejectJobModalComponent,
        ProjectJobsSearchComponent,
        ProjectHasUnsyncedChangesDirective,
        JobHasUnsyncedChangesDirective,
        FeatureDirective,
        PopupContentDirective,
        CreatePaulaObjectComponent,
        EmptyStateMessageComponent,
        TreeLevelComponent,
        StatusIndicatorComponent,
        ActionSheetComponent,
        ActionSheetButtonComponent,
        PopupComponent,
        EditLayeredNodePopupComponent,
        PopupHeaderComponent,
        PopupBodyComponent,
        DeleteLayeredNodePopupComponent,
        AddNodeComponent,
        CreateNodeInLayerPopupComponent,
        TreeComponent,
        QuestionRouterComponent,
        QuestionHeaderComponent,
        QuestionFooterComponent,
        QuestionV2TextComponent,
        QuestionV2TableComponent,
        QuestionBodyComponent,
        AnswerOverviewMenuV2Component,
        ProjectJobFormSubmitBtnV2Component,
        ProjectJobLayeredOverviewComponent,
        NodeBreadcrumbsComponent,
        QuestionV2JobLocationComponent,
        QuestionV2DatetimeComponent,
        QuestionV2SignatureComponent,
        QuestionV2ObjectComponent,
        QuestionV2QrCodeComponent,
        QuestionV2PhotoComponent,
        QuestionV2ReferenceImageComponent,
        QuestionV2ChoiceComponent,
        QuestionV2LocationComponent,
        InputErrorComponent,
        SpinnerComponent,
        PullToRefreshComponent,
        RejectInspectionFormModalComponent,
        ImageAnnotationTextPopupComponent
    ]
})
export class ComponentsModule {
}
