/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import "../../geometry.js";
import i from "../../Viewpoint.js";
import e from "../../core/Accessor.js";
import has from "../../core/has.js";
import { removeMaybe as n } from "../../core/maybe.js";
import { addFrameTask as o } from "../../core/scheduling.js";
import { createScreenPoint as s } from "../../core/screenUtils.js";
import { Milliseconds as a } from "../../core/time.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as h } from "../../core/accessorSupport/decorators/subclass.js";
import { create as m } from "../../core/libs/gl-matrix-2/factories/vec2f64.js";
import p from "../ViewAnimation.js";
import u from "./PaddedViewState.js";
import { easingFunctions as c, parse as d } from "./unitBezier.js";
import { copy as l } from "./viewpointUtils.js";
import { Camera as w } from "./pointToPoint/Camera.js";
import { Animation as _ } from "../animation/pointToPoint/Animation.js";
import { defaultSettings2D as g } from "../animation/pointToPoint/Settings.js";
import f from "../../geometry/Point.js";
const v = 2e3,
  j = 64;
class y {
  constructor(t) {
    this._view = t, this._animation = new _(() => new w(this._view)), this._current = new w(this._view);
  }
  get _source() {
    return this._animation.definition.source;
  }
  get _target() {
    return this._animation.definition.target;
  }
  get duration() {
    return this._animation.time;
  }
  get animation() {
    return this._animation;
  }
  update(t, i, e = {}) {
    l(this._current.viewpoint, t), l(this._source.viewpoint, t), l(this._target.viewpoint, i), this._animation.update(this._source, this._target, e);
  }
  applyRatio(t, i) {
    this._animation.cameraAt(i, this._current), l(t, this._current.viewpoint);
  }
}
let T = class extends e {
  constructor(t) {
    super(t), this._animation = null, this._destinationViewState = new u(), this.updateFunction = null, this.easing = c.ease, this.viewpoint = new i({
      targetGeometry: new f(),
      scale: 0,
      rotation: 0
    }), this._updateTask = o({
      postRender: this._postRender.bind(this)
    }), this._updateTask.pause(), this._transition = new y(t.view);
  }
  destroy() {
    this._updateTask = n(this._updateTask);
  }
  get animation() {
    return this._animation;
  }
  set animation(t) {
    this._animation = t, this.view.animation = t;
  }
  animate(t, i, e) {
    this.stop();
    const n = this.viewpoint;
    l(n, i);
    const o = ("string" == typeof e?.easing ? d(e.easing) : e?.easing) || this.easing,
      s = t.target;
    this._transition.update(this.viewpoint, s, {
      apex: {
        maximumDistance: Math.min(Math.min(i.scale, s.scale) * j, this.view.constraints.effectiveMinScale),
        desiredSlope: 5e-8
      },
      duration: e?.duration,
      maxDuration: "auto" === e?.animationMode ? a(1 / 0) : e?.maxDuration ?? g.maxDuration,
      speedFactor: e?.speedFactor,
      easing: "function" == typeof o ? t => o(t) : void 0
    }), "auto" === e?.animationMode && (this._destinationViewState.copy(this.view.state), this._destinationViewState.viewpoint = s, x(this._transition.animation, e, this.view.state, this._destinationViewState) || this._transition.update(this.viewpoint, s, {
      duration: a(0)
    }));
    const r = () => {
      this.animation === t && this._updateTask && ("finished" === t.state && (this._transition.applyRatio(this.viewpoint, 1), this.view.state && (this.view.state.viewpoint = this.viewpoint.clone())), this.animation = null, this.updateFunction = null);
    };
    return t.when(r, r), this._startTime = performance.now(), this._updateTask.resume(), this.animation = t, t;
  }
  animateContinuous(t, i) {
    this.stop(), this.updateFunction = i, this.viewpoint = t;
    const e = new p({
        target: t.clone()
      }),
      n = () => {
        this.animation === e && this._updateTask && (this.animation = null, this.updateFunction = null);
      };
    return e.when(n, n), this._startTime = performance.now(), this._updateTask.resume(), this.animation = e, e;
  }
  stop() {
    this.animation && (this.animation.stop(), this.animation = null, this.updateFunction = null);
  }
  _postRender(t) {
    const i = this.animation;
    if (i && i.state !== p.State.STOPPED) {
      if (this.updateFunction) this.updateFunction(this.viewpoint, t.deltaTime), this.animation?.update(this.viewpoint);else {
        const t = performance.now() - this._startTime,
          i = this._transition.duration,
          e = i > 0 ? t / i : 1,
          n = e >= 1;
        this._transition.applyRatio(this.viewpoint, e), n && this.animation?.finish();
      }
      this.view.state && (this.view.state.viewpoint = this.viewpoint.clone());
    } else this._updateTask.pause();
  }
};
function x(t, i, e, n) {
  if (null != i?.duration) return !0;
  const {
      time: o,
      isLinear: a
    } = t,
    r = i?.speedFactor || 1,
    h = i?.maxDuration ?? g.maxDuration / r;
  if (has("esri-goto-debug") && (console.log("speed factor:", r), console.log("adjusted duration:\t\t", o), console.log("adjusted max duration:\t", h), console.log("is linear:", a)), o > h) return has("esri-goto-debug") && console.warn(`failed "auto" heuristic: animations must be shorter than ${h / 1e3}s`), !1;
  if (a) {
    const t = 1.5,
      i = m(),
      a = s(...n.toScreen(i, ...e.center)),
      p = s(...e.toScreen(i, ...n.center)),
      u = null != p && p.x > -(t - .5) * e.size[0] && p.x < (t + .5) * e.size[0] && p.y > -(t - .5) * e.size[1] && p.y < (t + .5) * e.size[1],
      c = null != a && a.x > -(t - .5) * n.size[0] && a.x < (t + .5) * n.size[0] && a.y > -(t - .5) * n.size[1] && a.y < (t + .5) * n.size[1];
    if (has("esri-goto-debug") && (console.log(`dest is within ${t} screens at start view:`, u), console.log(`start is within ${t} screens at dest view:`, c)), o > v / r && !u && !c) return has("esri-goto-debug") && console.warn(`failed "auto" heuristic: linear animations must be...\n  - shorter than ${v / 1e3 / r}s, or\n  - shorter than ${h / 1e3}s and the destination is close enough to the starting screen, or\n  - shorter than ${h / 1e3}s and the start point is close enough to the destination screen`), !1;
  }
  return !0;
}
t([r()], T.prototype, "easing", void 0), t([r()], T.prototype, "view", void 0), t([r()], T.prototype, "viewpoint", void 0), T = t([h("esri.views.2d.AnimationManager")], T);
const S = T;
export { S as default };