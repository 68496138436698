/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { watch as t } from "../../core/reactiveUtils.js";
import { addFrameTask as e } from "../../core/scheduling.js";
import a from "./PaddedViewState.js";
class s {
  constructor(t) {
    this.view = t, this._stationaryHandle = null, this._frameTaskHandle = null, this._updateParameters = null, this._updateRequested = !1, this.stationary = !0, this.animationInProgress = !1, this.prepare = () => {
      this._updateParameters && (this._updateParameters.state = this.view.state, this._updateParameters.stationary = this.view.stationary, this._updateParameters.pixelRatio = window.devicePixelRatio, this._updateParameters.renderingOptions = this.view.renderingOptions, this._updateParameters.targetState.copy(this.view.state), this.view.animation && !("then" in this.view.animation.target) && (this._updateParameters.targetState.viewpoint = this.view.animation.target));
    }, this.update = () => {
      if (this._updateRequested = !1, this.view?.destroyed) return;
      const {
        allLayerViews: t,
        graphicsView: e,
        labelManager: a,
        state: {
          id: s
        }
      } = this.view;
      t.forEach(this._updateLayerView, this), null != a && (a.lastUpdateId !== s && (a.viewChange(), a.lastUpdateId = s), a.updateRequested && a.processUpdate(this._updateParameters)), null != e && (e.lastUpdateId !== s && (e.viewChange(), e.lastUpdateId = s), e.updateRequested && e.processUpdate(this._updateParameters)), this.view.graphicsTileStore.setViewState(this._updateParameters.state), this.animationInProgress || this._updateRequested || this._frameTaskHandle?.pause();
    };
  }
  destroy() {
    this.stop();
  }
  start() {
    if (this._frameTaskHandle) return;
    const s = this.view;
    this.stationary = s.stationary, this._updateParameters = {
      state: s.state,
      targetState: new a(),
      pixelRatio: window.devicePixelRatio,
      stationary: this.stationary,
      renderingOptions: s.renderingOptions
    }, this._stationaryHandle = t(() => s.stationary, t => {
      this.stationary = t, this.requestFrame();
    }), this._frameTaskHandle = e(this), this.requestUpdate();
  }
  stop() {
    this._frameTaskHandle && (this._updateRequested = !1, this._stationaryHandle?.remove(), this._frameTaskHandle.remove(), this._updateParameters = this._stationaryHandle = this._frameTaskHandle = null, this.stationary = !0, this.animationInProgress = !1);
  }
  requestUpdate() {
    this._updateRequested || (this._updateRequested = !0, this.requestFrame());
  }
  requestFrame() {
    this._frameTaskHandle && this._frameTaskHandle.resume();
  }
  _updateLayerView(t) {
    if (!t.attached) return void this.requestUpdate();
    const e = this.view.state,
      a = t.lastUpdateId;
    null != a && (this.stationary || t.moving) || (t.moving = !0), a !== e.id && t.viewChange(), this.stationary && t.moving && (t.moving = !1, t.moveEnd()), t.lastUpdateId = e.id, t.updateRequested && t.processUpdate(this._updateParameters), "layerViews" in t && t.layerViews?.forEach(this._updateLayerView, this);
  }
}
export { s as default };