import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatestWith, filter, map, switchMap} from 'rxjs/operators';
import {FormService} from '../../services/form.service';
import {Question} from '../../models/question/question';
import {Observable, of, Subscription} from 'rxjs';
import {FormUtils} from '../../utils/form-utils';
import {AnyProjectJobForm, FORM_END_LOCATION_QUESTION_POSITION, FORM_START_LOCATION_QUESTION_POSITION} from '../../models/project-job-form';
import {IndexedLayerNode} from '../../models/layered-form-node';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-question-router',
  templateUrl: './question-router.component.html',
})
export class QuestionRouterComponent implements OnInit, OnDestroy {
    public form$: Observable<AnyProjectJobForm | null> = (() => {
        // Check if parent is set and throw error if not
        const parent = this.route.parent;
        if (parent === null) {
            throw new Error('No parent route found');
        }
        return parent
    })().params
        .pipe(
            switchMap(({ job }) => this.formService.getJob(+job)),
        );

    public node$ = this.route.data
        .pipe(
            map(({ node }) => node as IndexedLayerNode),
        );

    public isLocationQuestion$ = this.route.params
        .pipe(
            filter(({ question }) => !!question),
            map(({ question }) => +question),
            map((question) => (question === FORM_START_LOCATION_QUESTION_POSITION
                || +question === FORM_END_LOCATION_QUESTION_POSITION)),
        );

    public jobWithQuestionAndNode$ = this.form$.pipe(
        combineLatestWith(this.route.params, this.node$),
        map(([job, {question}, node]) => ({job, question, node})),
    );

    public question$: Observable<Question | null> = this.jobWithQuestionAndNode$.pipe(
        filter(({question, job}) => !!question && !!job),
        switchMap(({question, job, node}) => {
            return job === null ? of(null) : this.formService.getQuestionAsObservable(job.id, +question, node);
        }),
    );

    private subscriptions: Subscription[] = [];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private formService: FormService,
                private toastr: ToastrService
    ) {}

    public ngOnInit() {
        const subscription = this.jobWithQuestionAndNode$.subscribe(({ question, job, node }) => {
            if (!question && job) {
                const firstQuestion = FormUtils.determineStartingQuestionPosition(job, node);
                if (firstQuestion === null) {
                    this.toastr.error('Ongeldige opdracht, geen vragen gevonden');
                }

                this.router.navigate(['questions', firstQuestion], { relativeTo: this.route, queryParamsHandling: 'preserve' });
            }
        });

        if (subscription) {
            this.subscriptions.push(subscription);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public textQuestion(value: Question) {
        return value.type === 'text' ? value : null;
    }

    public numberQuestion(value: Question) {
        return value.type === 'number' ? value : null;
    }

    public tabularQuestion(value: Question) {
        return value.type === 'tabular' ? value : null;
    }

    public tableQuestion(value: Question) {
        return (value.type === 'table' || value.type === 'list') ? value : null;
    }

    public databaseQuestion(value: Question) {
        return value.type === 'database' ? value : null;
    }

    public photoQuestion(value: Question) {
        return value.type === 'photo' ? value : null;
    }

    public formulaQuestion(value: Question) {
        return value.type === 'formula' ? value : null;
    }

    public signatureQuestion(value: Question) {
        return value.type === 'signature' ? value : null;
    }

    public dateQuestion(value: Question) {
        return value.type === 'date' ? value : null;
    }

    public objectQuestion(value: Question) {
        return value.type === 'object' ? value : null;
    }

    public qrCodeQuestion(value: Question) {
        return value.type === 'qrCode' ? value : null;
    }

    public railStraightQuestion(value: Question) {
        return value.type === 'railStraight' ? value : null;
    }

    public choiceMatrixQuestion(value: Question) {
        return value.type === 'choiceMatrix' ? value : null;
    }

    public referenceImageQuestion(value: Question) {
        return value.type === 'referenceImage' ? value : null;
    }

    public choiceQuestion(value: Question) {
        return value.type === 'choice' ? value : null;
    }

    public locationQuestion(value: Question) {
        return value.type === 'location' ? value : null;
    }

    bundledQuestion(question: Question) {
        return question.type === 'bundled' ? question : null;
    }
}
