export function convertToNumber(value: string): string {
    const matchResult = value.match(/,[0-9]+$/);

    if (matchResult && matchResult.index) {
        const beforeFraction = value.substring(0, matchResult.index).replace(/[,.]/, '');
        const afterFraction = value.substring(matchResult.index + 1).replace(/[,.]/, '');

        return afterFraction.length > 0 ? Number(`${beforeFraction}.${afterFraction}`).toFixed(afterFraction.length) : beforeFraction;
    }

    return value;
}
