/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
let e,
  n = null;
const t = new Map();
async function i(i) {
  null == n && (null == e && (e = import("../../../layers/Lyr3DWasmPerSceneView.js")), n = await e);
  const l = i.view;
  let a = t.get(l);
  return a || (a = new n.default({
    view: l
  }), t.set(l, a)), await a.initializeWasm(), a.add3DTilesLayerView(i);
}
function l(e) {
  return t.get(e);
}
function a(i) {
  const l = i.view,
    a = t.get(l);
  if (a) {
    a.remove3DTilesLayerView(i) < 1 && (t.delete(l), 0 === t.size && (e = null, n = null));
  }
}
export { i as addLayerViewToWasm, l as getLyr3DWasm, a as removeLayerViewFromWasm };