<div class="popup__header flex items-center justify-center">
    <app-icon iconName="oval-exclamation" class="bg-warning mr-2"></app-icon>
    Formulier verzonden
</div>

<div class="popup__content">
    Let op: Een of meerdere keuringen voldoet niet aan de eisen. Keuringsformulier is afgekeurd.
</div>

<div class="popup__footer">
    <button class="vwui-btn is-primary" (click)="resetForm()">Opnieuw keuren</button>
    <button class="vwui-btn" (click)="closeForm()">Naar overzicht</button>
</div>
