/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import t from "../../core/Accessor.js";
import { ignoreAbortErrors as i } from "../../core/promiseUtils.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
import r from "./ZoomConditions2D.js";
import n from "./ZoomConditions3D.js";
let m = class extends t {
  constructor(o) {
    super(o);
  }
  destroy() {
    this.view = null;
  }
  get canZoomIn() {
    return null != this._zoomConditions && this._zoomConditions.canZoomIn;
  }
  get canZoomOut() {
    return null != this._zoomConditions && this._zoomConditions?.canZoomOut;
  }
  get state() {
    return this.view?.ready ? "ready" : "disabled";
  }
  set view(o) {
    o ? "2d" === o.type ? this._zoomConditions = new r({
      view: o
    }) : "3d" === o.type && (this._zoomConditions = new n({
      view: o
    })) : this._zoomConditions = null, this._set("view", o);
  }
  zoomIn() {
    if (!this.canZoomIn) return;
    const o = this.view;
    "2d" === o.type ? o.mapViewNavigation.zoomIn() : i(o.goTo({
      zoomFactor: 2
    }));
  }
  zoomOut() {
    if (!this.canZoomOut) return;
    const o = this.view;
    "2d" === o.type ? o.mapViewNavigation.zoomOut() : i(o.goTo({
      zoomFactor: .5
    }));
  }
};
o([s()], m.prototype, "_zoomConditions", void 0), o([s()], m.prototype, "canZoomIn", null), o([s()], m.prototype, "canZoomOut", null), o([s({
  readOnly: !0
})], m.prototype, "state", null), o([s()], m.prototype, "view", null), m = o([e("esri.widgets.Zoom.ZoomViewModel")], m);
const p = m;
export { p as default };