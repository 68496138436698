/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { outQuad as i, inQuad as n } from "../../easing.js";
import { Path as e } from "../Path.js";
import { Segment as t } from "../Segment.js";
import { optimalDistance as o } from "./planning.js";
class s extends e {
  constructor(i, n) {
    super(), this._preallocSegments = [new t(), new t(), new t()], this._ascensionSegment = null, this._descensionSegment = null, this.update(i, n);
  }
  update(i, n) {
    if (!i) return;
    this.definition ? this.definition.copyFrom(i) : this.definition = i.clone();
    const e = n?.apex ? o(i, n.apex) : null;
    this.segments.length = 0, this._ascensionSegment = null, this._descensionSegment = null, e ? this._updateWithApex(e, n?.apex) : this._updateWithoutApex();
  }
  segmentInterpolateComponentsAt(e, t, o) {
    e.interpolateComponentsAt(t, o), e === this._ascensionSegment ? o.zoom = i(o.zoom) : e === this._descensionSegment && (o.zoom = n(o.zoom));
  }
  _updateWithApex(i, n) {
    const [e, t, o] = this._preallocSegments,
      s = n?.ascensionFactor ?? .5,
      d = Math.min(1 - s, null != n?.ascensionFactor && null != n.descensionFactor ? n.descensionFactor : .5),
      a = 1 - s - d;
    e.definition ? e.definition.copyFrom(this.definition) : e.definition = this.definition.clone(), e.definition.compared.targetZoom = i, e.definition.compared.pan = this.definition.compared.pan * s, e.definition.compared.rotate = this.definition.compared.rotate * s, e.definition.segmentEnd = s, e.update(), this._ascensionSegment = e, this.segments.push(e), a > 0 && (t.definition ? t.definition.copyFrom(this.definition) : t.definition = this.definition.clone(), t.definition.copyFrom(this.definition), t.definition.compared.sourceZoom = i, t.definition.compared.targetZoom = i, t.definition.compared.pan = this.definition.compared.pan * a, t.definition.compared.rotate = this.definition.compared.rotate * a, t.definition.segmentStart = e.definition.segmentEnd, t.definition.segmentEnd = e.definition.segmentEnd + a, t.update(), this.segments.push(t)), o.definition ? o.definition.copyFrom(this.definition) : o.definition = this.definition.clone(), o.definition.compared.sourceZoom = i, o.definition.compared.pan = this.definition.compared.pan * d, o.definition.compared.rotate = this.definition.compared.rotate * d, o.definition.segmentStart = s + a, o.update(), this._descensionSegment = o, this.segments.push(o);
  }
  _updateWithoutApex() {
    const [i] = this._preallocSegments;
    i.update(this.definition), this.segments.push(i);
  }
}
export { s as Path };