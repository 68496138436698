import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImageViewerService {
    private visibleSubject = new BehaviorSubject<boolean>(false);
    public isVisible$ = this.visibleSubject.asObservable();

    setVisible(visible: boolean) {
        this.visibleSubject.next(visible);
    }
}
