import {ProjectJobFormChapter} from '../models/project-job-form-chapter';
import {Question} from '../models/question/question';

export class Questions {
    static getChapterNumber(chapter: ProjectJobFormChapter): number {
        return chapter ? chapter.position / 1000 : 0;
    }

    static getChapterPositionForQuestion(questionPosition: number): number | null {
        if (questionPosition < 1000) {
            // Non-chapter questions in layered forms
            return null;
        }
        return Math.floor(questionPosition / 1000) * 1000;
    }

    static getChapterTitle(chapter: ProjectJobFormChapter): string {
        return chapter ? `${Questions.getChapterNumber(chapter)}. ${chapter.title}` : '';
    }

    static getQuestionTitle(question: Question): string {
        // Assume question numbers below 1000 are non-chapter questions (layered forms)

        const chapterNumber = Questions.getChapterPositionForQuestion(question.position);
        const questionNumber = chapterNumber === null ? question.position : question.position - chapterNumber;
        const questionPosition = chapterNumber === null ? `${questionNumber}` : `${chapterNumber/1000}.${questionNumber}`;

        return `${questionPosition} ${question.title}`.trim();
    }
}
