// @ts-strict-ignore
import {DrawComponentActionShape, DrawComponentState} from './draw-component';
import Konva from 'konva';
import {PathDrawComponent} from './path-draw-component';

export class DrawComponentPencil extends PathDrawComponent {

    readonly transformerAnchors = ['bottom-right'];
    readonly drawingShapeDefaultConfig = {
        hitStrokeWidth: 30,
        points: [0, 0, 150, 0],
    };
    readonly drawingShape: Konva.Line;

    constructor(
        uuid: string,
        state: DrawComponentState,
    ) {
        super(uuid, DrawComponentActionShape.PENCIL, state);

        this.drawingShape = new Konva.Line({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);

        this.addDraggablePoints();
        this.draw();
    }

    updateState() {
        super.updateState();

        this.state.config.points = this.drawingShape.points();
    }

}
