<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
    ></app-question-description>

    <ng-container *ifFeature="['showSummaryOnSignature']">
        <ng-container *ngIf="answeredQuestionsForSummary$ | async as answeredQuestionsForSummary">
            <div *ngIf="answeredQuestionsForSummary.length > 0">
                <p class="summary-description">
                    {{ 'COMPONENTS.QUESTION_TYPE_SIGNATURE.summary.description' | translate }}
                </p>
                <h5>{{ 'COMPONENTS.QUESTION_TYPE_SIGNATURE.summary.answers' | translate }}</h5>
                <div class="summary">
                    <div class="summary-question" *ngFor="let item of answeredQuestionsForSummary">
                        <div class="summary-question__title">
                            {{ item.question.position|positionFormat }}
                            {{ item.question.title }}
                            <span class="text-danger" *ngIf="item.question?.required">*</span>
                        </div>
                        <div class="summary-question__answer" [ngSwitch]="item.question.type">
                            <ng-container *ngSwitchCase="'photo'">
                                <div class="image-grid">
                                    <div *ngFor="let uuid of splitUuids(item.answer.value)">
                                        <app-image-thumbnail class="image-grid__image" [uuid]="uuid"
                                                             [jobId]="currentForm?.id"
                                                             [questionPosition]="currentQuestion?.position">
                                        </app-image-thumbnail>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'signature'">
                                <div class="image-grid">
                                    <app-image-thumbnail class="image-grid__image" [uuid]="item.answer.value"
                                                         [jobId]="currentForm?.id"
                                                         [questionPosition]="currentQuestion?.position">
                                    </app-image-thumbnail>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                {{ item.answer.value | dateFormat: (isDateQuestion(item.question)?.time ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy') }}
                            </ng-container>
                            <ng-container *ngSwitchCase="'choice'">
                                <div *ngIf="isChoiceQuestion(item.question) as choiceQuestion">
                                    <ul>
                                        <ng-container *ngFor="let choice of choiceQuestion.choices">
                                            <li *ngIf="isQuestionChoiceSelected(item.question, item.answer, choice)">
                                                {{ choice.title }}
                                            </li>
                                        </ng-container>
                                    </ul>
                                    <div *ngIf="item.answer.remarkImage || item.answer.remarkText">
                                        <strong>{{'Opmerking'|translate}}</strong>
                                        <div *ngIf="item.answer.remarkText">
                                            <div class="summary-remarkanswer">{{ item.answer.remarkText }}</div>
                                        </div>
                                        <div *ngIf="item.answer.remarkImage">
                                            <div class="image-grid">
                                                <div *ngFor="let uuid of splitUuids(item.answer.remarkImage)">
                                                    <app-image-thumbnail class="image-grid__image"
                                                                         [uuid]="uuid"
                                                                         [jobId]="currentForm?.id"
                                                                         [questionPosition]="currentQuestion?.position">
                                                    </app-image-thumbnail>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{ item.answer.value}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="signature question-content" (click)="showSignaturePad()">
        <div class="signature__placeholder" *ngIf="!imageSrc">
            <app-icon iconName="add-signature"></app-icon>
            {{ 'COMPONENTS.QUESTION_TYPE_SIGNATURE.summary.addsignature' | translate }}
        </div>
        <app-memsafe-img class="signature__image" *ngIf="imageSrc" [src]="imageSrc"></app-memsafe-img>
        <div class="signature__disabled" *ngIf="disabled"></div>
    </div>

    <app-signature-pad
        [showSignaturePad$]="modalVisible$"
        [signatureImageSrc]="imageSrc"
        (saved)="onSignatureSaved($event)"
        (remove)="onSignatureRemove()"
    ></app-signature-pad>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="control.valid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
