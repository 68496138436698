import {AnnotateComponent, AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {Text} from 'konva/lib/shapes/Text'
import {Rect} from 'konva/lib/shapes/Rect'
import {Group} from 'konva/lib/Group';

export class AnnotateComponentText extends AnnotateComponent {

    readonly drawingShapeDefaultConfig = {
        text: 'Tekst',
    };
    readonly drawingShape: Group = new Group();
    readonly drawingText: Text = new Text();
    readonly drawingBackground: Rect = new Rect();

    constructor(
        uuid: string,
        state: AnnotateComponentState,
    ) {
        super(uuid, AnnotateComponentActionShape.TEXT, state);

        if (state.config && this.drawingBackground) {

            const textConfig = state.config.textConfig ? {
                ...this.drawingShapeDefaultConfig, ...state.config.textConfig
            } : {...this.drawingShapeDefaultConfig};

            this.drawingText = new Text(textConfig);

            if (this.state.config && this.state.config.backgroundRect) {
                this.drawingBackground = new Rect({...state.config.backgroundRect});
                this.updateTextBackground();
            }

            this.drawingShape = new Group(state.config);
            this.drawingShape.add(this.drawingBackground);
            this.drawingShape.add(this.drawingText);
            this.drawingLayer.add(this.drawingShape);

            this.addEvents();
            this.draw();
        }
    }

    private updateTextBackground() {
        if (this.state.config && this.state.config.backgroundRect && this.drawingBackground && this.drawingText) {
            // Calculate the dynamic width and height including the padding
            let dynamicWidth = this.drawingText.getWidth();
            let dynamicHeight = this.drawingText.getHeight();
            if (this.state.config.backgroundRect.padding && this.state.config.backgroundRect.padding.length === 4) {
                dynamicWidth += this.state.config.backgroundRect.padding[1] + this.state.config.backgroundRect.padding[3];
                dynamicHeight += this.state.config.backgroundRect.padding[0] + this.state.config.backgroundRect.padding[2];
                this.drawingBackground.setPosition({
                    x: -this.state.config.backgroundRect.padding[3],
                    y: -this.state.config.backgroundRect.padding[0]
                });
            }

            this.drawingBackground.width(dynamicWidth);
            this.drawingBackground.height(dynamicHeight);
        }
    }

}
