import {AnnotateComponent, AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {Line} from 'konva/lib/shapes/Line';

export class AnnotateComponentLine extends AnnotateComponent {

    readonly drawingShapeDefaultConfig = {
        line: 'Line',
    };
    readonly drawingShape: Line;

    constructor(
        uuid: string,
        state: AnnotateComponentState,
    ) {
        super(uuid, AnnotateComponentActionShape.LINE, state);
        this.drawingShape = new Line({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);
        this.draw();
    }
}
