import {QuestionStatusIndicator} from '../components/status-indicator/status-indicator.component';
import {FormUtils} from './form-utils';
import {QuestionTolerance} from './question-tolerance';
import {AnyLayeredFormNode} from '../models/layered-form-node';
import {LayeredProjectJobForm} from '../models/project-job-form';

export interface NodeStatus {
    self: QuestionStatusIndicator;
    allQuestions: QuestionStatusIndicator;
    questions: {
        position: number;
        title: string;
        status: QuestionStatusIndicator;
    }[];
    questionsValid: boolean;
}

export function determineNodeStatuses(
    nodes: AnyLayeredFormNode[],
    currentForm: LayeredProjectJobForm,
): {[nodeId: string]: NodeStatus} {
    const nodeStatuses: {[key: string]: NodeStatus} = {};

    // Recursive function to determine the status of a node
    const determineNodeStatus = (currentNode: AnyLayeredFormNode): NodeStatus => {
        const questions = FormUtils.questionsForNode(currentForm, currentNode);
        const questionStatuses: NodeStatus['questions'] = []

        let anyQuestionIntolerant = false;
        let anyQuestionRequiredAndNotAnswered = false;
        // Invalid if required and not answered
        // Also invalid if intolerant and user has not provided a new answer
        let anyQuestionInvalid = false;

        for (const question of questions) {
            if (!FormUtils.isQuestionVisible(currentForm, question.position, currentNode)) {
                // Question is not visible, so it does not affect the status of the node
                continue;
            }

            const newestAnswer = FormUtils.getLatestAnswer(currentForm, question.position, currentNode);
            const newestAnswerTolerant = QuestionTolerance.getToleranceMessage(question, newestAnswer?.value || null).tolerant;

            const questionStatus = {
                position: question.position,
                title: question.title,
                status: 'wip' as QuestionStatusIndicator
            }

            if (newestAnswerTolerant === false) {
                questionStatus.status = 'intolerant';
                anyQuestionIntolerant = true;
                if (!newestAnswer || newestAnswer.value == '' || newestAnswer.revision < currentForm.answerRevision) {
                    anyQuestionInvalid = true;
                }
            } else if (newestAnswer !== null) {
                questionStatus.status = 'ok';
            } else {
                questionStatus.status = 'wip';
                // Optional questions are marked as wip but do not make the whole node wip
                if (question.required) {
                    anyQuestionRequiredAndNotAnswered = true;
                    anyQuestionInvalid = true;
                }
            }

            questionStatuses.push(questionStatus);
        }

        const allQuestions = anyQuestionIntolerant
            ? 'intolerant'
            : anyQuestionRequiredAndNotAnswered ? 'wip' : 'ok';

        const children = nodes.filter(potentialChild => potentialChild.parent === currentNode.id);

        const selfStatus: QuestionStatusIndicator = children.map(child => {
            // Get calculated status or calculate it if it has not been calculated yet
            return nodeStatuses[child.id] || determineNodeStatus(child);
        }).reduce((selfStatus, child) => {
            if (child.self === 'intolerant') {
                return 'intolerant';
            } else if (selfStatus === 'ok' && child.self !== 'ok') {
                return child.self;
            } else {
                return selfStatus;
            }
        }, allQuestions);

        return {
            self: selfStatus,
            allQuestions,
            questions: questionStatuses,
            questionsValid: anyQuestionInvalid === false,
        }
    }

    for (const node of nodes) {
        // If the node has not been visited yet, determine its status
        // Node could have been visited before if it is a child of another node
        if (!nodeStatuses[node.id]) {
            nodeStatuses[node.id] = determineNodeStatus(node);
        }
    }

    return nodeStatuses;
}
