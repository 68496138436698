import {
    AnnotateComponent,
    AnnotateComponentAction,
    AnnotateComponentActionShape,
    AnnotateComponentActionType,
    AnnotateComponentState
} from './annotate-component';
import {AnnotateComponentArrow} from './annotate-component-arrow';
import {AnnotateComponentCircle} from './annotate-component-circle';
import {AnnotateComponentIcon} from './annotate-component-icon';
import {AnnotateComponentPencil} from './annotate-component-pencil';
import {AnnotateComponentSquare} from './annotate-component-square';
import {AnnotateComponentText} from './annotate-component-text';
import {Subject, Subscription} from 'rxjs';
import {v4 as uuid, v4 as uuid4} from 'uuid';
import {Node} from 'konva/lib/Node';
import {Layer} from 'konva/lib/Layer';
import {Stage} from 'konva/lib/Stage';
import {ShapeConfig} from 'konva/lib/Shape';
import {CircleConfig} from 'konva/lib/shapes/Circle';
import {Image as KonvaImage} from 'konva/lib/shapes/Image';
import Konva from 'konva';
import KonvaPointerEvent = Konva.KonvaPointerEvent;
import {AnnotateToolConfiguration} from '../../models/annotate-tool-configuration';
import {AnnotateComponentLine} from './annotate-component-line';

export type AnnotateComponentShape
    = AnnotateComponentArrow
    | AnnotateComponentCircle
    | AnnotateComponentIcon
    | AnnotateComponentPencil
    | AnnotateComponentSquare
    | AnnotateComponentText
    | AnnotateComponentLine;

export interface AnnotateComponentsViewportConfig {
    src: string;
    x: number;
    y: number;
    width: number;
    height: number;
    scale: number;
    crop: {
        src?: string;
        x: number;
        y: number;
        width: number;
        height: number;
        scale: number;
    };
}

export interface DrawComponentsSaveState {
    history: AnnotateComponentAction[];
    comment: string;
    src: string;
}

export class PhotoAnnotationEditor {

    readonly container: HTMLDivElement;
    readonly backgroundImage: HTMLImageElement;
    readonly drawingLayer: Layer;
    readonly onChange$: Subject<AnnotateComponentAction>;
    readonly onSelectionChange$ = new Subject<AnnotateComponentShape | null>();
    private viewport: Stage;
    private sourceLayer: Layer | null = null;
    private subscriptions: { id: string, subscription: Subscription }[] = [];
    private pencilComponent: AnnotateComponentPencil | null = null;
    private pencilComponentSubscription: Subscription | null = null;
    private currentShapeStart: { x: number, y: number } | null = null;
    private shapeConfig: AnnotateToolConfiguration;

    public components: AnnotateComponentShape[] = [];
    public selectedUuid: string | null = null;
    public isDrawing = false;
    public currentColor: string | undefined;

    private currentViewportConfig: AnnotateComponentsViewportConfig | null = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private callbackReferences: Record<string, any> = {
        'mousedown touchstart': () => {
        },
        'mousemove touchmove': () => {
        },
        'mouseup touchend': () => {
        }
    };

    constructor(
        container: HTMLDivElement,
        backgroundImage: HTMLImageElement,
        actions: AnnotateComponentAction[],
        shapeConfig: AnnotateToolConfiguration,
        options: {
            selectedUuid?: string | null,
            isDrawing?: boolean,
            pencilColor?: string
        } = {}
    ) {
        this.selectedUuid = options.selectedUuid ?? null;

        this.shapeConfig = shapeConfig;
        this.container = container;
        this.backgroundImage = backgroundImage;
        this.drawingLayer = new Layer();
        this.onChange$ = new Subject();

        this.viewport = new Stage({
            container: this.container,
            width: this.backgroundImage.naturalWidth,
            height: this.backgroundImage.naturalHeight,
        });

        this.initializeViewport().then(() => {
            this.viewport?.on('click tap', this.onClick.bind(this));
            this.executeActions(actions);
        });

        if (options.isDrawing) {
            this.drawLineStart(options.pencilColor ?? '#ff5454');
        }
    }

    async fitContainerInParentElement() {
        await this.nextRenderFrame();

        if (this.currentViewportConfig) {
            const offsetWidth = this.container.offsetWidth;
            const offsetHeight = this.container.offsetHeight;

            if (offsetWidth && offsetHeight) {
                const scale = Math.min(
                    offsetWidth / this.currentViewportConfig.crop.width,
                    offsetHeight / this.currentViewportConfig.crop.height,
                );

                if (this.viewport && this.viewport.scale()?.x !== scale) {
                    AnnotateComponent.VIEWPORT_SCALE = scale;
                    this.viewport.width(this.currentViewportConfig.crop.width * scale);
                    this.viewport.height(this.currentViewportConfig.crop.height * scale);
                    this.viewport.scale({x: scale, y: scale});
                    this.viewport.draw();
                }
            }
        }
    }

    get onChange() {
        return this.onChange$;
    }

    drawLineStart(color: string) {
        this.currentColor = color;

        if (this.viewport) {
            this.viewport.on('mousedown touchstart', this.onTouchStart.bind(this));
            this.viewport.on('mousemove touchmove', this.onTouchMove.bind(this));
            this.viewport.on('mouseup touchend', this.onTouchEnd.bind(this));
        }
    }

    drawLineStop() {
        if (this.viewport) {
            this.viewport.off('mousedown touchstart');
            this.viewport.off('mousemove touchmove');
            this.viewport.off('mouseup touchend');
        }
    }

    addComponent(shape: AnnotateComponentActionShape, config: ShapeConfig) {
        this.drawLineStop();
        // Ensure the added elements are big enough to interact with
        const cropWidth = this.currentViewportConfig?.crop.width;
        const cropHeight = this.currentViewportConfig?.crop.height;

        if (cropWidth && cropHeight) {
            const scaling = this.getDynamicScaling();
            const offsetX = (cropWidth / 100) * 16;
            const offsetY = (cropHeight / 100) * 16;
            const offsetMin = Math.min(offsetX, offsetY);
            const circleRadius = (config as CircleConfig).radius || AnnotateComponentCircle.DefaultConfigRadius;
            const offsetCircleRadius = shape === AnnotateComponentActionShape.CIRCLE && scaling ? (circleRadius * scaling) : 0;

            config.scaleX = config.scaleY = scaling;

            // Get the proper origin of the drawing layer
            // So adding a component will always happen within the viewport
            const transform = this.drawingLayer.getAbsoluteTransform().copy();
            transform.invert();
            const screenOriginPosition = {x: 0, y: 0};
            const drawingOriginPosition = transform.point(screenOriginPosition);

            const component = this.createComponent(shape, {
                originPoint: {
                    x: drawingOriginPosition.x + offsetMin + offsetCircleRadius,
                    y: drawingOriginPosition.y + offsetMin + offsetCircleRadius,
                },
                config,
            });

            this.bindComponent(component);
            if (component.drawingShape) {
                this.onClick({
                    target: component.drawingShape
                });
            }
            component.emitAction(AnnotateComponentActionType.ADD);
        }
    }

    /**
     * Execute a single action without triggering the change event
     * This is used during the initial loading and updating the viewport
     */
    public executeAction(action: AnnotateComponentAction, shouldDraw = false) {
        let component;
        if (action.type === AnnotateComponentActionType.ADD) {
            try {
                // Catch any errors that might occur during the creation of the component,
                // so we don't block the other components from being created
                component = this.createComponent(action.shape, action.state, action.uuid);
                this.bindComponent(component);
            } catch (e) {
                console.error('Failed to create component', e);
            }
        } else if (action.type === AnnotateComponentActionType.REMOVE) {
            component = this.findComponent(action.uuid);
            if (component) {
                this.removeComponent(component);
            }
        } else if (action.type === AnnotateComponentActionType.CHANGE) {
            component = this.findComponent(action.uuid);
            if (component) {
                component.setState(action.state);
            }
        } else if (action.type === AnnotateComponentActionType.VIEWPORT && action.state.viewportConfig) {
            this.updateViewport(action.state.viewportConfig);
        }

        if (shouldDraw) {
            if (component) {
                component.draw();
            }
            this.drawingLayer.draw();
        }
    }

    destroy() {
        if (this.viewport) {
            Object.keys(this.callbackReferences).forEach((eventString) => {
                this.viewport?.off(eventString, this.callbackReferences[eventString]);
            });

            // Safari fix for canvas memory clearing
            this.viewport.width(0);
            this.viewport.height(0);
            this.viewport.clearCache();
            this.viewport.destroy();
        }
    }

    toBlob() {
        return this.viewport.toBlob({
            pixelRatio: Math.max(this.backgroundImage.naturalWidth / this.viewport.width(), 1),
        }) as Promise<Blob>;
    }

    currentColorChanged(color: string) {
        // Update the color of the current selected component
        this.components.find((component) => {
            return component.uuid === this.selectedUuid;
        })?.updateColor(color);
    }

    private onTouchStart(e: KonvaPointerEvent) {
        e.evt.preventDefault(); // prevent page scrolling

        if (!this.drawingLayer) {
            throw new Error('Draw layer not initialized');
        }

        try {
            this.pencilComponent = new AnnotateComponentPencil(uuid(), {
                originPoint: {x: 0, y: 0},
                config: {
                    points: [this.getPointerPosition().x, this.getPointerPosition().y],
                    stroke: this.currentColor,
                    strokeWidth: 5,
                    perfectDrawEnabled: false,
                },
                draggablePoints: false
            });

            this.pencilComponent.drawingShape.name('draw-component');
            this.pencilComponentSubscription?.unsubscribe();
            this.pencilComponentSubscription = this.pencilComponent.onAction().subscribe((action) => {
                this.onChange$.next(action);
            })
            this.components.push(this.pencilComponent);
            this.drawingLayer.add(this.pencilComponent.getDrawingLayer());
            this.pencilComponent.draw();

            this.isDrawing = true;
        } catch (e) {
            console.error('TouchStart failed', e);
        }
    }

    private onTouchMove(e: KonvaPointerEvent) {
        e.evt.preventDefault(); // prevent page scrolling

        if (!this.drawingLayer) {
            throw new Error('Draw layer not initialized');
        }

        if (this.isDrawing) {
            try {
                this.updateShape();
                this.drawingLayer.batchDraw();
            } catch (e) {
                console.error('TouchMove failed', e);
            }
        }
    }

    private onTouchEnd(e: KonvaPointerEvent) {
        e.evt.preventDefault(); // prevent page scrolling

        if (this.isDrawing && this.pencilComponent && this.drawingLayer) {
            this.isDrawing = false;

            this.pencilComponent.emitAction(AnnotateComponentActionType.ADD);
        }
    }

    private updateShape() {
        if (!this.pencilComponent) {
            throw new Error('Current shape start not initialized');
        }

        const {x, y} = this.getPointerPosition();
        const line = this.pencilComponent.drawingShape;
        const [lastX, lastY] = this.pencilComponent.drawingShape.points().slice(-2);

        const threshold = this.currentViewportConfig!.width / 100;

        const distance = Math.sqrt(Math.pow(x - lastX, 2) + Math.pow(y - lastY, 2));
        if (distance > threshold) {
            line.points(line.points().concat([x, y]));
        }
    }

    private getDynamicScaling() {
        if (this.currentViewportConfig) {
            const cropWidth = this.currentViewportConfig.crop.width;
            const cropHeight = this.currentViewportConfig.crop.height;
            const cropRatioX = cropWidth / this.currentViewportConfig.width;
            const cropRatioY = cropHeight / this.currentViewportConfig.height;
            const cropRatio = Math.min(cropRatioX, cropRatioY);

            // Device pixel ratio of 3 seems to be out of scale on Android, so max the scaling at 2
            return Math.min(2, (AnnotateComponent.UI_SCALING / this.currentViewportConfig.crop.scale)) * cropRatio;
        }
    }

    private async initializeViewport() {
        await this.addSourceLayer(this.backgroundImage).then(() => {
            this.viewport?.add(this.drawingLayer);
            this.updateViewport(this.getInitialViewportConfigForImage(this.backgroundImage));
        });
    }

    private async addSourceLayer(image: HTMLImageElement) {
        const sourceLayer = new Layer({
            name: 'source-layer',
            listening: false,
        });
        const imageComponent = new KonvaImage({
            name: 'source-image',
            x: 0,
            y: 0,
            width: image.naturalWidth,
            height: image.naturalHeight,
            image: image,
            perfectDrawEnabled: false,
            listening: false,
            draggable: false,
            opacity: 1,
        });
        sourceLayer.add(imageComponent);
        this.sourceLayer = sourceLayer;
        this.viewport?.add(sourceLayer);
    }

    private getPointerPosition() {
        if (!this.viewport) {
            throw new Error('Stage not initialized');
        }

        const transform = this.viewport.getAbsoluteTransform().copy();

        transform.invert();

        const position = this.viewport.getPointerPosition();

        if (!position) {
            throw new Error('Pointer position not initialized');
        }

        return transform.point(position);
    }

    private updateViewport(viewportConfig: AnnotateComponentsViewportConfig) {
        this.viewport?.width(viewportConfig.crop.width);
        this.viewport?.height(viewportConfig.crop.height);

        if (this.sourceLayer) {
            // Ensure the positioning and scaling is normalized to the cropped area
            this.sourceLayer.setPosition({
                x: viewportConfig.x - viewportConfig.crop.x,
                y: viewportConfig.y - viewportConfig.crop.y,
            });
            this.sourceLayer.scale({
                x: viewportConfig.crop.scale,
                y: viewportConfig.crop.scale
            });
            this.sourceLayer.draw();
        }

        // Ensure the positioning and scaling is normalized to the cropped area
        // So we don't have to deal with calculating back positioning to the original image size
        this.drawingLayer.setPosition({
            x: viewportConfig.x - viewportConfig.crop.x,
            y: viewportConfig.y - viewportConfig.crop.y
        });
        this.drawingLayer.scale({
            x: viewportConfig.crop.scale,
            y: viewportConfig.crop.scale
        });

        this.drawingLayer.draw();

        this.currentViewportConfig = viewportConfig;
    }

    private async executeActions(actions: AnnotateComponentAction[]) {
        actions.forEach((action) => {
            this.executeAction(action);
        });

        await this.nextRenderFrame();

        if (this.selectedUuid) {
            this.updateSelection();
        }

        await this.nextRenderFrame();

        this.drawingLayer.draw();
    }

    private findComponent(uuid: string): AnnotateComponentShape | undefined {
        return this.components.find((component) => {
            return component.uuid === uuid;
        });
    }

    private createComponent(shape: AnnotateComponentActionShape, state: AnnotateComponentState, uuid = uuid4()): AnnotateComponentShape {
        // Clone the state, so we don't update any references accidentally
        const clonedState = JSON.parse(JSON.stringify(state));

        let component;
        switch (shape) {
            case AnnotateComponentActionShape.ARROW:
                component = new AnnotateComponentArrow(uuid, clonedState);
                break;
            case AnnotateComponentActionShape.CIRCLE:
                component = new AnnotateComponentCircle(uuid, clonedState);
                break;
            case AnnotateComponentActionShape.ICON:
                component = new AnnotateComponentIcon(uuid, clonedState, this.shapeConfig);
                break;
            case AnnotateComponentActionShape.PENCIL:
                component = new AnnotateComponentPencil(uuid, clonedState);
                break;
            case AnnotateComponentActionShape.SQUARE:
                component = new AnnotateComponentSquare(uuid, clonedState);
                break;
            case AnnotateComponentActionShape.TEXT:
                component = new AnnotateComponentText(uuid, clonedState);
                break;
            case AnnotateComponentActionShape.LINE:
                component = new AnnotateComponentLine(uuid, clonedState);
                break;
            default:
                throw new Error(`Unsupported tool ${shape}`);
        }

        // Give the main component the name draw component so our click handlers work on combined draw components as well
        // ( Text component consists of two components within a single group )
        if (component.drawingShape) {
            component.drawingShape.name('draw-component');
        }
        return component;
    }

    private removeComponent(component: AnnotateComponent) {
        // Clear the existing subscriptions
        this.subscriptions.forEach((handler) => {
            if (handler.id === component.uuid) {
                handler.subscription.unsubscribe();
            }
        });
        this.subscriptions = this.subscriptions.filter((handler) => {
            return handler.id !== component.uuid;
        });

        component.drawingLayer.remove();
        this.components = this.components.filter((existingComponent) => {
            return existingComponent.uuid !== component.uuid;
        });
    }

    private bindComponent(component: AnnotateComponentShape) {
        this.components.push(component);
        this.drawingLayer.add(component.getDrawingLayer());
        component.draw();
        this.subscriptions.push({
            id: component.uuid,
            subscription: component.onAction().subscribe((action) => {
                if (action.type === AnnotateComponentActionType.REMOVE) {
                    this.removeComponent(component);
                }
                this.drawingLayer.draw();
                this.onChange$.next(action);
            })
        });

        this.drawingLayer.draw();
    }

    private onClick(event: { target: Node }) {
        const target = event.target.name() === 'draw-component' ? event.target : event.target.findAncestor('.draw-component');

        const selectedComponent = this.components.find(component => component.drawingShape === target);
        if (selectedComponent) {
            this.onSelectionChange$.next(selectedComponent);
            this.drawLineStop();
            selectedComponent.drawingLayer.moveToTop();
            selectedComponent.showTransformTool();
            this.selectedUuid = selectedComponent.uuid;

            this.components = this.components.sort((a, b) => {
                return a.drawingLayer.index - b.drawingLayer.index;
            });
        } else {
            this.onSelectionChange$.next(null);
            this.selectedUuid = null;
        }


        this.updateSelection();
    }

    private updateSelection() {
        this.components.forEach((component) => {
            if (component.uuid !== this.selectedUuid) {
                component.hideTransformTool();
            } else {
                component.showTransformTool();
            }
        });

        this.drawingLayer.draw();
    }

    private getInitialViewportConfigForImage(image: HTMLImageElement): AnnotateComponentsViewportConfig {
        return {
            x: 0,
            y: 0,
            width: image.naturalWidth,
            height: image.naturalHeight,
            scale: 1,
            src: '',
            crop: {
                x: 0,
                y: 0,
                width: image.naturalWidth,
                height: image.naturalHeight,
                scale: 1,
            }
        };
    }

    private nextRenderFrame(): Promise<void> {
        return new Promise((resolve) => requestAnimationFrame(() => resolve()));
    }

}
