<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
    ></app-question-description>

    <div *ngIf="(object$|async) as object">
        <app-object-detail [paulaObject]="object"></app-object-detail>
    </div>

    <div class="modal-actions">
        <button type="button" class="vwui-btn is-primary" (click)="openModal()">Kies een object</button>
    </div>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="control.valid"></app-question-footer>
