/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { clock as e } from "../../../core/clock.js";
import { someMap as t } from "../../../core/MapUtils.js";
import { removeMaybe as i } from "../../../core/maybe.js";
import { InputHandler as o } from "../InputHandler.js";
import { DefaultParameters as n, manhattanDistance as s, getPointerId as l } from "./support.js";
class r extends o {
  constructor(t = n.maximumDoubleClickDelay, i = n.maximumDoubleClickDistance, o = n.maximumDoubleTouchDelay, s = n.maximumDoubleTouchDistance, l = e) {
    super(!1), this._maximumDoubleClickDelay = t, this._maximumDoubleClickDistance = i, this._maximumDoubleTouchDelay = o, this._maximumDoubleTouchDistance = s, this._clock = l, this._pointerState = new Map(), this._click = this.registerOutgoing("click"), this._doubleClick = this.registerOutgoing("double-click"), this.registerIncoming("immediate-click", this._handleImmediateClick.bind(this)), this.registerIncoming("pointer-down", this._handlePointerDown.bind(this));
  }
  onUninstall() {
    this._pointerState.forEach(e => e.doubleClickTimer = i(e.doubleClickTimer));
  }
  get hasPendingInputs() {
    return t(this._pointerState, e => null != e.doubleClickTimer);
  }
  _clearDoubleClickTimer(e, t) {
    const o = this._pointerState.get(e);
    o && (o.doubleClickTimer = i(o.doubleClickTimer), t && this._click.emit(o.event.data, void 0, o.event.modifiers), this._pointerState.delete(e), this.refreshHasPendingInputs());
  }
  _doubleClickTimeoutExceeded(e) {
    const t = this._pointerState.get(e);
    1 === t.pointerDownCount && this._click.emit(t.event.data, void 0, t.event.modifiers), t.doubleClickTimer = null, this._pointerState.delete(e), this.refreshHasPendingInputs();
  }
  _handleImmediateClick(e) {
    const t = e.data,
      {
        pointerType: i
      } = t.native,
      o = c(t);
    if (!this._pointerState.has(o)) return void this._startClick(e);
    const n = this._pointerState.get(o),
      {
        data: l,
        modifiers: r
      } = n.event,
      a = "touch" === i ? this._maximumDoubleTouchDistance : this._maximumDoubleClickDistance;
    s(l, t) > a ? (this._clearDoubleClickTimer(o, !0), this._startClick(e)) : (this._clearDoubleClickTimer(o, !1), 2 === n.pointerDownCount && this._doubleClick.emit(l, void 0, r));
  }
  _handlePointerDown(e) {
    const t = l(e.data),
      i = this._pointerState.get(t);
    i && (i.pointerDownCount += 1);
  }
  _startClick(e) {
    const {
        data: t
      } = e,
      {
        native: {
          pointerType: i
        }
      } = t,
      o = l(t),
      n = "touch" === i ? this._maximumDoubleTouchDelay : this._maximumDoubleClickDelay,
      s = this._clock.setTimeout(() => this._doubleClickTimeoutExceeded(o), n),
      r = 1;
    this._pointerState.set(o, {
      event: e,
      doubleClickTimer: s,
      pointerDownCount: r
    }), this.refreshHasPendingInputs();
  }
}
function c(e) {
  const {
    pointerId: t,
    pointerType: i,
    button: o
  } = e.native;
  return "mouse" === i ? `${t}:${o}` : `${i}`;
}
export { r as SingleAndDoubleClick };