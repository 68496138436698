// @ts-strict-ignore
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-create-comment',
    templateUrl: './create-comment.component.html'
})
export class CreateCommentComponent {
    @ViewChild('stageContainer', {static: true}) stageContainer: ElementRef;
    @ViewChild('hostContainer', {static: true}) hostContainer: ElementRef;

    @Input() visible = false;
    @Input() comment: string;
    @Output() cancel = new EventEmitter<void>();
    @Output() save = new EventEmitter<string>();

    commentForm = new FormGroup({
        comment: new FormControl<string>(undefined)
    });

    cancelComment() {
        this.cancel.emit();
    }

    saveComment() {
        this.save.emit(this.commentForm.get('comment').value);
    }
}
