// @ts-strict-ignore
import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DrawComponents, DrawComponentsSaveState, DrawComponentsViewportConfig} from '../../classes/draw/draw-components';
import {ImageToolConfiguration} from '../../models/image-tool-configuration';
import {ImageTool} from '../../models/image-tool';
import {ProjectJobAnswerMeta} from '../../models/project-job-answer-meta';
import {DrawComponentAction, DrawComponentActionShape, DrawComponentActionType} from '../../classes/draw/draw-component';
import {v4 as uuid4} from 'uuid';
import {HistoryManager} from '../../classes/draw/history-manager';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {App} from '@capacitor/app';
import {PluginListenerHandle} from '@capacitor/core/types/definitions';

@Component({
    selector: 'app-image-annotation-v2',
    templateUrl: './image-annotation-v2.component.html',
})
export class ImageAnnotationV2Component implements OnInit, OnDestroy {

    @ViewChild('container', {static: true}) container: ElementRef<HTMLDivElement>;
    @ViewChild('toolbar', {static: true}) toolbar: ElementRef<HTMLDivElement>;
    @ViewChild('textComponent', {static: true}) textField: ElementRef<HTMLInputElement>;

    @Input() set visible(visible) {
        this.setStateOnVisibilityChange(visible);
    }

    get visible() {
        return this._visible;
    }

    @Input() set originalImage(originalImage: HTMLImageElement) {
        const imageChanged = originalImage !== this._originalImage;

        if (imageChanged) {
            this.clearCanvasMemory();
            this.historyManager.clear();
            this.comment = null;
            this._originalImage = originalImage;

            if (this._originalImage) {
                this.editedImage = this._originalImage;
                this.updateViewport();
            }
        }
    }

    get originalImage() {
        return this._originalImage;
    }

    @Input() toolConfig: ImageToolConfiguration;

    @Input() set metaData(projectJobAnswerMeta: ProjectJobAnswerMeta) {
        this._metaData = projectJobAnswerMeta;

        if (projectJobAnswerMeta) {
            this.comment = projectJobAnswerMeta.comment;
        }

        if (this.originalImage) {
            if (this._metaData) {
                this.historyManager.setActions(this._metaData.history);
                //this.lastAmountOfActions = this.historyManager.getCompressedActions().length;
            }
            this.updateViewport();
        }
    }

    get metaData(): ProjectJobAnswerMeta {
        return this._metaData;
    }

    @Input() showRemove = true;

    @Output() cancel = new EventEmitter<string>();
    @Output() remove = new EventEmitter<string>();
    @Output() save = new EventEmitter<DrawComponentsSaveState>();

    toolsVisible = false;
    cropping = false;
    isAddingComment = false;

    cropConfig: DrawComponentsViewportConfig;
    canUndo = false;
    canRedo = false;
    privacyBlur = false;
    editedImage: HTMLImageElement;
    private _originalImage: HTMLImageElement;
    private _metaData: ProjectJobAnswerMeta | undefined;
    private _visible: boolean;
    private drawComponents: DrawComponents;
    private historyManager: HistoryManager;
    //private lastAmountOfActions = 0;
    private subscriptions: Subscription[] = [];

    givenText = '';
    public textfieldVisible$ = new BehaviorSubject(false);
    private chosenTool: ImageTool | null = null;
    public comment: string | null = null;

    private backButtonListener: PluginListenerHandle | undefined;

    constructor() {
        this.historyManager = new HistoryManager();
    }

    ngOnInit(): void {
        this.subscriptions.push(this.historyManager.onChange.pipe(
            debounceTime(500) // Debounce the creation of bitmap filled events to make sure the undo and redo actions stay performant
        ).subscribe(() => {
            this.sendSaveEvent();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        this.restoreNonEditingState();
    }

    chooseImageTool(imageTool: ImageTool) {
        this.chosenTool = imageTool;
        this.drawComponents.setBlurMode(false);
        this.privacyBlur = false;

        // For a text element, we need to open a modal first and focus the text field so our keyboard is opened
        if (imageTool.component === DrawComponentActionShape.TEXT) {
            this.textfieldVisible$.next(true);
            if (this.textField) {
                setTimeout(() => {
                    this.textField.nativeElement.focus();
                }, 100);
            }

            // Any other component does not need further initialisation
        } else {
            this.drawComponents.addComponent(imageTool.component, JSON.parse(JSON.stringify(imageTool.initialisationObject)));
        }
    }

    async close() {
        if (this.historyManager.canUndoAction() || this.historyManager.canRedoAction()) {
            await this.cancel.emit(this.drawComponents.getEditedSrc());
        } else {
            this.cancel.emit();
        }

        this.restoreNonEditingState();
    }

    toggleBlur() {
        this.privacyBlur = !this.privacyBlur;
        this.drawComponents.setBlurMode(this.privacyBlur);
    }

    addingComment() {
        this.isAddingComment = true;
    }

    closeAddComment() {
        this.isAddingComment = false;
    }

    async removeImage() {
        this.remove.emit();
        this.cancel.emit();
    }

    closeTools() {
        this.toolsVisible = false;
    }

    openTools() {
        this.toolsVisible = true;
    }

    async saveCroppedImage(data: DrawComponentsViewportConfig) {
        this.cropping = false;

        this.updateViewport(data);
    }

    async saveComment(data: string) {
        this.comment = data ? data : null;
        this.sendSaveEvent();
        this.closeAddComment();
    }

    addViewportAction(data: DrawComponentsViewportConfig) {
        const cropAction: DrawComponentAction = {
            uuid: uuid4(),
            type: DrawComponentActionType.VIEWPORT,
            shape: DrawComponentActionShape.VIEWPORT,
            state: {
                viewportConfig: {
                    x: data.x,
                    y: data.y,
                    width: data.width,
                    height: data.height,
                    scale: data.scale,
                    src: '',
                    crop: {
                        x: data.crop.x,
                        y: data.crop.y,
                        width: data.crop.width,
                        height: data.crop.height,
                        scale: data.crop.scale
                    }
                }
            }
        };

        this.drawComponents.executeAction(cropAction);
        this.drawComponents.setBlurMode(false);
        this.historyManager.appendAction(cropAction);
    }

    undo() {
        const undoneAction = this.historyManager.undoAction();
        if (undoneAction) {
            this.loadHistory(this.historyManager.getCompressedActions());
        }
    }

    redo() {
        const redoneAction = this.historyManager.redoAction();
        if (redoneAction) {
            if (redoneAction.type === DrawComponentActionType.VIEWPORT) {
                this.loadHistory(this.historyManager.getCompressedActions());
            } else {
                this.drawComponents.executeAction(redoneAction, true);
            }
        }
    }

    cropImage() {
        this.cropping = true;
    }

    cancelCrop() {
        this.cropping = false;
    }

    @HostListener('window:resize')
    onResize() {
        if (this.drawComponents) {
            this.drawComponents.fitContainerInParentElement();
            this.syncToolbarWidth();
        }
    }

    addTextComponent() {
        const configuration = JSON.parse(JSON.stringify(this.chosenTool.initialisationObject));
        if (!configuration.textConfig) {
            configuration.textConfig = {};
        }

        configuration.textConfig.text = this.givenText;
        this.drawComponents.addComponent(this.chosenTool.component, configuration);
        this.closeTextComponent();
    }

    closeTextComponent() {
        this.givenText = '';
        this.textfieldVisible$.next(false);
    }

    private refreshStage() {
        const history = this.metaData && this.metaData.history ? this.metaData.history : [];
        this.loadHistory(history);
    }

    private loadHistory(history: DrawComponentAction[]) {
        // Clear some memory from our previous instance
        // And keep the selected uuid
        let selectedUuid = null;
        if (this.drawComponents) {
            selectedUuid = this.drawComponents.selectedUuid;
            this.clearCanvasMemory();
        }

        this.drawComponents = new DrawComponents(
            this.container.nativeElement,
            this.originalImage,
            history,
            selectedUuid
        );

        let viewportConfig = this.cropConfig;
        history.forEach((action) => {
            if (action.type === DrawComponentActionType.VIEWPORT) {
                viewportConfig = action.state.viewportConfig;
            }
        });
        viewportConfig.src = this.originalImage.src;
        this.cropConfig = viewportConfig;

        this.drawComponents.fitContainerInParentElement();
        this.drawComponents.onChange.subscribe((action) => {
            this.historyManager.appendAction(action);
        });

        this.canUndo = this.historyManager.canUndoAction();
        this.canRedo = this.historyManager.canRedoAction();

        this.syncToolbarWidth();
    }

    private syncToolbarWidth() {
        // Make sure the width of the toolbar is the same as the with for the container
        // So our icons will be overlaying the canvas at all times
        setTimeout(() => {
            this.toolbar.nativeElement.style.width = this.container.nativeElement.offsetWidth + 'px';
        }, 50);
    }

    private updateViewport(newCropData?: DrawComponentsViewportConfig) {
        if (!newCropData) {
            this.cropConfig = {
                x: 0,
                y: 0,
                width: this.originalImage.width,
                height: this.originalImage.height,
                scale: 1,
                src: this.originalImage.src,
                crop: {
                    x: 0,
                    y: 0,
                    width: this.originalImage.width,
                    height: this.originalImage.height,
                    scale: 1,
                }
            };

            this.refreshStage();
        } else {
            this.cropConfig = newCropData;
            this.addViewportAction(newCropData);
            this.loadHistory(this.historyManager.getCompressedActions());
        }
    }

    private clearCanvasMemory() {
        if (this.drawComponents) {
            this.drawComponents.destroy();
            this.drawComponents = null;
        }
    }

    private sendSaveEvent() {
        this.canUndo = this.historyManager.canUndoAction();
        this.canRedo = this.historyManager.canRedoAction();

        this.save.emit({
            history: this.historyManager.getCompressedActions(),
            comment: this.comment,
            src: this.cropConfig && this.cropConfig.src ? this.cropConfig.src : this.originalImage.src,
        });
    }

    private setStateOnVisibilityChange(visible: boolean) {
        this._visible = visible;
        if (visible && this._originalImage) {
            this.refreshStage();
        }

        if (visible && this._metaData) {
            this.comment = this.metaData && this.metaData.comment ? this.metaData.comment : null;
        }

        if (this._visible) {
            this.disableBackButton();
        } else {
            this.reEnableBackButton();
        }
    }

    private disableBackButton() {
        this.backButtonListener = App.addListener('backButton', () => {
            // DO nothing on backbutton click!
        });
    }

    private reEnableBackButton() {
        if (this.backButtonListener) {
            this.backButtonListener.remove();
            this.backButtonListener = undefined;
        }
    }

    private restoreNonEditingState() {
        this.clearCanvasMemory();
        this.reEnableBackButton();
    }
}
