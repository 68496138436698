import {ActivatedRouteSnapshot, ParamMap} from '@angular/router';
import {environment} from '../../environments/environment';

export function paramMapGetNumberOrFail(map: ParamMap | undefined, name: string): number {
    const value = map?.get(name);
    if (value === null || value === undefined) {
        throw new Error(`ParamMap missing required key '${name}'`)
    }
    const numericValue = +value;
    if (isNaN(numericValue)) {
        throw new Error(`Expected numeric value from parammap, got '${value}'`)
    }

    return numericValue
}

export function numberParamInNestedRoute(snapshot: ActivatedRouteSnapshot, name: string): number | null {
    const paramValue = paramInNestedRoute(snapshot, name);
    if (paramValue === null) {
        return null;
    }

    const numericValue = +paramValue;
    if (isNaN(numericValue)) {
        throw new Error(`Expected numeric value from parammap, got '${paramValue}'`)
    }

    return numericValue
}

export function paramInNestedRoute(snapshot: ActivatedRouteSnapshot, name: string): string | null {
    if (snapshot.params[name]) {
        return snapshot.params[name];
    } else {
        const firstChild = snapshot.firstChild;

        if (firstChild) {
            return paramInNestedRoute(firstChild, name);
        } else {
            return null;
        }
    }
}

export function numberParamInNestedRouteOrFail(snapshot: ActivatedRouteSnapshot, name: string): number {
    const value = numberParamInNestedRoute(snapshot, name);
    if (value === null) {
        if (environment.production === false) {
            console.warn('Parameter not found in nested route', snapshot, name);
        }
        throw new Error(`ParamMap missing required key '${name}'`)
    }

    return value
}
