import {
    AnyProjectJobForm,
    FORM_END_LOCATION_QUESTION_POSITION,
    FORM_START_LOCATION_QUESTION_POSITION,
    LayeredProjectJobForm, NODE_FIRST_QUESTION_POSITION,
    ProjectJobForm
} from '../models/project-job-form';
import {Question} from '../models/question/question';
import {ProjectJobAnswer} from '../models/project-job-answer';
import {ProjectJobFormChapter} from '../models/project-job-form-chapter';
import {QuestionChoice} from '../models/question-choice';
import {extractLocationAnswer} from '../models/project-job-form-extra';
import {RailStraightMeasureResult} from '../plugins/rail-straight-plugin';
import {QuestionTolerance} from './question-tolerance';
import {AnyLayeredFormNode, DatabaseLayeredFormNode, SubtypeLayeredFormNode} from '../models/layered-form-node';
import {DatabaseFormLayer} from '../models/database-form-layer';
import {SubtypeFormLayer} from '../models/subtype-form-layer';
import {AnnotatableImage} from '../components/image-annotation-v3/annotatable-image';
import {AnyFormLayer} from '../models/form-layer';

export class FormUtils {
    static mergeForm(upstream: AnyProjectJobForm, local: AnyProjectJobForm) {
        const form: AnyProjectJobForm = {
            ...local,
            ...upstream
        };

        local.answers.forEach(answer => {
            // Add local answer if it doesn't exist upstream
            if (-1 === form.answers.findIndex(it => {
                return it.node?.id === answer.node?.id && it.position === answer.position;
            })) {
                form.answers.push(answer);
            }
        });

        return form;
    }

    static getCurrentChapter(form: AnyProjectJobForm, currentPosition: number | undefined): ProjectJobFormChapter | null {
        if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                if (currentPosition !== undefined && chapter.position === (Math.floor(currentPosition / 1000) * 1000)) {
                    return chapter;
                }
            }
        }

        return null;
    }

    static getFormItemAnswers(answers: ProjectJobAnswer[], position: number, node: AnyLayeredFormNode | undefined) {
        return answers.filter(answer => answer.position === position && answer.node?.id === node?.id);
    }

    static getFormItemLastAnswer(answers: ProjectJobAnswer[], position: number, node: AnyLayeredFormNode | undefined): ProjectJobAnswer | null {
        const matchingAnswers = this.getFormItemAnswers(answers, position, node);

        if (matchingAnswers && matchingAnswers.length) {
            return matchingAnswers.reduce((prev, current) => prev.revision > current.revision ? prev : current);
        }

        return null;
    }

    static getLatestAnswer(form: AnyProjectJobForm, position: number | undefined, node: AnyLayeredFormNode | undefined): ProjectJobAnswer | null {
        const {answers} = form;
        const currentPosition = position;

        const matchingAnswers = answers.filter(answer => answer.position === currentPosition && node?.id === answer.node?.id);

        if (matchingAnswers.length > 0) {
            return matchingAnswers.reduce((prev, current) => prev.revision > current.revision ? prev : current);
        }

        return null;
    }

    static getPreviousAnswer(form: AnyProjectJobForm, position: number | undefined, node?: AnyLayeredFormNode): ProjectJobAnswer | null {
        const {answers} = form;
        const currentPosition = position;

        const matchingAnswers = answers.filter(answer => answer.position === currentPosition
            && answer.revision !== form.answerRevision
            && answer.node?.id === node?.id);

        if (matchingAnswers.length > 0) {
            return matchingAnswers.reduce((prev, current) => prev.revision > current.revision ? prev : current);
        }

        return null;
    }

    static getPreviousPosition(form: AnyProjectJobForm, currentPosition: number, node?: AnyLayeredFormNode): number | null {
        const questions = this.getQuestions(form, node);
        const currentQuestionIndex = questions.findIndex(question => {
            // We don't need to filter on node here because we already did that in getQuestions
            return question.position === currentPosition;
        });

        if (currentQuestionIndex > 0) {
            let questionIndex = currentQuestionIndex - 1;
            while (questionIndex >= 0) {
                const questionPosition = questions[questionIndex].position;
                if (this.isQuestionVisible(form, questionPosition, node)) {
                    return questionPosition;
                }
                questionIndex--;
            }
        } else if (currentQuestionIndex === 0 && form.askLocation) {
            return FORM_START_LOCATION_QUESTION_POSITION;
        } else if (currentPosition === FORM_END_LOCATION_QUESTION_POSITION && questions[questions.length - 1]) {
            return questions[questions.length - 1]?.position
        }

        return null;
    }

    static showLocationOnMap(form: AnyProjectJobForm): boolean {
        return form.showLocationOnMap;
    }

    static isLocationQuestionValid(form: AnyProjectJobForm): boolean {
        if (!form.askLocation) {
            return true;
        }

        const answer = extractLocationAnswer(form.extraFields);

        const fromValid = answer.locationFrom != '' || (answer.gpsLatitudeFrom != null && answer.gpsLongitudeFrom != null);
        const toValid = answer.locationTo != '' || (answer.gpsLatitudeTo != null && answer.gpsLongitudeTo != null)
            || form.objectLocationType === 'Point'; // Point location type only has from location so to is always valid

        return fromValid && toValid;
    }

    static getNextPosition(form: AnyProjectJobForm, current?: number, node?: AnyLayeredFormNode): number | null {
        const currentPosition = current;
        const questions = this.getQuestions(form, node);

        // Index is -1 if current position is undefined or is location question
        const currentQuestionIndex = currentPosition === undefined || currentPosition === FORM_START_LOCATION_QUESTION_POSITION
            ? -1
            : questions.findIndex(question => question.position === currentPosition);

        const successiveQuestions = questions.slice(currentQuestionIndex + 1, questions.length);

        const nextQuestion = successiveQuestions.find(question => this.isQuestionVisible(form, question.position, node));

        if (currentPosition === FORM_END_LOCATION_QUESTION_POSITION) {
            return null;
        } else if (nextQuestion) {
            return nextQuestion.position;
        } else if (!FormUtils.isLocationQuestionValid(form)) {
            return FORM_END_LOCATION_QUESTION_POSITION;
        } else {
            return null;
        }
    }

    static isQuestionVisible(form: AnyProjectJobForm, position: number, node?: AnyLayeredFormNode): boolean {
        const question = this.getQuestionByPosition(form, position, node);

        const chapterPosition = Math.floor(position / 1000) * 1000;
        // Layered forms don't have chapters so chapter is always visible for layered forms
        const chapterVisible = form.type === 'layeredJobForm'
            || (chapterPosition === position ? true : FormUtils.isChapterVisible(form, chapterPosition));
        if (chapterVisible && (!question.questionDependency || question.questionDependency.length === 0)) {
            return true;
        }

        return chapterVisible && -1 !== question.questionDependency.findIndex(choice => {
            return this.isQuestionChoiceSelected(form, choice, node);
        });
    }

    static isChapterVisible(form: ProjectJobForm, position: number): boolean {
        const chapter = this.getChapterByPosition(form, position);

        if (!chapter.questionDependency || chapter.questionDependency.length === 0) {
            return true;
        }

        return -1 !== chapter.questionDependency.findIndex(choice => {
            return this.isQuestionChoiceSelected(form, choice, undefined);
        });
    }

    static getChapterByPosition(form: ProjectJobForm, position: number): ProjectJobFormChapter {
        const chapter = form.chapters
            .find(it => it.position === position);

        if (!chapter) {
            throw new Error(`Couldn't find chapter with position ${position}`);
        }

        return chapter;
    }

    static getQuestionByPosition(form: AnyProjectJobForm, position: number, node?: AnyLayeredFormNode): Question {
        const questions = this.getQuestions(form, node);

        const question = questions
            .find(it => it.position === position);

        if (!question) {
            throw new Error(`Couldn't find question with position ${position}`);
        }

        return question;
    }

    static getQuestionByReference(form: AnyProjectJobForm, reference: string, node: AnyLayeredFormNode | undefined): Question | null {
        const questions = this.getQuestions(form, node);

        const question = questions
            .find(it => it.reference === reference);

        return question || null;
    }

    // TODO: Check other nodes as well in layered form?
    static isQuestionChoiceSelected(form: AnyProjectJobForm, choice: QuestionChoice, node: AnyLayeredFormNode | undefined): boolean {
        if (form.type === 'layeredJobForm' && node) {
            for (const question of this.questionsForNode(form, node)) {
                if (question.type === 'choice' && question.choices.findIndex(it => it.id === choice.id) !== -1) {
                    const lastAnswer = this.getFormItemLastAnswer(form.answers, question.position, node);
                    return !!(lastAnswer && lastAnswer.value && lastAnswer.value.toString().indexOf(choice.id) !== -1);
                }
            }
        } else if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                for (const question of chapter.questions) {
                    if (question.type === 'choice' && question.choices.findIndex(it => it.id === choice.id) !== -1) {
                        const lastAnswer = this.getFormItemLastAnswer(form.answers, question.position, undefined);
                        return !!(lastAnswer && lastAnswer.value && lastAnswer.value.toString().indexOf(choice.id) !== -1);
                    }
                }
            }
        }

        return false;
    }

    static getLatestImageUUIDsForForm(form: AnyProjectJobForm, allFormNodes: AnyLayeredFormNode[]): string[] {
        const imageUUIDs: string[] = [];

        this.addImageUUIDForQuestionImageDescriptions(imageUUIDs, form);

        if (form.type === 'layeredJobForm') {
            for (const node of allFormNodes) {
                for (const question of this.questionsForNode(form, node)) {
                    const answer = this.getFormItemLastAnswer(form.answers, question.position, node);

                    // Add image UUIDs for all available and relevant answer values
                    if (answer) {
                        this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
                    }
                }
            }
        } else if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                for (const question of chapter.questions) {
                    const answer = this.getFormItemLastAnswer(form.answers, question.position, undefined);

                    // Add image UUIDs for all available and relevant answer values
                    if (answer) {
                        this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
                    }
                }
            }
        }

        return imageUUIDs;
    }

    static getAllImageUUIDsForForm(form: AnyProjectJobForm, allFormNodes: AnyLayeredFormNode[]): string[] {
        const imageUUIDs: string[] = [];

        this.addImageUUIDForQuestionImageDescriptions(imageUUIDs, form);

        if (form.type === 'layeredJobForm') {
            for (const node of allFormNodes) {
                for (const question of this.questionsForNode(form, node)) {
                    const questionAnswers = this.getFormItemAnswers(form.answers, question.position, node);

                    // Add image UUIDs for all available and relevant answer values
                    for (const answer of questionAnswers) {
                        this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
                    }
                }
            }
        } else if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                for (const question of chapter.questions) {
                    const questionAnswers = this.getFormItemAnswers(form.answers, question.position, undefined);

                    // Add image UUIDs for all available and relevant answer values
                    for (const answer of questionAnswers) {
                        this.addImageUUIDsForQuestionAndAnswer(imageUUIDs, question, answer);
                    }
                }
            }
        }

        return imageUUIDs;
    }

    private static addImageUUIDForQuestionImageDescriptions(imageUUIDs: string[], form: AnyProjectJobForm) {
        if (form.type === 'layeredJobForm') {
            for (const layer of form.layers) {
                if (layer.type == 'databaseLayer') {
                    for (const question of layer.questions) {
                        if (question.descriptionType === 'image' && question.imageDescription !== null) {
                            imageUUIDs.push(question.imageDescription);
                        }
                    }
                } else if (layer.type === 'subtypeLayer') {
                    for (const subtype of layer.subtypes) {
                        for (const question of subtype.questions) {
                            if (question.descriptionType === 'image' && question.imageDescription !== null) {
                                imageUUIDs.push(question.imageDescription);
                            }
                        }
                    }
                }
            }
        } else {
            for (const chapter of form.chapters) {
                for (const question of chapter.questions) {
                    if (question.descriptionType === 'image' && question.imageDescription !== null) {
                        imageUUIDs.push(question.imageDescription);
                    }
                }
            }
        }
    }

    /**
     * Adds image UUID's for the given question ans answer combination to the given Array of imageUUIDs.
     * @param imageUUIDs string[] of image UUIDs
     * @param question Question
     * @param answer ProjectJobAnswer
     */
    static addImageUUIDsForQuestionAndAnswer(imageUUIDs: string[], question: Question, answer: ProjectJobAnswer): string[] {
        // Images from photo value
        if (question.type === 'photo') {
            if (answer && answer.value !== null) {
                const images = FormUtils.getImageUUIDsFromAnswer(answer.value);
                images.forEach((image) => imageUUIDs.push(image));
            }
        }

        // Images from signature value
        if (question.type === 'signature') {
            if (answer && answer.value !== null && answer.value !== '') {
                imageUUIDs.push(answer.value);
            }
        }

        // Images from choice remarkImage
        if (question.type === 'choice') {
            if (answer && answer.remarkImage !== null) {
                const images = FormUtils.getImageUUIDsFromAnswer(answer.remarkImage);
                images.forEach((image) => imageUUIDs.push(image));
            }
        }

        if (question.type === 'referenceImage') {
            let images = FormUtils.getImageUUIDsFromAnswer(question.referenceImages);
            images.forEach((image) => imageUUIDs.push(image));

            if (answer && answer.value !== null) {
                images = FormUtils.getImageUUIDsFromAnswer(answer.value);
                images.forEach((image) => imageUUIDs.push(image));
            }
        }

        if (question.type === 'railStraight') {
            const parsedValue: RailStraightMeasureResult | null = answer.value ? JSON.parse(answer.value) : null;
            if (parsedValue && parsedValue.qi_images) {
                parsedValue.qi_images.forEach((image) => imageUUIDs.push(image));
            }
        }

        return imageUUIDs;
    }

    static getImageUUIDsFromAnswer(answer: string): string[] {
        if (!answer) {
            return [];
        } else if (answer.startsWith('[')) {
            try {
                return (JSON.parse(answer) as AnnotatableImage[])
                    .flatMap(it => [it.originalPhotoId, it.modifiedPhotoId].filter((x): x is string => !!x));
            } catch (error) {
                console.error('error parsing image answer', error);
                return [];
            }
        } else {
            return answer.split(',');
        }
    }

    static determineStartingQuestionPosition(form: AnyProjectJobForm, node?: AnyLayeredFormNode): number | null {
        if (form.answerRevision >= 1) {
            const firstIntolerantPosition = FormUtils.determineFirstIntolerantPosition(form, node);
            return firstIntolerantPosition ?? FormUtils.getNextPosition(form, undefined, node);
        } else if (form.askLocation) {
            return FORM_START_LOCATION_QUESTION_POSITION;
        } else if (form.type === 'layeredJobForm' && node) {
            const questions = this.questionsForNode(form, node);
            const sorted = questions.sort((a, b) => a.position - b.position);

            return sorted[0]?.position ?? null;
        } else {
            return FormUtils.getNextPosition(form, undefined, node);
        }
    }

    static determineFirstIntolerantPosition(form: AnyProjectJobForm, node?: AnyLayeredFormNode): number | null {
        if (form.type === 'jobForm') {
            for (const chapter of form.chapters) {
                const result = FormUtils.determineFirstIntolerantPositionForQuestionList(form, chapter.questions, undefined);

                if (result) {
                    return result;
                }
            }

            return null;
        } else {
            if (!node) {
                throw new Error('Cannot determine first intolerant position for layered form without node');
            }

            const questions = this.questionsForNode(form, node);

            return FormUtils.determineFirstIntolerantPositionForQuestionList(form, questions, node) ?? NODE_FIRST_QUESTION_POSITION;
        }
    }

    static determineFirstIntolerantPositionForQuestionList(form: AnyProjectJobForm, questions: Question[], node: AnyLayeredFormNode | undefined): number | null {
        for (const question of questions) {
            const answer = FormUtils.getFormItemLastAnswer(form.answers, question.position, node);
            if (answer && !QuestionTolerance.getToleranceMessage(question, answer.value).tolerant) {
                return question.position;
            }
        }

        return null;
    }

    static isLayeredForm(form: unknown): form is LayeredProjectJobForm {
        return typeof form === 'object' && form !== null && 'type' in form && form.type === 'layeredJobForm';
    }

    /**
     * Returns all questions for traditional forms and all questions in current node for layered forms.
     */
    static getQuestions(form: AnyProjectJobForm, node?: AnyLayeredFormNode): Question[] {
        if (form.type === 'layeredJobForm' && node) {
            return FormUtils.questionsForNode(form, node);
        } else if (form.type === 'jobForm') {
            return form.chapters.flatMap(chapter => chapter.questions);
        } else {
            throw new Error(`Unknown form type ${form.type} or no node provided for layered form`);
        }
    }

    static questionsForNode(form: LayeredProjectJobForm, node: AnyLayeredFormNode): Question[] {
        if (!FormUtils.isLayeredForm(form)) {
            return [];
        }

        if (FormUtils.isSubtypeNode(node)) {
            return node.subtype.questions;
        } else if (FormUtils.isDatabaseNode(node)) {
            const layer = form.layers.find(layer => layer.id === node.layer);

            if (!layer || !FormUtils.isDatabaseLayer(layer)) {
                throw new Error(`Could not find layer with id ${node.layer}`);
            }

            return layer.questions;
        } else {
            return [];
        }
    }

    static isSubtypeLayer(layer: unknown): layer is SubtypeFormLayer {
        return typeof layer === 'object' && layer !== null && 'type' in layer && layer.type === 'subtypeLayer';
    }

    static isDatabaseLayer(layer: unknown): layer is DatabaseFormLayer {
        return typeof layer === 'object' && layer !== null && 'type' in layer && layer.type === 'databaseLayer';
    }

    static isSubtypeNode(node: unknown): node is SubtypeLayeredFormNode {
        return typeof node === 'object' && node !== null && 'type' in node && node.type === 'subtype';
    }

    static isDatabaseNode(node: unknown): node is DatabaseLayeredFormNode {
        return typeof node === 'object' && node !== null && 'type' in node && node.type === 'database';
    }

    static layerIdForNode(node: AnyLayeredFormNode, layers: AnyFormLayer[]): number {
        if (this.isDatabaseNode(node)) {
            return node.layer
        } else if (this.isSubtypeNode(node)) {
            const subtypeLayer = layers.find(layer => this.isSubtypeLayer(layer) && layer.subtypes.some(subtype => subtype.id === node.subtype.id));
            if (!subtypeLayer) {
                throw new Error(`Subtype layer not found for subtype id ${node.subtype.id}`);
            }
            return subtypeLayer.id;
        } else {
            throw new Error(`Unknown node type`);
        }
    }
}
