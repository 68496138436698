<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <div class="question-type" [class.is-disabled]="disabled">
            <label class="input-label">Antwoord</label>

            <div class="tabular-question">
                <ng-container *ngFor="let row of rowControls.controls; let rowIndex = index;">
                    <details class="tabular-question__row" [formGroup]="row" #rows>
                        <summary class="tabular-question__summary">
                            <app-icon class="tabular-question__summary-marker" iconName="chevron-right"></app-icon>
                            <span class="tabular-question__summary-label">{{ row.value.label }}</span>
                            <button type="button" class="tabular-question__summary-action"
                                    (click)="popoverRowIndex = rowIndex;"
                                    cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                                <app-icon iconName="more-vertical"></app-icon>
                            </button>
                            <ng-template cdkConnectedOverlay
                                         [cdkConnectedOverlayOrigin]="trigger"
                                         [cdkConnectedOverlayPositions]="popoverPreferredPositions"
                                         [cdkConnectedOverlayPositionStrategy]="buildPopoverPositionStrategy(trigger)"
                                         [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.close()"
                                         [cdkConnectedOverlayOpen]="popoverRowIndex === rowIndex"
                                         (overlayOutsideClick)="popoverRowIndex = null">
                                <div class="tabular-question__popover">
                                    <ul class="tabular-question__popover-list">
                                        <li class="tabular-question__popover-list-item">
                                            <button type="button" (click)="editRow(rowIndex)" class="tabular-question__popover-button">Rij-label wijzigen</button>
                                        </li>
                                        <li class="tabular-question__popover-list-item">
                                            <button type="button" (click)="showDeleteModal(rowIndex)" class="tabular-question__popover-button">
                                                Rij verwijderen
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </ng-template>
                        </summary>
                        <div class="tabular-question__content">
                            <ng-container *ngFor="let column of currentQuestion.columns; index as i;">
                                <div class="tabular-question__column"
                                     *ngIf="row.controls.columns.controls[column.key] as columnControl"
                                     [ngSwitch]="column.type"
                                     [class.ng-invalid]="columnControl.invalid"
                                     [class.ng-touched]="columnControl.touched"
                                >
                                    <div class="input-label">{{ column.label }}</div>
                                    <ng-container *ngFor="let error of columnControl.errors|keyvalue">
                                        <div [ngSwitch]="error.key" class="text-danger" *ngIf="columnControl.touched">
                                            <ng-container *ngSwitchCase="'required'">Dit veld is verplicht</ng-container>
                                            <ng-container *ngSwitchCase="'number'">Vul een geldig getal in</ng-container>
                                            <ng-container *ngSwitchCase="'maxDecimals'">
                                                Het antwoord moet exact {{ error.value }} {{ error.value === 1 ? 'decimaal' : ' decimalen' }} bevatten
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <app-number-input
                                        *ngSwitchCase="'number'"
                                        [formControl]="columnControl">
                                    </app-number-input>

                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="isTableColumnText(column) as columnText" [ngSwitch]="columnText.config.multiline">
                                        <textarea *ngSwitchCase="true"
                                                  [formControl]="columnControl"></textarea>

                                            <input *ngSwitchDefault
                                                   [formControl]="columnControl"
                                                   [type]="column.type" />
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </details>
                </ng-container>

                <div class="tabular-question__add-row">
                    <div class="tabular-question__add-button-container">
                        <button type="button" class="vwui-btn tabular-question__add-button" (click)="addRow()">
                            {{ 'Rij toevoegen'|translate }}
                            <app-icon iconName="plus"></app-icon>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="isValid" (tapOnInvalidButton)="checkValidity()"></app-question-footer>
</form>
