<div class="popup fixed inset-0 bg-black bg-opacity-40 transition-opacity p-6 z-50 flex"
     [class.opacity-0]="!visible"
     [class.pointer-events-none]="!visible"
     [class.popup--fullscreen]="popupConfig?.fullscreen"
>
    <div class="p-safe flex flex-col min-h-0 max-w-full grow justify-center items-center transition-transform"  [class.translate-y-full]="!visible">
        <div class="popup-content-container bg-white w-full max-w-4xl rounded-lg overflow-auto">
            <ng-template appPopupContent></ng-template>
        </div>
    </div>
</div>
