import { registerPlugin } from '@capacitor/core';

/**
 * All fields have been marked as optional because they are supplied by the external railstraight app
 * and that app could omit or skip fields at any time, so we need to verify a field exists before using it.
 */
export interface RailStraightMeasureResult {
    pv_min: number;
    pv_max: number;
    instrument: string;
    speed_class_index: number;
    speed_class_labels: string[];
    qis: number[];
    qi_images: string[];
    length_of_device_mm?: number;
    latitude?: number
    longitude?: number;
    joint_length_mm?: string; // 35.00 mm
    measurement_datetime?: string; // 2023-12-31T23:59
}

export interface RailStraightPlugin {
    measure(): Promise<RailStraightMeasureResult>;
}

const RailStraight = registerPlugin<RailStraightPlugin>('RailStraight');

export default RailStraight;
