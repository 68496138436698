<div class="vwui-alert mb" *ngIf="showAlert">
    <app-icon iconName="oval-exclamation"></app-icon>
    Je hebt de limiet van {{ maxImageCount }} foto’s bereikt.
</div>

<ng-template #imageGridItems>
    <button type="button" [disabled]="isUploadDisabled" class="image-grid__item image-grid__item--add" (click)="showImageUploadModal()">
        <app-icon iconName="add-image"></app-icon>
        Foto toevoegen
    </button>
    <div *ngFor="let image of images" class="image-grid__item" (click)="showImageViewer(image)">
        <app-image-thumbnail class="image-grid__image" [uuid]="image.modifiedPhotoId ?? image.originalPhotoId" [jobId]="jobId"
                             [questionPosition]="questionPosition" [comment]="!!image.description"></app-image-thumbnail>
        <div class="drag-handle" *ngIf="(annotationVersion$ | async) === 2"></div>
    </div>
</ng-template>

<div *ngIf="(annotationVersion$ | async) !== 2" class="image-grid">
    <ng-container [ngTemplateOutlet]="imageGridItems"></ng-container>
</div>

<div *ngIf="(annotationVersion$ | async) === 2" id="sortable-container" class="sortable-container">
    <div class="image-grid" [sortablejs]="images" [sortablejsOptions]="sortableJsOptions">
        <ng-container [ngTemplateOutlet]="imageGridItems"></ng-container>
    </div>
</div>

<app-image-viewer
    [image]="selectedImage$|async"
    [canEdit]="!disabled"
    [canRemove]="!disabled"
    [jobId]="jobId"
    (remove)="removeImage($event)"
    (save)="replaceImage($event)"
    (closed)="selectedImage$.next(null)"
></app-image-viewer>

<app-modal [visible$]="uploadImageModalVisible$">
    <h1 class="modal-title">Foto toevoegen</h1>
    <div class="image-selector">
        <button type="button" class="image-selector__btn" (click)="addPhoto(false)" [disabled]="imageSourceSelected">
            <app-icon iconName="camera"></app-icon>
            Camera
        </button>
        <button type="button" class="image-selector__btn" (click)="addPhoto(true)" [disabled]="imageSourceSelected">
            <app-icon iconName="image"></app-icon>
            Galerij
        </button>
        <label class="image-selector__btn">
            <input type="file" accept="application/pdf" (change)="addPdf($event)" multiple hidden>
            <app-icon iconName="pdf"></app-icon>
            PDF-bestand
        </label>
    </div>
</app-modal>

<div class="fixed w-full h-full left-0 top-0 p-8 flex justify-center items-center bg-black/40 z-20" *ngIf="loading">
    <div class="flex flex-col items-center bg-white p-8 shadow-m rounded-lg">
        <div class="flex">
            <app-spinner></app-spinner>
        </div>
        <div class="mt-2" *ngIf="loadingMessage">
            {{ loadingMessage }}
        </div>
    </div>
</div>
