<div class="mb-2">
    <h3 class="vwui-list-item__title">{{ paulaObject.objectOmschrijvingKort }}</h3>
    <table class="keypair-table">
        <tr>
            <td>Object ID</td>
            <td>{{ paulaObject?.objectId || '-' }}</td>
        </tr>
        <tr>
            <td>Hoofdfilter</td>
            <td>{{ paulaObject?.objectFilter || '-' }}</td>
        </tr>
        <tr>
            <td>Subfilter</td>
            <td>{{ paulaObject?.objectSubFilter || '-' }}</td>
        </tr>
        <tr>
            <td>Geo</td>
            <td>{{ paulaObject?.objectGeo || '-' }}</td>
        </tr>
    </table>
</div>
