/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import e from "../../../core/Accessor.js";
import r from "../../../core/Collection.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as t } from "../../../core/accessorSupport/decorators/subclass.js";
import c from "./GamepadInputDevice.js";
let p = class extends e {
  constructor(...o) {
    super(...o), this.devices = new r(), this.enabledFocusMode = "document";
  }
};
o([s({
  type: r.ofType(c),
  readOnly: !0
})], p.prototype, "devices", void 0), o([s({
  type: ["document", "view", "none"]
})], p.prototype, "enabledFocusMode", void 0), p = o([t("esri.views.input.gamepad.GamepadSettings")], p);
const i = p;
export { i as default };