<div class="create-comment" [class.create-comment--visible]="visible">
   <div class="question">
       <h5>{{ 'COMPONENTS.IMAGE_ANNOTATION.COMMENT.FIELD.header' | translate }}</h5>
       <p>{{ 'COMPONENTS.IMAGE_ANNOTATION.COMMENT.FIELD.description' | translate }}</p>
       <form [formGroup]="commentForm">
           <label for="commentInputField">{{ 'COMPONENTS.IMAGE_ANNOTATION.COMMENT.FIELD.label' | translate }}</label>
           <input
               id="commentInputField"
               type="text"
               [value]="comment"
               formControlName="comment">
       </form>
   </div>

   <div class="actionbar">
       <button class="vwui-btn" (click)="cancelComment()">{{ 'COMPONENTS.IMAGE_ANNOTATION.COMMENT.button.cancel' | translate }}</button>
       <button class="vwui-btn is-primary" (click)="saveComment()">
           {{ 'COMPONENTS.IMAGE_ANNOTATION.COMMENT.button.add' | translate }}
       </button>
   </div>
</div>
