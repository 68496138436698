import {Inject, Injectable} from '@angular/core';
import {ProjectJobAnswer} from '../models/project-job-answer';
import {ProjectJobAnswerService} from './project-job-answer.service';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import {debounceTime, filter, map} from 'rxjs/operators';
import {FormService} from './form.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ProjectJobService} from './project-job.service';
import {FormImageService} from './form-image.service';
import {ProjectJobStatus} from '../models/project-job-form';
import {FormUtils} from '../utils/form-utils';
import {ProjectService} from './project.service';
import {ProjectJobAnswerMeta} from '../models/project-job-answer-meta';
import {ProjectJobAnswerMetaService} from './project-job-answer-meta.service';
import {AppInsightsService} from './app-insights/app-insights.service';
import {AnyLayeredFormNode} from '../models/layered-form-node';
import {NodeService} from './node.service';
import {AppStateUploader} from './app-insights/app-state-uploader';
import {Identifiable} from '../models/identifiable';
import {Deferred} from '../utils/deferred';
import {dateReviver} from '../interceptors/date-http-interceptor.service';
import {AnnotateToolConfigService} from './annotate-tool-config.service';

interface SyncAnswerResultSuccess {
    status: 'success';
    // The answer that was synced to id is not null
    answer: ProjectJobAnswer & Identifiable;
}
interface SyncAnswerResultConflict {
    status: 'conflict';
}
interface SyncAnswerResultError {
    status: 'error';
}

type SyncAnswerResult = SyncAnswerResultSuccess | SyncAnswerResultConflict | SyncAnswerResultError;

@Injectable({
    providedIn: 'root'
})
export class FormSyncService {
    private static STORAGE_KEY = 'formSyncStore';

    private activeSyncSubject = new BehaviorSubject<boolean>(false);
    private pendingSyncDeferred: Deferred<void> | null = null;

    private store$ = new BehaviorSubject<Readonly<QueuedJob[]>>(this.loadDataFromLocalStorage());
    private syncErrorSubject = new Subject();
    public syncError$ = this.syncErrorSubject.pipe(
        debounceTime(500)
    );

    private lockedJobsSubject = new BehaviorSubject<Readonly<number[]>>([]);

    constructor(
        private formService: FormService,
        private formImageService: FormImageService,
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        @Inject('ProjectService') private projectService: ProjectService,
        @Inject('ProjectJobAnswerService') private answerService: ProjectJobAnswerService,
        @Inject('ProjectJobAnswerMetaService') private answerMetaService: ProjectJobAnswerMetaService,
        private annotationToolConfigService: AnnotateToolConfigService,
        private nodeService: NodeService,
        private appInsights: AppInsightsService,
        private appStateUploader: AppStateUploader
    ) {
        this.store$.pipe(debounceTime(100)).subscribe(store => {
            localStorage.setItem(FormSyncService.STORAGE_KEY, JSON.stringify(store));
        });
    }

    projectSyncStateChanges(projectId: number): Observable<boolean> {
        return this.store$.pipe(
            map(store => -1 !== store.findIndex(it => it.projectId === projectId)),
            debounceTime(500)// Debounce syncState to prevent flickering sync icon
        );
    }

    jobSyncStateChanges(jobId: number): Observable<boolean> {
        return this.store$.pipe(
            map(store => -1 !== store.findIndex(it => it.id === jobId)),
            debounceTime(500)// Debounce syncState to prevent flickering sync icon
        );
    }

    async saveAnswer(projectId: number, answer: ProjectJobAnswer) {
        await this.updateQueuedJob(projectId, answer.job, async job => {
            const jobAnswerIndex = job.queuedAnswers.findIndex(it => {
                return it.node?.id === answer.node?.id && it.position === answer.position && it.revision === answer.revision;
            });
            if (jobAnswerIndex === -1) {
                job.queuedAnswers.push(answer);
            } else {
                job.queuedAnswers[jobAnswerIndex] = answer;
            }

            await this.formService.updateOrAddAnswerInStore(answer);
        });
    }

    async transition(projectId: number, jobId: number, transition: ProjectJobStatus) {
        await this.updateQueuedJob(projectId, jobId, async job => {
            if (transition === 'Rejected') {
                await this.formService.updateForm(jobId, form => ({
                    ...form,
                    state: 'Rejected',
                    answerRevision: form.answerRevision + 1
                }));
            }

            job.queuedTransition = transition;
        });

        await this.sync()
    }

    async rejectWithObstruction(projectId: number, jobId: number, obstructionCode: string, obstructionRemarks: string | null) {
        await this.updateQueuedJob(projectId, jobId, async job => {
            job.queuedObstruction = {obstructionCode, obstructionRemarks};
        });
        await this.sync();
    }

    async sync() {
        if (this.activeSyncSubject.value) {
            if (this.pendingSyncDeferred === null) {
                this.pendingSyncDeferred = new Deferred<void>();
            }
            return this.pendingSyncDeferred.promise;
        } else {
            try {
                this.activeSyncSubject.next(true);
                let anySyncFailed = false;
                const syncStart = new Date();

                await this.nodeService.syncNodes();

                // List of queued jobs
                const store = await this.getStore();
                for (const job of store) {
                    try {
                        await this.updateQueuedJob(job.projectId, job.id, async job => {
                            await this.addMissingAnswersToQueue(job);
                        });

                        await this.syncJob(job);
                    } catch (e) {
                        console.error(`Sync error: ${e}`, e);
                        anySyncFailed = true;
                    }
                }

                // Keep jobs with at least 1 queued answer or a queued transition
                // Do this with a new copy of the store, so we don't discard jobs that have been updated while syncing
                const newStore = (await this.getClonedStore()).filter(job => job.queuedAnswers.length !== 0 || job.queuedTransition);
                this.store$.next(newStore);

                this.appInsights.trackEvent(
                    'formSyncService.sync',
                    new Date().getTime() - syncStart.getTime(),
                )
                if (anySyncFailed) {
                    // Automatic state upload
                    await this.appStateUploader.automaticUploadState().catch(err => console.error('Automatic state upload failed', err));
                }
            } finally {
                this.activeSyncSubject.next(false);
            }

            if (this.pendingSyncDeferred !== null) {
                const pendingSyncDeferred = this.pendingSyncDeferred;
                this.pendingSyncDeferred = null;
                // Run pending sync in background
                this.sync().then(
                    () => pendingSyncDeferred.resolve(),
                    (err) => pendingSyncDeferred.reject(err)
                )
            }
        }
    }

    async removeFormsById(ids: number[]) {
        if (ids.length === 0) {
            return;
        }

        const store = await this.getClonedStore();

        this.store$.next(store.filter(form => -1 === ids.indexOf(form.id)));
    }

    getQueuedIds(projectId: number): Promise<number[]> {
        return firstValueFrom(this.store$.pipe(
            map(store => store
                .filter(queudJob => queudJob.projectId === projectId)
                .map(queuedJob => queuedJob.id)
            )
        ));
    }

    /**
     * Determine if the given job id has been synchronized yet
     */
    async isJobSynchronized(jobId: number): Promise<boolean> {
        const store = await this.getStore();
        return !store.some((job) => job.id === jobId);
    }

    /**
     * Sync answers one by one and keep a
     * @param job
     * @private
     */
    private async syncJob(job: QueuedJob) {
        // Remember which answers have been synced by VALUE, not just by node / position
        // This is needed to avoid removing answers from queue that have been updated while syncing
        // We can detect this by comparing the synced answer with the queued answer
        const syncedAnswers: {
            node: {
                id: string;
            } | null;
            position: number;
            revision: number;
            value: string | null;
            remarkImage: string | null;
            remarkText: string | null;
            updatedAt: Date;
        }[] = [];
        for (const answer of job.queuedAnswers) {
            try {
                const answerSyncResult = await this.syncAnswer(job.projectId, answer)
                if (answerSyncResult.status === 'success') {
                    syncedAnswers.push(answerSyncResult.answer);
                    await this.formService.updateOrAddAnswerInStore(answerSyncResult.answer)
                }
            } catch (e) {
                console.error('SyncAnswer failed', e);
            }
        }

        const updatedJob = await this.updateQueuedJob(job.projectId, job.id, async updateJob => {
            // Discard queued answers that match a synced answer
            // And that are created before or at the same time as the synced answer
            updateJob.queuedAnswers = updateJob.queuedAnswers.filter(queuedAnswer => -1 === syncedAnswers.findIndex(syncedAnswer => {
                return syncedAnswer.node?.id === queuedAnswer.node?.id
                    && syncedAnswer.position === queuedAnswer.position
                    && syncedAnswer.revision === queuedAnswer.revision
                    && syncedAnswer.remarkImage === queuedAnswer.remarkImage
                    && syncedAnswer.remarkText === queuedAnswer.remarkText
                    // if the synced answer updatedAt is newer, then we can discard the queued answer
                    && syncedAnswer.updatedAt >= queuedAnswer.updatedAt
            }));
        });

        // Sync queued transition if all answers are synced and a transition is queued
        if (updatedJob.queuedAnswers.length === 0) {
            if (updatedJob.queuedObstruction) {
                await this.syncObstruction(job);
            } else if (job.queuedTransition) {
                await this.syncTransition(job);
            }
        }
    }

    private async syncTransition(job: QueuedJob) {
        try {
            // Determine if job requires verification
            let transition = job.queuedTransition;
            if (transition === null) {
                console.error('syncTransition called without queuedTransition');
                return;
            }

            if (job.queuedTransition === ProjectJobStatus.Approved) {
                const project = await this.projectService.get(job.projectId);
                if (project.requireVerification) {
                    transition = ProjectJobStatus.AvailableForVerification;
                }
            }

            const form = await firstValueFrom(this.projectJobService.transition(job.projectId, job.id, transition));

            // Clean images and metadata from local storage for approved or available-for-verification forms
            if (form.status !== ProjectJobStatus.Rejected) {
                await this.formImageService.removeFormImages(form);

                form.answers.forEach((answer) => {
                    this.answerMetaService.clearLocalMetadata({
                        id: answer.id,
                        position: answer.position,
                        job: form.id
                    });
                });
            }

            await this.updateQueuedJob(job.projectId, job.id, async job => {
                job.queuedTransition = null;
            });

            await this.formService.mergeForm(form);
        } catch (e) {
            if  (e instanceof HttpErrorResponse) {
                if (e.error instanceof ErrorEvent) {
                    // A client-side or network error occurred.
                    console.warn(`Sync of job #${job.id}, transition ${job.queuedTransition} failed:`, e.error.message);
                } else if (e.status === 400 && e.error?.globalErrors?.length > 0 && e?.error?.globalErrors[0] === 'Missing answer(s)') {
                    console.error(`Sync of job #${job.id}, transition ${job.queuedTransition} failed, server is missing answers, removing queued transition`);
                    this.appInsights.logTrace("Transition failed, missing answers", {
                        queuedJob: job,
                    });
                    // Return instead of throwing error so queued transition is removed
                    return;
                } else if (e.status === 400 && e.error?.code === '110') {
                    // 110: Transition blocked / Invalid transition
                    console.error(`Sync of job #${job.id}, transition ${job.queuedTransition} failed, invalid transition, removing queued transition`);
                    this.appInsights.logTrace("Transition failed, invalid transition", {
                        queuedJob: job,
                    });
                    // Return instead of throwing error so queued transition is removed
                    return;
                } else {
                    // All other server error
                    console.error(
                        `Sync of job #${job.id}, transition ${job.queuedTransition} failed, status code ${e.status}:`,
                        e.message
                    );
                }
            }


            throw new Error('syncTransition failed', {cause: e});
        }
    }

    private async syncObstruction(job: QueuedJob) {
        try {
            const queuedObstruction = job.queuedObstruction;
            if (!queuedObstruction) {
                console.error('syncObstruction called without queuedObstruction');
                return;
            }

            const form = await firstValueFrom(this.projectJobService.rejectWithObstruction(
                job.projectId,
                job.id,
                queuedObstruction.obstructionCode,
                queuedObstruction.obstructionRemarks
            ));

            await this.updateQueuedJob(job.projectId, job.id, async job => {
                job.queuedObstruction = undefined;
                job.queuedTransition = null;
            });

            await this.formService.mergeForm(form);
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                const error: HttpErrorResponse = e;
                if (error.error instanceof ErrorEvent) {
                    // A client-side or network error occurred.
                    console.warn(`Sync of job #${job.id}, obstruction sync failed:`, error.error.message);
                } else {
                    // All other server error
                    console.warn(
                        `Sync of job #${job.id}, obstruction sync failed, status code ${error.status}:`,
                        error.message
                    );
                }
            } else {
                console.warn(`Sync of job #${job.id}, obstruction sync failed:`, e);
            }
        }
    }

    /**
     * Returns true if answer should be considered Synced and removed from queue
     */
    private async syncAnswer(projectId: number, answer: ProjectJobAnswer): Promise<SyncAnswerResult> {
        try {
            const question = await this.formService.getQuestion(answer.job, answer.position, answer.node?.id);

            // Upload question value images if needed
            const imageUUIDs = FormUtils.addImageUUIDsForQuestionAndAnswer([], question, answer);

            let projectJobAnswerMeta: ProjectJobAnswerMeta[] = [];
            const annotationVersion = await firstValueFrom(this.annotationToolConfigService.version());
            if (annotationVersion === 2) {
                projectJobAnswerMeta = await this.answerMetaService.findLocalProjectJobAnswerMeta(answer);

                // Synchronize the original images as well, so they can be reloaded later
                imageUUIDs.push(...projectJobAnswerMeta
                    .filter((metadata) => {
                        return metadata.answer_id !== metadata.photo_id;
                    })
                    .map((metadata) => {
                        return metadata.photo_id;
                    })
                );
            }

            if (imageUUIDs.length > 0) {
                try {
                    await this.formImageService.processQueuedImages();
                } catch (e) {
                    throw new Error('ImageSyncError', { cause: e });
                }
            }

            const createdAnswer = await firstValueFrom(this.answerService.postProjectJobAnswer(projectId, answer));

            if (annotationVersion === 2) {
                // Synchronize the metadata
                await this.syncAnswerMetadata(createdAnswer, projectJobAnswerMeta);
            }

            return {
                status: 'success',
                answer: createdAnswer
            };
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.error instanceof ErrorEvent) {
                // A client-side or network error occurred.
                console.warn(`Sync of job #${answer.job}, answer ${answer.position} failed:`, error.error.message);

                return {status: 'error'};
            } else if (error instanceof HttpErrorResponse && error.status) {
                // All other server error
                console.warn(
                    `Sync of job #${answer.job}, answer ${answer.position} failed, status code ${error.status}:`,
                    error.message
                );
            } else if (error instanceof Error && error.message === 'ImageSyncError') {
                console.warn(`Sync of job #${answer.job}, answer ${answer.position} failed, image sync error:`, error.cause);
                this.syncErrorSubject.next(null);
            } else {
                // Javascript Error
                console.warn(
                    `Sync of job #${answer.job}, answer ${answer.position} failed`,
                    error
                );
            }

            return {status: 'error'};
        }
    }

    private async syncAnswerMetadata(answer: ProjectJobAnswer, metadata: ProjectJobAnswerMeta[]) {
        try {
            await firstValueFrom(this.answerMetaService.postProjectJobAnswerMeta(answer, metadata));

            return true;
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.error instanceof ErrorEvent) {
                console.warn(`Sync of answer meta data #${answer.job}, answer ${answer.position} failed:`, error.error.message);
            } else if (error instanceof HttpErrorResponse && error.status) {
                // All other server error
                console.warn(`Sync of answer meta data #${answer.job}, answer ${answer.position} failed:`, error.status);
            } else {
                // Javascript Error
                console.warn(
                    `Sync of job #${answer.job}, answer ${answer.position} failed`,
                    error
                );
            }

            return false;
        }
    }

    private getStore(): Promise<Readonly<QueuedJob[]>> {
        return firstValueFrom(this.store$);
    }

    private async getClonedStore(): Promise<QueuedJob[]> {
        const store = await this.getStore();
        return JSON.parse(JSON.stringify(store), dateReviver);
    }

    private async updateQueuedJob(projectId: number, jobId: number, update: (job: MutableQueuedJob) => Promise<void>) {
        try {
            // Lock job to prevent concurrent updates
            await this.getJobLock(jobId);

            const store = await this.getStore();
            const job = store.find(it => it.id === jobId) || {
                id: jobId,
                projectId,
                queuedTransition: null,
                queuedAnswers: []
            };

            await update(job);

            const newStore = await this.getClonedStore();

            const jobIndex = newStore.findIndex(it => it.id === jobId);
            if (jobIndex === -1) {
                newStore.push(job);
            }

            this.store$.next(newStore);

            return job;
        } finally {
            await this.releaseJobLock(jobId);
        }
    }

    private loadDataFromLocalStorage(): QueuedJob[] {
        const items: Partial<QueuedJob>[] = JSON.parse(localStorage.getItem(FormSyncService.STORAGE_KEY) || '[]', dateReviver) || [];
        // Map items to ensure all properties are present
        return items.filter((partialQueuedJob): partialQueuedJob is Partial<QueuedJob> & Pick<QueuedJob, 'id'|'projectId'> => {
            if (!(partialQueuedJob.id !== undefined && partialQueuedJob.projectId !== undefined)) {
                console.error('Invalid queued job in local storage, missing id or projectId', partialQueuedJob);
                return false;
            } else {
                return true;
            }
        }).map<QueuedJob>(partialQueuedJob => {
            return {
                id: partialQueuedJob.id,
                projectId: partialQueuedJob.projectId,
                queuedTransition: partialQueuedJob.queuedTransition || null,
                queuedAnswers: partialQueuedJob.queuedAnswers || [],
                queuedObstruction: partialQueuedJob.queuedObstruction
            };
        })
    }

    private async getJobLock(jobId: number): Promise<void> {
        const lockedJobs = await firstValueFrom(this.lockedJobsSubject);
        if (lockedJobs.includes(jobId)) {
            // Job is already locked, wait for it to be released
            await firstValueFrom(this.lockedJobsSubject.pipe(
                filter(lockedJobs => !lockedJobs.includes(jobId))
            ))
            // Retry getting the lock
            return this.getJobLock(jobId);
        }
        this.lockedJobsSubject.next([...lockedJobs, jobId]);
    }

    private async releaseJobLock(jobId: number) {
        const lockedJobs = await firstValueFrom(this.lockedJobsSubject);
        this.lockedJobsSubject.next(lockedJobs.filter(it => it !== jobId));
    }

    private async addMissingAnswersToQueue(job: MutableQueuedJob) {
        // For unknown reasons we see occurrences of Answers that have not been synced yet that are missing from the store
        // But are stored in the local cached form, re-add them here and log an error
        // For performance reasons only do this if synchronize is true

        // Older version of this fix was bugged and added already synced answers to queuedAnswers,
        // clean those up so users don't need to manually clear local storage, we only keep answers with the job property set
        // TODO: Remove this code in a few weeks when all users have updated to a version with the fix
        const jobId = job.id;
        job.queuedAnswers = job.queuedAnswers.filter(queuedAnswer => !!queuedAnswer.job);

        const form = await firstValueFrom(this.formService.getJob(jobId));
        if (form === null) {
            console.error(`Form with id ${jobId} not found in store, cannot check for missing answers`)
        } else {
            const missingAnswers = form.answers.filter(answer => {
                // missing answers are answers that are not synced yet (so id is null) and are not already queued
                return answer.id === null && !job.queuedAnswers.some(queuedAnswer => {
                    return queuedAnswer.position === answer.position
                        && queuedAnswer.revision === answer.revision
                        && queuedAnswer.node?.id === answer.node?.id;
                });
            });
            if (missingAnswers.length > 0) {
                console.error(`Found ${missingAnswers.length} missing answers for job ${jobId}, adding them to the queue`, missingAnswers);
                job.queuedAnswers.push(...missingAnswers);
            }
        }
    }
}

export interface MutableQueuedJob {
    id: number;
    projectId: number;
    queuedTransition: ProjectJobStatus | null;
    queuedAnswers: ProjectJobAnswer[];
    queuedObstruction?: {
        obstructionCode: string;
        obstructionRemarks: string | null;
    };
    node?: AnyLayeredFormNode;
}
export type QueuedJob = Readonly<MutableQueuedJob>;
