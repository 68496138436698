import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {AnyProjectJobForm, LayeredProjectJobForm} from '../../models/project-job-form';
import {firstValueFrom, Subscription} from 'rxjs';
import {liveQuery} from 'dexie';
import {db} from '../../db/db';
import {AnyFormLayer} from '../../models/form-layer';
import {PopupService} from '../../services/popup.service';
import {NodeService} from '../../services/node.service';
import {FormService} from '../../services/form.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AnyLayeredFormNode} from '../../models/layered-form-node';
import {EditLayeredNodePopupComponent} from '../edit-layered-node-popup/edit-layered-node-popup.component';
import {numberParamInNestedRouteOrFail} from '../../utils/param-map-util';
import {determineNodeStatuses} from '../../utils/form-node-status';

@Component({
  selector: 'app-project-job-layered-overview',
  templateUrl: './project-job-layered-overview.component.html',
})
export class ProjectJobLayeredOverviewComponent implements OnInit, OnDestroy {
    public form$ = this.formService.openForm$.pipe(
        filter<AnyProjectJobForm | undefined, LayeredProjectJobForm>((it): it is LayeredProjectJobForm => {
            return it !== undefined && it.type === 'layeredJobForm';
        }),
    );

    public nodes$ = this.form$.pipe(
        switchMap(form => liveQuery(() => db.nodes.where('jobFormId').equals(form.id).sortBy('name'))),
    );
    public rootNodes$ = this.nodes$.pipe(
        map(nodes => this.findRootNodes(nodes)),
    );

    public nodeStatuses$ = this.nodes$.pipe(
        withLatestFrom(this.form$),
        map(([nodes, form]) => determineNodeStatuses(nodes, form))
    )

    public canSubmit$ = this.nodeStatuses$.pipe(
        map(nodeStatuses => {
            const nodeStatusList = Object.values(nodeStatuses);
            return nodeStatusList.length > 0 && nodeStatusList.every(nodeStatus => nodeStatus.questionsValid);
        })
    )

    public leafLayer?: AnyFormLayer;
    private subscriptions: Subscription[] = [];

    constructor(private popupService: PopupService, private nodeService: NodeService, private formService: FormService, private router: Router, private route: ActivatedRoute, private activatedRoute: ActivatedRoute) {
    }

    openEditNodePopup(node: AnyLayeredFormNode, tree: AnyLayeredFormNode[]) {
        this.popupService.open(EditLayeredNodePopupComponent, {
            data: {
                node,
                tree,
            },
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            this.form$.subscribe(form => {
                this.updateLeafLayer(form);
            }),
        );
    }

    private findRootNodes(nodes: AnyLayeredFormNode[]) {
        return nodes.filter(n => !n.parent);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    async addNode(lastLayerFlow = false) {
        const form = await firstValueFrom(this.form$);
        const firstLayer = form.layers[0];

        if (!firstLayer) {
            throw new Error('No first layer found');
        }

        if (lastLayerFlow) {
            await this.router.navigate(['add-nodes', 'last', firstLayer.id], { relativeTo: this.route.parent });
        } else {
            await this.router.navigate(['add-nodes', firstLayer.id], { relativeTo: this.route.parent });
        }
    }

    private updateLeafLayer(form: LayeredProjectJobForm) {
        const sorted = form.layers
            ?.sort((a, b) => a.order - b.order) ?? []

        this.leafLayer = sorted[sorted.length - 1];
    }

    get projectId() {
        return numberParamInNestedRouteOrFail(this.activatedRoute.snapshot, 'project');
    }

    get jobId() {
        return numberParamInNestedRouteOrFail(this.activatedRoute.snapshot, 'job');
    }
}
