/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
function n(n) {
  return n instanceof Float32Array && n.length >= 2;
}
function r(n) {
  return Array.isArray(n) && n.length >= 2;
}
function t(t) {
  return n(t) || r(t);
}
export { t as isVec2, n as isVec2f32, r as isVec2f64 };