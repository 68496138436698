import {AnnotateComponent, AnnotateComponentActionShape, AnnotateComponentState} from './annotate-component';
import {Circle} from "konva/lib/shapes/Circle"

export class AnnotateComponentCircle extends AnnotateComponent {

    static readonly DefaultConfigRadius = 50;

    readonly drawingShapeDefaultConfig = {
        radius: AnnotateComponentCircle.DefaultConfigRadius,
    };
    readonly drawingShape: Circle;

    constructor(
        uuid: string,
        state: AnnotateComponentState,
    ) {
        super(uuid, AnnotateComponentActionShape.CIRCLE, state);

        this.drawingShape = new Circle({...this.drawingShapeDefaultConfig, ...state.config});
        this.drawingLayer.add(this.drawingShape);
        this.draw();
    }

    updateState() {
        super.updateState();

        if (this.state.config) {
            this.state.config.radius = this.drawingShape.radius();
        }
    }

    updateTransformer() {
        super.updateTransformer();

        this.transformer.rotateEnabled( false);
    }

}
