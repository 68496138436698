import {Component, HostBinding, Input} from '@angular/core';
import {AccordionIcon} from '../accordion-group/accordion-group.component';

@Component({
  selector: 'app-accordion-list-item',
  templateUrl: './accordion-list-item.component.html'
})
export class AccordionListItemComponent {
    @HostBinding('class.accordion-list__item') classes = true;

    startIconClass: {[key: string]: boolean} = {};
    private _startIcon: AccordionIcon = 'none';

    get startIcon(): AccordionIcon {
        return this._startIcon;
    }

    @Input()
    set startIcon(value: AccordionIcon) {
        this._startIcon = value;
        this.startIconClass = {
            [this._startIcon]: true,
        }
    }
}
