import {AnnotateComponentAction, AnnotateComponentActionType} from './annotate-component';
import {Subject} from 'rxjs';

export class AnnotateHistoryManager {

    private onChange$ = new Subject<number>();
    private currentHistoryIndex = 0;
    actions: AnnotateComponentAction[] = [];

    public setActions(actions: AnnotateComponentAction[]) {
        this.actions = actions;
        this.currentHistoryIndex = actions.length;
    }

    public clear() {
        this.actions = [];
        this.currentHistoryIndex = 0;
    }

    get onChange() {
        return this.onChange$;
    }

    public appendAction(action: AnnotateComponentAction) {
        // Clear the future actions if a new action has been added after undoing
        if (this.currentHistoryIndex < this.actions.length) {
            this.actions.splice(this.currentHistoryIndex, this.actions.length);
        }

        this.actions.push(action);
        this.currentHistoryIndex = this.actions.length;
        this.onChange$.next(this.currentHistoryIndex);
    }

    public undoAction(): AnnotateComponentAction | null {
        if (this.canUndoAction()) {
            this.currentHistoryIndex -= 1;
            const action = this.actions[this.currentHistoryIndex];
            this.onChange$.next(this.currentHistoryIndex);

            return action;
        }

        return null;
    }

    public redoAction(): AnnotateComponentAction | null {
        if (this.canRedoAction()) {
            this.currentHistoryIndex += 1;
            const action = this.actions[this.currentHistoryIndex - 1];
            this.onChange$.next(this.currentHistoryIndex);
            return action;
        }

        return null;
    }

    public canUndoAction(): boolean {
        return this.currentHistoryIndex > 0;
    }

    public canRedoAction(): boolean {
        return this.currentHistoryIndex < this.actions.length;
    }

    /**
     * Compresses the currently active actions in the following manner
     * - Clears properties that remain the same after a change event
     */
    public getCompressedActions() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const componentState: {[key: string]: any} = {};

        let actions = this.actions;
        if (this.canRedoAction()) {
            actions = this.actions.slice(0, this.currentHistoryIndex);
        }

        return actions.map((uncompressedAction) => {
            if (
                ( uncompressedAction.type === AnnotateComponentActionType.CHANGE || uncompressedAction.type === AnnotateComponentActionType.REMOVE)
                && componentState[uncompressedAction.uuid]) {

                const newState = {
                    originPoint: uncompressedAction.state.originPoint,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    config: {} as any
                };
                const existingState = componentState[uncompressedAction.uuid];
                for (const key in uncompressedAction.state.config) {
                    if (!(key in existingState.config) ||
                        JSON.stringify(existingState.config[key]) !== JSON.stringify(uncompressedAction.state.config[key])) {
                        const newStateValue = uncompressedAction.state.config[key];
                        newState.config[key] = newStateValue;

                        componentState[uncompressedAction.uuid].config[key] = newStateValue;
                    }
                }

                return {
                    uuid: uncompressedAction.uuid,
                    type: uncompressedAction.type,
                    shape: uncompressedAction.shape,
                    state: newState,
                };

            // VIEWPORT events have their source images compressed out
            } else if (uncompressedAction.type === AnnotateComponentActionType.VIEWPORT && uncompressedAction.state.viewportConfig) {
                uncompressedAction.state.viewportConfig.src = '';
                uncompressedAction.state.viewportConfig.crop.src = '';

                return uncompressedAction;
            // ADD events aren't compressed as they contain the initial state
            } else {
                componentState[uncompressedAction.uuid] = uncompressedAction.state;
                return uncompressedAction;
            }
        });
    }

}
