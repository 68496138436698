/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import "../core/Logger.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import "../core/Error.js";
import { enumeration as e } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
var s;
let p = s = class extends r {
  constructor(o) {
    super(o), this.type = "none";
  }
  clone() {
    return new s({
      type: this.type
    });
  }
};
o([e({
  none: "none",
  stayAbove: "stay-above"
})], p.prototype, "type", void 0), p = s = o([t("esri.ground.NavigationConstraint")], p);
export { p as NavigationConstraint };