import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ProjectJobService} from '../../services/project-job.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {FormService} from '../../services/form.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-project-job-form-pdf-btn',
    templateUrl: './project-job-form-pdf-btn.component.html'
})
export class ProjectJobFormPdfBtnComponent implements OnInit, OnDestroy {
    jobId: number | null = null;
    generatingExport = false;

    constructor(
        @Inject('ProjectJobService') private projectJobService: ProjectJobService,
        private formService: FormService,
        private toastr: ToastrService,
        private translateService: TranslateService
    ) {}

    subscription: Subscription | null = null;

    ngOnInit() {
        this.subscription = this.formService.openForm$.subscribe(form => {
            if (form) {
                this.jobId = form.id;
            }
        })
    }

    async openConceptReport() {
        this.generatingExport = true;

        // export as blob
        try {
            if (this.jobId) {
                await this.projectJobService.openJobExport(this.jobId);
            }
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                await this.toastr.error(this.translateService.instant('Exporteren van de opdracht is mislukt.'));
            }
        } finally {
            this.generatingExport = false;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

}
