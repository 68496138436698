import {FormArray} from '@angular/forms';

export class SortablejsBinding {

    constructor(private target: FormArray | Array<unknown>) {
    }

    insert(index: number, item: unknown) {
        if (this.isFormArray(this.target)) {
            this.target.insert(index, item);
        } else {
            this.target.splice(index, 0, item);
        }
    }

    get(index: number) {
        return this.isFormArray(this.target) ? this.target.at(index) : this.target[index];
    }

    remove(index: number) {
        let item;

        if (this.isFormArray(this.target)) {
            item = this.target.at(index);
            this.target.removeAt(index);
        } else {
            item = this.target.splice(index, 1)[0];
        }

        return item;
    }

    // we need this to identify that the target is a FormArray
    private isFormArray(target: unknown): target is FormArray {
        return target instanceof FormArray;
    }

}
