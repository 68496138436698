<app-question-header></app-question-header>

<form action="" (submit)="submit()">
    <app-question-body>
        <app-question-description
            *ngIf="currentQuestion?.descriptionType"
            [question]="currentQuestion"
        ></app-question-description>

        <app-tolerance-message *ngIf="tolerance" [tolerance]="tolerance"></app-tolerance-message>

        <div class="question-type mt-4 is-disabled" *ngIf="showPreviousAnswer">
            <label class="input-label">Vorig antwoord</label>

            <ng-container *ngTemplateOutlet="field; context: { form: rowControlsForTolerance }"></ng-container>
        </div>

        <div class="question-type mt-4">
            <label class="input-label">Antwoord</label>

            <ng-container *ngTemplateOutlet="field; context: { form: rowControls }"></ng-container>
        </div>
    </app-question-body>

    <app-question-footer [doGoForward]="doGoForward" [currentValue]="currentValue" [canGoForward]="isValid" (tapOnInvalidButton)="showErrorToast()"></app-question-footer>
</form>

<ng-template #field let-form="form">
    <table class="table-question__table">
        <tr *ngFor="let control of form.controls; let i = index">
            <td>
                {{ currentQuestion.questionLabel + ' ' + (i + 1) }}
            </td>
            <td>
                <input [formControl]="control" type="text" inputmode="decimal" (keydown.space)="$event.preventDefault()" />

                <span class="text-danger" *ngIf="control.errors?.required && control.touched">Antwoord verplicht</span>
                <span class="text-danger" *ngIf="control.errors?.number === false">Antwoord is geen geldig getal</span>
            </td>
        </tr>

        <ng-container *ngIf="calculateStatistics(form.value) as statistics">
            <tr *ngIf="statistics.total">
                <td>
                    <strong>Totaal:</strong>
                </td>
                <td>
                    <input type="text" inputmode="decimal" readonly [value]="statistics.total ? (statistics.total | number: '1.0-10') : '-'" />
                </td>
            </tr>
            <tr *ngIf="statistics.average">
                <td>
                    <strong>Gemiddelde:</strong>
                </td>
                <td>
                    <input type="text" inputmode="decimal" readonly [value]="statistics.average ? (statistics.average | number: '1.0-10') : '-'" />
                </td>
            </tr>
        </ng-container>
    </table>
</ng-template>
