<div class="image-annotation" [class.image-annotation--visible]="visible" #hostContainer>
    <div class="image-annotation__header">
        <button class="image-annotation__header__button" (click)="doCancel()">
            Annuleren
        </button>
        <button class="image-annotation__header__button" (click)="undo()" [disabled]="historyStep === 0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M12.5 8c-2.65 0-5.05 1-6.9 2.6L2 7v9h9l-3.62-3.62A7.95 7.95 0 0120.1 16l2.37-.78A10.51 10.51 0 0012.5 8z"/>
            </svg>
        </button>
        <button class="image-annotation__header__button" (click)="redo()" [disabled]="historyStep === history.length">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M18.4 10.6a10.5 10.5 0 00-16.86 4.62L3.9 16a8 8 0 0112.72-3.62L13 16h9V7l-3.6 3.6z"/>
            </svg>
        </button>
        <button class="image-annotation__header__button" (click)="doSave()">
            Opslaan
        </button>
    </div>
    <div class="image-annotation__container" #stageContainer>
    </div>
    <div class="image-annotation__footer">
        <div class="image-annotation__tools">
            <button class="image-annotation__tools__tool"
                    [class.image-annotation__tools__tool--active]="currentTool === 'pencil'" (click)="currentTool = 'pencil'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M20.71 7.04a1 1 0 000-1.41l-2.34-2.34a1 1 0 00-1.41 0l-1.84 1.83 3.75 3.75M3 17.25V21h3.75L17.81 9.93l-3.75-3.75L3 17.25z"/>
                </svg>
            </button>
            <button class="image-annotation__tools__tool"
                    [class.image-annotation__tools__tool--active]="currentTool === 'circle'" (click)="currentTool = 'circle'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 2A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z"/>
                </svg>
            </button>
            <button class="image-annotation__tools__tool"
                    [class.image-annotation__tools__tool--active]="currentTool === 'square'" (click)="currentTool = 'square'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M3 3v18h18V3"/>
                </svg>
            </button>
            <button class="image-annotation__tools__tool"
                    [class.image-annotation__tools__tool--active]="currentTool === 'arrow'" (click)="currentTool = 'arrow'">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19 6.41L17.59 5 7 15.59V9H5v10h10v-2H8.41L19 6.41z"/>
                </svg>
            </button>

        </div>
        <div class="image-annotation__palette">
            <button class="image-annotation__palette__color" *ngFor="let color of palette"
                    (click)="currentColor = color"
                    [style.--color]="color"
                    [class.image-annotation__palette__color--active]="currentColor === color"></button>
        </div>
    </div>
</div>
