/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "../Map.js";
import i from "../TimeExtent.js";
import r from "../core/Accessor.js";
import { createTask as a } from "../core/asyncUtils.js";
import o from "../core/Collection.js";
import s from "../core/CollectionFlattener.js";
import n from "../core/Error.js";
import l from "../core/Evented.js";
import p from "../core/Handles.js";
import { makeHandle as h } from "../core/handleUtils.js";
import "../core/has.js";
import d from "../core/Loadable.js";
import y from "../core/Logger.js";
import { destroyMaybe as c, abortMaybe as u } from "../core/maybe.js";
import { EsriPromiseMixin as m } from "../core/Promise.js";
import { after as g } from "../core/promiseUtils.js";
import { watch as f, whenOnce as w, when as v, sync as M, syncAndInitial as V } from "../core/reactiveUtils.js";
import { property as R } from "../core/accessorSupport/decorators/property.js";
import "../core/RandomLCG.js";
import { subclass as _ } from "../core/accessorSupport/decorators/subclass.js";
import { owningCollectionProperty as S } from "../core/support/OwningCollection.js";
import { UpdatingHandles as j } from "../core/support/UpdatingHandles.js";
import F from "../geometry/Extent.js";
import L from "../geometry/HeightModelInfo.js";
import O from "../geometry/SpatialReference.js";
import { equals as C } from "../geometry/support/spatialReferenceUtils.js";
import { AnalysesCollection as E } from "../support/AnalysesCollection.js";
import { GraphicsCollection as b } from "../support/GraphicsCollection.js";
import { system as T, unknown as k } from "../time/timeZoneUtils.js";
import { BasemapView as x } from "./BasemapView.js";
import Z from "./LayerViewManager.js";
import I from "./Magnifier.js";
import D from "./SelectionManager.js";
import H from "./Theme.js";
import { ToolViewManager as q } from "./ToolViewManager.js";
import P from "./input/Input.js";
import { ViewEvents as U } from "./input/ViewEvents.js";
import A from "./navigation/Navigation.js";
import { DefaultsFromMap as z } from "./support/DefaultsFromMap.js";
var W;
let G = W = class extends l.EventedMixin(m(r)) {
  constructor(e) {
    super(e), this._userSpatialReference = null, this._cursor = null, this.handles = new p(), this.updatingHandles = new j(), this.allLayerViews = new s({
      getCollections: () => [this.basemapView?.baseLayerViews, this.groundView?.layerViews, this.layerViews, this.basemapView?.referenceLayerViews],
      getChildrenFunction: B
    }), this.groundView = null, this.basemapView = null, this.fatalError = null, this.graphics = new b(), this.analyses = new E(), this.typeSpecificPreconditionsReady = !0, this.layerViews = new o(), this.magnifier = new I(), this.padding = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    }, this.ready = !1, this.spatialReferenceWarningDelay = 1e3, this.supportsGround = !0, this.timeExtent = null, this.type = null, this.scale = null, this.updating = !1, this.initialExtentRequired = !0, this.input = new P(), this.navigation = new A(), this.layerViewManager = null, this.analysisViewManager = null, this.isHeightModelInfoRequired = !1, this.width = null, this.height = null, this.resizing = !1, this.suspended = !1, this.viewEvents = new U(this), this.persistableViewModels = new o(), this._isValid = !1, this._readyCycleForced = !1, this._lockedSpatialReference = null, this._userTimeZone = null, this._lockedTimeZone = null, this.theme = null, this.handles.add(f(() => this.preconditionsReady, e => {
      const t = this.ready;
      if (e ? (this._lockedSpatialReference = this.spatialReference, this._lockedTimeZone = this.timeZone, W.views.add(this)) : (this._lockedSpatialReference = null, W.views.remove(this)), this.notifyChange("spatialReference"), !e && t) this.toolViewManager?.detach(), null != this.analysisViewManager && this.analysisViewManager.detach(), this.layerViewManager?.clear(), this._teardown();else if (e && !t) {
        try {
          this._startup();
        } catch (i) {
          return void queueMicrotask(() => {
            this.fatalError = new n("startup-error", null, i);
          });
        }
        null != this.analysisViewManager && this.analysisViewManager.attach(), this.toolViewManager.attach();
      }
    }, M));
  }
  initialize() {
    this.addResolvingPromise(Promise.all([this.loadAsyncDependencies(), this.validate()]).then(() => (this._isValid = !0, w(() => this.ready)))), this.basemapView = new x({
      view: this
    }), this.layerViewManager = new Z({
      view: this,
      layerViewImporter: {
        importLayerView: e => this.importLayerView(e),
        hasLayerViewModule: e => this.hasLayerViewModule(e)
      },
      supportsGround: this.supportsGround
    }), this.toolViewManager = new q({
      view: this
    }), this._setupSpatialReferenceLogger(), this.selectionManager = new D({
      view: this
    }), this.addHandles([f(() => this.initialExtentRequired, e => this.defaultsFromMap.required = {
      ...this.defaultsFromMap.required,
      extent: e
    }, V), f(() => this.ready, e => {
      this.defaultsFromMap && (this.defaultsFromMap.suspended = e, this.defaultsFromMap.userSpatialReference = e ? this.spatialReference : this._userSpatialReference);
    }, M), f(() => this._userSpatialReference, e => {
      this.defaultsFromMap && (this.defaultsFromMap.userSpatialReference = e);
    }, V)]);
  }
  _setupSpatialReferenceLogger() {
    let e = null;
    this.addHandles([f(() => this.defaultsFromMap?.ready, t => {
      const i = this.map?.allLayers.length > 0;
      if (t && !this.spatialReference && i) {
        if (null != e) return;
        const t = h(() => e = u(e));
        e = a(async t => {
          try {
            await g(this.spatialReferenceWarningDelay, null, t);
          } catch {
            return;
          } finally {
            e = null;
          }
          y.getLogger(this).warn("#spatialReference", "no spatial reference could be derived from the currently added map layers");
        }), this.addHandles(t, "spatial-reference-logger-task");
      } else this.removeHandles("spatial-reference-logger-task");
    }, {
      sync: !0
    })]);
  }
  destroy() {
    this.destroyed || (W.views.remove(this), this.viewEvents.destroy(), this.allLayerViews.destroy(), this.navigation && (this.navigation.destroy(), this._set("navigation", null)), this.graphics = c(this.graphics), this.analyses = c(this.analyses), this.defaultsFromMap.destroy(), this._set("defaultsFromMap", null), c(this.analysisViewManager), this.toolViewManager = c(this.toolViewManager), this.layerViewManager = c(this.layerViewManager), this.selectionManager = c(this.selectionManager), this.basemapView = c(this.basemapView), this.groundView?.destroy(), this.layerViews?.forEach(e => e.destroy()), this.layerViews.length = 0, this.invalidate(), this._emitter.clear(), this.handles.destroy(), this.map = c(this.map), this.updatingHandles.destroy());
  }
  _startup() {
    this._set("ready", !0);
  }
  _teardown() {
    this._set("ready", !1);
  }
  whenReady() {
    return Promise.resolve(this);
  }
  toMap() {
    return y.getLogger(this).error("#toMap()", "Not implemented on this instance of View"), null;
  }
  get activeTool() {
    return this.toolViewManager?.activeTool;
  }
  set activeTool(e) {
    this.toolViewManager && (this.toolViewManager.activeTool = e);
  }
  get animation() {
    return this._get("animation");
  }
  set animation(e) {
    this._set("animation", e);
  }
  get center() {
    return null;
  }
  get _defaultsFromMapSettings() {
    return {};
  }
  get defaultsFromMap() {
    return new z({
      required: {
        tileInfo: !1,
        heightModelInfo: !1,
        extent: !1
      },
      map: () => this.map,
      getSpatialReferenceSupport: (e, t) => this.getSpatialReferenceSupport(e, t),
      ...this._defaultsFromMapSettings
    });
  }
  get extent() {
    return this._get("extent");
  }
  set extent(e) {
    this._set("extent", e);
  }
  get heightModelInfo() {
    return this.getDefaultHeightModelInfo();
  }
  get interacting() {
    return this.navigating;
  }
  get navigating() {
    return !1;
  }
  get preconditionsReady() {
    return !(this.fatalError || !this._isValid || this._readyCycleForced || !this.map || d.isLoadable(this.map) && !this.map.loaded || 0 === this.width || 0 === this.height || !this.spatialReference || !this._validateSpatialReference(this.spatialReference) || !this._lockedSpatialReference && !this.defaultsFromMap?.ready || !this.typeSpecificPreconditionsReady);
  }
  get resolution() {
    return 0;
  }
  set map(e) {
    e !== this._get("map") && (e?.destroyed && (y.getLogger(this).warn("#map", "The provided map is already destroyed", {
      map: e
    }), e = null), d.isLoadable(e) && e.load().catch(() => {}), this.constructed && !this.destroyed && (this.forceReadyCycle(), this._lockedSpatialReference = null), this._set("map", e));
  }
  get spatialReference() {
    const e = this._userSpatialReference || this._lockedSpatialReference || this.getDefaultSpatialReference() || null;
    if (e && this.defaultsFromMap?.required?.heightModelInfo) {
      const t = e.clone();
      return t.vcsWkid = this.defaultsFromMap.vcsWkid, t.latestVcsWkid = this.defaultsFromMap.latestVcsWkid, t;
    }
    return e;
  }
  set spatialReference(e) {
    const t = !C(e, this._get("spatialReference"));
    this._set("_userSpatialReference", e), t && (this._set("spatialReference", e), this._spatialReferenceChanged(e));
  }
  _spatialReferenceChanged(e) {}
  get stationary() {
    return !this.animation && !this.navigating && !this.resizing;
  }
  get timeZone() {
    return this._userTimeZone ?? this._lockedTimeZone ?? this.getDefaultTimeZone() ?? T;
  }
  set timeZone(e) {
    const t = new Set(["etc/utc", "etc/gmt", "gmt"]),
      i = new Set(Intl.supportedValuesOf("timeZone").map(e => e.toLowerCase()));
    this._userTimeZone = e, e === T || e === k || t.has(e.toLowerCase()) || i.has(e.toLowerCase()) || y.getLogger(this).warn("#timeZone", `the parsed value '${e}' may not be a valid IANA time zone`);
  }
  get tools() {
    return this.toolViewManager?.tools;
  }
  get initialExtent() {
    return this.defaultsFromMap?.extent;
  }
  get cursor() {
    return this.toolViewManager?.cursor ?? this._cursor ?? "default";
  }
  set cursor(e) {
    this._cursor = e, this.notifyChange("cursor");
  }
  get size() {
    return [this.width, this.height];
  }
  get effectiveTheme() {
    return this.theme ?? new H();
  }
  whenLayerView(e) {
    return this.layerViewManager?.whenLayerView(e) ?? Promise.reject();
  }
  getDefaultSpatialReference() {
    return this.defaultsFromMap?.spatialReference;
  }
  getDefaultHeightModelInfo() {
    return (this.map && "heightModelInfo" in this.map ? this.map.heightModelInfo : void 0) ?? this.defaultsFromMap?.heightModelInfo ?? null;
  }
  getDefaultTimeZone() {
    return null;
  }
  importLayerView(e) {
    throw new n("importLayerView() not implemented");
  }
  hasLayerViewModule(e) {
    return !1;
  }
  async validate() {}
  async loadAsyncDependencies() {}
  invalidate() {
    this._isValid = !1;
  }
  getSpatialReferenceSupport() {
    return {
      constraints: null
    };
  }
  _validateSpatialReference(e) {
    return null != this.getSpatialReferenceSupport(e);
  }
  when(e, t) {
    return this.isResolved() && !this.ready && y.getLogger(this).warn("#when()", "Calling view.when() while the view is no longer ready but was already resolved once will resolve immediately. Use reactiveUtils.whenOnce(() => view.ready).then(...) instead."), super.when(e, t);
  }
  forceReadyCycle() {
    this.ready && (v(() => this.destroyed || !1 === this.preconditionsReady, () => this._readyCycleForced = !1, {
      once: !0
    }), this._readyCycleForced = !0);
  }
  addAndActivateTool(e) {
    this.toolViewManager.tools.add(e), this.activeTool = e;
  }
  tryFatalErrorRecovery() {
    this.fatalError = null;
  }
};
G.views = new o(), e([R()], G.prototype, "_userSpatialReference", void 0), e([R()], G.prototype, "activeTool", null), e([R({
  readOnly: !0
})], G.prototype, "allLayerViews", void 0), e([R()], G.prototype, "groundView", void 0), e([R()], G.prototype, "animation", null), e([R()], G.prototype, "basemapView", void 0), e([R()], G.prototype, "center", null), e([R({
  readOnly: !0
})], G.prototype, "_defaultsFromMapSettings", null), e([R()], G.prototype, "defaultsFromMap", null), e([R()], G.prototype, "fatalError", void 0), e([R({
  type: F
})], G.prototype, "extent", null), e([R(S(b, "graphics"))], G.prototype, "graphics", void 0), e([R(S(E, "analyses"))], G.prototype, "analyses", void 0), e([R({
  readOnly: !0,
  type: L
})], G.prototype, "heightModelInfo", null), e([R({
  readOnly: !0
})], G.prototype, "interacting", null), e([R({
  readOnly: !0
})], G.prototype, "navigating", null), e([R({
  readOnly: !0,
  dependsOn: ["fatalError", "_isValid", "_readyCycleForced", "map", "map.loaded?", "width", "height", "spatialReference", "_lockedSpatialReference", "defaultsFromMap.ready", "typeSpecificPreconditionsReady"]
})], G.prototype, "preconditionsReady", null), e([R({
  readOnly: !0
})], G.prototype, "typeSpecificPreconditionsReady", void 0), e([R({
  type: o,
  readOnly: !0
})], G.prototype, "layerViews", void 0), e([R()], G.prototype, "resolution", null), e([R({
  type: I
})], G.prototype, "magnifier", void 0), e([R({
  value: null,
  type: t
})], G.prototype, "map", null), e([R()], G.prototype, "padding", void 0), e([R({
  readOnly: !0
})], G.prototype, "ready", void 0), e([R({
  type: O
})], G.prototype, "spatialReference", null), e([R()], G.prototype, "spatialReferenceWarningDelay", void 0), e([R()], G.prototype, "stationary", null), e([R({
  readOnly: !0
})], G.prototype, "supportsGround", void 0), e([R({
  type: i
})], G.prototype, "timeExtent", void 0), e([R({
  type: String,
  nonNullable: !0
})], G.prototype, "timeZone", null), e([R()], G.prototype, "tools", null), e([R()], G.prototype, "toolViewManager", void 0), e([R({
  readOnly: !0
})], G.prototype, "type", void 0), e([R({
  type: Number
})], G.prototype, "scale", void 0), e([R({
  readOnly: !0
})], G.prototype, "updating", void 0), e([R({
  readOnly: !0
})], G.prototype, "initialExtentRequired", void 0), e([R({
  readOnly: !0
})], G.prototype, "initialExtent", null), e([R()], G.prototype, "cursor", null), e([R({
  readOnly: !0
})], G.prototype, "input", void 0), e([R({
  type: A,
  nonNullable: !0
})], G.prototype, "navigation", void 0), e([R()], G.prototype, "layerViewManager", void 0), e([R()], G.prototype, "analysisViewManager", void 0), e([R()], G.prototype, "selectionManager", void 0), e([R()], G.prototype, "width", void 0), e([R()], G.prototype, "height", void 0), e([R({
  readOnly: !0
})], G.prototype, "resizing", void 0), e([R({
  value: null,
  readOnly: !0
})], G.prototype, "size", null), e([R({
  readOnly: !0
})], G.prototype, "suspended", void 0), e([R({
  readOnly: !0
})], G.prototype, "viewEvents", void 0), e([R({
  readOnly: !0
})], G.prototype, "persistableViewModels", void 0), e([R()], G.prototype, "_isValid", void 0), e([R()], G.prototype, "_readyCycleForced", void 0), e([R()], G.prototype, "_lockedSpatialReference", void 0), e([R()], G.prototype, "_userTimeZone", void 0), e([R()], G.prototype, "_lockedTimeZone", void 0), e([R({
  type: H
})], G.prototype, "theme", void 0), e([R({
  readOnly: !0,
  type: H
})], G.prototype, "effectiveTheme", null), G = W = e([_("esri.views.View")], G);
const N = G;
function B(e) {
  return e.layerViews;
}
export { N as default };