<app-question-header></app-question-header>
<app-question-body>
    <app-question-description
        *ngIf="currentQuestion?.descriptionType"
        [question]="currentQuestion"
    ></app-question-description>


    <div class="question-type">
        <button type="button" class="qr-code-scanner__add-answer mb" (click)="scanQrCode()">
            <app-icon iconName="qr-code-scan"></app-icon>
            {{ 'Scan QR- of barcode'|translate }}
        </button>

        <ng-container *ngIf="this.control.value">
            <div class="input-label">{{ 'Antwoord'|translate }}</div>
            <textarea [value]="this.control.value" [readOnly]="true" [disabled]="true"></textarea>
            <button (click)="clearAnswer()" type="button" class="qr-code-scanner__clear-answer">{{ 'Antwoord wissen'|translate }}</button>
        </ng-container>
    </div>
</app-question-body>

<app-question-footer [currentValue]="currentValue" [canGoForward]="control.valid" (tapOnInvalidButton)="showToastError()"></app-question-footer>

