"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Factory = void 0;
const Util_1 = require("./Util");
const Validators_1 = require("./Validators");
const GET = 'get',
  SET = 'set';
exports.Factory = {
  addGetterSetter(constructor, attr, def, validator, after) {
    exports.Factory.addGetter(constructor, attr, def);
    exports.Factory.addSetter(constructor, attr, validator, after);
    exports.Factory.addOverloadedGetterSetter(constructor, attr);
  },
  addGetter(constructor, attr, def) {
    const method = GET + Util_1.Util._capitalize(attr);
    constructor.prototype[method] = constructor.prototype[method] || function () {
      const val = this.attrs[attr];
      return val === undefined ? def : val;
    };
  },
  addSetter(constructor, attr, validator, after) {
    const method = SET + Util_1.Util._capitalize(attr);
    if (!constructor.prototype[method]) {
      exports.Factory.overWriteSetter(constructor, attr, validator, after);
    }
  },
  overWriteSetter(constructor, attr, validator, after) {
    const method = SET + Util_1.Util._capitalize(attr);
    constructor.prototype[method] = function (val) {
      if (validator && val !== undefined && val !== null) {
        val = validator.call(this, val, attr);
      }
      this._setAttr(attr, val);
      if (after) {
        after.call(this);
      }
      return this;
    };
  },
  addComponentsGetterSetter(constructor, attr, components, validator, after) {
    let len = components.length,
      capitalize = Util_1.Util._capitalize,
      getter = GET + capitalize(attr),
      setter = SET + capitalize(attr),
      n,
      component;
    constructor.prototype[getter] = function () {
      const ret = {};
      for (n = 0; n < len; n++) {
        component = components[n];
        ret[component] = this.getAttr(attr + capitalize(component));
      }
      return ret;
    };
    const basicValidator = (0, Validators_1.getComponentValidator)(components);
    constructor.prototype[setter] = function (val) {
      let oldVal = this.attrs[attr],
        key;
      if (validator) {
        val = validator.call(this, val);
      }
      if (basicValidator) {
        basicValidator.call(this, val, attr);
      }
      for (key in val) {
        if (!val.hasOwnProperty(key)) {
          continue;
        }
        this._setAttr(attr + capitalize(key), val[key]);
      }
      if (!val) {
        components.forEach(component => {
          this._setAttr(attr + capitalize(component), undefined);
        });
      }
      this._fireChangeEvent(attr, oldVal, val);
      if (after) {
        after.call(this);
      }
      return this;
    };
    exports.Factory.addOverloadedGetterSetter(constructor, attr);
  },
  addOverloadedGetterSetter(constructor, attr) {
    const capitalizedAttr = Util_1.Util._capitalize(attr),
      setter = SET + capitalizedAttr,
      getter = GET + capitalizedAttr;
    constructor.prototype[attr] = function () {
      if (arguments.length) {
        this[setter](arguments[0]);
        return this;
      }
      return this[getter]();
    };
  },
  addDeprecatedGetterSetter(constructor, attr, def, validator) {
    Util_1.Util.error('Adding deprecated ' + attr);
    const method = GET + Util_1.Util._capitalize(attr);
    const message = attr + ' property is deprecated and will be removed soon. Look at Konva change log for more information.';
    constructor.prototype[method] = function () {
      Util_1.Util.error(message);
      const val = this.attrs[attr];
      return val === undefined ? def : val;
    };
    exports.Factory.addSetter(constructor, attr, validator, function () {
      Util_1.Util.error(message);
    });
    exports.Factory.addOverloadedGetterSetter(constructor, attr);
  },
  backCompat(constructor, methods) {
    Util_1.Util.each(methods, function (oldMethodName, newMethodName) {
      const method = constructor.prototype[newMethodName];
      const oldGetter = GET + Util_1.Util._capitalize(oldMethodName);
      const oldSetter = SET + Util_1.Util._capitalize(oldMethodName);
      function deprecated() {
        method.apply(this, arguments);
        Util_1.Util.error('"' + oldMethodName + '" method is deprecated and will be removed soon. Use ""' + newMethodName + '" instead.');
      }
      constructor.prototype[oldMethodName] = deprecated;
      constructor.prototype[oldGetter] = deprecated;
      constructor.prototype[oldSetter] = deprecated;
    });
  },
  afterSetFilter() {
    this._filterUpToDate = false;
  }
};